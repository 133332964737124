import { post, put, remove } from "../core";

export const createWarehouse = async (body: any) => {
    try {
        const res = await post('/warehouse', body);
        return res.data;
    } catch (err) {
        return Promise.reject(err)
    }
}

export const updateWarehouse = async (id: any, body: any) => {
    try {
        const res = await put(`/warehouse/edit/${id}`, body);
        return res.data;
    } catch (err) {
        return Promise.reject(err)
    }
}

export const deleteWarehouse = async (id: any) => {
    try {
        const res = await remove(`/warehouse/delete/${id}`);
        return res.data;
    } catch (err) {
        return Promise.reject(err)
    }
}