import { post, put, remove } from '../core';

export const addExpressRateCard = async(body: any) => {
    try{
        const res = await post('/create/express/rate-card', body);
        return res.data
    }catch(err){
        return Promise.reject(err);
    }
}

export const addPartialRateCard = async(body: any) => {
    try{
        const res = await post('/create/partial-load/rate-card', body);
        return res.data;
    }catch(err){
        return Promise.reject(err);
    }
} 

export const updateExpressRateSettings = async (rateSettingsId: string, rateExpressId: string, updatedDetails: any) => {
    try{
        const res = await put(`/update/express-rate/${rateExpressId}/rate-settings/${rateSettingsId}`, updatedDetails);
        return res.data;
    }catch(err){
        return Promise.reject(err);
    }
}

export const updateExpressRateCard = async(rateExpressId: string, body: any) => {
    try{
        const res = await put(`/update/express/rate-card/${rateExpressId}`, body)
        return res.data;
    }catch(err){
        return Promise.reject(err)
    }
}

export const deleteExpressRateCard = async(rateExpressId: string) => {
    try{
        const res = await remove(`/delete/express/rate-card/${rateExpressId}`)
        return res.data;
    }catch(err){
        return Promise.reject(err)
    }
}

export const updatePartialRateCard = async(ratePartialId: string, body: any) => {
    try{
        const res = await put(`/update/partial-load/rate-card/${ratePartialId}`, body)
        return res.data;
    }catch(err){
        return Promise.reject(err)
    }
}

export const deletePartialRateCard = async(ratePartialId: string) => {
    try{
        const res = await remove(`/delete/partial-load/rate-card/${ratePartialId}`)
        return res.data;
    }catch(err){
        return Promise.reject(err)
    }
}

export const addParcelRateCard = async(body: any) => {
    try{
        const res = await post('/create/parcel/rate-card', body);
        return res.data;
    }catch(err){
        return Promise.reject(err)
    }
}

export const addParcelRateSettings = async(rateParcelId: string, body: any) => {
    try{
        const res = await post(`/add/parcel-rate/${rateParcelId}/rate-settings`, body);
        return res.data;
    }catch(err){
        return Promise.reject(err);
    }
}

export const updateParcelRateSettings = async(rateParcelId: string, rateSettingsId: string, updatedDetails: any) => {
    try{
        const res = await put(`/update/parcel-rate/${rateParcelId}/rate-settings/${rateSettingsId}`, updatedDetails);
        return res.data;
    }catch(err){
        return Promise.reject(err);
    }
}

export const updateParcelRateCard = async(rateParcelId: string, body: any) => {
    try{
        const res = await put(`/update/parcel/rate-card/${rateParcelId}`, body)
        return res.data;
    }catch(err){
        return Promise.reject(err)
    }
}

export const deleteParcelRateCard = async(rateParcelId: string) => {
    try{
        const res = await remove(`/delete/parcel/rate-card/${rateParcelId}`)
        return res.data;
    }catch(err){
        return Promise.reject(err)
    }
}

export const addPartialSettings = async(ratePartialLoadId: any, body: any) => {
    try{
        const res = await post(`/add/partial-load/${ratePartialLoadId}/rate-settings`, body);
        return res.data;
    }catch(err){
        return Promise.reject(err);
    }
}

export const updatePartialSettings = async(ratePartialLoadId: any,rateSettingId: any, body: any) => {
    try{
        const res = await put(`/update/partial-load/${ratePartialLoadId}/rate-settings/${rateSettingId}`, body);
        return res.data;
    }catch(err){
        return Promise.reject(err);
    }
}

export const deletePartialSettings = async(ratePartialLoadId: any,rateSettingId: any) => {
    try{
        const res = await remove(`/delete/partial-load/${ratePartialLoadId}/rate-settings/${rateSettingId}`);
        return res.data;
    }catch(err){
        return Promise.reject(err);
    }
}

export const addExpressSettings = async(rateExpressId: any, body: any) => {
    try{
        const res = await post(`/add/express/${rateExpressId}/rate-settings`, body);
        return res.data;
    }catch(err){
        return Promise.reject(err);
    }
}

export const deleteExpressSettings = async(rateExpressId: any,rateSettingId: any) => {
    try{
        const res = await remove(`/delete/express/${rateExpressId}/rate-settings/${rateSettingId}`);
        return res.data;
    }catch(err){
        return Promise.reject(err);
    }
}

export const deleteParcelSettings = async(rateParcelId: any,rateSettingId: any) => {
    try{
        const res = await remove(`/delete/parcel-rate/${rateParcelId}/rate-settings/${rateSettingId}`);
        return res.data;
    }catch(err){
        return Promise.reject(err);
    }
}

export const addInterislandSettings = async(rateInterislandId: any, body: any) => {
    try{
        const res = await post(`/add/interisland/${rateInterislandId}/rate-settings`, body);
        return res.data;
    }catch(err){
        return Promise.reject(err);
    }
}

export const addInterislandRateCard = async(body: any) => {
    try{
        const res = await post('/create/interisland/rate-card', body);
        return res.data
    }catch(err){
        return Promise.reject(err);
    }
}

export const deleteInterislandSettings = async(rateInterislandId: any,rateSettingId: any) => {
    try{
        const res = await remove(`/delete/interisland/${rateInterislandId}/rate-settings/${rateSettingId}`);
        return res.data;
    }catch(err){
        return Promise.reject(err);
    }
}

export const updateInterislandRateSettings = async (rateSettingsId: string, rateInterislandId: string, updatedDetails: any) => {
    try{
        const res = await put(`/update/interisland-rate/${rateInterislandId}/rate-settings/${rateSettingsId}`, updatedDetails);
        return res.data;
    }catch(err){
        return Promise.reject(err);
    }
}

export const updateinterislandRateCard = async(rateInterislandId: string, body: any) => {
    try{
        const res = await put(`/update/interisland/rate-card/${rateInterislandId}`, body)
        return res.data;
    }catch(err){
        return Promise.reject(err)
    }
}

export const deleteInterislandRateCard = async(rateInterislandId: string) => {
    try{
        const res = await remove(`/delete/interisland/rate-card/${rateInterislandId}`)
        return res.data;
    }catch(err){
        return Promise.reject(err)
    }
}