import { alpha, Box, styled } from '@mui/material'
import SimpleBar from 'simplebar-react';
import { BrowserView, MobileView } from 'react-device-detect';
import React from 'react'

//root style
const RootStyle = styled(BrowserView)({
    flexGrow: 1,
    height: 'calc(100% - 160px)',
    overflow: 'hidden',
})

//scrollbar wrapper
const SimpleBarStyle = styled(SimpleBar)(({ theme }) => ({
    maxHeight: '100%',
    '& .simplebar-scrollbar': {
        '&:before': {
            backgroundColor: alpha(theme.palette.grey[500], 0.48),
        },
        '& .simplebar-visible:before': {
            opacity: 1
        }
    },
    '& .simplebar-track.simplebar-vertical': {
        width: 10
    },
    '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
        height: 6
    },
    '& .simple-mask': {
        zIndex: 'inherit'
    }
}))


const SimpleBarScroll = ({ children, sx, ...other }) => {
    return (
        <>
            <RootStyle>
                <SimpleBarStyle timeout={500} clickOnTrack={false} sx={sx} {...other}>
                    {children}
                </SimpleBarStyle>
            </RootStyle>
            <MobileView>
                <Box sx={{overflowX: 'auto', ...sx}} {...other}>
                    {children}
                </Box>
            </MobileView>
        </>
    )
}

export default SimpleBarScroll