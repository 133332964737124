import { useEffect, useState } from 'react'   
import { SpanContainer, Span } from '../../../../components/common/Span';  
import { useTable, useFilters, useSortBy, usePagination, useRowSelect } from 'react-table' 
import { Dispatch, RootState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { BiSortDown, BiSortUp } from 'react-icons/bi'
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md'

import { ColumnFilter } from '../../../../components/Table/ColumnFilter'
import '../../../../components/Table/Table.css'
import './Checkbox'
import Checkbox from '../Table/Checkbox';

const Table = ({ columns, data }: any) => {
    const [select, setSelect] = useState(0);
    const [selected, setSelected] = useState([]) as any;
    const dispatch = useDispatch<Dispatch>();
    const tableInstance = useTable({ 
        columns, 
        data, 
        defaultColumn: { Filter: ColumnFilter },
        initialState: {
            sortBy: [
                {
                    id: 'createdAt',    
                    desc: true
                }
            ]
        } 
    },
        useFilters, useSortBy, usePagination,
        useRowSelect,
        hooks => {
        hooks.visibleColumns.push(columns => [ 
            // Let's make a column for selection
            {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
                <div onClick={() => setSelect(select + 1)}>
                    <Checkbox {...getToggleAllRowsSelectedProps()} />
                </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) =>(
                <div onClick={() => setSelect(select + 1)}>
                    <Checkbox {...row.getToggleRowSelectedProps()} />
                </div>
            ),
            },
            ...columns,
        ])
        } 
    ) 
    

    const { 
        getTableProps, 
        getTableBodyProps,
        headerGroups, 
        nextPage, 
        pageOptions,
        previousPage,
        setPageSize,
        state, 
        setHiddenColumns,
        rows,
        prepareRow,
        selectedFlatRows,
    } = tableInstance

    const { pageIndex, pageSize, selectedRowIds } = state

    useEffect(() => {
        console.log(columns.filter(column => !column.isVisible));
        setHiddenColumns(
          columns.filter(column => !column.isVisible).map(column => column.accessor)
        );
      }, [setHiddenColumns, columns]); 
      
    const firstPageRows = rows.slice(0,10)

    useEffect(() => {
        const ids: any = selectedFlatRows.map(item => item.original.id)
        dispatch.UI.updateFormId({target: 'editRequestWallet', value: ids})
    },[select]);
    
    return (<>  
    <table {...getTableProps()} border='0' cellSpacing='0' cellPadding='0' className='w-full'>
        <thead>
            {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}> 
                    <div className='flex justify-center font-semibold px-3.5 whitespace-nowrap'>
                        {column.render('Header')}   
                            {column.isSorted
                            ? column.isSortedDesc
                                ? <BiSortDown className='my-auto text-lg mx-2'/>
                                : <BiSortUp className='my-auto text-lg mx-2'/>
                            : ''} 
                    </div>
                    <div className='pt-2 mx-2'>
                        {column.canFilter ? column.render('Filter') : null}
                    </div> 
                </th>   
                ))}
            </tr>
            ))}
        </thead>
        
        <tbody {...getTableBodyProps()}> 
            {firstPageRows.map((row) => {
            prepareRow(row);
            return (
                <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                        return <td {...cell.getCellProps()} className='text-center font-semibold'>{cell.render('Cell')}</td>;
                    })}
                </tr>
            );
            })}
        </tbody>
    </table>  

      <pre>
        <code>
        </code>
      </pre> 
     
    <div className='flex  justify-end p-4'> 
        <div className='px-4'>
            <SpanContainer color='grey'>Rows per page: </SpanContainer>
            <select
                className='px-1.5 text-base focus:outline-none text-grey-7'
                value={pageSize} 
                onChange={(e) => setPageSize(Number(e.target.value))}>
                    {[10,25,50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
            </select> 
        </div>

        <SpanContainer padding='px-4' color='grey'>  
            {pageIndex + 1}-{pageSize} of {pageOptions.length}
        </SpanContainer> 

        <div className='flex my-auto'>
            <SpanContainer padding='pr-2'>
                <MdArrowBackIosNew onClick={() => previousPage()} className='text-grey-4 cursor-auto'/>  
            </SpanContainer>  
            <SpanContainer>
                <MdArrowForwardIos onClick={() => nextPage()} className='text-grey-4 cursor-auto'/>
            </SpanContainer> 
        </div> 
        </div>  
    </>);
   }


export default Table;

export const handleMultiSortBy = (column, setSortBy, meinSortBy) => {
    //set sort desc, aesc or none?
    const desc =
      column.isSortedDesc === true
        ? undefined
        : column.isSortedDesc === false
        ? true
        : false;
    setSortBy([{ id: column.id, desc }, ...meinSortBy]);
  };