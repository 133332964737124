/* eslint-disable import/no-anonymous-default-export */

import { useState, useEffect, useRef } from 'react';

import { Span, SpanContainer } from '../../components/common/Span';
import { InputContainer } from '../../components/common/Containers';
import { Input } from '../../components/common/Input';
import { Button } from '../../components/common/Button';

import { IoCloseSharp } from 'react-icons/io5';
import Loader from '../../components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import PostErrorAlert from '../../components/PostErrorAlert';
import BackDrop from '../../components/BackDrop';

import { updateAdminUser, verifyAdminUserSetPassword } from '../../common/api';
import Switch from '../../components/Switch/Switch';

type Props = {
  setEditUser: Function;
};

export default ({ setEditUser, ...props }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const refSwitch: any = useRef(null);

  const editUserId: any = useSelector(
    (state: RootState) => state.UI.forms.editUser?.id ?? null
  );
  const users = useSelector(
    (state: RootState) => state.Table.tables.users?.data
  );
  const user =
    users.length > 0 && users.filter((user) => user.id === editUserId)[0];
  const roles = useSelector(
    (state: RootState) => state.Table.tables.roles.data
  );
  // const roles_names = roles?.length > 0 && roles?.map(role => role.roleName);

  const [verified, setVerified] = useState(false);
  let [data, setData] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [active, setActive] = useState(
    user?.status === 'active' ? true : false
  );

  const [updatedUser, setUpdatedUser] = useState({
    firstName: user?.firstName,
    middleName: user?.middleName,
    lastName: user?.lastName,
    suffix: user?.suffix,
    phoneNumber: user?.phoneNumber?.slice(3),
    email: user?.email,
    //employeeNumber: user?.employeeNumber,
    role: user?.role?.id,
  }) as any;

  const handleChange = (e, name) => {
    console.log(e.target.value);
    setUpdatedUser({
      ...updatedUser,
      [name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      ...updatedUser,
      status: active ? 'active' : 'inactive',
      role: roles?.filter((Role) => Role?.id === updatedUser?.role)[0],
      roleId: user.role,
    };
    try {
      dispatch.UI.setIsLoading(true);
      const res = await updateAdminUser({ id: editUserId, payload });
      if (res?.isSuccess) {
        dispatch.UI.updateFormId({ target: 'editUser', value: null });
        dispatch.UI.setAlert({ message: 'User updated', type: 'Success' });
        dispatch.UI.setIsLoading(false);
        dispatch.Table.getFirebaseData({ name: 'admin_profile', table: 'users' })
      }
    } catch (err: any) {
      let errMsg = err?.data?.error ?? 'Unable to update user';
      dispatch.UI.setIsLoading(false);
      setOpenAlert(true);
      setErrMessage(errMsg);
    }
  };

  const isVerified = async () => {
    const res = await verifyAdminUserSetPassword(editUserId);
    if (res.isPasswordSet) {
      setVerified(true);
    } else {
      setVerified(false);
    }
  };

  useEffect(() => {
    isVerified();
  }, []);

  useEffect(() => {
    dispatch.Table.getFirebaseData({ name: 'role', table: 'roles' });
  }, []);

  useEffect(() => {
    if (active) {
      refSwitch?.current?.click();
    }
  }, []);
  return (
    <>
      <div className="side absolute inset-y-0 right-0 px-0">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="flex py-4 border-b border-grey-6">
              <IoCloseSharp
                onClick={() => {
                  dispatch.UI.updateFormId({
                    target: 'editUser',
                    value: null,
                  });
                }}
                // onClick={() => window.location.reload()}
                className="my-auto mx-2 text-lg cursor-pointer"
              />
              <Span weight="semi" size="lg">
                Edit User
              </Span>
            </div>
            <BackDrop />
            <form className="mt-4" onSubmit={handleSubmit}>
              <div className="text-left py-4 mt-2">
                <Span weight="semi" size="lg">
                  Profile
                </Span>

                <Span margin="mt-4">First Name</Span>
                <Input
                  placeholder="First Name"
                  value={updatedUser?.firstName}
                  onChange={(e) => handleChange(e, 'firstName')}
                  required
                />

                <Span margin="mt-4">Middle Name</Span>
                <Input
                  placeholder="Middle Name (optional)"
                  onChange={(e) => handleChange(e, 'middleName')}
                  value={updatedUser?.middleName}
                />

                <Span margin="mt-4">Last Name</Span>
                <Input
                  placeholder="Last Name"
                  onChange={(e) => handleChange(e, 'lastName')}
                  value={updatedUser?.lastName}
                  required
                />

                <Span margin="mt-4">Suffix</Span>
                <Input
                  placeholder="Suffix (optional)"
                  onChange={(e) => handleChange(e, 'suffix')}
                  value={updatedUser?.suffix}
                />
              </div>

              <div className="text-left py-4 mt-2">
                <Span weight="semi" size="lg">
                  Contact Details
                </Span>

                <Span margin="mt-4">Phone Number</Span>
                <InputContainer margin="mt-4">
                  <SpanContainer padding="p-2" position="absolute">
                    +63
                  </SpanContainer>
                  <Input
                    padding="pl-10"
                    type="phone"
                    maxLength={10}
                    minLength={10}
                    placeholder="Phone Number"
                    onChange={(e) => handleChange(e, 'phoneNumber')}
                    value={updatedUser?.phoneNumber}
                    required
                  />
                </InputContainer>

                <Span margin="mt-4">Email Address</Span>
                <Input
                  placeholder="Email Address"
                  type="email"
                  onChange={(e) => handleChange(e, 'email')}
                  value={updatedUser?.email}
                  required
                />

                {/* <Span margin="mt-4">Employee Number</Span>
                <Input
                  placeholder="Employee Number"
                  type="text"
                  onChange={(e) => handleChange(e,"employeeNumber")} 
                  value={updatedUser?.employeeNumber}
                  required
                  disabled
                /> */}

                <Span margin="mt-4">Role</Span>
                <select
                  className="w-full rounded text-base focus:outline-none border border-grey-1 p-2 text-grey-7"
                  onChange={(e) => handleChange(e, 'role')}
                  defaultValue={updatedUser?.role}
                >
                  <option disabled selected>
                    Choose role
                  </option>
                  {(roles || []).map((role, index) => (
                    <option value={role?.id} key={index}>
                      {role?.roleName}
                    </option>
                  ))}
                </select>

                <PostErrorAlert
                  openAlert={openAlert}
                  setOpenAlert={setOpenAlert}
                  message={errMessage}
                />

                <Span margin="mt-4">Status</Span>
                {/* <Span margin='mt-4' border={verified ? 'green' : 'red'} color={verified ? 'green' : 'red'} padding='p-2' radius='rounded'>{verified ? 'Active' : 'Inactive'}</Span> */}
                <Switch
                  refSwitch={refSwitch}
                  active={active}
                  setActive={setActive}
                />
              </div>

              <div className="flex inset-x-0 bottom-0 py-4">
                <Button
                  margin="mx-2"
                  bg="white"
                  border="lightgrey"
                  color="black"
                  onClick={() =>
                    dispatch.UI.updateFormId({
                      target: 'editUser',
                      value: null,
                    })
                  }
                >
                  Cancel
                </Button>
                <Button margin="mx-2" type="submit">
                  Update User
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </>
  );
};
