import { useHistory } from 'react-router-dom';
import { Dispatch } from '../../../store';
import { useDispatch } from 'react-redux';

import { updateExpressStatus } from '../../../common/api';
import { ModalContainer } from '../../../components/common/Containers'
import { Button } from '../../../components/common/Button' 
import { Span } from '../../../components/common/Span'  
import questionIcon from '../../../images/Qmark_icon.png'
    
type Props = {
    setOngoingModal: Function,
    ongoingModal: boolean,
    orderId: string 
} 
  
export default ({setOngoingModal, ongoingModal, orderId, ...props}: Props) => { 

    const history = useHistory();
    const dispatch = useDispatch<Dispatch>();

    const handleOngoing = async() => {
        setOngoingModal(false);
        try{
            dispatch.UI.setIsLoading(true);
            const res = await updateExpressStatus("on_going", orderId);
            dispatch.UI.setIsLoading(false);
            if(res.isSuccess){
                dispatch.UI.setAlert({message: `Order Number Ongoing`, type: 'Success'})
            }
        }catch(err){
            dispatch.UI.setIsLoading(false);
            dispatch.UI.setAlert({message: 'Unable to update booking!', type: 'Error'})
        }
    }
      
    return (<>   
    {ongoingModal && (
    <>
        <ModalContainer>  
            <div className="px-4 py-6 whitespace-normal"> 
                <div className='flex'> 
                    <img src={questionIcon} alt={questionIcon} className='h-8 mr-2' />
                    <div className='text-left'>  
                        <div className='flex'>
                            <Span size='lg' weight='bold'>Are you sure you want to change the status&nbsp;</Span>
                            <Span size='lg' weight='bold' color='brown'>Ongoing</Span>
                            <Span weight='bold'> ?</Span>
                        </div>  

                        <Span color='lightgrey'>
                            Note: You cannot undo this action
                        </Span> 
                    </div>
                </div>
                
                
                <div className='flex justify-end my-4 mx-2'> 
                    <Button width='none' border='lightgrey' color='darkgrey' padding='px-8' bg='white' margin='mx-2'
                        onClick={() => setOngoingModal(false)}
                    >
                        No
                    </Button>  
                    
                    <Button width='none' padding='px-8' onClick={handleOngoing}>
                        Yes
                    </Button>
                </div> 
            </div> 
        </ModalContainer> 
    
        <div className="opacity-60 fixed inset-0 z-40 bg-black-1"></div>
    </>
  )}
  </>);
}   