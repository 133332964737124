import { useState } from 'react'  
import { GridContainer } from '../../../../components/common/Containers' 
import { Span } from '../../../../components/common/Span'   
import { BsDot } from 'react-icons/bs'
import { PACKAGE } from '../../../../components/constants/status'; 
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../../../store';
import Stops from './Stops';
 
export default ({stops, expressId,status}: any) => {  
    return (<>
            {stops?.map((dropOff, index) => (
                <Stops key={index} dropOff={dropOff} expressId={expressId} status={status} />
            ))}
    </>
    )
}
