import { Link } from 'react-router-dom'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { Span } from '../../../components/common/Span'
import { ColumnFilter } from "../../../components/Table/ColumnFilter";
import moment from 'moment';
import StatusDriver from './StatusDriver'
import { DRIVER, APPROVAL, DRIVERGROUPSHOW } from '../../../components/constants/status';

export const COLUMNS = [
    {
        Header: 'id',
        Filter: ColumnFilter,
        accessor: 'driverId',
        isVisible: false,
    },
    {
        accessor: data => data.firstName === undefined ? `${data?.firstName} ${data?.lastName}` : `${data.firstName} ${data.lastName}`,
        Header: 'Driver Name',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
                <span className='capitalize'>
                    {`${d.firstName} ${d.lastName}`}
                </span>
            )
        }
    },
    {
        accessor: data => data.phoneNumber === undefined ? data.phoneNumber : data.phoneNumber,
        Header: 'Contact Number',
        Filter: ColumnFilter,
        isVisible: true,
    },
    {
        accessor: data => {
            const vehicle = data?.driverVehicle.find(v => v?.status === 'active');
            return `${vehicle?.vehicleType} ${vehicle?.plateNumber}`
        },
        Header: 'Vehicle',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            const vehicle = d?.driverVehicle.find(v => v?.status === 'active');
            return (
                <>
                    <Span transform='capitalize'>{vehicle?.vehicleType?.replace('_', ' ')}</Span>
                    <Span transform='uppercase'>{vehicle?.plateNumber}</Span>
                </>
            )
        }
    },
    {
        accessor: data => data.deliveryArea !== undefined ? data.deliveryArea.driverGroup : '',
        Header: 'Driver Group',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
                <span>{DRIVERGROUPSHOW[d?.deliveryArea?.driverGroup]}</span>
            )
        }
    },
    {
        accessor: data => data.deliveryArea !== undefined ? data?.deliveryArea?.cluster : '',
        Header: 'Cluster',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data.row.original?.deliveryArea;
            return (
                <span className='capitalize'>
                    {d?.cluster}
                </span>
            )
        }
    },
    {
        accessor: data => data.deliveryArea !== undefined ? data?.deliveryArea?.clusterArea : '',
        Header: 'Area',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data.row.original?.deliveryArea;
            return (
                <span className='capitalize'>
                    {d?.clusterArea}
                </span>
            )
        }
    },
    {
        field: "driverApprovalStatus",
        Header: 'Approval Status',
        Filter: ColumnFilter,
        isVisible: true,
        accessor: 'driverApprovalStatus',
        Cell: data => {
            const d = data.row.original;
            return (
                <div className="flex justify-center whitespace-nowrap">
                    {d.driverApprovalStatus === 'approved' ?
                        <Span border='green' bg='green' padding='px-4' width='full' margin='mx-4'
                            color='green' radius='xl' size='sm'>
                            {APPROVAL[d.driverApprovalStatus]}
                        </Span>
                        :
                        <Span border='red' bg='red' padding='px-4' width='full' margin='mx-4' color='red'
                            radius='xl' size='sm'>
                            {APPROVAL[d.driverApprovalStatus]}
                        </Span>
                    }
                </div>
            )
        }
    },
    {
        field: "status",
        Header: 'Driver Status',
        Filter: ColumnFilter,
        isVisible: true,
        accessor: 'driverStatus',
        Cell: data => {
            const d = data.row.original;
            return (
                <div className="flex justify-center whitespace-nowrap">
                    {d.driverStatus === 'active' ?
                        <Span border='green' bg='green' padding='px-4' width='full' margin='mx-4' color='green' radius='xl'
                            size='sm'>
                            {DRIVER[d?.driverStatus]}
                        </Span>
                        :
                        <Span border='red' bg='red' padding='px-4' width='full' margin='mx-4' color='red' radius='xl'
                            size='sm'>
                            {DRIVER[d?.driverStatus]}
                        </Span>
                    }
                </div>
            )
        }
    },
    {
        accessor: ' ',
        Header: ' ',
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
                <StatusDriver id={d?.driverId === undefined ? d?.id : d?.driverId} status={d?.driverApprovalStatus} email={d?.email} />
            )
        }

    }
]