import { useHistory } from 'react-router';
import { Span, SpanContainer } from '../../../../../components/common/Span';
import { Input } from '../../../../../components/common/Input';
import { Button } from '../../../../../components/common/Button';
import { Textarea } from '../../../../../components/common/Textarea';
import {
  GridContainer,
  InputContainer,
} from '../../../../../components/common/Containers';
import { IoCloseSharp } from 'react-icons/io5';

import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../../../store';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PostErrorAlert from '../../../../../components/PostErrorAlert';
import BackDrop from '../../../../../components/BackDrop';

import '../../../CSS/Rate.css';
import {
  onlyNumberKeyAndDot,
  onlyNumberKey,
} from '../../../../../common/utils';
import { updateExpressRateSettings, updatePartialSettings } from '../../../../../common/api';
import { Select } from '../../../../../components/common/Select';

export default ({ ratePartialId }: { ratePartialId: any }) => {
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();
  let { id }: any = useParams();

  const [openAlert, setOpenAlert] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const data: any | [] = useSelector((state: RootState) => state.Express.settingData);
  const warehouse = useSelector((state: RootState) => state.Table.tables.warehouse.data);

  const initialState = {
    baseFare: data?.baseFare,
    commission: data?.commission,
    standardPricePerKM: data?.standardPricePerKM,
    driverShare: data?.driverShare,
    maxWeight: data?.maxWeight,
    warehouse: data?.warehouse,
  }

  const [updatedDetails, setUpdatedDetails] = useState({}) as any;

  const onChange = (e: any, name: any) => {
    const value = e.target.value;
    setUpdatedDetails({
      ...updatedDetails,
      [name]: value,
    });
  };

  const isDataChange = () => {
    const originalData = Object.entries(data).map((entry) => `${entry[0]} ${entry[1]}`);
    const updatedData = Object.entries(updatedDetails).map((entry) => `${entry[0]} ${entry[1]}`);
    const result = updatedData.map((data) => originalData.includes(data)).some((res) => res === false);
    return result;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isDataChange()) {
      setOpenAlert(true);
      setErrMessage('No changes made!');
      return;
    }
    const payload = {
      ...updatedDetails,
      baseFare: Number(updatedDetails.baseFare),
      commission: Number(updatedDetails.commission),
      standardPricePerKM: Number(updatedDetails.standardPricePerKM),
      driverShare: Number(updatedDetails.driverShare),
      maxWeight: Number(updatedDetails.maxWeight)
    }
    try {
      dispatch.UI.setIsLoading(true);
      const res = await updatePartialSettings(ratePartialId, id, payload);
      if (res.isSuccess) {
        history.push(`/rate/partial/${ratePartialId}`);
        dispatch.UI.setAlert({ message: 'Rate setting updated', type: 'Success' });
        dispatch.UI.setIsLoading(false);
        //window.location.reload();
      }
    } catch (err: any) {
      const errMsg = err?.data?.error === undefined ? 'Unable to update rate setting' : err.data.error;
      setOpenAlert(true);
      setErrMessage(errMsg);
      dispatch.UI.setIsLoading(false);
    }
  };

  useEffect(() => {
    setUpdatedDetails(initialState);
  }, [data])

  return (
    <div className="side-rate">
      <BackDrop />
      <form onSubmit={handleSubmit}>
        <div className="flex py-4 border-b border-grey-6 mb-4">
          <IoCloseSharp
            onClick={() => history.push(`/rate/partial/${ratePartialId}`)}
            className="my-auto text-lg cursor-pointer"
          />
          <Span weight="semi" size="xl" margin="mx-2">
            Update Rate
          </Span>
        </div>

        <div>
          <div className="pt-4">
            <Span margin="mt-4" size="lg" weight="medium">
              Basic Fare
            </Span>
            <GridContainer grid="two" padding="none">
              <div>
                <Span margin="mt-4">Basic Rate</Span>
                <InputContainer margin="none">
                  <SpanContainer
                    padding="normal"
                    position="absolute"
                    border="right"
                    weight="semi"
                  >₱</SpanContainer>
                  <Input
                    padding="pl-12"
                    type="text"
                    border="grey"
                    pattern="^[0-9\.]*$"
                    onKeyPress={onlyNumberKeyAndDot}
                    onChange={(e) => onChange(e, 'baseFare')}
                    value={updatedDetails?.baseFare ?? 0}
                    required
                  />
                </InputContainer>
              </div>

              <div>
                <Span margin="mt-4">Price per Km</Span>
                <InputContainer margin="none">
                  <SpanContainer
                    padding="normal"
                    position="absolute"
                    border="right"
                    weight="semi"
                  >₱</SpanContainer>
                  <Input
                    padding="pl-12"
                    type="text"
                    border="grey"
                    pattern="^[0-9\.]*$"
                    onKeyPress={onlyNumberKeyAndDot}
                    onChange={(e) => onChange(e, 'standardPricePerKM')}
                    value={updatedDetails?.standardPricePerKM ?? 0}
                    required
                  />
                </InputContainer>
              </div>

              <div>
                <Span>Commission</Span>
                <InputContainer margin="none">
                  <SpanContainer
                    padding="normal"
                    position="absolute"
                    border="right"
                    weight="semi"
                  >%</SpanContainer>
                  <Input
                    padding="pl-14"
                    type="text"
                    border="grey"
                    pattern="^[0-9\.]*$"
                    onKeyPress={onlyNumberKey}
                    onChange={(e) => onChange(e, 'commission')}
                    value={updatedDetails?.commission ?? 0}
                    required
                  />
                </InputContainer>
              </div>

              <div>
                <Span>Driver Share</Span>
                <InputContainer margin="none">
                  <SpanContainer
                    padding="normal"
                    position="absolute"
                    border="right"
                    weight="semi"
                  >%</SpanContainer>
                  <Input
                    padding="pl-14"
                    type="text"
                    border="grey"
                    pattern="^[0-9\.]*$"
                    onKeyPress={onlyNumberKey}
                    onChange={(e) => onChange(e, 'driverShare')}
                    value={updatedDetails?.driverShare ?? 0}
                    required
                  />
                </InputContainer>
              </div>

              <div>
                <Span>Max Weight</Span>
                <InputContainer margin="none">
                  <SpanContainer
                    padding="normal"
                    position="absolute"
                    border="right"
                    weight="semi"
                  >Kg</SpanContainer>
                  <Input
                    padding="pl-16"
                    type="text"
                    border="grey"
                    pattern="^[0-9\.]*$"
                    onKeyPress={onlyNumberKey}
                    onChange={(e) => onChange(e, 'maxWeight')}
                    value={updatedDetails?.maxWeight ?? 0}
                    required
                  />
                </InputContainer>
              </div>
            </GridContainer>

            <GridContainer grid='two' padding='none'>
              <div>
                <Span margin='mt-4'>Warehouse</Span>
                <InputContainer margin="none">
                  <Select
                    required
                    value={updatedDetails?.warehouse}
                    onChange={(e) => onChange(e, 'warehouse')}
                  >
                    <option disabled selected>Choose Warehouse</option>
                    {warehouse?.map(wh => (
                      <option key={wh?.id} value={wh?.id}>{wh?.name}</option>
                    ))}
                  </Select>
                </InputContainer>
              </div>
            </GridContainer>
          </div>
        </div>

        <PostErrorAlert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          message={errMessage}
        />

        <div className="flex my-2 py-4 text-center">
          <Button
            margin="mx-2"
            bg="white"
            border="lightgrey"
            color="black"
            onClick={() => history.push(`/rate/partial/${ratePartialId}`)}
          >
            Cancel
          </Button>
          <Button margin="mx-2" type="submit">
            Update
          </Button>
        </div>
      </form>
    </div>
  );
};
