import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '../../store'

import { Span } from '../../components/common/Span'
import { Button } from '../../components/common/Button'
import { Container } from '../../components/common/Containers'

import Table from '../../components/Table/Table'
import { COLUMNS } from './Table/column'
import NoData from '../../components/common/NoData/NoData'

import { BiPlusCircle } from 'react-icons/bi'
import Loader from "../../components/Loader";

import AddWH from './AddWH'
import EditWH from './EditWH'

export default () => {

    const dispatch = useDispatch<Dispatch>();

    const [isLoading, setIsLoading] = useState(true);
    const [addWarehouse, setAddWarehouse] = useState(false);

    const editWarehouseId: any = useSelector((state: RootState) => state.UI.forms.editWarehouse?.id);
    const filters: any = useSelector((state: RootState) => state.Table.tables.warehouse.filters);
    const data: any[] = useSelector((state: RootState) => state.Table.tables.warehouse.data)
        .sort((a, b) => { return a.createdAt.seconds - b.createdAt.seconds });

    useEffect(() => {
        dispatch.Table.subscribeToFirebase({ name: 'warehouse', table: 'warehouse' })
        setIsLoading(false);

        return () => {
            dispatch.Table.UNSUBSCRIBE()
        }
    }, [filters])

    const columns = useMemo(() => COLUMNS, [])

    return (<>
        {isLoading ?
            <Loader />
            : <>
                <Container border='grey' padding='none'>
                    <div className='flex justify-between p-4'>
                        <Span margin='mt-4' weight='bold' size='2xl'>Warehouse</Span>

                        <div className='flex justify-end p-4'>
                            <Button width='none' onClick={() => { setAddWarehouse(true); }}>
                                <div className='flex pr-2'>
                                    <BiPlusCircle className='my-auto mx-2 text-2xl' />
                                    Add Warehouse
                                </div>
                            </Button>
                        </div>
                    </div>

                    {addWarehouse &&
                        <AddWH setAddWarehouse={setAddWarehouse} />
                    }

                    <div className='overflow-auto'>
                        {data?.length < 0
                            ? <NoData />
                            : <Table columns={columns} data={data} />
                        }
                    </div>

                </Container>
            </>}
        {editWarehouseId && <>
            <EditWH editWarehouseId={editWarehouseId} />
        </>}
    </>)
}