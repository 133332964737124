import { FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material'
import React, { forwardRef } from 'react'
import { DRIVERGROUP } from '../../../components/constants/status'
import { IMaskInput } from 'react-imask';

const TextMaskCustom = forwardRef((props: any, ref: any) => {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask='#00-000-000-000'
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    )
})

const Profiles = ({ values, handleBlur, errors, handleChange, touched }) => {

    return (
        <>
            <Typography variant='h6' sx={{ fontWeight: '700', marginBottom: '.5rem' }}>PROFILE</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor='delivery_group'><span style={{ color: '#F44336' }}>*</span>Delivery Group</InputLabel>
                        <Select
                            name='deliveryGroup'
                            id='delivery_group'
                            value={values.deliveryGroup}
                            onBlur={handleBlur}
                            fullWidth
                            error={Boolean(touched.deliveryGroup && errors.deliveryGroup)}
                            onChange={handleChange('deliveryGroup')}
                            displayEmpty
                            sx={{
                                boxShadow: 'none',
                                height: 40,
                                '& legend': {
                                    display: 'none'
                                },
                                '& fieldset': {
                                    top: 0,
                                },
                            }}
                        >
                            <MenuItem value='' disabled>Choose Group</MenuItem>
                            {DRIVERGROUP?.map(({ name, value }) => (
                                <MenuItem key={value} value={value}>{name?.replace(/_/g, ' ')}</MenuItem>
                            ))}
                        </Select>
                        {touched.deliveryGroup && errors.deliveryGroup && (
                            <FormHelperText error id='delivery-group'>
                                {errors.deliveryGroup}
                            </FormHelperText>
                        )}
                    </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor='dateOfBirth'><span style={{ color: '#F44336' }}>*</span>Date of Birth</InputLabel>
                        <OutlinedInput
                            name='bday'
                            type='date'
                            placeholder='Date of Birth'
                            id='dateOfBirth'
                            onBlur={handleBlur}
                            fullWidth
                            error={Boolean(touched.bday && errors.bday)}
                            value={values.bday}
                            onChange={handleChange('bday')}
                            sx={{
                                height: 40,
                                '& legend': {
                                    display: 'none'
                                },
                                '& fieldset': {
                                    top: 0
                                }
                            }}
                        />
                        {touched.bday && errors.bday && (
                            <FormHelperText error id='delivery-group'>
                                {errors.bday}
                            </FormHelperText>
                        )}
                    </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor='tinNumber'><span style={{ color: '#F44336' }}></span>Tax Identification Number</InputLabel>
                        <OutlinedInput
                            name='tin'
                            placeholder='123-456-789-000'
                            id='tinNumber'
                            onBlur={handleBlur}
                            fullWidth
                            error={Boolean(touched.tin && errors.tin)}
                            value={values.tin}
                            onChange={handleChange('tin')}
                            inputComponent={TextMaskCustom}
                            sx={{
                                height: 40,
                                '& legend': {
                                    display: 'none'
                                },
                                '& fieldset': {
                                    top: 0
                                }
                            }}
                        />
                        {touched.tin && errors.tin && (
                            <FormHelperText error id='tinNumber'>
                                {errors.tin}
                            </FormHelperText>
                        )}
                    </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor='gender'><span style={{ color: '#F44336' }}>*</span>Gender</InputLabel>
                        <Select
                            name='gender'
                            id='gender'
                            value={values.gender}
                            onBlur={handleBlur}
                            fullWidth
                            error={Boolean(touched.gender && errors.gender)}
                            onChange={handleChange('gender')}
                            displayEmpty
                            sx={{
                                boxShadow: 'none',
                                height: 40,
                                '& legend': {
                                    display: 'none'
                                },
                                '& fieldset': {
                                    top: 0,
                                },
                            }}
                        >
                            <MenuItem value='' disabled>Choose Gender</MenuItem>
                            <MenuItem value='male'>Male</MenuItem>
                            <MenuItem value='feMale'>Fe Male</MenuItem>
                        </Select>
                        {touched.gender && errors.gender && (
                            <FormHelperText error id='gender'>
                                {errors.gender}
                            </FormHelperText>
                        )}
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

export default Profiles