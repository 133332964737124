import { get } from "../core";

export const getAutoCompleteSuggestions = async(input: any) => {
    try{
        const res = await get(`/maps/autocomplete/${input}`)
        return res.data;
    }catch(err){
        return Promise.reject(err)
    }
}

export const getPlaceDetails = async(placeId) => {
    try{
        const res = await get(`/maps/place-details/${placeId}`);
        return res.data;
    }catch(err){
        return Promise.reject(err);
    }
}