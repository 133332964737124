import { useMemo, useState, useEffect} from 'react' 
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '../../../store'

import { Span } from '../../../components/common/Span' 
import { Button } from '../../../components/common/Button'
import { Container } from '../../../components/common/Containers'

import { BiPlusCircle } from 'react-icons/bi'
import AddRateCard from './Add/AddRateCard'

import Table from './Table/Table' 
import { COLUMNS } from './Table/column'   
import NoData from '../../../components/common/NoData/NoData'

import EditRate from './Update/EditRate'
import Loader from '../../../components/Loader'

export default () => {   

    const dispatch = useDispatch<Dispatch>();

    const editRateId: any = useSelector((state: RootState) => state.UI.forms.editPartialRate?.id);   
    const filters: any = useSelector((state: RootState) => state.Table.tables.partial_rate.filters);
    const data: any[] = useSelector((state: RootState) => state.Table.tables.partial_rate.data)
        .sort((a, b) => {return a.createdAt.seconds - b.createdAt.seconds}); 
    
    const columns = useMemo(() => COLUMNS, []);

    const [rateCard, setAddRateCard] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dispatch.Table.subscribeToFirebase({name: 'rate_partial_load', table: 'partial_rate'})

        return () => {
            dispatch.Table.UNSUBSCRIBE()
        }
    },[filters]);

    useEffect(() => {
        setIsLoading(false);
    },[])

    return (<>
            {isLoading && (
                <Loader />
            )}
            <Container border='grey' padding='none'> 
                <div className='p-4'>
                    <div className='flex justify-between'>
                        <Span margin='mt-4' weight='bold' size='2xl'>Partial Load Rate</Span>  
                        <div className='flex justify-end px-4 my-4'>
                            <Button width='none' onClick={() => {setAddRateCard(true);}}>
                                <div className='flex pr-2'>
                                    <BiPlusCircle className='my-auto mx-2 text-2xl'/> 
                                    Add Rate Card
                                </div>
                            </Button>
                        </div>  
                    </div> 
                </div> 
                
                <div className='overflow-auto'>
                    {data?.length < 0 
                        ? <NoData />
                        : <Table columns={columns} data={data}/> 
                    } 
                </div>
            </Container>    

            <AddRateCard setAddRateCard={setAddRateCard} rateCard={rateCard}/> 

            {editRateId && <>
                <EditRate setEditRate={editRateId}/>
            </>}   
    </>
    )
}
