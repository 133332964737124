import * as React from 'react';
import classnames from 'classnames'


type Props = React.HTMLProps<HTMLSelectElement> & {
  children?: React.ReactNode
  width?: 'none',
  border?: 'none',
  radius?: 'none',
  margin?: 'mr-3'
}

let getWidth = (width: Props['width']) => {
  switch (width) {
    case 'none':
      return '';
    default:
      return 'w-full';
  }
}

let getBorder = (border: Props['border']) => {
  switch (border) {
    case 'none':
      return 'border-none';
    default:
      return 'border border-grey-1';
  }
}

let getRadius = (radius: Props['radius']) => {
  switch (radius) {
    case 'none':
      return 'rounded-none';
    default:
      return 'rounded';
  }
}

let getMargin = (margin: Props['margin']) => {
  switch (margin) {
    case 'mr-3':
      return 'mr-3';
    default:
      return 'none';
  }
}

export const Select = ({ width, margin, radius, border, children, ...props }: Props) => {
  return <select className={classnames("text-base focus:outline-none p-2 text-grey-7 capitalize",
    getMargin(margin), getBorder(border), getRadius(radius), getWidth(width))}
    {...props}>
    {children}
  </select>
}