// Parcel
const STATUS = {
  new: "New",
  for_pickup: "For Pickup",
  picked_up: "Picked up", //Picked
  in_transit: "In Transit",
  out_for_delivery: "Out for Delivery",
  failed_delivery: "Failed Delivery",
  delivered: "Delivered",
  canceled: "Canceled",
};

const SUB_STATUS = [
  "For Pickup",
  "Picked up",
  "In Transit",
  "Out for Delivery",
  "Failed Delivery",
];

// Driver
const DRIVER = {
  pending: "Inactive",
  active: "Active",
  inactive: "Inactive",
};
//role
const ROLE_STATUS = {
  pending: "Inactive",
  active: "Active",
  inactive: "Inactive",
};

const APPROVAL = {
  pending: "Pending",
  reject: "Reject",
  approved: "Approved",
};

const INTERISLAND_NEXT_STATUS = {
  assigned: "for_pickup",
  for_pickup: "picked_up",
  picked_up: "for_transfer",
  for_transfer: "ongoing_transfer",
  ongoing_transfer: "transferred",
  transferred: "assigned_delivery",
  assigned_delivery: "for_delivery",
  for_delivery: "ongoing_delivery",
  ongoing_delivery: "delivered"
}

// Express
const EXPRESS = {
  new: "New",
  pending: "New",
  assigned: "Assigned",
  for_pickup: "For Pickup",
  picked_up: "Picked up",
  on_going: "Ongoing",
  failed_delivery: "Failed Delivery",
  delivered: "Delivered",
  canceled: "Canceled",
  for_transfer: "For Transfer",
  ongoing_transfer: "Ongoing Transfer",
  transferred: "Transferred",
  assigned_delivery: "Assigned Delivery",
  for_delivery: "For Delivery",
  ongoing_delivery: "Ongoing Delivery",
};

const PACKAGE_STATUS = {
  pending: Object.keys(EXPRESS)[1],
  assigned: Object.keys(EXPRESS)[2],
  for_pickup: Object.keys(EXPRESS)[3],
  picked_up: Object.keys(EXPRESS)[4],
  on_going: Object.keys(EXPRESS)[5],
  delivered: Object.keys(EXPRESS)[7],
  failed_delivery: Object.keys(EXPRESS)[6],
  canceled: Object.keys(EXPRESS)[8],
};

const NEW = ["new", "created", "pending"];
const ONGOING = [
  "assigned",
  "for_pickup",
  "picked_up",
  "in_transit",
  "out_for_delivery",
  "on_going",
  "ongoing",
  "for_transfer",
  "ongoing_transfer",
  "transferred",
  "assigned_delivery",
  "for_delivery",
  "ongoing_delivery",
];
const DELIVERED = ["delivered", "failed_delivery"];
const CANCELED = ["canceled"];
const PENDING = ["pending"];
const APPROVE = ["approve", "approved"];
const DECLINED = ["declined"];

const TAB = {
  new: NEW,
  ongoing: ONGOING,
  delivered: DELIVERED,
  canceled: CANCELED,
  pending: PENDING,
  approve: APPROVE,
  declined: DECLINED,
};

const DRIVERGROUP = [
  {
    name: "PARCEL_AND_GROCERY_DELIVERY",
    value: "parcel_and_grocery_delivery",
  },
  {
    name: "FOOD_DELIVERY_ONLY",
    value: "food_delivery_only",
  },
  {
    name: "BULKY_ORDERS_ONLY",
    value: "bulky_orders_only",
  },
  {
    name: "ALL_OF_THE_ABOVE",
    value: "all_of_the_above",
  },
];

const DRIVERGROUPSHOW = {
  parcel_and_grocery_delivery: "PARCEL AND GROCERY DELIVERY",
  food_delivery_only: "FOOD DELIVERY ONLY",
  bulky_orders_only: "BULKY ORDERS ONLY",
  all_of_the_above: "AL OF THE ABOVE",
};

const VEHICLETYPE = ["motorcycle", "economy", "light_van", "l300", "closed_van"];

const VEHICLE = {
  motorcycle: "Motorcycle",
  economy: "Economy",
  light_van: "Light Van",
  l300: "L300 Van",
  closed_van: "Closed Van",
};

const INCLUDEID = [
  "deliveries",
  "wallet_transaction",
  "wallet_withdrawal_requests",
];

const WALLET = {
  admin_adjustment: "Admin Adjustment",
  top_up: "Top Up",
  cash_out: "Cash Out",
};

const TRANSACTION = {
  cash_out: "Cash Out",
  admin_adjustment: "Admin Adjustment",
  top_up: "Top Up",
  wallet: "Wallet",
};

const PACKAGE = {
  pending: "Pending",
  on_going: "On Going",
  delivered: "Delivered"
}

const DECLINED_REASON = [
  'Incorrect Bank Name',
  'Incorrect Account Name',
  'Incorrect Account Number'
]

export {
  STATUS,
  TAB,
  NEW,
  ONGOING,
  DELIVERED,
  CANCELED,
  DRIVERGROUP,
  VEHICLETYPE,
  DRIVER,
  APPROVAL,
  EXPRESS,
  SUB_STATUS,
  DRIVERGROUPSHOW,
  PENDING,
  APPROVE,
  DECLINED,
  INCLUDEID,
  WALLET,
  TRANSACTION,
  PACKAGE,
  VEHICLE,
  DECLINED_REASON,
  PACKAGE_STATUS,
  ROLE_STATUS,
  INTERISLAND_NEXT_STATUS
}
