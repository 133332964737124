import { Button, CircularProgress, Container, Grid, Paper, useTheme } from '@mui/material'
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import Step from './Step';
import PersonalDetails from './PersonalDetails';
import ScrollToError from './ScrollToError';
import Documents from './Documents/Documents';
import { initialState, initialStateDoc, validationSchema, validationSchemaDoc } from './StateValidation';
import { updateDriverInfoByDriver, uploadDriverRequirements, verifyDriverSetDetails } from '../../common/api';
import { useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { Dispatch } from '../../store';
import { useDispatch } from 'react-redux';
import Thankyou from './Thankyou';
import LinkExpired from './LinkExpired';
import AlertSnackBar from '../../components/AlertSnackBar';
import Loader from '../../components/Loader';

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const Upload = () => {
  const query = useQuery();
  const token: any = query.get('token');
  const decoded: any = jwtDecode(token);
  const dispatch = useDispatch<Dispatch>();

  const [isDetails, setIsDetails] = useState(false);
  const [isDocuments, setIsDocuments] = useState(false);
  const [activeStep, setActiveStep] = useState<any>(1);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleOnSubmit = async (values, actions) => {
    switch (activeStep) {
      case (1):
        try {
          const body = {
            ...values,
            driverVehicle: [
              {
                vehicleType: values?.vehicleType,
                vehicleModel: values?.vehicleModel,
                plateNumber: values?.plateNumber,
                status: values?.status,
              }
            ],
            deliveryArea: {
              driverGroup: values?.deliveryGroup,
              cluster: values?.cluster,
              clusterCity: values?.clusterCity,
              clusterArea: values?.clusterArea,
            },
            EmergencyContactNumber: `+63${values?.contactNumber?.replace(/-/g, '')}`,
            driverLicenseRestriction: values?.licenseRestriction
          }
          const obj = { ...body.driverVehicle?.find(v => v.status === 'active'), ...body.deliveryArea, contactNumber: '', licenseRestriction: '', deliveryGroup: '' } //remove this in body
          Object.keys(obj).forEach((key) => delete body[key]);

          const res = await updateDriverInfoByDriver(decoded?.driverId, body);
          if (res.isSuccess) {
            setActiveStep(2);
            setIsDetails(true);
            dispatch.UI.setAlert({ type: 'Success', message: 'Details submitted' });
          }
        } catch (err: any) {
          const errMsg = err?.data?.error ?? 'Unable to submit'
          dispatch.UI.setAlert({ type: 'Error', message: errMsg });
        }
        return;
      case (2):
        try {
          const res = await uploadDriverRequirements(decoded?.driverId, values)
          if (res.isSuccess) {
            setIsDetails(true);
            setIsDocuments(true);
            setActiveStep(3);
          }
        } catch (err: any) {
          const errMsg = err?.data?.error ?? 'Unable to submit'
          dispatch.UI.setAlert({ type: 'Error', message: errMsg });
        }
        return;
      default:
        return;
    }
  }

  const verify = async () => {
    try {
      const res = await verifyDriverSetDetails(decoded?.driverId);

      if (res.isUpdatedDetails && res.isUploadedDocs) {
        setActiveStep(4);
        setLoading(false)
        return
      }

      if (res.isUpdatedDetails) {
        setActiveStep(2);
        setIsDetails(true);
        setLoading(false);
        return;
      }

      if (!res.isUpdatedDetails && !res.isUploadedDocs) {
        setLoading(false)
        return
      }

    } catch (err) {
      console.log(err)
    }
  }

  const subContainer: any = {
    component: Paper,
    elevation: 3,
    sx: { borderRadius: '20px' }
  }

  useEffect(() => {
    verify()
  }, []);

  if (loading) {
    return <Loader />
  }

  return (
    <div className=' h-screen overflow-y-auto'>
      <AlertSnackBar open={open} setOpen={setOpen} />
      <Container fixed sx={{ padding: '5vh 0' }}>
        <Grid {...subContainer} >
          <Step setIsDetails={setIsDetails} isDetails={isDetails} setIsDocuments={setIsDocuments} isDocuments={isDocuments} />
          {/* form */}
          <Formik
            initialValues={activeStep === 1 ? initialState : initialStateDoc}
            validationSchema={activeStep === 1 ? validationSchema : validationSchemaDoc}
            onSubmit={async (values, actions) => handleOnSubmit(values, actions)}
          >
            {({ handleSubmit, values, errors, handleBlur, handleChange, isSubmitting, touched }: any) => {
              console.log(errors, touched, values);
              return (
                <form noValidate autoComplete='off' onSubmit={handleSubmit} >
                  <div className='p-6'>
                    <ScrollToError />

                    {activeStep === 1 ? (
                      <PersonalDetails values={values} handleBlur={handleBlur} errors={errors} handleChange={handleChange} touched={touched} />
                    ) : activeStep === 2 ? (
                      <Documents values={values} handleBlur={handleBlur} errors={errors} handleChange={handleChange} touched={touched} />
                    ) : activeStep === 3 ? (
                      <Thankyou />
                    ) : <LinkExpired />}

                    <br />
                    {[1, 2].includes(activeStep) && (
                      <Grid
                        container
                        justifyContent='flex-end'
                        flexDirection='row'
                        alignItems='center'
                        sx={{
                          padding: '10px 0px'
                        }}
                      >
                        <Button
                          type='submit'
                          variant='contained'
                          disableElevation
                          disabled={isSubmitting}
                          sx={{
                            borderRadius: 0,
                            width: '200px'
                          }}
                        >
                          {isSubmitting ? <CircularProgress size={25} /> : 'Submit'}
                        </Button>
                      </Grid>
                    )}
                  </div>
                </form>
              )
            }}
          </Formik>
        </Grid>
      </Container >
    </div>
  )
}

export default Upload