import { useState } from 'react';
import { Span } from '../../../../components/common/Span';
import { Input } from '../../../../components/common/Input';
import { Button } from '../../../../components/common/Button';
import { Textarea } from '../../../../components/common/Textarea';
import { IoCloseSharp } from 'react-icons/io5';
import PostErrorAlert from '../../../../components/PostErrorAlert';
import BackDrop from '../../../../components/BackDrop';
import { addExpressRateCard } from '../../../../common/api';
import { Dispatch } from '../../../../store';
import { useDispatch } from 'react-redux';

import '../../CSS/Rate.css';

import Switch from 'react-switch';

type Props = {
  setAddRateCard: Function;
  rateCard: boolean;
};

export default ({ setAddRateCard, rateCard, ...props }: Props) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [data, setData] = useState({
    name: '',
    isDefault: false,
    description: '',
  });

  const dispatch = useDispatch<Dispatch>();

  const handleChange = (name: string, e: any) => {
    setData({
      ...data,
      [name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch.UI.setIsLoading(true);
      const res = await addExpressRateCard(data);
      if (res.isSuccess) {
        setAddRateCard(false);
        dispatch.UI.setAlert({ message: 'Add rate card', type: 'Success' });
        dispatch.UI.setIsLoading(false);
      }
    } catch (err: any) {
      const errMsg =
        err?.data?.error === undefined
          ? 'Unable to add rate card'
          : err.data.error;
      setOpenAlert(true);
      setErrMessage(errMsg);
      dispatch.UI.setIsLoading(false);
    }
  };

  return (
    <>
      {rateCard && (
        <form onSubmit={handleSubmit}>
          <div className="side-rate">
            <BackDrop />
            <div className="flex py-4 border-b border-grey-6">
              <IoCloseSharp
                onClick={() => setAddRateCard(false)}
                className="my-auto mx-2 text-lg cursor-pointer"
              />
              <Span weight="semi" size="lg">
                Add Rate
              </Span>
            </div>

            <div>
              <Span margin="mt-4">Name</Span>
              <Input
                required
                placeholder="Name"
                type="text"
                id="merchantName"
                onChange={(e) => handleChange('name', e)}
              />
            </div>

            <div>
              <Span margin="mt-4">Description</Span>
              <Textarea
                placeholder="Description (Optional)"
                onChange={(e) => handleChange('description', e)}
              />
            </div>

            <div className="flex justify-between">
              <Span margin="mt-4">Default Card</Span>

              <Switch
                onChange={() =>
                  setData({ ...data, isDefault: !data.isDefault })
                }
                checked={data.isDefault}
                checkedIcon={false}
                uncheckedIcon={false}
                onColor="#9A7F3E"
              />
            </div>

            <PostErrorAlert
              openAlert={openAlert}
              setOpenAlert={setOpenAlert}
              message={errMessage}
            />

            <div className="flex absolute inset-x-0 bottom-0 mb-8 p-4 text-center">
              <Button
                margin="mx-2"
                bg="white"
                border="lightgrey"
                color="black"
                onClick={() => setAddRateCard(false)}
              >
                Cancel
              </Button>
              <Button margin="mx-2" type="submit">
                Create
              </Button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};
