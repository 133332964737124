import { getDoc, doc, getFirestore } from "firebase/firestore";
import { post, get, put, postMultipart } from "../core"; 

export const updateExpressStatus = async (status: any,orderId: string, ) => {
  try {
    const res = await put(`/admin/express-orders/update-status/${orderId}`, {
      status: status, 
    });
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
}; 

export const cancelBookingByOrderId = async (orderId: string) => {
  try{
    const res = await put(`/admin/express-orders/${orderId}/cancel`)
    return res.data;
  }catch(err){
    return Promise.reject(err);
  }
}

export const updateDeliveryDriver = async ({orderId, driver}: any) => {
  try{
    const res = await put(`/re-assign/delivery/${orderId}`, driver)
    return res.data;
  }catch(err){
    return Promise.reject(err);
  }
}

export const addDeliveryDriver = async ({orderId, driver}: any) => {
  try{
    const res = await put(`/assign/delivery/${orderId}`, driver)
    return res.data;
  }catch(err){
    return Promise.reject(err);
  }
}

export const sharelink = async ({shareAuth}: any) => {
  try{  
    const res = await post(`/public/authentication`, shareAuth)
    return res.data;
  }catch(err){
    return Promise.reject(err);
  }
}


export const getSingleDoc = async(colName: string, docId: string) => {
  try{
    const db = getFirestore();

    const document = await getDoc(doc(db, colName, docId));
    return document.data();
  }catch(err){
    return Promise.reject(err)
  }
}

export const uploadExpressPod = async(expressId: any, locationId:any, file: any) => {
  try{
    const body = new FormData();
    body.append('file', file);
    body.append('name', 'Proof-Of-Delivery');

    const res = await postMultipart(`/admin/express/${expressId}/upload/proof-of-delivery/${locationId}`, body);
    return res.data;
  }catch(err){
    return Promise.reject(err);
  }
}