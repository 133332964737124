import React from 'react'
import { onlyNumberKey, onlyNumberKeyAndDot } from '../../../../../common/utils'
import { GridContainer, InputContainer } from '../../../../../components/common/Containers'
import { Input } from '../../../../../components/common/Input'
import { Select } from '../../../../../components/common/Select'
import { Span, SpanContainer } from '../../../../../components/common/Span'
import { VEHICLE, VEHICLETYPE } from '../../../../../components/constants/status'

const Addons = ({ onChange, data }) => {
    return (
        <div className="pt-4">
            <Span margin="mt-4" size="lg" weight="medium">
                Add On Rates
            </Span>
            <GridContainer grid="two" padding="none">
                <div>
                    <Span margin="mt-4">Additional Stop</Span>
                    <InputContainer margin="none">
                        <SpanContainer
                            padding="normal"
                            position="absolute"
                            border="right"
                            weight="semi"
                        >₱</SpanContainer>
                        <Input
                            padding="pl-12"
                            type="text"
                            border="grey"
                            pattern="^[0-9\.]*$"
                            onKeyPress={onlyNumberKeyAndDot}
                            onChange={(e) => onChange(e, 'additionalStopPrice')}
                            value={data?.additionalStopPrice ?? 0}
                            required
                        />
                    </InputContainer>
                </div>

                <div>
                    <Span margin="mt-4">Special Help</Span>
                    <InputContainer margin="none">
                        <SpanContainer
                            padding="normal"
                            position="absolute"
                            border="right"
                            weight="semi"
                        >₱</SpanContainer>
                        <Input
                            padding="pl-12"
                            type="text"
                            border="grey"
                            pattern="^[0-9\.]*$"
                            onKeyPress={onlyNumberKeyAndDot}
                            onChange={(e) => onChange(e, 'specialHelp')}
                            value={data?.specialHelp ?? 0}
                            required
                        />
                    </InputContainer>
                </div>

                <div>
                    <Span>Extra Help</Span>
                    <InputContainer margin="none">
                        <SpanContainer
                            padding="normal"
                            position="absolute"
                            border="right"
                            weight="semi"
                        >₱</SpanContainer>
                        <Input
                            padding="pl-12"
                            type="text"
                            border="grey"
                            pattern="^[0-9\.]*$"
                            onKeyPress={onlyNumberKeyAndDot}
                            onChange={(e) => onChange(e, 'extraHelp')}
                            value={data?.extraHelp ?? 0}
                            required
                        />
                    </InputContainer>
                </div>

                <div>
                    <Span>Customer Staff</Span>
                    <InputContainer margin="none">
                        <SpanContainer
                            padding="normal"
                            position="absolute"
                            border="right"
                            weight="semi"
                        >₱</SpanContainer>
                        <Input
                            padding="pl-12"
                            type="text"
                            border="grey"
                            pattern="^[0-9\.]*$"
                            onKeyPress={onlyNumberKeyAndDot}
                            onChange={(e) => onChange(e, 'customerStaff')}
                            value={data?.customerStaff ?? 0}
                            required
                        />
                    </InputContainer>
                </div>

                <div>
                    <Span>Safety Gloves</Span>
                    <InputContainer margin="none">
                        <SpanContainer
                            padding="normal"
                            position="absolute"
                            border="right"
                            weight="semi"
                        >₱</SpanContainer>
                        <Input
                            padding="pl-12"
                            type="text"
                            border="grey"
                            pattern="^[0-9\.]*$"
                            onKeyPress={onlyNumberKeyAndDot}
                            onChange={(e) => onChange(e, 'safetyGloves')}
                            value={data?.safetyGloves ?? 0}
                            required
                        />
                    </InputContainer>
                </div>

                <div>
                    <Span>Hard Hat</Span>
                    <InputContainer margin="none">
                        <SpanContainer
                            padding="normal"
                            position="absolute"
                            border="right"
                            weight="semi"
                        >₱</SpanContainer>
                        <Input
                            padding="pl-12"
                            type="text"
                            border="grey"
                            pattern="^[0-9\.]*$"
                            onKeyPress={onlyNumberKeyAndDot}
                            onChange={(e) => onChange(e, 'hardHat')}
                            value={data?.hardHat ?? 0}
                            required
                        />
                    </InputContainer>
                </div>

            </GridContainer>
        </div>
    )
}

export default Addons