import { useEffect, useRef, useState } from "react";

import DeleteModal from './Modal/DeleteModal'
import { useDispatch } from 'react-redux';
import { Dispatch } from "../../store";
import ChangePasswordModal from "./Modal/ChangePassword";
import { getUrl } from "../../common/utils";
import { sendCustomerChangePasswordEmail, sendAdminChangePasswordEmail } from "../../common/api";

import edit_icon from '../../images/edit_icon.png';
import changepassword_icon from '../../images/changepassword_icon.png';
import delete_icon from '../../images/delete_icon.png'
import ReactTooltip from 'react-tooltip';
import { Container } from "../common/Containers";
import { Paper, Popover, Zoom } from "@mui/material";
import { CgMoreVerticalO } from 'react-icons/cg'
import './status.css'
import ListenForOutsideClicks from "../ListenForOutsideClicks";

const StatusComponent = ({ id, email, user, handleEdit, ...props }: any) => {
    const dispatch = useDispatch<Dispatch>();

    const [deleteModal, setDeleteModal] = useState(false)
    const [changePassword, setChangePassword] = useState(false);
    const [open, setOpen] = useState(false);
    const [listening, setListening] = useState(false);
    const menuRef: any = useRef(null);

    useEffect(ListenForOutsideClicks(listening, setListening, menuRef, setOpen));

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setOpen(true)
    };

    const handleDelete = (e) => {
        e.preventDefault();
        setDeleteModal(true);
    }
    const handleChangePassword = async () => {
        const url = await getUrl();
        const body = {
            email,
            url: `${url}changepassword`,
            userId: id,
            userType: user === 'userAdmin' ? 'useradmin-resetpassword' : 'customer-resetpassword'
        }
        try {
            dispatch.UI.setIsLoading(true);
            let res;
            if (user === 'userAdmin') {
                res = await sendAdminChangePasswordEmail(body)
            } else {
                res = await sendCustomerChangePasswordEmail(body)
            }
            if (res.isSendEmail) {
                setChangePassword(true);
                dispatch.UI.setIsLoading(false);
            }
        } catch (err: any) {
            const errMsg = err.data.error === undefined ? 'Unable to change password' : err.data.error;
            dispatch.UI.setAlert({ message: errMsg, type: 'Error' });
            dispatch.UI.setIsLoading(false);
        }
    }

    return (
        <>
            <div className='flex items-center justify-center px-4 cursor-pointer relative' onClick={handleClick}>
                <div ref={menuRef}>
                    <CgMoreVerticalO className='text-xl' />
                </div>

                <Zoom in={open} >
                    <Paper elevation={5} className='pop_over z-50'>
                        <Container>
                            <div className='flex items-start justify-around flex-col my-auto'>
                                <div className="flex w-full transition-all hover:scale-105 cursor-pointer" onClick={handleEdit}>
                                    <img src={edit_icon} placeholder={edit_icon}
                                        className="status_icon" data-tip data-for='edit'
                                    />
                                    {/* <ReactTooltip id='edit' backgroundColor="#8d8d8d"> */}
                                    <span>Edit</span>
                                    {/* </ReactTooltip> */}
                                </div>

                                {user !== 'role' && (
                                    <div className="flex transition-all hover:scale-105 py-1 cursor-pointer" onClick={handleChangePassword}>
                                        <img src={changepassword_icon} placeholder={changepassword_icon}
                                            className="status_icon" data-tip data-for='pw'
                                        />
                                        {/* <ReactTooltip id='pw' backgroundColor="#8d8d8d"> */}
                                        <span>Change Password</span>
                                        {/* </ReactTooltip> */}
                                    </div>
                                )}

                                <div className="flex transition-all hover:scale-105 cursor-pointer" onClick={handleDelete}>
                                    <img src={delete_icon} placeholder={delete_icon}
                                        className="status_icon" data-tip data-for='delete'
                                    />
                                    {/* <ReactTooltip id='delete' backgroundColor="#8d8d8d"> */}
                                    <span>Delete</span>
                                    {/* </ReactTooltip> */}
                                </div>

                            </div>
                        </Container>
                    </Paper>
                </Zoom>
            </div>
            <DeleteModal setDeleteModal={setDeleteModal} id={id} deleteModal={deleteModal} user={user} />
            <ChangePasswordModal setChangePassword={setChangePassword} changePassword={changePassword} />
        </>
    )
}

export default StatusComponent