import { useState } from "react";

import { Button } from '../../../../components/common/Button'

import CancelModal from '../../Modal/Cancel'
import ForPickupModal from '../../Modal/ForPickup'
import PickedUpModal from '../../Modal/PickedUp'
import OngoingModal from '../../Modal/Ongoing'
import DeliveredModal from '../../Modal/Delivered'
import FailDelivery from '../../Modal/FailDelivery';

import { EXPRESS, INTERISLAND_NEXT_STATUS } from '../../../../components/constants/status'
import UpdateStatus from "../../Modal/UpdateStatus";

const StatusExpress = ({ orderId, status }: any) => {

    const [cancelModal, setCancelModal] = useState(false)
    const [updateModal, setUpdateModal] = useState(false)

    const handleCancel = (e) => {
        e.preventDefault();
        setCancelModal(true);
    }
    
    const handleUpdateStatus = (e) => {
        e.preventDefault();
        setUpdateModal(true);
    }

    return (<>
        <div className='flex justify-between'>

            {/* New */}
            {EXPRESS[status] === 'New' && <>
                <Button bg='red' width='none' padding='px-4' onClick={handleCancel}>
                    Cancel Booking
                </Button>
            </>}


            {/* Assigned */}
            {!['pending', 'new', 'delivered', 'canceled', 'failedDelivery', 'transferred'].includes(status) && (
                <>
                    <div>
                    </div>

                    <Button
                        bg='white-brown' border='brown' color='brown' width='none' padding='px-4'
                        onClick={handleUpdateStatus}
                    >
                        {EXPRESS[INTERISLAND_NEXT_STATUS[status]]}
                    </Button>
                </>
            )
            }

        </div>


        <CancelModal setCancelModal={setCancelModal} orderId={orderId} cancelModal={cancelModal} />
        <UpdateStatus setUpdateModal={setUpdateModal} orderId={orderId} updateModal={updateModal} nextStatus={INTERISLAND_NEXT_STATUS[status]} />

    </>)
}

export default StatusExpress

