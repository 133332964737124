import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';
import { debounce } from "lodash";
import { getAutoCompleteSuggestions, getPlaceDetails } from '../../common/api';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { Span } from '../common/Span';
import location_icon from '../../images/Location.png'
import { provinceData } from './location_json/province';

const Province = ({ warehouse, setWarehouse }) => {
    const locations = provinceData;
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({
        address: warehouse?.serviceArea ?? [],
        searchStr: ''
    })
    console.log(warehouse)
    const renderInput = (params) => {
        return <TextField
            placeholder='Select Province...'
            sx={{
                '& legend': {
                    display: 'none'
                },
                '& fieldset': {
                    top: 0
                },
            }}
            onChange={(e: any) => {
                setData({ ...data, searchStr: e.target.value })
            }}
            {...params}
        />
    }

    useEffect(() => {
        setWarehouse({
            ...warehouse,
            serviceArea: data?.address
        })
    },[data?.address])

    return (
        <>
            <Span margin='mt-4'>Service Area</Span>
            <Autocomplete
                disablePortal
                disableClearable
                multiple
                filterSelectedOptions
                size="small"
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                onFocus={() => setOpen(true)}
                id="combo-box-demo"
                options={locations}
                value={data?.address}
                onChange={(e: any, newValue: any) => {
                    console.log(newValue);
                    setData({ ...data, address: newValue })
                }}
                getOptionLabel={(option: any) => option}
                ListboxProps={{
                    style: {
                        maxHeight: '220px',
                    }
                }}
                renderOption={(props, option) => {
                    const data = option;
                    return (<>
                        <li {...props}>
                            <div className="flex flex-row items-center justify-center">
                                <img src={location_icon} alt="" className="w-6 h-6 object-contain" />
                                <Span>{data}</Span>
                            </div>
                        </li>
                    </>)
                }}
                renderInput={(params) => renderInput(params)}
            />
        </>
    )
}

export default Province