import {useState, useEffect} from 'react'
import { ModalContainer } from '../../../../../components/common/Containers'
import { Button } from '../../../../../components/common/Button'  
import { Select } from '../../../../../components/common/Select' 
import { Input } from '../../../../../components/common/Input' 
import { Span } from '../../../../../components/common/Span' 
import warningIcon from '../../../../../images/Warning_icon.png' 

import PostErrorAlert from '../../../../../components/PostErrorAlert';
import BackDrop from "../../../../../components/BackDrop";
import { useDispatch } from "react-redux";
import { Dispatch } from "../../../../../store";
import { onlyNumberKeyDotAndNegative } from '../../../../../common/utils/helpers';
import { updateWalletAmount } from '../../../../../common/api';

type Props = {
    setUpdateWallet: Function,
    updateWallet: boolean,
    id: string
} 
  
export default ({setUpdateWallet, updateWallet, id, ...props}: Props) => { 

    const dispatch = useDispatch<Dispatch>();
    
    const [openAlert, setOpenAlert] = useState(false);
    const [errMessage, setErrMessage] = useState('');
    const [data, setData] = useState({
        id: id,
        amount: 0,
        transactionType: '',
        remarks: ''
    })as any;
    
    const onChange = (e, name) => {
        setData({
            ...data,
            [name]: e.target.value
        });
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        try{
            const body = {
                ...data,
                amount: parseFloat(data.amount)
            }
            dispatch.UI.setIsLoading(true);
            const res = await updateWalletAmount(body);
            if(res.isSuccess){
                setUpdateWallet(false);
                dispatch.UI.setAlert({message: 'Update wallet', type: 'Success'})
                dispatch.UI.setIsLoading(false);
            }
        }catch(err: any){
            const errMsg = !err.data ? 'Unable to update wallet' : err.data.error;
            setOpenAlert(true);
            setErrMessage(errMsg);
            dispatch.UI.setIsLoading(false);
        }
    }

    return (<>
    {updateWallet && (<>
        <ModalContainer> 
            <form onSubmit={handleSubmit}>
                <BackDrop />
                <div className="p-6 whitespace-normal"> 
                    <div className='flex'> 
                        <img src={warningIcon} alt={warningIcon} className='h-8 mr-2' />
                        <div className='text-left'>  
                        <Span size='lg' weight='bold'>Update Wallet</Span> 
                        </div>
                    </div>

                    <Span margin='mt-4'>Select Amount</Span>
                    <Input 
                        type='text' 
                        required min={1} 
                        pattern="^[0-9\.\-]*$"
                        onKeyPress={onlyNumberKeyDotAndNegative}
                        onChange={(e) => onChange(e, 'amount')}
                    />

                    <Span margin='mt-4'>Type</Span> 
                    <Select required onChange={(e) => onChange(e, 'transactionType')}>
                        <option value="" selected disabled hidden> Type </option> 
                        <option value="admin_adjustment">Admin adjustment</option> 
                        <option value="top_up">Top up</option> 
                    </Select>   

                    <Span margin='mt-4'>Remarks</Span>
                    <Input 
                        placeholder="Remarks" 
                        type='text'
                        onChange={(e) => onChange(e, 'remarks')}
                    /> 

                    <PostErrorAlert openAlert={openAlert} setOpenAlert={setOpenAlert} message={errMessage} />
                    
                    <div className='flex justify-end my-4 mx-2'> 
                        <Button width='none' border='grey' color='black' padding='px-8' bg='white' margin='mx-2'
                            onClick={() => setUpdateWallet(false)}
                        >
                            Cancel
                        </Button>  
                        
                        <Button width='none' padding='px-8' type='submit'>
                            Update Wallet
                        </Button>
                    </div> 
                </div> 
            </form>
        </ModalContainer> 

        <div className="opacity-60 fixed inset-0 z-40 bg-black-1"></div>
    </>)}
  </>);
}   