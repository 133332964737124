import { createModel } from "@rematch/core";
import {
  authenticateViaPassword,
  getUserCredentials,
  updateUserProfile,
  registerUser,
} from "../common/api/endpoints/login";
import { IUserInfo } from "../types"; 
import { RootModel } from ".";
import { getFirebaseToken } from "../common/utils";

export interface State {
  customToken: string;
  isUserLoggedIn: boolean;
  userInfo: IUserInfo | null;
  userToken: string;
  shouldRedirectToLogin: boolean;
}

const initialState = {
  customToken: "",
  isUserLoggedIn: false,
  shouldRedirectToLogin: false,
  userInfo: null,
  userToken: "",
} as State

export const User = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState}
    },
    setUserLoggedIn(state, payload: boolean) {
      state.isUserLoggedIn = payload;
      return { ...state };
    },
    setUserInfo(state, payload: IUserInfo | null) {
      state.userInfo = payload;
      return { ...state };
    },
    setUserToken(state, payload: string) {
      state.userToken = payload;
      return { ...state };
    },
    updateState(state, newState: Partial<State>) {
      return { ...state, ...newState }
    },
  },
  effects: (dispatch) => ({
    /**
     * @name login
     * @desc login user using email and password
     */
    async login({email, password}) {
      dispatch.UI.setIsUserLoading({ target: "login", value: true });
      try {
        let res = await authenticateViaPassword({email, password});

        const customToken = res.accessToken
        
        const {  userToken } = await getFirebaseToken(customToken)
        // console.log(userToken)
        dispatch.User.updateState({ customToken: customToken, userToken });
        
        dispatch.User.updateState({
          customToken: userToken,
          userToken
        });

        dispatch.UI.setErrorMsg({
          target: "login",
          value: "",
        });

        dispatch.UI.resetForm("login");
        await this.getUserInfoViaToken();
      } catch (err: any) {
        dispatch.UI.setAlert({message: 'Invalid credentials', type:'Error'})
        dispatch.UI.setErrorMsg({
          target: "login",
          value: err.data?.error,
        });
      } finally {
        dispatch.UI.setIsUserLoading({ target: "login", value: false });
      }
    },
    /**
     * @name getUserInfoViaToken
     * @desc fetches and sets user data via token(state.userToken)
     */
    async getUserInfoViaToken() {
        let user_profiles = await getUserCredentials();

        dispatch.User.setUserInfo({
          ...user_profiles
        });
        dispatch.User.setUserLoggedIn(true);
    },
    async updateUserProfile (body: Partial<IUserInfo>) {
      try {
        const data = await updateUserProfile(body)
        if (data.isSuccess) {
          dispatch.UI.setAlert({ message: 'Profile updated.', type: "success"})
        }
        return true
      } catch (err) {
        dispatch.UI.setAlert({ message: 'Update failed.', type: 'error'})
        return false
      }
  },
  async logout(payload, rootState) {

    dispatch.User.resetState();
    dispatch.UI.resetState();
    dispatch.Table.resetState();
    dispatch.User.updateState({ shouldRedirectToLogin: true }); 
  
  },

  
  }),
});
