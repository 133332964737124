import classnames from 'classnames';
import * as React from 'react';

type Props = React.HTMLProps<HTMLTextAreaElement> & {
  children?: React.ReactNode,  
  radius?: 'none',
  border?: 'none',
}
 
 
let getRadius = (radius: Props['radius']) => {
    switch (radius) {
    case 'none':
        return 'rounded-none'   
    default:
        return 'rounded-md';
    }
}

let getBorder = (border: Props['border']) => {
    switch (border) {  
    case 'none':
        return 'border-none' 
    default:
        return 'border border-getgrey-1';
    }
}

export const Textarea = ({border, radius, children, ...props} : Props) => (  
  <textarea className={classnames("focus:outline-none appearance-none block w-full p-2 mb-3 leading-tight icon",
    getBorder(border), getRadius(radius))}
    {...props}/>
);