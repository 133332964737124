import { FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material'

const DriverLicense = ({ values, handleBlur, errors, handleChange, touched }) => {

    return (
        <>
            <Typography variant='h6' sx={{ fontWeight: '700', marginBottom: '.5rem' }}>DERIVER LICENSE</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor='licenseType'><span style={{ color: '#F44336' }}>*</span>License Type</InputLabel>
                        <Select
                            name='licenseType'
                            id='licenseType'
                            value={values.licenseType}
                            onBlur={handleBlur}
                            fullWidth
                            error={Boolean(touched.licenseType && errors.licenseType)}
                            onChange={handleChange('licenseType')}
                            displayEmpty
                            sx={{
                                boxShadow: 'none',
                                height: 40,
                                '& legend': {
                                    display: 'none'
                                },
                                '& fieldset': {
                                    top: 0,
                                },
                            }}
                        >
                            <MenuItem value='' disabled>License Type</MenuItem>
                            <MenuItem value='studentPermit'>Student Permit</MenuItem>
                            <MenuItem value='nonProfessional'>Non Professional</MenuItem>
                            <MenuItem value='professional'>Professional</MenuItem>
                        </Select>
                        {touched.licenseType && errors.licenseType && (
                            <FormHelperText error id='licenseType'>
                                {errors.licenseType}
                            </FormHelperText>
                        )}
                    </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor='licenseNumber'><span style={{ color: '#F44336' }}>*</span>License Number</InputLabel>
                        <OutlinedInput
                            name='licenseNumber'
                            placeholder='License Number'
                            id='licenseNumber'
                            onBlur={handleBlur}
                            fullWidth
                            error={Boolean(touched.licenseNumber && errors.licenseNumber)}
                            value={values.licenseNumber}
                            onChange={handleChange('licenseNumber')}
                            sx={{
                                height: 40,
                                '& legend': {
                                    display: 'none'
                                },
                                '& fieldset': {
                                    top: 0
                                }
                            }}
                        />
                        {touched.licenseNumber && errors.licenseNumber && (
                            <FormHelperText error id='licenseNumber'>
                                {errors.licenseNumber}
                            </FormHelperText>
                        )}
                    </Stack>
                </Grid>

            </Grid>
        </>
    )
}

export default DriverLicense