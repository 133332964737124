import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Span } from '../../../components/common/Span';
import { Input } from '../../../components/common/Input';
import { Button } from '../../../components/common/Button';
import { onlyNumberKey } from '../../../common/utils';
import { updateCustomer, verifyCustomerSetPassword } from '../../../common/api';

import PostErrorAlert from '../../../components/PostErrorAlert';
import BackDrop from '../../../components/BackDrop';
import '../Customer.css';
import { IoCloseSharp } from 'react-icons/io5';
import { RiErrorWarningFill } from 'react-icons/ri';

import Loader from '../../../components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../store';
import moment from 'moment';

type Props = {
  setEditCustomer: Function;
};

export default ({ setEditCustomer, ...props }: Props) => {
  const dispatch = useDispatch<Dispatch>();

  const editCustomerId: any = useSelector(
    (state: RootState) => state.UI.forms.editCustomer?.id ?? null
  );
  const customers = useSelector(
    (state: RootState) => state.Table.tables.customers.data
  );
  const data =
    customers.length > 0 &&
    customers?.filter((customer) => customer.id === editCustomerId)[0];

  const [verified, setVerified] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  const [updatedCustomer, setUpdatedCustomer] = useState({
    name: `${data?.firstName} ${data?.lastName}`,
    phoneNumber: data?.phoneNumber?.slice(3),
    email: data?.email,
  });

  const isNoChanges = () => {
    const originalData = Object.entries({
      ...data,
      name: `${data.firstName} ${data.lastName}`,
    }).map((entry) => `${entry[0]} ${entry[1]}`);
    const updatedData = Object.entries({
      ...updatedCustomer,
      phoneNumber: `+63${updatedCustomer.phoneNumber}`,
    }).map((entry) => `${entry[0]} ${entry[1]}`);
    const result = updatedData
      .map((item) => originalData.includes(item))
      .some((res) => res === false);
    return result;
  };

  const onChange = (e, name) => {
    e.preventDefault();
    setUpdatedCustomer({
      ...updatedCustomer,
      [name]: e.target.value,
    });
  };

  const showError = () => {
    setErrMessage('No Changes made!');
    setOpenAlert(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isChange = isNoChanges();
    if (!isChange) {
      return showError();
    }
    try {
      dispatch.UI.setIsLoading(true);
      const res = await updateCustomer({ id: editCustomerId, updatedCustomer });
      if (res?.isSuccess) {
        dispatch.UI.updateFormId({ target: 'editCustomer', value: null });
        dispatch.UI.setAlert({ message: 'Customer updated', type: 'Success' });
        dispatch.UI.setIsLoading(false);
        dispatch.Table.getFirebaseData({ name: 'customer_profile', table: 'customers' })
      }
    } catch (err: any) {
      let errMsg = err?.data?.error ?? 'Unable to update customer';
      dispatch.UI.setIsLoading(false);
      setOpenAlert(true);
      setErrMessage(errMsg);
    }
  };

  const isVerified = async () => {
    const res = await verifyCustomerSetPassword(editCustomerId);
    if (res.isPasswordSet) {
      setVerified(true);
    } else {
      setVerified(false);
    }
  };

  const date = new Date(data?.createdAt?.seconds * 1000);
  const dataCreated = moment(date).format('MMMM Do YYYY, h:mm:ss a');

  useEffect(() => {
    isVerified();
  }, []);
  return (
    <>
      <div className="side absolute inset-y-0 right-0 px-0">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <form onSubmit={handleSubmit}>
              <div className="flex py-2 border-b border-grey-6">
                <BackDrop />
                <IoCloseSharp
                  onClick={() => {
                    dispatch.UI.updateFormId({
                      target: 'editCustomer',
                      value: null,
                    });
                  }}
                  className="my-auto mx-2 text-lg cursor-pointer"
                />
                <Span weight="semi" size="lg">
                  Update Customer
                </Span>
              </div>

              <div className="text-left py-4">
                <Span weight="semi" size="lg">
                  Customer Information
                </Span>

                <Span margin="mt-4">ID</Span>
                <Input placeholder="ID" value={data.id} disabled />

                <Span margin="mt-4">Name</Span>
                <Input
                  placeholder="Name"
                  value={updatedCustomer?.name}
                  required
                  onChange={(e: any) => onChange(e, 'name')}
                />

                <Span margin="mt-4">Phone Number</Span>
                <Input
                  placeholder="Phone Number"
                  maxLength={10}
                  minLength={10}
                  value={updatedCustomer.phoneNumber}
                  onKeyPress={onlyNumberKey}
                  required
                  onChange={(e: any) => onChange(e, 'phoneNumber')}
                />

                <Span margin="mt-4">Email</Span>
                <Input
                  placeholder="Email"
                  type="email"
                  value={updatedCustomer.email}
                  required
                  onChange={(e: any) => onChange(e, 'email')}
                />

                <Span margin="mt-4">Date Created</Span>
                <Input
                  placeholder="Date Created"
                  disabled
                  value={dataCreated}
                />

                <Span margin="mt-4">Status</Span>
                <Span
                  margin="mt-4"
                  border={verified ? 'green' : 'red'}
                  color={verified ? 'green' : 'red'}
                  padding="p-2"
                  radius="rounded"
                >
                  {verified ? 'Active' : 'Inactive'}
                </Span>
              </div>

              <PostErrorAlert
                openAlert={openAlert}
                setOpenAlert={setOpenAlert}
                message={errMessage}
              />

              <div className="flex inset-x-0 bottom-0 py-4">
                <Button
                  margin="mx-2"
                  bg="white"
                  border="lightgrey"
                  color="black"
                  onClick={() => {
                    dispatch.UI.updateFormId({
                      target: 'editCustomer',
                      value: null,
                    });
                  }}
                >
                  Cancel
                </Button>
                <Button margin="mx-2" type="submit">
                  Update Customer
                </Button>
              </div>
            </form>
          </>
        )}
      </div>

      {edit && (
        <>
          <div className="popup_edit">
            <div className="flex">
              <RiErrorWarningFill className="text-xl my-auto text-orange-1 cursor-pointer" />
              <Span margin="mx-2">
                Discard changes made in the user information?
              </Span>
            </div>

            <div className="flex justify-end py-2">
              <Button
                margin="mx-2"
                bg="white"
                border="lightgrey"
                color="black"
                width="none"
                padding="px-2"
                radius="none"
                onClick={() => setEdit(false)}
              >
                Cancel
              </Button>
              {/* Link to='onClick={() => history.push("/customers/")} */}
              <Button
                margin="mx-2"
                width="none"
                padding="px-2"
                radius="none"
                onClick={() => window.location.reload()}
              >
                Discard
              </Button>
              {/* </Link> */}
            </div>
          </div>
        </>
      )}
    </>
  );
};
