/* eslint-disable import/no-anonymous-default-export */

import { useState } from 'react';

import { Span } from '../../components/common/Span';
import { Input } from '../../components/common/Input';
import { Button } from '../../components/common/Button';

import { IoCloseSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import PostErrorAlert from '../../components/PostErrorAlert';
import BackDrop from '../../components/BackDrop';
import Location from '../../components/Location/Location';
import Province from '../../components/Location/Province';
import { updateWarehouse } from '../../common/api';
import { InputContainer } from '../../components/common/Containers';
import { Select } from '../../components/common/Select';

type Props = {
  editWarehouseId: string;
};

export default ({ editWarehouseId, ...props }: Props) => {
  const dispatch = useDispatch<Dispatch>();

  const [openAlert, setOpenAlert] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const whData = useSelector((state: RootState) => state.Table.tables.warehouse.data).find(wh => wh.id === editWarehouseId);

  const initialState = {
    name: whData?.name,
    island: whData?.island,
    location: whData?.location,
    serviceArea: whData?.serviceArea
  }

  const [warehouse, setWarehouse] = useState(initialState);

  const handleChange = async (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    setWarehouse({
      ...warehouse,
      [name]: value
    })
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch.UI.setIsLoading(true);
      const res = await updateWarehouse(editWarehouseId, warehouse);
      if (res?.isSuccess) {
        dispatch.UI.setAlert({ message: "Warehouse updated!", type: "Success" });
        dispatch.UI.setIsLoading(false);
        dispatch.UI.updateFormId({ target: 'editWarehouse', value: null });
      }
    } catch (err: any) {
      const errMsg = err?.data?.error ?? "Unable to update Warehouse";
      dispatch.UI.setIsLoading(false);
      setOpenAlert(true);
      setErrMessage(errMsg);
    }
  };

  return (
    <>
      <div className="side absolute inset-y-0 right-0 px-0">
        <div className="flex py-4 border-b border-grey-6">
          <IoCloseSharp
            onClick={() => {
              dispatch.UI.updateFormId({ target: 'editWarehouse', value: null });
            }}
            className="my-auto mx-2 text-lg cursor-pointer"
          />
          <Span weight="semi" size="lg">
            Update Warehouse
          </Span>
        </div>
        <BackDrop />
        <form className="mt-4" onSubmit={handleSubmit}>
          <div className="text-left py-4 mt-2">
            <div className="text-left py-4">
              <Span weight="semi" size="lg">
                Warehouse
              </Span>

              <Span margin="mt-4">Name</Span>
              <Input
                onChange={handleChange}
                name='name'
                value={warehouse?.name ?? ''}
                placeholder="Warehouse Name"
                required
              />

              <Span margin="mt-4">Island</Span>
              <InputContainer margin="none">
                <Select
                  required
                  name='island'
                  value={warehouse?.island ?? ''}
                  onChange={handleChange}
                >
                  <option disabled selected>Choose Island</option>
                  <option value='Luzon' >Luzon</option>
                  <option value='Boracay' >Boracay</option>
                  <option value='Mindanao' >Mindanao</option>
                  <option value='Cebu' >Cebu</option>
                  <option value='Masbate Island' >Masbate Island</option>
                  <option value='Panay' >Panay</option>
                  <option value='Mindoro' >Mindoro</option>
                  <option value='Visayas' >Visayas</option>
                </Select>
              </InputContainer>
              
              <Location setWarehouse={setWarehouse} warehouse={warehouse} />

              <Province setWarehouse={setWarehouse} warehouse={warehouse} />
            </div>

            <PostErrorAlert
              openAlert={openAlert}
              setOpenAlert={setOpenAlert}
              message={errMessage}
            />
          </div>

          <div className="flex h-[30vh]" />
          <div className="flex inset-x-0 bottom-0 py-4">
            <Button
              margin="mx-2"
              bg="white"
              border="lightgrey"
              color="black"
              onClick={() =>
                dispatch.UI.updateFormId({
                  target: 'editWarehouse',
                  value: null,
                })
              }
            >
              Cancel
            </Button>
            <Button margin="mx-2" type="submit">
              Update Warehouse
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};
