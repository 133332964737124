/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */
import { useEffect, useState } from "react";
import { Span, SpanContainer } from "../../../components/common/Span";
import { Input } from "../../../components/common/Input";
import { Button } from "../../../components/common/Button";
import { Select } from "../../../components/common/Select";
import { onlyNumberKey } from "../../../common/utils";
import { GridContainer, InputContainer } from "../../../components/common/Containers";
import "../CSS/Parcel.css";
import { IoCloseSharp } from "react-icons/io5";
import { RiErrorWarningFill } from "react-icons/ri";

import ParcelNext from "./ParcelNext";
import {
  doc,
  collection,
  query,
  getDocs,
  getDoc,
  getFirestore,
} from "firebase/firestore";

import { Autocomplete, TextField } from "@mui/material";

type Props = {
  setAddParcel: Function;
};
let UNSUBSCRIBE: any;

export default ({ setAddParcel, ...props }: Props) => {
  const [cancel, setCancel] = useState(false);
  const [next, setNext] = useState(false);
  let [user, setUsers] = useState([] as any);

  const [data, setData] = useState({
    packageDetails: {
      value: '', 
      size: '',
      weight: '',
      height: '',
      length: '',
      width : '',
      transportMode: '',
      paymentType: '',  
      itemDescription: '', 
    },
    consignee: {
        consigneeName: '',
        dropOffArea : '',
        address: '',
        phoneNumber: '',
        email: '', 
    }, 
    shipper: {
        name: '',
        address : '', 
        pickUpArea: '',
        phoneNumber: '',
        email: '',  
    },
    pickUpDate: '',
    customerName: '',
    customerId : '',
    reference1 : '',
    reference2 : '',
    reference3 : '',
    remarks: ''
  }) as any;

  const onChange = (e: any, name: string, dest: string) => {
      const value = e.target.value;
      if(dest === 'none'){
        setData({
          ...data,
          [name]: value
        })
      }else{
        setData({
          ...data,
          [dest]:{
            ...data[dest],
            [name]: value
          }
        })
      }
  }

  const handleNext = (e) => {
    e.preventDefault();
    setNext(true);
  }

  const db = getFirestore();

  const getData = async () => {
    let data: any = [];
    if (UNSUBSCRIBE) UNSUBSCRIBE();

    const q = query(collection(db, "customer_profile"));

    const querySnapshot = await getDocs(q);
    UNSUBSCRIBE = querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });

    setUsers(
      data.map((d) => ({ id: d.id, label: d.firstName + " " + d.lastName }))
    );
  };

  let [location, setLocation] = useState([] as any); 
  const getLocation = async () => {
    // let data: any = [];
    // if (UNSUBSCRIBE) UNSUBSCRIBE();

    const docRef = doc(db, "config", "parcel_delivery");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      const data = docSnap.data()?.areas ?? [];
      setLocation(data);
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }

    // const q = query(collection(db, "config", "parcel_delivery"));

    // const querySnapshot = await getDocs(q);
    // UNSUBSCRIBE = querySnapshot.forEach((doc) => {
    //   data.push(doc.data());
    // });

    // setLocation(data[0].areas);
    // console.log(data)
  }; 

  useEffect(() => {
    getData();
    getLocation();
  }, []);

  return (
    <>
      <div className="side-parcel absolute inset-y-0 right-0 px-0">
        <div className="flex py-4 border-b border-grey-6">
          <IoCloseSharp
            onClick={() => setAddParcel(false)}
            className="my-auto mx-2 text-lg cursor-pointer"
          />
          <Span weight="semi" size="lg">
            Add Parcel
          </Span>
        </div>

        <form name="form" onSubmit={handleNext}>
          <div className="text-left py-4">
            <div className="flex">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={user}
                sx={{ width: 300 }}
                onChange={(e, newValue: any) => {
                  setData({
                    ...data,
                    customerName: newValue.label,
                    customerId: newValue.id
                  })
                }}
                renderInput={(params) => <TextField {...params} label="Customer" required />}
              />
            </div>
          </div>

            <Span margin="mt-4" size="lg" weight="semi">
              Shipper Information
            </Span>
            <GridContainer padding="none">
              <div className="col-span-2">
                <Span margin="mt-4">Name</Span>
                <Input
                  required
                  placeholder="Name"
                  type="text"
                  id="merchantName"
                  onChange={(e: any) => onChange(e, 'name', 'shipper')}
                />
              </div>

              <div>
                <Span margin="mt-4">Phone Number</Span> 
                  <InputContainer margin="none">
                    <SpanContainer padding="p-2" position="absolute">
                        +63
                    </SpanContainer>
                    <Input
                        onKeyPress={onlyNumberKey}
                        required
                        type='tel'
                        placeholder="Phone Number" 
                        padding="pl-10" maxLength={10} minLength={10}
                        id="merchantPhone"
                        onChange={(e: any) => onChange(e, 'phoneNumber', 'shipper')}
                    />
                  </InputContainer>
                  </div>
            </GridContainer>

            <div>
              <Span margin="mt-4">Email</Span>
              <Input
                required
                placeholder="Email"
                type="email"
                id="merchantEmail"
                onChange={(e: any) => onChange(e, 'email', 'shipper')}
              />
            </div>

            <div className="flex space-x-5">
              <div className="flex-auto w-20"> 
                <Span margin="mt-4">Pick Up Area</Span>  
                <Select
                onChange={(e: any) => onChange(e, 'pickUpArea', 'shipper')}
                required
              >
                 <option value="" selected disabled hidden>Pick Up Area</option>
                {location?.map((loc:any) => { 
                  console.log(loc)
                  return ( 
                    <option className='capitalize'>{loc}</option>
                  );
                })}
              </Select> 
              </div> 

              <div className="flex-auto w-80">
                <Span margin="mt-4">Address</Span>
                <Input
                  placeholder="Address"
                  type="text"
                  id="merchantAddress"
                  required
                  onChange={(e: any) => onChange(e, 'address', 'shipper')}
                />
            </div>
          </div>

          <div className="text-left py-4">
            <Span size="lg" weight="semi">
              Consignee Information
            </Span>
            <GridContainer padding="none">
              <div className="col-span-2">
                <Span margin="mt-4">Name</Span>
                <Input
                  required
                  placeholder="Name"
                  type="text"
                  id="consigneeName"
                  onChange={(e: any) => onChange(e, 'consigneeName', 'consignee')}
                />
              </div>

              <div>
                <Span margin="mt-4">Phone Number</Span>  
                  <InputContainer margin="none">
                    <SpanContainer padding="p-2" position="absolute">
                        +63
                    </SpanContainer>
                    <Input
                        minLength={10}
                        onKeyPress={onlyNumberKey}
                        required
                        placeholder="Phone Number" 
                        padding="pl-10" type="phone" maxLength={10} 
                        id="consigneePhone"
                        onChange={(e: any) => onChange(e, 'phoneNumber', 'consignee')}
                    />
                  </InputContainer>
              </div>
            </GridContainer>

            <div>
              <Span margin="mt-4">Email</Span>
              <Input
                required
                placeholder="Email"
                type="email"
                id="consigneeEmail"
                onChange={(e: any) => onChange(e, 'email', 'consignee')}
              />
            </div>

            <div className="flex space-x-5">
              <div className="flex-auto w-20">
                <Span margin="mt-4">Drop Off Area</Span>
                <Select
                  id="dropOffArea"
                  onChange={(e: any) => onChange(e, 'dropOffArea', 'consignee')}
                >
                  <option value="" selected disabled hidden>Drop Off</option>
                  {location?.map((loc:any) => { 
                    return ( 
                      <option className='capitalize'>{loc}</option>
                    );
                  })}
                </Select> 
              </div>

              <div className="flex-auto w-80">
                <Span margin="mt-4">Address</Span>
                <Input
                  required
                  placeholder="Address"
                  type="text"
                  id="consigneeAddress"
                  onChange={(e: any) => onChange(e, 'address', 'consignee')}
                />
              </div>
            </div>
          </div>
          <div className="flex inset-x-0 bottom-0 py-4 text-center">
            <Button
              margin="mx-2"
              bg="white"
              border="lightgrey"
              color="black"
              onClick={() => setAddParcel(false)}
            >
              Cancel
            </Button>
            <Button margin="mx-2" type="submit" >
              Next
            </Button>
          </div>
        </form>
      </div>

      {cancel && (
        <>
          <div className="popup_edit">
            <div className="flex">
              <RiErrorWarningFill className="text-xl my-auto text-orange-1" />
              <Span margin="mx-2">
                Discard changes made in the user information?
              </Span>
            </div>

            <div className="flex justify-end py-2">
              <Button
                margin="mx-2"
                bg="white"
                border="lightgrey"
                color="black"
                width="none"
                padding="px-2"
                radius="none"
                onClick={() => setCancel(false)}
              >
                Cancel
              </Button>
              <Button
                margin="mx-2"
                width="none"
                padding="px-2"
                radius="none"
                onClick={() => window.location.reload()}
              >
                Discard
              </Button>
            </div>
          </div>
        </>
      )}

      {next && (
        <>
          <ParcelNext setNext={setNext} data={data} setData={setData} onChange={onChange}/>
        </>
      )}
    </>
  );
};
