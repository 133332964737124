import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';
import { Input } from '../common/Input'

export function ColumnFilter({
    column: {
      id,
      filterValue, 
      setFilter,
    },
   }) {
    const dispatch = useDispatch<Dispatch>();


    return (
      <Input 
        width='none'
        value={filterValue || ""}
        onChange={(e:any) => { 

          
          setFilter(e.target.value || undefined);
        }} 
      />
    );
   }
    