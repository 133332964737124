import { Models } from "@rematch/core";

import { User } from "./UserModel";
import { UI } from "./UIModel";
import { Table } from "./TableModel";
import { Parcel } from "./ParcelModel";
import { Express } from './ExpressModel';

export interface RootModel extends Models<RootModel> {
  User: typeof User;
  UI: typeof UI;
  Table: typeof Table;
  Parcel: typeof Parcel;
  Express: typeof Express;
}

export const models: RootModel = {
  User,
  UI,
  Table,
  Parcel,
  Express
};
