import { Checkbox, FormControlLabel } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

const Action = ({ permissions, setPermissions, item, name, CA }) => {
  const [checked, setChecked] = useState(false);
  const checkboxRef: any = useRef(null);

  useEffect(() => {
    const data = {
      name: item?.name,
      action: item?.action,
    };
    switch (checked) {
      case true:
        return setPermissions({ type: 'ADD_ACTION', payload: { name, data } });
      case false:
        return setPermissions({
          type: 'DELETE_ACTION',
          payload: { name, data },
        });
    }
  }, [checked]);

  useEffect(() => {
    if (CA?.action?.includes(item?.action)) {
      checkboxRef?.current?.click();
    }
  }, []);
  return (
    <div
      key={item.name}
      className="mt-2 flex items-center justify-start flex-row ml-12"
    >
      <FormControlLabel
        sx={{ height: '20px' }}
        label={item.name}
        control={
          <Checkbox
            ref={checkboxRef}
            // checked={checked[0] && checked[1]}
            // indeterminate={checked[0] !== checked[1]}
            onChange={() => {
              setChecked(!checked);
            }}
            color="default"
          />
        }
      />
    </div>
  );
};

export default Action;
