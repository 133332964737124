import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from '../../store';
import { useLocation } from 'react-router-dom';
import { CenterContainer, InputContainer } from '../../components/common/Containers';
import { Input } from '../../components/common/Input';
import { Button } from '../../components/common/Button';
import AlertSnackBar from '../../components/AlertSnackBar';
import { SpinnerCircularFixed } from 'spinners-react';
import designbg from '../../images/login_design3.png'
import designbg1 from '../../images/login_design.png'

import loginlogo from '../../images/itlogiclogo.png'
import username_icon from '../../images/username.png'
import password_icon from '../../images/password.png'

import { setCustomerPassword, setAdminUserPassword, verifyCustomerSetPassword, verifyAdminUserSetPassword, verifyDriverSetPassword, setDriverPassword } from '../../common/api';
import { Span } from '../../components/common/Span';
import jwtDecode from 'jwt-decode';
import { BRAND_IMAGE } from '../../components/constants';

function useQuery() {
    return new URLSearchParams(useLocation().search)
}


export default () => {

    const dispatch = useDispatch<Dispatch>();

    const query = useQuery();
    const token: any = query.get('token')
    const decoded: any = jwtDecode(token);
    const [count, setCount] = useState(5);
    const [isChangePassword, setIsChangePassword] = useState(true);
    const [resetPassword, setResetPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [password, setPassword] = useState({
        newPassword: '',
        confirmPassword: '',
        userId: decoded?.userId,
        userType: decoded?.userType,
    });

    const onChange = (name, e) => {
        setPassword({
            ...password,
            [name]: e.target.value
        })
    }

    const handleSubmit = async (e: any, btnType: string) => {
        e.preventDefault();
        if (password.newPassword !== password.confirmPassword) {
            setOpen(true)
            return dispatch.UI.setAlert({ message: "'New Password' and 'Confirm Password' not matched!", type: 'Error' })
        }
        if (btnType === 'RESET') {
            try {
                setIsLoading(true)
                let res;
                if (password.userType === 'customer-resetpassword') {
                    res = await setCustomerPassword(password.userId, { password: password.confirmPassword })
                } else if (password.userType === 'useradmin-resetpassword') {
                    res = await setAdminUserPassword(password.userId, { password: password.confirmPassword })
                } else if (password.userType === 'driver-resetpassword') {
                    res = await setDriverPassword(password.userId, { password: password.confirmPassword })
                }

                if (res.isSuccess) {
                    setResetPassword(true);
                }
                setIsLoading(false)
            } catch (err: any) {
                let errMsg = 'Unable to reset password'
                if (err.data.error !== undefined) {
                    errMsg = err.data.error
                }
                dispatch.UI.setAlert({ message: errMsg, type: 'Error' });
            }
        }

        if (btnType === 'OK') {
            setIsLoading(true);
            if (password.userType === 'customer-resetpassword') return window.location.href = process.env.REACT_APP_HOME_PAGE ?? '';
            if (password.userType === 'useradmin-resetpassword') return window.location.href = process.env.REACT_APP_BASE_URL_WITHOUT_SSL ?? '';
            if (password.userType === 'driver-resetpassword') return window.close();
        }
    }

    const verifyLink = async () => {
        try {
            let res;
            if (password.userType === 'customer-resetpassword') {
                res = await verifyCustomerSetPassword(password.userId)
            } else if (password.userType === 'useradmin-resetpassword') {
                res = await verifyAdminUserSetPassword(password.userId)
            } else if (password.userType === 'driver-resetpassword') {
                res = await verifyDriverSetPassword(password.userId)
            }

            if (res.isChangePassword) {
                setIsChangePassword(true);
                setIsLoading(false)
            }

            if (!res.isChangePassword) {
                setIsChangePassword(false);
                dispatch.UI.setAlert({ message: 'Link has expire', type: 'Error' });
            }
        } catch (err: any) {
            let errMsg = 'Unable to verify account'
            if (err.data.error !== undefined) {
                errMsg = err.data.error
            }
            dispatch.UI.setAlert({ message: errMsg, type: 'Error' });
        }
    }

    useEffect(() => {
        if (!isChangePassword) {
            let timer = setInterval(() => {
                setCount(count - 1);
            }, 1000);
            if (count === 0) {
                clearInterval(timer)
                return window.close();
            }
        }
    });

    useEffect(() => {
        verifyLink();
    }, []);
    return (<>
        <AlertSnackBar open={open} setOpen={setOpen} />
        <div className='min-h-screen overflow-hidden"'>
            <form onSubmit={(e) => handleSubmit(e, resetPassword ? 'OK' : 'RESET')}>
                <img src={designbg} alt="" className='absolute top-0 right-0' />
                <img src={designbg1} alt="" className='absolute bottom-0 left-0' />

                <CenterContainer>
                    <img src={BRAND_IMAGE['BRAND_LOGO']} alt="" className='login_logo' />
                    <Span weight='bold' align='center'>{!isChangePassword ? 'This link has expire!' : 'Change Password'}</Span>
                    {!isLoading ? <>
                        {resetPassword ? (
                            <>
                                <Span align='center'>You can now use your new password</Span>
                            </>
                        ) : (
                            <>
                                <InputContainer margin='mt-4'>
                                    <Input padding='pl-10'
                                        onChange={(e: any) => onChange('newPassword', e)}
                                        type="password" placeholder='New Password'
                                        required
                                        minLength={8}
                                    />
                                    <img src={password_icon} alt="" className='login_icon' />
                                </InputContainer>

                                <InputContainer margin='mt-4'>
                                    <Input padding='pl-10'
                                        onChange={(e: any) => onChange('confirmPassword', e)}
                                        type="password" placeholder='Confirm Password'
                                        required
                                        minLength={8}
                                    />
                                    <img src={password_icon} alt="" className='login_icon' />
                                </InputContainer>
                            </>
                        )}

                        <Button
                            shadow='grey' margin='mt-6' type='submit'>
                            {resetPassword ? 'OK' : 'Reset Password'}
                        </Button>
                    </> : <>
                        {!isChangePassword ? (
                            <Span weight='bold' align='center'>{`Page will close in  ${count} . . .`}</Span>
                        ) : (

                            <div className='my-auto items-center overscroll-none z-10 bg-white'>
                                <SpinnerCircularFixed
                                    className='m-auto'
                                    style={{ color: '#1c1c1c' }}
                                />
                            </div>
                        )}
                    </>}
                </CenterContainer>
            </form>
        </div>
    </>)
}