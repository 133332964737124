import WHManagementPage from '../containers/Warehouse/Warehouse';  
import Header from '../components/Header/Header' 
import SidebarPage from '../components/Sidebar/Sidebar'
import { MainContainer } from '../components/common/Containers' 
 
const RoleManagement = () => {
    return ( 
        <>  
            <SidebarPage />
            <MainContainer>    
                <Header /> 
                <main> 
                    <WHManagementPage />  
                </main> 
            </MainContainer>
        </>
    )
}

export default RoleManagement
