import { Span } from "../../../components/common/Span";
import { EXPRESS } from "../../../components/constants/status";

import { ColumnFilter } from "./filter";
import moment from "moment";

export const COLUMNS = [
  {
    id: "orderCode",
    field: "orderCode",
    accessor: "orderCode",
    Header: "Order Number",
    Filter: ColumnFilter,
    isVisible: true,
  },
  {
    id: "ordertype",
    field: "ordertype",
    accessor: "ordertype",
    Header: "Delivery Type",
    Filter: ColumnFilter,
    isVisible: true,
  },
  {
    id: "pickup",
    field: "pickup",
    accessor: data => data?.pickup.location?.address,
    Header: "Pick Up",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data.row.original;
      const pickupLA = d.pickup.location?.address;
      return (
        <div className="p-2">
          <span>{pickupLA}</span>
        </div>
      );
    },
  },
  {
    id: "dropOff",
    field: "dropOff",
    accessor: data => data?.dropOff.location?.address,
    Header: "Drop Off",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data.row.original;
      const dropOffLA = d.dropOff.location?.address;
      return (
        <div className="p-2">
          <span>{dropOffLA}</span>
        </div>
      );
    },
  },
  {
    accessor: (data) => {
      const date = new Date(data?.createdAt?.seconds * 1000);
      const formatedDate = moment(date).format("MMMM Do YYYY, h:mm:ss a");
      return formatedDate;
    },
    id: "createdAt",
    field: "createdAt",
    Header: "Date&Time Placed",
    isVisible: true,
    Cell: (data) => {
      const d = data.row.original;
      let date = new Date(1000);

      if (d?.createdAt?.seconds) {
        date = new Date(d?.createdAt?.seconds * 1000);
      }
      return <>{moment(date).format("MMMM Do YYYY, h:mm:ss a")}</>;
    },
  },
  // {
  //   id: "orderType",
  //   field: "orderType",
  //   accessor: "orderType",
  //   Header: "Order Type",
  //   Filter: ColumnFilter,
  //   isVisible: true,
  //   Cell: (data) => {
  //     const orderType = data.row.original.ordertype;
  //     return <>{`${orderType?.charAt(0).toUpperCase()}${orderType.slice(1)}`}</>;
  //   },
  // },
  {
    id: "deliverySchedule",
    field: "deliverySchedule",
    accessor: data => {
      const date = new Date(data?.deliverySchedule?.seconds * 1000);
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
    Header: "Delivery Schedule",
    Filter: ColumnFilter,
    isVisible: false,
    Cell: (data) => {
      const d = data.row.original;
      let date = new Date(1000);
      
      if (d?.deliverySchedule?.seconds) {
        date = new Date(d?.deliverySchedule?.seconds * 1000);
      }
      return <>{`${moment(date).format("MMMM Do YYYY, h:mm:ss a")}`}</>;
    },
  },
  {
    id: "status",
    field: "status",
    accessor: "status",
    Header: "Status",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data.row.original;
      return (
        <div className="flex justify-center whitespace-nowrap">
          <Span
            border="red"
            bg="red"
            padding="px-4"
            width="full"
            margin="mx-4"
            color="red"
            radius="xl"
            size="sm"
            transform="capitalize"
          >
            {EXPRESS[d.status]}
          </Span>
        </div>
      );
    },
  },
  {
    id: "commission",
    field: "commission",
    accessor:'commission',
    Header: "Commission",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data.row.original;

      const commission = (d.pickupCommission ?? d.commission) / 100;
      return (
        <div className="p-2">
          <span>{commission}%</span>
        </div>
      );
    },
  },
  {
    accessor: (data) => {
      const total = parseFloat(data?.totalCharge)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return total;
    },
    id: "totalCharge",
    field: "totalCharge",
    Header: "Grand Total",
    Filter: ColumnFilter,
    isVisible: true,
    Cell: (data) => {
      const d = data.row.original;
      return (
        <span className="text-brown-1 text-base">
          ₱{" "}
          {parseFloat(d?.totalCharge)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </span>
      );
    },
  },
];
