import { createModel } from "@rematch/core";
import { RootModel } from "./index";

type Substatus = { 
    substatus: string; 
}

type ParcelType = { 
    customerId?:string,
    merchantName?: string,
    pickUpArea?:string,
    merchantAddress?: string,
    merchantPhone?: string,
    merchantEmail?: string, 
    consigneeName?: string,
    dropOffArea?:string,
    consigneeAddress?: string,
    consigneePhone?: string,
    consigneeEmail?: string, 
    value?: string,
    size?: string,
    weight?: string,
    height?: string,
    length?: string,
    width?: string,
    deliveryType?: string,
    paymentType?: string,
    itemDescription?: string,
    reference1?: string,
    reference2?: string,
    reference3?: string,
    remarks?: string
}

  const initialState = { 
    parcel: {},
    substatus: {}
  }

  export const Parcel = createModel<RootModel>()({
    state: initialState, 
    reducers: {
        setParcel(state, payload: ParcelType) {
            return {...state, parcel: payload};
        },
        setSubstatus(state, payload: Substatus) {
            return {...state, substatus: payload};
        },
    },
    effects: (dispatch) => ({
        async updateParcel(payload: ParcelType, state){
            dispatch.Parcel.setParcel(payload);
        },
        async updateSubstatus(payload: Substatus, state){
            dispatch.Parcel.setSubstatus(payload);
        },
        async cleanCart(payload:boolean, state) {
            dispatch.Parcel.updateParcel({});
          },
        async parcel(payload: ParcelType, state) {  
            dispatch.Parcel.updateParcel({});
            
            dispatch.Parcel.cleanCart(true); 
      
          },
    }),
});