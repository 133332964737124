import { useEffect, useMemo, useState} from 'react' 
import { Link, useParams } from 'react-router-dom'  

import { Span } from '../../../../components/common/Span' 
import { Button } from '../../../../components/common/Button' 
import { Container } from '../../../../components/common/Containers'
import Table from '../../../../components/Table/Table' 

import { BiPlusCircle } from 'react-icons/bi'
import { ImArrowLeft2 } from 'react-icons/im'

import { COLUMNS } from './Table/column'   
import NoData from '../../../../components/common/NoData/NoData'

import { Dispatch, RootState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';

import UpdateWallet from './Update/UpdateWallet'
import wallet from '../../../../images/walletadmin_icon.png'
import {doc, getFirestore, onSnapshot} from 'firebase/firestore'

let UNSUBSCRIBE: any;

export default () => {   
    
    let { id, } = useParams();

    const dispatch = useDispatch<Dispatch>();

    const [userWallet, setUserWallet] = useState() as any;
    const data: any[] = useSelector((state: RootState) => state.Table.tables.transaction_log.data)
        .filter(d => d.wallet?.id === id)
        .sort((a, b) => {return a.createdAt.seconds - b.createdAt.seconds}) 
    
    const [updateWallet, setUpdateWallet] = useState(false);
    
    const db = getFirestore();
    const getData = async() => {
        if(UNSUBSCRIBE) UNSUBSCRIBE()

        UNSUBSCRIBE = onSnapshot(doc(db,'wallet', id), (doc) => {
            if(doc.exists()){
                setUserWallet(doc.data())
            }else{
                setUserWallet("No such document!")
            }
        })
    }

    useEffect(() => {
        getData();
    },[]);
    
    useEffect(() => {
        dispatch.Table.subscribeToFirebase({name: 'wallet_transaction', table: 'transaction_log'})

        return () => {
            dispatch.Table.UNSUBSCRIBE()
        }
    },[]); 
    
    const columns = useMemo(() => COLUMNS, [])
    return (<>
    <Container border='grey' padding='none'>
        <div className='flex justify-between p-4'>
            <div className='flex py-4'>
                <Link to='/wallet/walletList' className='my-auto text-grey-14'>
                    <ImArrowLeft2  className='my-auto'/>
                </Link>
                <Span margin='my-auto' weight='bold' size='2xl' padding='px-4' transform='capitalize'>
                    {userWallet?.name}
                </Span> 
            </div>  
                
            <div className='flex justify-end p-4'>
            <div className="flex border-2 border-grey-2 p-2 mx-4 rounded">
                <img src={wallet} alt={wallet} className="h-6 mr-2"/>
                <Span weight="semi">
                PHP {parseFloat(userWallet?.balance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Span>
            </div>

                <Button width='none' 
                onClick={() => {setUpdateWallet(true);}}
                >
                    <div className='flex pr-2'>
                        <BiPlusCircle className='my-auto mx-2 text-2xl'/> 
                        Update Wallet
                    </div>
                </Button>
            </div>
        </div>  

        <div className='overflow-auto'>
            {data?.length < 0 
                ? <NoData />
                : <Table columns={columns} data={data}/> 
            }           
        </div>        
    </Container>     

        <UpdateWallet setUpdateWallet={setUpdateWallet} updateWallet={updateWallet} id={id}/>
    </>)
}