import parcel from '../../images/parcelgray_icon.png';
import customer from '../../images/customergray_icon.png';
import user from '../../images/usergray_icon.png';
import express from '../../images/expressgray_icon.png';
import driver from '../../images/drivergray_icon.png';
import rate from '../../images/rate_icon.png';
import wallet from '../../images/wallet_icon.png';
import billing from '../../images/Billing_icongray.png';
import role from '../../images/rolegray_icon.png';
import warehouse from '../../images/graywarehouse_icon.png';
import interisland from '../../images/interisland_icondisable.png'

import parcelActive from '../../images/parceldefault_icon.png';
import customerActive from '../../images/customerdefault_icon.png';
import userActive from '../../images/userdefault_icon.png';
import expressActive from '../../images/expressdefault_icon.png';
import driverActive from '../../images/driverdefault_icon.png';
import rateActive from '../../images/ratedefault_icon.png';
import walletActive from '../../images/walletgray_icon.png';
import billingActive from '../../images/Billing_iconwhite.png';
import roleActive from '../../images/role_icon.png';
import warehouseActive from '../../images/whitewarehouse_Icon.png'
import interislandActive from '../../images/interislandicon_enable.png'

import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';

export const MenuData = [
  {
    title: 'Parcel',
    path: '/parcels?tab=new',
    icon: <img src={parcel} />,
    iconActive: <img src={parcelActive} />,
    roleType: 'manageParcel',
  },
  {
    title: 'Express',
    path: '/express?tab=new',
    icon: <img src={express} />,
    iconActive: <img src={expressActive} />,
    roleType: 'manageExpress',
  },
  {
    title: 'Interisland',
    path: '/interisland?tab=new',
    icon: <img src={interisland} />,
    iconActive: <img src={interislandActive} />,
    roleType: 'manageInterisland',
  },
  {
    title: 'Customer',
    path: '/customers',
    icon: <img src={customer} />,
    iconActive: <img src={customerActive} />,
    roleType: 'manageCustomers',
  },
  {
    title: 'Users',
    path: '/users',
    icon: <img src={user} />,
    iconActive: <img src={userActive} />,
    roleType: 'manageUsers',
  },
  {
    title: 'Driver',
    path: '/driver',
    icon: <img src={driver} />,
    iconActive: <img src={driverActive} />,
    roleType: 'manageDrivers',
  },
  {
    title: 'Rate',
    path: '/rate/expressRate',
    subName: 'rate',
    icon: <img src={rate} />,
    iconActive: <img src={rateActive} />,
    iconClosed: <IoIosArrowForward />,
    iconOpened: <IoIosArrowDown />,
    subNav: [
      {
        title: 'Express Rate',
        path: '/rate/expressRate',
      },
      {
        title: 'Parcel Rate',
        path: '/rate/parcelRate',
      },
      {
        title: 'Partial Rate',
        path: '/rate/partialRate',
      },
      {
        title: 'Interisland Rate',
        path: '/rate/interislandRate',
      },
    ],
    roleType: 'manageRate',
  },
  {
    title: 'Wallet',
    path: '/wallet/walletList',
    subName: 'wallet',
    icon: <img src={walletActive} />,
    iconActive: <img src={wallet} />,
    iconClosed: <IoIosArrowForward />,
    iconOpened: <IoIosArrowDown />,
    subNav: [
      {
        title: 'List',
        path: '/wallet/walletList',
      },
      {
        title: 'Transaction Log',
        path: '/wallet/transactionLog',
      },
      {
        title: 'Withdrawal Request',
        path: '/wallet/withdrawalRequest?tab=pending',
      },
    ],
    roleType: 'manageWallet',
  },
  {
    title: 'Billing',
    path: '/billing',
    icon: <img src={billing} />,
    iconActive: <img src={billingActive} />,
    roleType: 'manageBilling',
  },
  {
    title: 'Role',
    path: '/role',
    icon: <img src={role} />,
    iconActive: <img src={roleActive} />,
    roleType: 'manageRole',
  },
  {
    title: 'Warehouse',
    path: '/warehouse',
    icon: <img src={warehouse} />,
    iconActive: <img src={warehouseActive} />,
    roleType: 'manageWarehouse',
  },
];
