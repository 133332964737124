import { ColumnFilter } from "../../../../../components/Table/filter"; 
import moment from 'moment';       
import { TRANSACTION } from '../../../../../components/constants/status';    

export const COLUMNS = [
    {
        id: "id",
        field: "id",
        accessor: 'id',
        Header: 'Transaction ID',
        Filter: ColumnFilter, 
        isVisible: true,
    },
    {
        id: "transactionType",
        field: "transactionType",
        accessor: 'transactionType',
        Header: 'Transaction Type',
        Filter: ColumnFilter, 
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return( 
                <span>
                    {TRANSACTION[d.transactionType]}
                </span>   
            )
        }
    },  
    {
        id: "createdBy",
        field: "createdBy",
        accessor: data => data?.processBy === undefined ? `${data?.wallet.name}` : `${data?.processBy?.firstName} ${data?.processBy?.lastName}`,
        Header: 'Created By',
        Filter: ColumnFilter, 
        isVisible: true,
    },  
    {   
        accessor: data => {
            const date = new Date(data?.createdAt?.seconds * 1000);
            const formatedDate = moment(date).format('MMMM Do YYYY, h:mm:ss a')
            return formatedDate;
        },
        id:'dateCreated',
        field: 'dateCreated',
        Header: 'Date Created',
        isVisible: true,
        Cell: data => {
        const d = data.row.original;
        let date = new Date(1000);

        if(d?.createdAt?.seconds){
            date = new Date(d?.createdAt?.seconds * 1000);
        }
        return <>{moment(date).format('MMMM Do YYYY, h:mm:ss a')}</>   }
    },  
    {
        id: "remarks",
        field: "remarks",
        accessor: "remarks",
        Header: 'Remarks',
        Filter: ColumnFilter,
        isVisible: true,        
    }, 
    {
        id: "amount",
        field: "amount",
        accessor: 'amount',
        Header: 'Amount',
        Filter: ColumnFilter, 
        isVisible: true,  
        Cell: data => {
            const d = data.row.original;
            const amount: any = d?.amount ?? 0;
            return (
            <span> 
                ₱ {parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </span>    
            ); 
        }        
    }
]