import { Box, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react';
import { BsCheckCircleFill } from 'react-icons/bs'

const Thankyou = () => {

    const [count, setCount] = useState(10);

    const stackProps = {
        spacing: 3,
        sx: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '5rem 2rem',
        }
    }

    useEffect(() => {
        let timer = setInterval(() => {
            setCount(count - 1);
        }, 1000);
        if (count === 0) {
            clearInterval(timer)
            return window.close();
        }
    }, [count]);

    return (
        <Stack {...stackProps}>
            <BsCheckCircleFill style={{ fontSize: '60px', color: 'green' }} />
            <Typography variant='h4' textAlign='center'>Thank you for submitting, Your application is being reviewed!</Typography>
            <Typography variant='body2' color='GrayText'>{`Page will close in ${count}...`}</Typography>
        </Stack>
    )
}

export default Thankyou