import ExpressRateViewPage from '../containers/Rate/Express/View/ExpressRateView'  
import Header from '../components/Header/Header' 
import SidebarPage from '../components/Sidebar/Sidebar'
import { MainContainer } from '../components/common/Containers' 
 
const ExpressRateView = () => { 

    return ( 
        <>  
            <SidebarPage />
            <MainContainer>    
                <Header /> 
                <main> 
                    <ExpressRateViewPage/>  
                </main> 
            </MainContainer>
        </>
    )
}

export default ExpressRateView
