import { useHistory } from 'react-router';
import { Span } from '../../../../../components/common/Span';
import { Button } from '../../../../../components/common/Button';
import { IoCloseSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../../../store';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PostErrorAlert from '../../../../../components/PostErrorAlert';
import BackDrop from '../../../../../components/BackDrop';
import '../../../CSS/Rate.css';
import { updateExpressRateSettings } from '../../../../../common/api';
import BasicFare from '../Forms/BasicFare';
import Addons from '../Forms/Addons';
import Warehouse from '../Forms/Warehouse';

export default ({ rateExpressId }: { rateExpressId: any }) => {
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();
  const { id } = useParams();

  const [openAlert, setOpenAlert] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const data: any | [] = useSelector((state: RootState) => state.Express.settingData);
  const warehouse = useSelector((state: RootState) => state.Table.tables.warehouse.data);

  const initialState = {
    vehicleType: data?.vehicleType,
    baseFare: data?.baseFare,
    commission: data?.commission,
    standardPricePerKM: data?.standardPricePerKM,
    driverShare: data?.driverShare,
    warehouse: data?.warehouse,
    additionalStopPrice: data?.additionalStopPrice,
    specialHelp: data?.specialHelp,
    customerStaff: data?.customerStaff,
    extraHelp: data?.extraHelp,
    safetyGloves: data?.safetyGloves,
    hardHat: data?.hardHat,
    dayRate: data?.dayRate,
    consolidationDiscount: data?.consolidationDiscount
  }

  const [updatedDetails, setUpdatedDetails] = useState() as any;

  const onChange = (e: any, name: any) => {
    const value = e.target.value;
    setUpdatedDetails({
      ...updatedDetails,
      [name]: value,
    });
  };

  const isDataChange = () => {
    const originalData = Object.entries(data).map((entry) => `${entry[0]} ${entry[1]}`);
    const updatedData = Object.entries(updatedDetails).map((entry) => `${entry[0]} ${entry[1]}`);
    const result = updatedData.map((data) => originalData.includes(data)).some((res) => res === false);
    return result;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isDataChange()) {
      setOpenAlert(true);
      setErrMessage('No changes made!');
      return;
    }
    try {
      dispatch.UI.setIsLoading(true);
      const payload = {
        ...updatedDetails,
        baseFare: Number(updatedDetails.baseFare),
        commission: Number(updatedDetails.commission),
        standardPricePerKM: Number(updatedDetails.standardPricePerKM),
        driverShare: Number(updatedDetails.driverShare),
        additionalStopPrice: Number(updatedDetails.additionalStopPrice),
        specialHelp: Number(updatedDetails.specialHelp),
        customerStaff: Number(updatedDetails.customerStaff),
        extraHelp: Number(updatedDetails.extraHelp),
        safetyGloves: Number(updatedDetails.safetyGloves),
        hardHat: Number(updatedDetails.hardHat),
        dayRate: Number(updatedDetails.dayRate),
        driverTip: Number(0),
        consolidationDiscount: Number(updatedDetails.consolidationDiscount),
      }
      const res = await updateExpressRateSettings(id, rateExpressId, payload);
      if (res.isSuccess) {
        history.push(`/rate/express/${rateExpressId}`);
        dispatch.UI.setAlert({ message: 'Rate card updated', type: 'Success' });
        dispatch.UI.setIsLoading(false);
        //window.location.reload();
      }
    } catch (err: any) {
      const errMsg =
        err?.data?.error === undefined
          ? 'Unable to update rate card'
          : err.data.error;
      setOpenAlert(true);
      setErrMessage(errMsg);
      dispatch.UI.setIsLoading(false);
    }
  };

  useEffect(() => {
    setUpdatedDetails(initialState);
  }, [data])

  return (
    <div className="side-rate">
      <BackDrop />
      <form onSubmit={handleSubmit}>
        <div className="flex py-4 border-b border-grey-6 mb-4">
          <IoCloseSharp
            onClick={() => history.push(`/rate/express/${rateExpressId}`)}
            className="my-auto text-lg cursor-pointer"
          />
          <Span weight="semi" size="xl" margin="mx-2">
            Update Rate
          </Span>
        </div>

        <div>
          <Span size="lg" weight="medium">
            {data.vehicleType.toUpperCase()}
          </Span>
        </div>

        <BasicFare onChange={onChange} data={updatedDetails} />

        <Addons onChange={onChange} data={updatedDetails} />

        <Warehouse onChange={onChange} data={updatedDetails} warehouse={warehouse} />

        <PostErrorAlert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          message={errMessage}
        />

        <div className="flex my-2 py-4 text-center">
          <Button
            margin="mx-2"
            bg="white"
            border="lightgrey"
            color="black"
            onClick={() => history.push(`/rate/express/${rateExpressId}`)}
          >
            Cancel
          </Button>
          <Button margin="mx-2" type="submit">
            Update
          </Button>
        </div>
      </form>
    </div>
  );
};
