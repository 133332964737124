import { GridContainer } from "../../../../components/common/Containers";
import { Span } from "../../../../components/common/Span";
import { BsDot } from "react-icons/bs";
import {
  CANCELED,
  DELIVERED,
  EXPRESS,
  NEW,
  ONGOING,
  PACKAGE_STATUS,
  STATUS,
} from "../../../../components/constants/status";
import { MdPayments } from "react-icons/md";

export default ({ pickUp, status }: any) => {
  return (
    <>
      <GridContainer grid="four" padding="px-8">
        <div>
          <Span weight="semi"> Address: </Span>
          <Span>{pickUp?.locationAddress}</Span>
          <p className="flex">
            <BsDot className="text-grey-11 my-auto" />
            <Span color="lightgrey">{pickUp?.locationDetails}</Span>
          </p>
        </div>

        <div>
          <Span weight="semi"> Contact Person </Span>
          <Span>{pickUp?.contactName}</Span>
        </div>

        <div>
          <Span weight="semi"> Contact Number </Span>
          <Span>{pickUp?.contactPhone}</Span>
        </div>

        <div>
          <Span weight="semi"> Package Status </Span>
          {status === PACKAGE_STATUS.canceled ? (
            <Span weight="semi" color="red">
              CANCELED
            </Span>
          ) : status === PACKAGE_STATUS.failed_delivery ? (
            <Span weight="semi" color="red">
              FAILED DELIVERY
            </Span>
          ) : status === PACKAGE_STATUS.pending ? (
            <Span weight="semi" color="green">
              PENDING
            </Span>
          ) : status === PACKAGE_STATUS.on_going ||
            status === PACKAGE_STATUS.delivered ||
            status === PACKAGE_STATUS.picked_up ||
            (pickUp?.action !== undefined && pickUp?.action === "completed") ? (
            <Span weight="semi" color="blue">
              PICKED UP
            </Span>
          ) : status === PACKAGE_STATUS.assigned ||
            PACKAGE_STATUS.for_pickup ? (
            <Span weight="semi" color="orange">
              ONGOING
            </Span>
          ) : (
            ""
          )}
        </div>

        {pickUp?.isReliableFP && (
          <div className="flex items-center gap-3 col-span-2">
            <Span color="red">
              <MdPayments />
            </Span>
            <Span color="red">Reliable For Payment</Span>
          </div>
        )}
      </GridContainer>
    </>
  );
};
