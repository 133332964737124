import StatusCustomer from './StatusCustomer' 

import { ColumnFilter } from "../../../components/Table/ColumnFilter";   
import moment from 'moment';

export const COLUMNS = [
    {
        accessor: 'id',
        Header: 'ID',
        Filter: ColumnFilter, 
        isVisible: true,
    },
    {
        accessor: data => `${data.firstName} ${data.lastName}`,
        Header: 'Customer Name',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
                <span className='capitalize'>{`${d.firstName} ${d.lastName}`}</span>
            )
        }     
    }, 
    {
        accessor: 'phoneNumber',
        Header: 'Contact No.',
        Filter: ColumnFilter,
        isVisible: true,        
    },      
    {
        accessor: 'email',
        Header: 'Email',
        Filter: ColumnFilter,
        isVisible: true,        
    },  
    {
        accessor: data => {
            const date = new Date(data?.createdAt?.seconds * 1000);
            const formatedDate = moment(date).format('MMMM Do YYYY, h:mm:ss a')
            return formatedDate;
        },
        Header: 'Date Created',
        isVisible: true,
        Cell: data => {
        const d = data.row.original;
        let date = new Date(1000);

        if(d?.createdAt?.seconds){
            date = new Date(d?.createdAt?.seconds * 1000);
        }
        return <>{moment(date).format('MMMM Do YYYY, h:mm:ss a')}</>   }
    },  
    {
        accessor: ' ',
        Header: ' ', 
        Filter: ColumnFilter,
        disableFilters: true, 
        disableSortBy: true,
        isVisible: true, 
        Cell: data => <StatusCustomer id={data.row.original.id} email={data.row.original.email}/> 
    }
]