import { post, put, get, postMultipart, remove } from '../core';
import { getUrl } from '../../utils';

export const createDriver = async (body: any) => {
    try {
        body = {
            ...body,
            phoneNumber: `+63${body.phoneNumber}`,
            url: `${getUrl()}verifyemailandresetpassword`
        }
        const res = await post('/driver/create/user-account', body);
        return res.data;
    } catch (err) {
        return Promise.reject(err);
    }
}

export const updateDriver = async ({ updatedDetails, editDriverId }: any) => {
    try {
        updatedDetails.phoneNumber = `+63${updatedDetails.phoneNumber}`
        updatedDetails.EmergencyContactNumber = `+63${updatedDetails.EmergencyContactNumber}`
        const res = await put(`/driver/update/user-account/${editDriverId}`, updatedDetails)
        return res.data
    } catch (err) {
        return Promise.reject(err);
    }
}

export const updateDriverStatus = async ({ body, id }: any) => {
    try {
        const res = await put(`/update/driver/approval/status/${id}`, body)
        return res.data;
    } catch (err) {
        return Promise.reject(err);
    }
}

export const setDriverPassword = async (userId: any, password: any) => {
    try {
        const res = await put(`/admin/set-driver-password/${userId}`, password)
        return res.data;
    } catch (err) {
        return Promise.reject(err);
    }
}

export const verifyDriverSetPassword = async (userId: any) => {
    try {
        const res = await get(`/admin/check-driver-password/${userId}`)
        return res.data;
    } catch (err) {
        return Promise.reject(err)
    }
}

export const uploadDriverRequirements = async (drivertId: string, files: any) => {
    try {
        const body = new FormData();
        body.append('name', 'drivers_requirements')
        Object.entries(files)?.forEach(([name, file]: any) => {
            body.append(name, file);
        })

        const res = await postMultipart(`/driver/${drivertId}/upload-application`, body)
        return res.data;
    } catch (err) {
        return Promise.reject(err)
    }
}

export const updateDriverInfoByDriver = async (driverId: string, body: any) => {
    try {
        const res = await put(`/driver/${driverId}/update-details`, body);
        return res.data;
    } catch (err) {
        return Promise.reject(err)
    }
}

export const verifyDriverSetDetails = async (driverId: string) => {
    try {
        const res = await get(`/driver/${driverId}/verify-application-link`)
        return res.data;
    } catch (err) {
        return Promise.reject(err)
    }
}

export const sendDriverChangePasswordEmail = async (body: any) => {
    try {
        const res = await post(`/admin/driver/reset-password`, body);
        return res.data;
    } catch (err) {
        return Promise.reject(err)
    }
}

export const deleteDriver = async (id: any) => {
    try {
        const res = await remove(`/driver/delete/user-account/${id}`);
        return res.data;
    } catch (err) {
        return Promise.reject(err)
    }
}