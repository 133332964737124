import { ColumnFilter } from "../../../../components/Table/filter";   

export const COLUMNS = [
    {
        id: "id",
        field: "id",
        accessor: 'id',
        Header: 'ID',
        Filter: ColumnFilter, 
        isVisible: true,
    },
    {
        id: "name",
        field: "name",
        accessor: 'name',
        Header: 'Name',
        Filter: ColumnFilter, 
        isVisible: true,  
        Cell: data => {
            const d = data.row.original;
            return( 
                <span className='capitalize'>
                    {d.name}
                </span>   
            )
        }
    },
    {    
        accessor: data =>data?.email === undefined ? `${data?.driverDetails?.email} ${data?.driverDetails?.phoneNumber}` : `${data?.email} ${data.phoneNumber}`,
        Header: 'Email/Contact No.',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data.row.original?.email === undefined ? data.row.original?.driverDetails : data.row.original;
            return(<>
                <p><span>{d.email}</span></p>
                <p><span>{d.phoneNumber}</span></p>
            </>)
        }    
    },      
    {
        id: "userType",
        field: "userType",
        accessor: 'type',
        Header: 'User Type',
        Filter: ColumnFilter,
        isVisible: true,   
        Cell: data => {
            const d = data.row.original;
            return( 
                <span className='capitalize'>
                    {d.type}
                </span>   
            )
        }     
    }, 
    {
        id: "amount",
        field: "amount",
        accessor: data => `₱ ${parseFloat(data.balance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
        Header: 'Amount',
        Filter: ColumnFilter, 
        isVisible: true,
    }
]