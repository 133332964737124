import { useState, useEffect } from 'react';
import { Dispatch } from '../../store';
import { useDispatch } from 'react-redux';
import './Login.css'
import { CenterContainer, InputContainer } from '../../components/common/Containers';
import { Input } from '../../components/common/Input';
import { Button } from '../../components/common/Button';
import AlertSnackBar from '../../components/AlertSnackBar';
import loginlogo from '../../images/itlogiclogo.png'
import designbg from '../../images/login_design3.png'
import designbg1 from '../../images/login_design.png'
import { SpinnerCircularFixed } from 'spinners-react';
import { useLocation } from 'react-router-dom';
import password_icon from '../../images/password.png' 
import jwtDecode from 'jwt-decode';
import { setCustomerPassword, setAdminUserPassword, setDriverPassword, verifyCustomerSetPassword, verifyAdminUserSetPassword, verifyDriverSetPassword } from '../../common/api';
import { Span } from '../../components/common/Span';
import { BRAND_IMAGE } from '../../components/constants';

function useQuery(){
    return new URLSearchParams(useLocation().search)
}

export default () => {   
    const query = useQuery();
    const token: any = query.get('token');
    const decoded: any = jwtDecode(token);
    const userId = decoded?.userId;
    const userType = decoded?.userType;
    const dispatch = useDispatch<Dispatch>()

    const [isLoading, setIsLoading] = useState(true);
    const [count, setCount] = useState(5);
    const [open, setOpen] = useState(false);
    const [verifySuccess, setVerifySuccess] = useState(false);
    const [isPasswordSet, setIsPasswordSet]: any = useState(false);
    const [password, setPassword] = useState({
        newPassword: '',
        confirmPassword: ''
    })

    const onChange = (name: string, e: any) => {
        setPassword({
            ...password,
            [name]: e.target.value
        })
    }

    const handleSubmit = async(e: any, btnType: string ) => {
        e.preventDefault();
        if(btnType === 'VERIFY'){
            const isMatched = password.newPassword === password.confirmPassword;
            if(!isMatched) return dispatch.UI.setAlert({message: "'New Password' and 'Confirm Password' not matched!", type:'Error'})
            try{
                setIsLoading(true)
                let res;
                if(userType === 'customer'){
                    res = await setCustomerPassword(userId, {password: password.confirmPassword})
                }else if(userType === 'admin-user'){
                    res = await setAdminUserPassword(userId, {password: password.confirmPassword})
                }else if(userType === 'driver'){
                    res = await setDriverPassword(userId, {password: password.confirmPassword})
                }
                if(res.isSuccess){
                    setVerifySuccess(true);
                }
                setIsLoading(false)
            }catch(err: any){
                let errMsg = 'Unable to verify account'
                if(err.data.error !== undefined){
                    errMsg = err.data.error
                }
                dispatch.UI.setAlert({message: errMsg, type: 'Error'});
            }
        }

        if(btnType === 'OK'){
            setIsLoading(true);
            if(userType === 'customer') return window.location.href = process.env.REACT_APP_HOME_PAGE ?? '';
            if(userType === 'admin-user') return window.location.href = process.env.REACT_APP_BASE_URL_WITHOUT_SSL ?? '';
            if(userType === 'driver') return window.close();
        }
        
    }

    const verifyLink = async() => {
        try{
            let res;
            if(userType === 'customer'){
                res = await verifyCustomerSetPassword(userId)
            }else if(userType === 'admin-user'){
                res = await verifyAdminUserSetPassword(userId)
            }else if(userType === 'driver'){
                res = await verifyDriverSetPassword(userId)
            }
           if(res.isPasswordSet){
                setIsPasswordSet(true);
                dispatch.UI.setAlert({message: 'Link has expire', type: 'Error'});
           }
           if(!res.isPasswordSet){
                setIsPasswordSet(false);
                setIsLoading(false)
           }
        }catch(err: any){
            let errMsg = 'Unable to verify account'
            if(err.data.error !== undefined){
                errMsg = err.data.error
            }
            dispatch.UI.setAlert({message: errMsg, type: 'Error'});
        }
    }

    useEffect(() => {
        if(isPasswordSet){
            let timer = setInterval(() => {
                setCount(count - 1);
            }, 1000);
            if(count === 0){
                clearInterval(timer)
                return window.close();
            }
        }
    });

    useEffect(() => {
        verifyLink();
    },[]);
    return (<>  
    <div className='min-h-screen overflow-hidden"'>
        <AlertSnackBar open={open} setOpen={setOpen} />
        <img src={designbg} alt="" className='absolute top-0 right-0'/> 
        <img src={designbg1} alt="" className='absolute bottom-0 left-0'/>  
        <CenterContainer>
        <img src={BRAND_IMAGE['BRAND_LOGO']} alt="" className='login_logo'/>
        <Span weight='bold' align='center'>{isPasswordSet ? 'This link has expire!' : verifySuccess ? 'Successful Verify Account!' : 'Verify Account'}</Span>
        {!isLoading ? (
            <form id='form1' onSubmit={(e) => handleSubmit(e, verifySuccess ? 'OK' : 'VERIFY')}>
            {verifySuccess ? (
                <>
                    <Span align='center'>You can now use your account</Span>
                </>
            ):(
                <>
                    <InputContainer margin='mt-4'> 
                        <Input padding='pl-10'
                            onChange={(e:any) => onChange('newPassword', e)} 
                            type="password" placeholder='New Password'
                            required
                            minLength={8}
                        />  
                        <img src={password_icon} alt="" className='login_icon'/> 
                    </InputContainer> 
        
                    <InputContainer margin='mt-4'> 
                        <Input padding='pl-10'
                            onChange={(e:any) => onChange('confirmPassword', e)} 
                            type="password" placeholder='Confirm Password'
                            required
                            minLength={8}
                        />  
                        <img src={password_icon} alt="" className='login_icon'/> 
                    </InputContainer> 
                </>
            )}
            <Button 
                transform='uppercase' shadow='grey' margin='mt-6' type='submit' form='form1'>
                {verifySuccess ? 'OK' : 'VERIFY'}
            </Button> 
            </form>
        ):(
            <>
            {isPasswordSet ? (
                <Span weight='bold' align='center'>{`Page will close in  ${count} . . .`}</Span>
            ) : (

                <div className='my-auto items-center overscroll-none z-10 bg-white'> 
                    <SpinnerCircularFixed 
                        className='m-auto'
                        style={{color: '#1c1c1c'}}
                    /> 
                </div> 
            )}
            </>
        )}
        </CenterContainer>   
    </div>
    </>)
}