import { GridContainer } from "../../../../components/common/Containers";
import { Span } from "../../../../components/common/Span";
import { BsDot } from "react-icons/bs";
import {
  CANCELED,
  DELIVERED,
  EXPRESS,
  NEW,
  ONGOING,
  PACKAGE_STATUS,
  STATUS,
} from "../../../../components/constants/status";
import { MdPayments } from "react-icons/md";

export default ({ pickUp, status }: any) => {
  return (
    <>
      <GridContainer padding="px-8">
        <div>
          <Span weight="semi"> Address: </Span>
          <Span>{pickUp?.location?.address}</Span>
          <p className="flex">
            <BsDot className="text-grey-11 my-auto" />
            <Span color="lightgrey">{pickUp?.locationDetails}</Span>
          </p>
        </div>

        <div>
          <Span weight="semi"> Name </Span>
          <Span>{pickUp?.name}</Span>
        </div>

        <div>
          <Span weight="semi"> Service Area </Span>
          <Span>{pickUp?.serviceArea?.map(area => `${area}, `)}</Span>
        </div>

        {pickUp?.isReliableFP && (
          <div className="flex items-center gap-3 col-span-2">
            <Span color="red">
              <MdPayments />
            </Span>
            <Span color="red">Reliable For Payment</Span>
          </div>
        )}
      </GridContainer>
    </>
  );
};
