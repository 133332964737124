import ParcelRateViewPage from '../containers/Rate/Parcel/View/ParcelRateView'  
import Header from '../components/Header/Header' 
import SidebarPage from '../components/Sidebar/Sidebar'
import { MainContainer } from '../components/common/Containers'
import { useParams } from 'react-router-dom' 
 
const ParcelRateView = () => {  

    return ( 
        <>  
            <SidebarPage />
            <MainContainer>    
                <Header /> 
                <main> 
                    <ParcelRateViewPage/>  
                </main> 
            </MainContainer>
        </>
    )
}

export default ParcelRateView
