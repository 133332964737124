import { FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material'
import React, { forwardRef } from 'react'
import { DRIVERGROUP } from '../../../components/constants/status'
import { IMaskInput } from 'react-imask';

const TextMaskCustom = forwardRef((props: any, ref: any) => {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask='#00-000-0000'
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    )
})

const Emergency = ({ values, handleBlur, errors, handleChange, touched }) => {

    return (
        <>
            <Typography variant='h6' sx={{ fontWeight: '700', marginBottom: '.5rem' }}>EMERGENCY CONTACT</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor='EmergecyName'><span style={{ color: '#F44336' }}>*</span>Name</InputLabel>
                        <OutlinedInput
                            name='EmergecyName'
                            placeholder='Name'
                            id='EmergecyName'
                            onBlur={handleBlur}
                            fullWidth
                            error={Boolean(touched.EmergecyName && errors.EmergecyName)}
                            value={values.EmergecyName}
                            onChange={handleChange('EmergecyName')}
                            inputProps={{
                                sx: { textTransform: 'capitalize' }
                            }}
                            sx={{
                                height: 40,
                                '& legend': {
                                    display: 'none'
                                },
                                '& fieldset': {
                                    top: 0
                                }
                            }}
                        />
                        {touched.EmergecyName && errors.EmergecyName && (
                            <FormHelperText error id='EmergecyName'>
                                {errors.EmergecyName}
                            </FormHelperText>
                        )}
                    </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor='contactNumber'><span style={{ color: '#F44336' }}>*</span>ContactNumber</InputLabel>
                        <OutlinedInput
                            name='contactNumber'
                            placeholder='930-123-1234'
                            id='contactNumber'
                            onBlur={handleBlur}
                            fullWidth
                            error={Boolean(touched.contactNumber && errors.contactNumber)}
                            value={values.contactNumber}
                            onChange={handleChange('contactNumber')}
                            inputComponent={TextMaskCustom}
                            startAdornment={
                                <InputAdornment position='start' variant='outlined' sx={{ paddingTop: '2px' }}>
                                    <Typography color='textSecondary' >+63</Typography>
                                </InputAdornment>
                            }
                            sx={{
                                height: 40,
                                '& legend': {
                                    display: 'none'
                                },
                                '& fieldset': {
                                    top: 0
                                }
                            }}
                        />
                        {touched.contactNumber && errors.contactNumber && (
                            <FormHelperText error id='contactNumber'>
                                {errors.contactNumber}
                            </FormHelperText>
                        )}
                    </Stack>
                </Grid>

            </Grid>
        </>
    )
}

export default Emergency