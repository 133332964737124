import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { Dispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import PostErrorAlert from '../../../components/PostErrorAlert';
import BackDrop from '../../../components/BackDrop';
import { onlyNumberKey } from '../../../common/utils';

import { Span, SpanContainer } from '../../../components/common/Span';
import { Input } from '../../../components/common/Input';
import { Button } from '../../../components/common/Button';
import { InputContainer } from '../../../components/common/Containers';

import '../Customer.css';
import { IoCloseSharp } from 'react-icons/io5';
import { RiErrorWarningFill } from 'react-icons/ri';
import { createCustomer } from '../../../common/api';
import { Form } from 'react-bootstrap';

type Props = {
  setAddCustomer: Function;
};

export default ({ setAddCustomer, ...props }: Props) => {
  const dispatch = useDispatch<Dispatch>();

  const [openAlert, setOpenAlert] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [edit, setEdit] = useState(false);

  const [customer, setCustomer] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  });

  const handleChange = async (name: string, event: any) => {
    let value = event.target.value;
    let newCustomer = { ...customer, [name]: value };
    setCustomer({ ...newCustomer });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch.UI.setIsLoading(true);
      const res = await createCustomer(customer);
      if (res?.isSuccess) {
        dispatch.UI.setAlert({ message: 'Admin user added!', type: 'Success' });
        dispatch.UI.setIsLoading(false);
        setAddCustomer(false)
        dispatch.Table.getFirebaseData({ name: 'customer_profile', table: 'customers' })
      }
    } catch (err: any) {
      const errMsg = err?.data?.error ?? 'Unable to add customer';
      dispatch.UI.setIsLoading(false);
      setErrMessage(errMsg);
      setOpenAlert(true);
    }
  };

  return (
    <>
      <div className="side absolute inset-y-0 right-0 px-0">
        <BackDrop />
        <div className="flex py-2 border-b border-grey-6">
          <IoCloseSharp
            onClick={() => setAddCustomer(false)}
            className="my-auto mx-2 text-lg cursor-pointer"
          />
          <Span weight="semi" size="lg">
            Add Customer
          </Span>
        </div>

        <Form className="mt-4" onSubmit={handleSubmit}>
          <div className="text-left py-4">
            <Span weight="semi" size="lg">
              Customer Information
            </Span>

            <Span margin="mt-4">First Name</Span>
            <Input
              placeholder="First Name"
              onChange={(e) => handleChange('firstName', e)}
              required
            />

            <Span margin="mt-4">Last Name</Span>
            <Input
              placeholder="Last Name"
              onChange={(e) => handleChange('lastName', e)}
              required
            />

            <Span margin="mt-4">Phone Number</Span>
            <InputContainer margin="mt-4">
              <SpanContainer padding="p-2" position="absolute">
                +63
              </SpanContainer>
              <Input
                padding="pl-10"
                type="tel"
                maxLength={10}
                minLength={10}
                placeholder="Phone Number"
                onChange={(e) => handleChange('phoneNumber', e)}
                required
                onKeyPress={onlyNumberKey}
              />
            </InputContainer>

            <Span margin="mt-4">Email</Span>
            <Input
              placeholder="Email"
              type="email"
              onChange={(e) => handleChange('email', e)}
              required
            />

            <PostErrorAlert
              openAlert={openAlert}
              setOpenAlert={setOpenAlert}
              message={errMessage}
            />

            <Span margin="mt-4">Status</Span>
            <Span
              margin="mt-4"
              border="red"
              color="red"
              padding="p-2"
              radius="rounded"
            >
              Inactive
            </Span>
          </div>

          <div className="flex inset-x-0 bottom-0 py-4">
            <Button
              margin="mx-2"
              bg="white"
              border="lightgrey"
              color="black"
              onClick={() => setAddCustomer(false)}
            >
              Cancel
            </Button>
            <Button margin="mx-2" type="submit">
              Add Customer
            </Button>
          </div>
        </Form>
      </div>

      {edit && (
        <>
          <div className="popup_edit">
            <div className="flex">
              <RiErrorWarningFill className="text-xl my-auto text-orange-1 cursor-pointer" />
              <Span margin="mx-2">
                Discard changes made in the user information?
              </Span>
            </div>

            <div className="flex justify-end py-2">
              <Button
                margin="mx-2"
                bg="white"
                border="lightgrey"
                color="black"
                width="none"
                padding="px-2"
                radius="none"
                onClick={() => setEdit(false)}
              >
                Cancel
              </Button>
              {/* Link to='onClick={() => history.push("/customers/")} */}
              <Button
                margin="mx-2"
                width="none"
                padding="px-2"
                radius="none"
                onClick={() => window.location.reload()}
              >
                Discard
              </Button>
              {/* </Link> */}
            </div>
          </div>
        </>
      )}
    </>
  );
};
