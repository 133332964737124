import { useState, useEffect } from 'react'
import { ModalContainer } from '../../../components/common/Containers'
import { Button } from '../../../components/common/Button'
import { Span } from '../../../components/common/Span'
import questionIcon from '../../../images/Qmark_icon.png'
import { deleteCustomer, deleteAdminUser, deleteRole, deleteDriver } from '../../../common/api';
import { Dispatch } from '../../../store';
import { useDispatch } from 'react-redux';

type Props = {
    setDeleteModal: Function,
    id: string,
    deleteModal: boolean,
    user: string
}

export default ({ setDeleteModal, id, deleteModal, user, ...props }: Props) => {

    const dispatch = useDispatch<Dispatch>();

    const whatToDelete = user === 'role' ? 'Role' : user === 'driver' ? 'Driver' : 'User';

    const handleDelete = async () => {
        try {
            dispatch.UI.setIsLoading(true);
            setDeleteModal(false)
            let res, table ;
            switch (user) {
                case 'userAdmin':
                    res = await deleteAdminUser(id)
                    table = 'users'
                    break;
                case 'userCustomer':
                    res = await deleteCustomer(id)
                    table = 'customers'
                    break;
                case 'role':
                    res = await deleteRole(id)
                    table = 'roles'
                    break;
                case 'driver':
                    res = await deleteDriver(id)
                    table = 'drivers'
                    break;
                default:
                    res.isSuccess = false
            }
            if (res.isSuccess) {
                dispatch.UI.setAlert({ message: 'User deleted', type: 'Success' })
                dispatch.UI.setIsLoading(false);
                dispatch.Table.deleteData({ table, id })
            }
            // if(user === 'userAdmin'){
            //     await deleteAdminUser(id)
            // }else{
            //     await deleteCustomer(id)
            // }

        } catch (err: any) {
            const errMsg = err?.data?.error ?? `Unable to delete ${whatToDelete}`;
            dispatch.UI.setAlert({ message: errMsg, type: 'Error' })
            dispatch.UI.setIsLoading(false);
        }
    };

    return (<>
        {deleteModal && <>
            <ModalContainer>
                <div className="px-4 py-6 whitespace-normal">
                    <div className='flex'>

                        <img src={questionIcon} alt={questionIcon} className='h-8 mr-2' />
                        <div className='text-left'>
                            <Span size='lg' weight='bold'>{`Are you sure you want to delete this ${whatToDelete}?`}</Span>

                            <Span color='lightgrey'>
                                Note: You cannot undo this action
                            </Span>
                        </div>
                    </div>


                    <div className='flex justify-end my-4 mx-2'>
                        <Button width='none' border='grey' color='black' padding='px-8' bg='white' margin='mx-2'
                            onClick={() => setDeleteModal(false)}
                        >
                            No
                        </Button>

                        <Button width='none' padding='px-8' onClick={handleDelete}>
                            Yes
                        </Button>
                    </div>
                </div>
            </ModalContainer>

            <div className="opacity-60 fixed inset-0 z-40 bg-black-1"></div>
        </>}
    </>);
}   