
import { Input } from '../../components/common/Input'

export function ColumnFilter({
    column: {
      filterValue, 
      setFilter,
    },
   }) {
    return (
      <Input 
        width='none'
        value={filterValue || ""}
        onChange={(e:any) => { 
          setFilter(e.target.value || undefined);
        }} 
      />
    );
   }
    