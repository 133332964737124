import { ColumnFilter } from "../../../../components/Table/ColumnFilter";  
import { Span } from '../../../../components/common/Span'  
import StatusDeleteRate from "./StatusDeleteRate";

export const COLUMNS = [    
    {   
        Header: 'Pickup Area',
        Filter: ColumnFilter, 
        accessor: 'pickUpArea', 
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
                <div className="m-auto"> 
                    <span className="capitalize">{d.pickUpArea}</span>
                </div>
            );
        }
    },
    {   
        Header: 'Drop off Area',
        Filter: ColumnFilter, 
        accessor: 'dropOffArea', 
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
                <div className="m-auto"> 
                    <span className="capitalize">{d.dropOffArea}</span>
                </div>
            );
        }
    },
    {   
        Header: 'Base Fare ₱',
        Filter: ColumnFilter, 
        accessor: 'baseFare', 
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
                <div className="m-auto"> 
                    <span>{parseFloat(d.baseFare).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </div>
            );
        }
              
    },  
    {   
        Header: 'Max Weight',
        Filter: ColumnFilter, 
        accessor: 'maxWeight', 
        isVisible: true,
    },  
    {   
        Header: 'Commision %',
        Filter: ColumnFilter, 
        accessor: 'commission', 
        isVisible: true,
    },  
    {   
        Header: 'Driver Share %',
        Filter: ColumnFilter, 
        accessor: 'driverShare', 
        isVisible: true,
    },
    {
        accessor: ' ',
        Header: ' ', 
        Filter: ColumnFilter,
        disableFilters: true, 
        disableSortBy: true,
        isVisible: true, 
        Cell: data => <StatusDeleteRate id={data.row.original.id} /> 
    }
]
