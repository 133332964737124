
import { useEffect, useState } from 'react' 
import { useHistory, useParams } from "react-router";
import { addBillingCustomer } from '../../../../common/api';
import PostErrorAlert from '../../../../components/PostErrorAlert';
import BackDrop from "../../../../components/BackDrop";
import { onlyNumberKey } from '../../../../common/utils';

import { Span } from '../../../../components/common/Span'
import { Input } from '../../../../components/common/Input'
import { Button } from '../../../../components/common/Button'
import '../../CSS/Driver.css'
import { IoCloseSharp } from 'react-icons/io5' 
import { RiErrorWarningFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../../store";
import { Autocomplete, TextField } from "@mui/material";
import { BiCircle } from 'react-icons/bi';

type Props = {
  setAddCustomer: Function,  
  addCustomer: boolean
} 
 

export default ({setAddCustomer, addCustomer, ...props}: Props) => { 

    const dispatch = useDispatch<Dispatch>();

    const customers: any[] = useSelector((state: RootState) => state.Table.tables.customers.data);
    const billingId = useParams()?.id;

    const [openAlert, setOpenAlert] = useState(false);
    const [errMessage, setErrMessage] = useState('');
    const [cancel, setCancel] = useState(false);
    const [active, setActive] = useState(false);
    const [customerDetails, setCustomerDetails] = useState() as any;

    const handleAddBilling = async(e) => {
        e.preventDefault();
        try{
            dispatch.UI.setIsLoading(true);
            const res = await addBillingCustomer({customerId: customerDetails?.id, billingId});
            setAddCustomer(false);
            dispatch.UI.setAlert({message: 'Billing added!', type: 'Success'});
            dispatch.UI.setIsLoading(false);
        }catch(err: any){
            const errMsg = err?.data?.error ?? 'Unable to add customer';
            dispatch.UI.setIsLoading(false);
            setOpenAlert(true);
            setErrMessage(errMsg);
        }
    }

    const getCapitalize = (string) => {
      const res = string?.split(' ')?.map(str => str[0]?.toUpperCase() + str?.substring(1))?.join(' ')?.replace('undefined', '');
      return res;
    }

    const handleChange = (e: any, ) => {
        const value = e.target.value;
        const name = e.target.name
        setCustomerDetails({
            ...customerDetails,
            [name]: value
        })
    }
    return (<>
    {cancel && (
        <>
          <div className="popup_edit">
            <div className="flex">
              <RiErrorWarningFill className="text-xl my-auto text-orange-1" />
              <Span margin="mx-2">
                Discard changes made in the user information?
              </Span>
            </div>

            <div className="flex justify-end py-2">
              <Button
                margin="mx-2"
                bg="white"
                border="lightgrey"
                color="black"
                width="none"
                padding="px-2"
                radius="none"
                onClick={() => setCancel(false)}
              >
                Cancel
              </Button> 
              <Button
                margin="mx-2"
                width="none"
                padding="px-2"
                radius="none"
                onClick={() => setAddCustomer(false)}
              >
                Discard
              </Button> 
            </div>
          </div>
        </>
      )}
    <div className="side-parcel absolute inset-y-0 right-0 px-0">
        <BackDrop />
        <div className='flex py-2'>
            <IoCloseSharp  
                onClick={() => setAddCustomer(false)}  
                className='my-auto mx-2 text-lg cursor-pointer'
            />
            <Span weight='semi' size='lg'>
                Add Customer
            </Span>
        </div>

        <form onSubmit={handleAddBilling} autoComplete='off' >
        <div className='text-left py-4'>
            <Span weight='semi' size='lg' transform='uppercase'>
                Customer Information
            </Span> 

            <Span margin='mt-4'>Name</Span>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={customers}
                onChange={(e, newValue: any) => {
                  console.log(newValue);
                    setCustomerDetails(newValue);
                }}
                getOptionLabel={(option:any) => {
                    const data = option;
                    return `${getCapitalize(data?.firstName) ?? ''} ${getCapitalize(data?.lastName) ?? ''}`
                }}
                ListboxProps={{
                    style:{
                        maxHeight: '220px',
                    }                  
                }}
                filterOptions={(options, value) => {
                    const result = [...options?.filter(item => JSON.stringify(Object.values({...item, name: `${item.firstName} ${item.lastName}`})).search(new RegExp(value?.inputValue, 'i')) !== -1)]
                    result.push({type: 'button'})                    
                    return result
                }}
                renderOption={(props, option) => {
                    const data = option;
                return(<>
                        <li {...props}>
                        {data?.type === 'button' ? (
                            <Span weight="semi"><a href="/customers" style={{color: '#9a7f3e', textDecoration: 'underline'}}>Add or update customer here!</a></Span>
                        ): (
                            <div className="flex flex-row">
                                <div>
                                    <BiCircle />
                                </div>
                                <div className="flex flex-col items-start justify-start align-baseline pl-2">
                                    <Span transform='capitalize' weight="semi">{`${data?.firstName ?? ''} ${data?.lastName ?? ''}`}</Span>
                                    <Span >{data?.email}</Span> 
                                    <Span >{data?.phoneNumber}</Span>
                                </div>
                            </div>
                        )}
                    </li>
                    </>)
                }}
                renderInput={(params) => <TextField required {...params} label=" " />}
            />

            {/* <Span margin='mt-4'>Email</Span>
            <Input placeholder='Email' onChange={handleChange} value={customerDetails?.email} name='email' disabled type='email' />

            <Span margin='mt-4'>Phone Number</Span>
            <Input placeholder='Phone Number' onChange={handleChange} name='email' disabled value={customerDetails?.phoneNumber ?? ''} type='email' /> */}
        </div>

        <PostErrorAlert openAlert={openAlert} setOpenAlert={setOpenAlert} message={errMessage} />  
            
        <div className="flex inset-x-0 bottom-0 py-4 text-center">
            <Button
              margin="mx-2"
              bg="white"
              border="lightgrey"
              color="black"
              onClick={()=> setAddCustomer(false)}
            >
              Cancel
            </Button>
            <Button margin="mx-2" type="submit">
              Add Customer
            </Button>
        </div>    
        </form>
    </div> 
    </>);
}   