import { ColumnFilter } from "../../../../components/Table/filter";   
import moment from 'moment'; 

export const COLUMNS = [
    {
        id: "id",
        field: "id",
        accessor: 'id',
        Header: 'ID',
        Filter: ColumnFilter, 
        isVisible: true,
    },
    {    
        accessor: data => `${data?.name} ${data?.email} ${data?.phoneNumber}`,
        Header: 'User Details',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data?.row?.original?.wallet;
            return(<div className='text-left px-2'>
                <p><span className='capitalize'>{d?.name}</span></p>
                <p><span>{d?.email}</span></p>
                <p><span>{d?.phoneNumber}</span></p>
            </div>)
        }    
    },      
    {
        id: "userType",
        field: "userType",
        accessor: data => data?.wallet?.type,
        Header: 'User Type',
        Filter: ColumnFilter,   
        Cell: data => {
            const d = data?.row?.original?.wallet;
            return(
                <span className='capitalize'>{d?.type}</span> 
            )
        }        
    },  
    {
        id: "bank",
        field: "bank",
        accessor: data => `${data?.bankName} ${data?.accountName} ${data?.accountNumber}`,
        Header: 'Bank Details',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data?.row?.original?.bank;
            return(<div className='text-left px-2'>
                <p><span className='capitalize'>{d?.bankName}</span></p>
                <p><span>{d?.accountName}</span></p>
                <p><span>{d?.accountNumber}</span></p>
            </div>)
        }            
    }, 
    {
        accessor: data => {
            const date = new Date(data?.createdAt?.seconds * 1000);
            const formatedDate = moment(date).format('MMMM Do YYYY, h:mm:ss a')
            return formatedDate;
        },
        Header: 'Date Request',
        isVisible: true,
        Cell: data => {
        const d = data?.row?.original;
        let date = new Date(1000);

        if(d?.createdAt?.seconds){
            date = new Date(d?.createdAt?.seconds * 1000);
        }
        return <>{moment(date).format('MMMM Do YYYY, h:mm:ss a')}</>   }
    }, 
    {
        accessor: data => {
            const date = new Date(data?.processDate?.seconds * 1000);
            const formatedDate = !data?.processDate ? '' : moment(date).format('MMMM Do YYYY, h:mm:ss a')
            return formatedDate;
        },
        Header: 'Date Process',
        isVisible: true,
        Cell: data => {
            const d = data?.row?.original;
            let date = new Date(1000);
    
            if(d?.createdAt?.seconds){
                date = new Date(d?.createdAt?.seconds * 1000);
            }
            return <>{moment(date).format('MMMM Do YYYY, h:mm:ss a')}</>   
        }
    },  
    {
        id: "balance",
        field: "balance",
        accessor: data => data?.wallet?.balance,
        Header: 'Balance',
        Filter: ColumnFilter, 
        isVisible: true,  
        Cell: data => {
            const d = data.row.original;
            return (
            <span> 
                ₱ {parseFloat(d.wallet.balance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </span>    
            ); 
        }        
    },
    {
        id: "amount",
        field: "amount",
        accessor: 'amount',
        Header: 'Amount',
        Filter: ColumnFilter, 
        isVisible: true,  
        Cell: data => {
            const d = data?.row?.original;
            const amount: any = d?.amount ?? 0;
            return (
                <span> 
                    ₱ {parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>    
            ); 
        }        
    },
    {
        id: "createdBy",
        field: "createdBy",
        accessor: data => !data.processBy ? data.wallet?.name : `${data?.processBy?.firstName} ${data?.processBy?.lastName}`,
        Header: 'Created By',
        Filter: ColumnFilter,
        isVisible: true,
    }, 
]