import { useState } from 'react';
import { Span } from '../../../../../components/common/Span';
import { Button } from '../../../../../components/common/Button';
import { IoCloseSharp } from 'react-icons/io5';
import PostErrorAlert from '../../../../../components/PostErrorAlert';
import BackDrop from '../../../../../components/BackDrop';
import { addExpressSettings } from '../../../../../common/api';
import { Dispatch, RootState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import '../../../CSS/Rate.css';
import BasicFare from '../Forms/BasicFare';
import Addons from '../Forms/Addons';
import Warehouse from '../Forms/Warehouse';

type Props = {
  setAddRateSetting: Function;
  rateSetting: boolean;
};

export default ({ setAddRateSetting, rateSetting, ...props }: Props) => {
  let { rateExpressId }: any = useParams();

  const warehouse = useSelector((state: RootState) => state.Table.tables.warehouse.data);

  const initialState = {
    vehicleType: '',
    baseFare: '',
    commission: '',
    standardPricePerKM: '',
    driverShare: '',
    warehouse: '',
    additionalStopPrice: '',
    specialHelp: '',
    customerStaff: '',
    extraHelp: '',
    safetyGloves: '',
    hardHat: '',
    dayRate: '',
    consolidationDiscount: '',
  }

  const [openAlert, setOpenAlert] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [data, setData] = useState(initialState);

  const dispatch = useDispatch<Dispatch>();

  const onChange = (e: any, name: string) => {
    setData({
      ...data,
      [name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch.UI.setIsLoading(true);
      const payload = {
        ...data,
        baseFare: Number(data.baseFare),
        commission: Number(data.commission),
        standardPricePerKM: Number(data.standardPricePerKM),
        driverShare: Number(data.driverShare),
        additionalStopPrice: Number(data.additionalStopPrice),
        specialHelp: Number(data.specialHelp),
        customerStaff: Number(data.customerStaff),
        extraHelp: Number(data.extraHelp),
        safetyGloves: Number(data.safetyGloves),
        hardHat: Number(data.hardHat),
        dayRate: Number(data.dayRate),
        consolidationDiscount: Number(data.consolidationDiscount),
      }
      const res = await addExpressSettings(rateExpressId, payload);
      if (res.isSuccess) {
        setAddRateSetting(false);
        dispatch.UI.setAlert({ message: 'Add rate Setting', type: 'Success' });
        dispatch.UI.setIsLoading(false);
        setData(initialState)
      }
    } catch (err: any) {
      const errMsg = err?.data?.error === undefined ? 'Unable to add rate Setting' : err.data.error;
      setOpenAlert(true);
      setErrMessage(errMsg);
      dispatch.UI.setIsLoading(false);
    }
  };

  return (
    <>
      {rateSetting && (
        <form onSubmit={handleSubmit}>
          <div className="side-rate">
            <BackDrop />
            <div className="flex py-4 border-b border-grey-6">
              <IoCloseSharp
                onClick={() => {
                  setData(initialState)
                  setAddRateSetting(false)
                }}
                className="my-auto mx-2 text-lg cursor-pointer"
              />
              <Span weight="semi" size="lg">
                Add Rate
              </Span>
            </div>

            <BasicFare onChange={onChange} data={data} />

            <Addons onChange={onChange} data={data} />

            <Warehouse onChange={onChange} data={data} warehouse={warehouse} />

            <PostErrorAlert
              openAlert={openAlert}
              setOpenAlert={setOpenAlert}
              message={errMessage}
            />

            <div className="flex mt-4 mb-8 p-4 text-center">
              <Button
                margin="mx-2"
                bg="white"
                border="lightgrey"
                color="black"
                onClick={() => {
                  setData(initialState)
                  setAddRateSetting(false)
                }}
              >
                Cancel
              </Button>
              <Button margin="mx-2" type="submit">
                Create
              </Button>
            </div>
          </div>
        </form>
      )
      }
    </>
  );
};

