import '../Customer.css' 
import { useDispatch } from 'react-redux';
import { Dispatch } from "../../../store";   
import StatusComponent from "../../../components/Status/StatusComponent";

const StatusCustomer = ({id, email, ...props}:any) => { 

    const dispatch = useDispatch<Dispatch>();   

    const handleEdit = () => { 
        dispatch.UI.updateFormId({
            target: "editCustomer",
            value: id
        })
    }

    return (<>    
        <StatusComponent id={id} email={email} handleEdit={handleEdit} user='userCustomer' /> 
    </>)
}

export default StatusCustomer

            