import { FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material'

const DeliveryDetails = ({ values, handleBlur, errors, handleChange, touched }) => {

    return (
        <>
            <Typography variant='h6' sx={{ fontWeight: '700', marginBottom: '.5rem' }}>DELIVERY DETAILS</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor='cluster'><span style={{ color: '#F44336' }}>*</span>Cluster</InputLabel>
                        <OutlinedInput
                            name='cluster'
                            placeholder='Cluster'
                            id='cluster'
                            onBlur={handleBlur}
                            fullWidth
                            error={Boolean(touched.cluster && errors.cluster)}
                            value={values.cluster}
                            onChange={handleChange('cluster')}
                            inputProps={{
                                sx: { textTransform: 'capitalize' }
                            }}
                            sx={{
                                height: 40,
                                '& legend': {
                                    display: 'none'
                                },
                                '& fieldset': {
                                    top: 0
                                }
                            }}
                        />
                        {touched.cluster && errors.cluster && (
                            <FormHelperText error id='cluster'>
                                {errors.cluster}
                            </FormHelperText>
                        )}
                    </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor='clusterCity'><span style={{ color: '#F44336' }}>*</span>City</InputLabel>
                        <OutlinedInput
                            name='clusterCity'
                            placeholder='City'
                            id='clusterCity'
                            onBlur={handleBlur}
                            fullWidth
                            error={Boolean(touched.clusterCity && errors.clusterCity)}
                            value={values.clusterCity}
                            onChange={handleChange('clusterCity')}
                            inputProps={{
                                sx: { textTransform: 'capitalize' }
                            }}
                            sx={{
                                height: 40,
                                '& legend': {
                                    display: 'none'
                                },
                                '& fieldset': {
                                    top: 0
                                }
                            }}
                        />
                        {touched.clusterCity && errors.clusterCity && (
                            <FormHelperText error id='clusterCity'>
                                {errors.clusterCity}
                            </FormHelperText>
                        )}
                    </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor='clusterArea'><span style={{ color: '#F44336' }}>*</span>Area</InputLabel>
                        <OutlinedInput
                            name='clusterArea'
                            placeholder='Area'
                            id='clusterArea'
                            onBlur={handleBlur}
                            fullWidth
                            error={Boolean(touched.clusterArea && errors.clusterArea)}
                            value={values.clusterArea}
                            onChange={handleChange('clusterArea')}
                            inputProps={{
                                sx: { textTransform: 'capitalize' }
                            }}
                            sx={{
                                height: 40,
                                '& legend': {
                                    display: 'none'
                                },
                                '& fieldset': {
                                    top: 0
                                }
                            }}
                        />
                        {touched.clusterArea && errors.clusterArea && (
                            <FormHelperText error id='address'>
                                {errors.clusterArea}
                            </FormHelperText>
                        )}
                    </Stack>
                </Grid>

            </Grid>
        </>
    )
}

export default DeliveryDetails