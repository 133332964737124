import { useState, useEffect } from 'react';
import { ModalContainer } from '../../../components/common/Containers';
import { Button } from '../../../components/common/Button';
import { Span } from '../../../components/common/Span';
import questionIcon from '../../../images/Qmark_icon.png';

import { Dispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import { updateDriverStatus } from '../../../common/api';

type Props = {
  setApproveModal: Function;
  approvedModal: boolean;
  id: string;
};

export default ({ setApproveModal, approvedModal, id, ...props }: Props) => {
  const dispatch = useDispatch<Dispatch>();

  const body = {
    driverApprovalStatus: 'approved',
  };

  const handleApproved = async (e) => {
    e.preventDefault();
    setApproveModal(false);
    try {
      dispatch.UI.setIsLoading(true);
      const res = await updateDriverStatus({ body, id });
      if (res?.isSuccess) {
        dispatch.UI.setAlert({ message: 'Status updated', type: 'Success' });
        dispatch.UI.setIsLoading(false);
        dispatch.Table.getFirebaseData({ name: 'driver_profile', table: 'drivers' })
      }
    } catch (err: any) {
      const errMsg = err?.data?.error ?? 'Unable to update status';
      dispatch.UI.setAlert({ message: errMsg, type: 'Error' });
      dispatch.UI.setIsLoading(false);
    }
  };

  return (
    <>
      {approvedModal && (
        <>
          <ModalContainer>
            <div className="px-4 py-6 whitespace-normal">
              <div className="flex">
                <img
                  src={questionIcon}
                  alt={questionIcon}
                  className="h-8 mr-2"
                />
                <div className="text-left">
                  <Span size="lg" weight="bold">
                    Are you sure you want to Approve?
                  </Span>
                </div>
              </div>

              <div className="flex justify-end my-4 mx-2">
                <Button
                  width="none"
                  border="grey"
                  color="black"
                  padding="px-8"
                  bg="white"
                  margin="mx-2"
                  onClick={() => setApproveModal(false)}
                >
                  No
                </Button>

                <Button width="none" padding="px-8" onClick={handleApproved}>
                  Yes
                </Button>
              </div>
            </div>
          </ModalContainer>

          <div className="opacity-60 fixed inset-0 z-40 bg-black-1"></div>
        </>
      )}
    </>
  );
};
