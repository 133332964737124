import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import './Login.css'
import { CenterContainer, InputContainer } from '../../components/common/Containers';
import { Input } from '../../components/common/Input';
import { Button } from '../../components/common/Button';

import designbg from '../../images/login_design3.png'
import designbg1 from '../../images/login_design.png'
import AlertSnackBar from '../../components/AlertSnackBar';

import loginlogo from '../../images/itlogiclogo.png'
import username_icon from '../../images/username.png'
import password_icon from '../../images/password.png'
import { useHistory, useParams, Link } from "react-router-dom";
import { RootState, Dispatch } from "../../store";
import { validateEmail } from '../../common/utils';
import { SpinnerCircularFixed } from 'spinners-react';
import { BRAND_IMAGE } from '../../components/constants';

export default () => {
    const history = useHistory();

    const dispatch = useDispatch<Dispatch>();

    const [error, setError] = useState(false);

    const isUserLoggedIn: any = useSelector((state: RootState) => state.User.isUserLoggedIn);
    const email: any = useSelector((state: RootState) => state.UI.forms.login.fields.email);
    const password: any = useSelector((state: RootState) => state.UI.forms.login.fields.password);
    const isRequesting = useSelector((state: RootState) => state.UI.forms.login.isRequesting);
    const errortype = useSelector((state: RootState) => state.UI.alert?.type);

    const isEmailValid = validateEmail(email)
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const handleLogin = async () => {
        dispatch.UI.resetAlert();
        setOpen(false);
        await dispatch.User.login({ email, password })
    }

    const handleEnter = (e: any) => {
        const ASCIICode = (e.which) ? e.which : e.keyCode;
        if (ASCIICode === 13) {
            handleLogin();
        }
    }

    const setFormValue = (field, value) => {
        dispatch.UI.setInputFormFields({
            target: "login",
            field: field,
            value: value,
        });
    }

    const login = () => {
        history.push("/parcels");
        const TIMEOUT = setTimeout(() => {
            setIsLoading(false);
            clearTimeout(TIMEOUT);
        }, 1000)
    }

    useEffect(() => {
        if (isRequesting) {
            setIsLoading(true)
        }
        if (!isRequesting && isUserLoggedIn) {
            return login();
        }
        if (errortype === 'Error' && !isRequesting) {
            setIsLoading(false);
        }

    }, [isRequesting, isUserLoggedIn]);

    // useEffect(() => {
    //     if (isUserLoggedIn) {  
    //         history.push("/parcels");
    //     } 
    //     return (() => {
    //         setError(true);
    //     })
    //     // eslint-disable-next-line
    // }, [isUserLoggedIn]);
    return (<>
        <AlertSnackBar open={open} setOpen={setOpen} />
        <div className='min-h-screen overflow-hidden"'>
            <img src={designbg} alt="" className='absolute top-0 right-0' />
            <img src={designbg1} alt="" className='absolute bottom-0 left-0' />

            <CenterContainer>
                <div className='h-[200px]'>
                    <img src={BRAND_IMAGE['BRAND_LOGO']} alt="" style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
                </div>
                {error && <>
                    <div className='text-center py-1 rounded border border-getAllRed-200 bg-getAllRed-300 bg-opacity-30'>
                        <span className='text-xs'>
                            Email or password is incorrect!
                        </span>
                    </div>
                </>}

                {!isLoading ? <>
                    <InputContainer margin='mt-4'>
                        <Input padding='pl-10'
                            value={email}
                            onKeyPress={handleEnter}
                            onChange={(e: any) => {
                                setFormValue('email', e.target.value)
                                validateEmail(e)
                            }}
                            type="text" placeholder='Email'
                        />
                        <img src={username_icon} alt="" className='login_icon' />
                    </InputContainer>

                    <InputContainer margin='mt-4'>
                        <Input padding='pl-10'
                            value={password}
                            onKeyPress={handleEnter}
                            onChange={(e: any) => setFormValue('password', e.target.value)}
                            type="password" placeholder='Password'
                        />
                        <img src={password_icon} alt="" className='login_icon' />
                    </InputContainer>

                    <Button
                        onClick={handleLogin}
                        disabled={!isEmailValid}
                        transform='uppercase' shadow='grey' margin='mt-6'>
                        Login
                    </Button>
                </> : <>
                    <div className='my-auto items-center overscroll-none z-10 bg-white'>
                        <SpinnerCircularFixed
                            className='m-auto'
                            style={{ color: '#FD8A8A' }}
                        />
                    </div>
                </>}

            </CenterContainer>
        </div>
    </>)
}