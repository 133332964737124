import React from 'react'
import DriverPage from '../containers/Driver/DriverManagement'  
import Header from '../components/Header/Header' 
import SidebarPage from '../components/Sidebar/Sidebar'
import { MainContainer } from '../components/common/Containers' 
 
const Parcels = () => {
    return ( 
        <>  
            <SidebarPage />
            <MainContainer>    
                <Header /> 
                <main> 
                    <DriverPage/>  
                </main> 
            </MainContainer>
        </>
    )
}

export default Parcels
