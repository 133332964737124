import { useDispatch } from 'react-redux';
import { Dispatch } from "../../../../store";

import delete_icon from '../../../../images/delete_icon.png'
import ReactTooltip from 'react-tooltip';
import { deleteInterislandSettings } from '../../../../common/api';
import { useParams, useHistory } from 'react-router-dom';

const StatusDeleteRate = ({ id, ...props }: any) => {
    let { rateInterislandId }: any = useParams();

    const history = useHistory();
    const dispatch = useDispatch<Dispatch>();

    const handleDelete = async (e) => {
        e.preventDefault();
        try {
            dispatch.UI.setIsLoading(true);
            const res = await deleteInterislandSettings(rateInterislandId, id);
            if (res.isSuccess) {
                history.push(`/rate/interisland/${rateInterislandId}`);
                dispatch.UI.setAlert({ message: 'Rate setting deleted', type: 'Success' });
                dispatch.UI.setIsLoading(false);
                //window.location.reload();
            }
        } catch (err: any) {
            const errMsg = err?.data?.error === undefined ? 'Unable to update rate setting' : err.data.error;
            dispatch.UI.setAlert({ message: errMsg, type: 'Error' });
            dispatch.UI.setIsLoading(false);
        }
    }

    return (<>
        <div className='flex my-auto'>
            {/* <BackDrop /> */}
            <img src={delete_icon} placeholder={delete_icon}
                className="status_icon" data-tip data-for='delete'
                onClick={handleDelete}
            />
            <ReactTooltip id='delete' backgroundColor="#8d8d8d">
                <span>Delete</span>
            </ReactTooltip>
        </div>
    </>)
}

export default StatusDeleteRate

