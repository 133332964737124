import React from 'react'
import Address from './forms/Address'
import DeliveryDetails from './forms/DeliveryDetails'
import DriverLicense from './forms/DriverLicense'
import Emergency from './forms/Emergency'
import Profiles from './forms/Profiles'
import RegisterVehicle from './forms/RegisterVehicle'

const PersonalDetails = ({ values, handleBlur, errors, handleChange, touched }) => {
    return (
        <>
            <Profiles values={values} handleBlur={handleBlur} errors={errors} handleChange={handleChange} touched={touched} />
            <br />
            <Emergency values={values} handleBlur={handleBlur} errors={errors} handleChange={handleChange} touched={touched} />
            <br />
            <DriverLicense values={values} handleBlur={handleBlur} errors={errors} handleChange={handleChange} touched={touched} />
            <br />
            <Address values={values} handleBlur={handleBlur} errors={errors} handleChange={handleChange} touched={touched} />
            <br />
            <DeliveryDetails values={values} handleBlur={handleBlur} errors={errors} handleChange={handleChange} touched={touched} />
            <br />
            <RegisterVehicle values={values} handleBlur={handleBlur} errors={errors} handleChange={handleChange} touched={touched} />
            <br />
        </>
    )
}

export default PersonalDetails