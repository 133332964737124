/* eslint-disable import/no-anonymous-default-export */
import { createWarehouse } from "../../common/api";
import { useState } from "react";
import { Span } from "../../components/common/Span";
import { Input } from "../../components/common/Input";
import { Button } from "../../components/common/Button";
import PostErrorAlert from "../../components/PostErrorAlert";
import { Dispatch } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import BackDrop from "../../components/BackDrop";

import { IoCloseSharp } from "react-icons/io5";
import Location from "../../components/Location/Location";
import Province from "../../components/Location/Province";
import { InputContainer } from "../../components/common/Containers";
import { Select } from "../../components/common/Select";

type Props = {
  setAddWarehouse: Function;
};

export default ({ setAddWarehouse, ...props }: Props) => {
  const dispatch = useDispatch<Dispatch>();

  const [openAlert, setOpenAlert] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const initialState = {
    name: '',
    island: '',
    location: {},
    serviceArea: []
  }

  const [warehouse, setWarehouse] = useState(initialState);

  const handleChange = async (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    setWarehouse({
      ...warehouse,
      [name]: value
    })
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch.UI.setIsLoading(true);
      const res = await createWarehouse(warehouse);
      if (res?.isSuccess) {
        dispatch.UI.setAlert({ message: "Warehouse added!", type: "Success" });
        dispatch.UI.setIsLoading(false);
        setWarehouse(initialState);
        setAddWarehouse(false);
      }
    } catch (err: any) {
      const errMsg = err?.data?.error ?? "Unable to add Warehouse";
      dispatch.UI.setIsLoading(false);
      setOpenAlert(true);
      setErrMessage(errMsg);
    }
  };

  return (
    <>
      <div className="side absolute inset-y-0 right-0 px-0">
        <BackDrop />
        <div className="flex py-4 border-b border-grey-6">
          <IoCloseSharp
            onClick={() => setAddWarehouse(false)}
            className="my-auto mx-2 text-lg cursor-pointer"
          />
          <Span weight="semi" size="lg">
            Add Warehouse
          </Span>
        </div>
        <Form className="mt-4" onSubmit={handleSubmit}>
          <div className="text-left py-4">
            <Span weight="semi" size="lg">
              Warehouse
            </Span>

            <Span margin="mt-4">Name</Span>
            <Input
              onChange={handleChange}
              name='name'
              placeholder="Warehouse Name"
              required
            />

            <Span margin="mt-4">Island</Span>
            <InputContainer margin="none">
              <Select
                required
                name='island'
                onChange={handleChange}
              >
                <option disabled selected>Choose Island</option>
                <option value='Luzon' >Luzon</option>
                <option value='Boracay' >Boracay</option>
                <option value='Mindanao' >Mindanao</option>
                <option value='Cebu' >Cebu</option>
                <option value='Masbate Island' >Masbate Island</option>
                <option value='Panay' >Panay</option>
                <option value='Mindoro' >Mindoro</option>
                <option value='Visayas' >Visayas</option>
              </Select>
            </InputContainer>

            <Location setWarehouse={setWarehouse} warehouse={warehouse} />

            <Province setWarehouse={setWarehouse} warehouse={warehouse} />
          </div>

          <PostErrorAlert
            openAlert={openAlert}
            setOpenAlert={setOpenAlert}
            message={errMessage}
          />

          <div className="flex h-[30vh]" />
          <div className="flex py-4">
            <Button
              margin="mx-2"
              bg="white"
              border="lightgrey"
              color="black"
              onClick={() => setAddWarehouse(false)}
            >
              Cancel
            </Button>
            <Button margin="mx-2" type="submit">
              Add Warehouse
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
