import moment from "moment";
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    deliveryGroup: Yup.string().required('Delivery Group is required'),
    bday: Yup.string()
        .required('Date of Birth is required')
        .test('DOB', 'Age must be 18 above', (value: any) => {
            return moment().diff(moment(value), 'years') > 17;
        }),
    gender: Yup.string().required('Gender is required'),
    EmergecyName: Yup.string().required(),
    contactNumber: Yup.string().required('Contact Number is required').min(12, 'Contact Number must be atleast 10 characters'),
    licenseType: Yup.string().required('License type is requried'),
    licenseNumber: Yup.string().required('License Number is requried'),
    province: Yup.string().required('Province is requried'),
    address: Yup.string().required('Address is requried'),
    city: Yup.string().required('City is requried'),
    cluster: Yup.string().required('Cluster is requried'),
    clusterCity: Yup.string().required('Cluster City is requried'),
    clusterArea: Yup.string().required('Cluster Area is requried'),
    licenseRestriction: Yup.string().required('Restriction is requried'),
    vehicleType: Yup.string().required('Vehicle type is requried'),
    vehicleModel: Yup.string().required('Vehicle model is requried'),
    plateNumber: Yup.string().required('Plate Number is requried'),
})

export const validationSchemaDoc = Yup.object().shape({
    license: Yup.string().required("Driver's license is requried"),
    orcr: Yup.string().required("OR CR is requried"),
    nbi: Yup.string().required("NBI Clearance is requried"),
    selfieWithLicense: Yup.string().required("selfie with License is requried"),
    vehicleWithPlateNumber: Yup.string().required("Vehicle Photo with Plate Number is requried"),
    vehicleInsurance: Yup.string().required("Vehicle Insurance is requried"),
    deedOfSale: Yup.string().required("Deed of Sale is requried"),
    letterOfAuthorization: Yup.string().required("Letter of Authorization is requried"),
    vaccinCard: Yup.string().required("Vaccination Card is requried"),
})

export const initialState = {
    deliveryGroup: '',
    bday: '',
    tin: '',
    gender: '',
    EmergecyName: '',
    contactNumber: '',
    licenseType: '',
    licenseNumber: '',
    province: '',
    address: '',
    city: '',
    cluster: '',
    clusterCity: '',
    clusterArea: '',
    licenseRestriction: '',
    vehicleType: '',
    vehicleModel: '',
    plateNumber: '',
    status: 'active',
}

export const initialStateDoc = {
    license: '',
    orcr: '',
    nbi: '',
    selfieWithLicense: '',
    vehicleWithPlateNumber: '',
    vehicleInsurance: '',
    deedOfSale: '',
    letterOfAuthorization: '',
    vaccinCard: '',
}