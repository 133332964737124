import { FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material'
import React, { forwardRef } from 'react'
import { IMaskInput } from 'react-imask';
import { VEHICLE } from '../../../components/constants/status';

const TextMaskCustom = forwardRef((props: any, ref: any) => {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask='#'
            definitions={{
                '#': /[1-8]/,
            }}
            inputRef={ref}
            onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    )
})

const RegisterVehicle = ({ values, handleBlur, errors, handleChange, touched }) => {

    return (
        <>
            <Typography variant='h6' sx={{ fontWeight: '700', marginBottom: '.5rem' }}>REGISTER VEHICLE</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor='licenseRestriction'><span style={{ color: '#F44336' }}>*</span>Driver License Restriction</InputLabel>
                        <OutlinedInput
                            name='licenseRestriction'
                            placeholder='3'
                            id='licenseRestriction'
                            onBlur={handleBlur}
                            fullWidth
                            error={Boolean(touched.licenseRestriction && errors.licenseRestriction)}
                            value={values.licenseRestriction}
                            onChange={handleChange('licenseRestriction')}
                            inputComponent={TextMaskCustom}
                            sx={{
                                height: 40,
                                '& legend': {
                                    display: 'none'
                                },
                                '& fieldset': {
                                    top: 0
                                }
                            }}
                        />
                        {touched.licenseRestriction && errors.licenseRestriction && (
                            <FormHelperText error id='licenseRestriction'>
                                {errors.licenseRestriction}
                            </FormHelperText>
                        )}
                    </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor='plateNumber'><span style={{ color: '#F44336' }}>*</span>Register Plate number</InputLabel>
                        <OutlinedInput
                            name='plateNumber'
                            placeholder='Plate Number'
                            id='plateNumber'
                            onBlur={handleBlur}
                            fullWidth
                            error={Boolean(touched.plateNumber && errors.plateNumber)}
                            value={values.plateNumber}
                            onChange={handleChange('plateNumber')}
                            inputProps={{
                                sx: { textTransform: 'uppercase' }
                            }}
                            sx={{
                                height: 40,
                                '& legend': {
                                    display: 'none'
                                },
                                '& fieldset': {
                                    top: 0
                                }
                            }}
                        />
                        {touched.plateNumber && errors.plateNumber && (
                            <FormHelperText error id='plateNumber'>
                                {errors.plateNumber}
                            </FormHelperText>
                        )}
                    </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor='vehicleType'><span style={{ color: '#F44336' }}>*</span>VehicleType</InputLabel>
                        <Select
                            name='vehicleType'
                            id='vehicleType'
                            value={values.vehicleType}
                            onBlur={handleBlur}
                            fullWidth
                            error={Boolean(touched.vehicleType && errors.vehicleType)}
                            onChange={handleChange('vehicleType')}
                            displayEmpty
                            sx={{
                                boxShadow: 'none',
                                height: 40,
                                '& legend': {
                                    display: 'none'
                                },
                                '& fieldset': {
                                    top: 0,
                                },
                            }}
                        >
                            <MenuItem value='' disabled>Choose Vehicle</MenuItem>
                            {Object.entries(VEHICLE)?.map(([value, name]) => (
                                <MenuItem key={value} value={value}>{name}</MenuItem>
                            ))}
                        </Select>
                        {touched.vehicleType && errors.vehicleType && (
                            <FormHelperText error id='vehicleType'>
                                {errors.vehicleType}
                            </FormHelperText>
                        )}
                    </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor='vehicleModel'><span style={{ color: '#F44336' }}>*</span>Vehicle Model</InputLabel>
                        <OutlinedInput
                            name='vehicleModel'
                            placeholder='Vehicle Model'
                            id='vehicleModel'
                            onBlur={handleBlur}
                            fullWidth
                            error={Boolean(touched.vehicleModel && errors.vehicleModel)}
                            value={values.vehicleModel}
                            onChange={handleChange('vehicleModel')}
                            sx={{
                                height: 40,
                                '& legend': {
                                    display: 'none'
                                },
                                '& fieldset': {
                                    top: 0
                                }
                            }}
                        />
                        {touched.vehicleModel && errors.vehicleModel && (
                            <FormHelperText error id='vehicleModel'>
                                {errors.vehicleModel}
                            </FormHelperText>
                        )}
                    </Stack>
                </Grid>

            </Grid>
        </>
    )
}

export default RegisterVehicle