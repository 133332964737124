import classnames from 'classnames';
import * as React from 'react';

type Props = React.HTMLProps<HTMLInputElement> & {
  children?: React.ReactNode,
  border?: 'none' | 'grey',
  padding?: 'pl-10' | 'py-1' | 'py-2' | 'pl-12' | 'pl-14' | 'pl-16'
}

let getBorderStyle = (border: Props['border']) => {
  switch (border) {
    case 'none':
      return 'border-none'
    case 'grey':
      return 'border border-grey-1';
    default:
      return 'border border-grey-1 focus:border-grey-2';
  }
}

let getPadding = (padding: Props['padding']) => {
  switch (padding) {
    case 'pl-16':
      return 'py-2.5 pl-16 pr-2.5'
    case 'pl-14':
      return 'py-2.5 pl-14 pr-2.5'
    case 'pl-12':
      return 'py-2.5 pl-12 pr-2.5'
    case 'pl-10':
      return 'py-2.5 pl-10 pr-2.5'
    case 'py-1':
      return 'py-1'
    case 'py-2':
      return 'py-2'
    default:
      return 'p-2';
  }
}

export const Input = ({ border, padding, ...props }: Props) => (
  <input className={classnames("rounded text-base w-full focus:outline-none text-black-1",
    getBorderStyle(border), getPadding(padding)
  )} {...props} />
);