import './Sidebar.css';
import { MenuData } from './MenuData';
import Menu from './Menu';
import { BRAND_IMAGE } from '../constants';
import SimpleBarScroll from '../third-party/SimpleBarScroll';

export default function SideBar() {
  return (
    <>
      <input type="checkbox" id="nav-toggle" />
      <div className="sidebar">
        <div className="sidebar-brand">
          <span>
            <img
              src={BRAND_IMAGE['BRAND_LOGO_WHITE']}
              alt="logo"
              className="logo"
            />
          </span>
        </div>

        <SimpleBarScroll
          sx={{
            '& .simplebar-content': {
              display: 'flex',
              flexDirection: 'column',
            },
          }}
        >
          <div className="sidebar-menu">
            <ul>
              {MenuData.map((item, index) => {
                return <Menu item={item} key={index} />;
              })}
            </ul>
          </div>
        </SimpleBarScroll>
      </div>
    </>
  );
}
