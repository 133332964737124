import { useMemo, useEffect } from 'react'  
import { Button } from '../../../../components/common/Button';   
import { SpanContainer, Span } from '../../../../components/common/Span';  
import { useTable, useFilters, useSortBy, usePagination } from 'react-table'
import { useHistory } from 'react-router-dom'

import { BiSortDown, BiSortUp } from 'react-icons/bi'
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md'

import { ColumnFilter } from '../../../../components/Table/ColumnFilter'
import '../../../../components/Table/Table.css'

const Table = ({ columns, data } : { columns: any; data: any; }) => {

    const history = useHistory();
    const handleRowClick =(e:any, row:any)=>{    
        history.push(`/rate/express/${row.original.rateExpressId}`);
    }

    const tableInstance = useTable({ 
        columns, 
        data,
        defaultColumn: { Filter: ColumnFilter },
        initialState: {
            sortBy: [
                {
                    id: 'id',    
                    desc: true
                }
            ]
        } 
    },
        useFilters, useSortBy, usePagination
    ) 
    

    const { 
        getTableProps, 
        getTableBodyProps,
        headerGroups,
        page,
        pageCount,
        nextPage, 
        pageOptions,
        previousPage,
        canNextPage,
        canPreviousPage,
        setPageSize,
        state, 
        setHiddenColumns,
        prepareRow, 
    } = tableInstance

    const { pageIndex, pageSize, filters } = state

    return (<>  
        <table {...getTableProps()} border='0' cellSpacing='0' cellPadding='0' className='w-full'>
        <thead>
            {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}> 
                    <div className='flex justify-center font-semibold px-3.5 py-4 whitespace-nowrap'>
                        {column.render('Header')}   
                            {column.isSorted
                            ? column.isSortedDesc
                                ? <BiSortDown className='my-auto text-lg mx-2'/>
                                : <BiSortUp className='my-auto text-lg mx-2'/>
                            : ''} 
                    </div>
                    <div className='pt-2 mx-2'>
                        {column.canFilter ? column.render('Filter') : null}
                    </div> 
                </th>   
                ))}
            </tr>
            ))}
        </thead>
        
        <tbody {...getTableBodyProps()}> 
            {page.map((row) => {
            prepareRow(row);
            return (
                <tr {...row.getRowProps()} className="cursor-pointer z-0">
                    {row.cells.map((cell, index) => {
                        return <td {...cell.getCellProps()} onClick={index !== 4 ? (e) => handleRowClick(e,row): ''} className='text-center font-semibold'>{cell.render('Cell')}</td>;
                    })}
                </tr>
            );
            })}
        </tbody>
    </table>  
    <div className='flex  justify-end p-4'> 
        <div className='px-4'>
            <SpanContainer color='grey'>Rows per page: </SpanContainer>
            <select
                className='px-1.5 text-base focus:outline-none text-grey-7'
                value={pageSize} 
                onChange={(e) => setPageSize(Number(e.target.value))}>
                    {[10,25,50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
            </select> 
        </div>

        <SpanContainer padding='px-4' color='grey'>  
            {pageIndex + 1}-{pageSize} of {pageOptions.length}
        </SpanContainer> 

        <div className='flex my-auto'>
            <SpanContainer padding='pr-2'>
                <MdArrowBackIosNew onClick={() => previousPage()} className='text-grey-4 cursor-pointer'/>  
            </SpanContainer>  
            <SpanContainer>
                <MdArrowForwardIos onClick={() => nextPage()} className='text-grey-4 cursor-pointer'/>
            </SpanContainer> 
        </div> 
        </div>  
    </>);
   }


export default Table;

export const handleMultiSortBy = (column, setSortBy, meinSortBy) => {
    //set sort desc, aesc or none?
    const desc =
      column.isSortedDesc === true
        ? undefined
        : column.isSortedDesc === false
        ? true
        : false;
    setSortBy([{ id: column.id, desc }, ...meinSortBy]);
  };
   
    