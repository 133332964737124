import { useState } from 'react'
import './Switch.scss';

type Props = {
  active: boolean,
  setActive: Function,
  refSwitch: any
}

export default function Switch ({active, setActive, refSwitch}: Props) {  
  const [clickCount, setClickCount] = useState(0);

  const handleActive = () => {
    if(active && clickCount === 0){
      setActive(true)
      setClickCount(clickCount + 1)
    }else{
      setActive(!active)
      setClickCount(clickCount + 1)
    }
  }
  
  return (
    <div className="toggle-switch">
      <input
        ref={refSwitch}
        type="checkbox"
        className="toggle-switch-checkbox"
        name="toggleSwitch"
        id="toggleSwitch"
        onClick={handleActive}
      />
      <label className="toggle-switch-label" htmlFor="toggleSwitch">
        <span className="toggle-switch-inner" />
        <span className="toggle-switch-switch" />
      </label>
    </div>
  )
}