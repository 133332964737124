import { createModel } from "@rematch/core";
import { collection, getDocs, getFirestore, onSnapshot, query, where, doc, getDoc } from "firebase/firestore";
import { ref } from "yup";
import { RootModel } from ".";

interface IFirebasePayload {
  colName: string;
  name: string;
  filter: string;
  id: any;
}

interface IDataPayload {
  name: string;
  data: any;
}

interface ISetTableIsCheckedPayload {
  target: string;
  value: boolean;
}


export interface IFilterValue {
    field: string;
    operation: string;
    value: any;
  }
  

const initialState = {
  deliveries: null,
  driversProfile: null,
  customer: null,
  isLoading: false,
  currentRoute: null,
  settingData: null,
} as any;

export const Express = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState(){
      return { ...initialState }
    },
    updateState(state, newState){
      return{
        ...state,
        ...newState
      }
    },
    setData(state, payload: IDataPayload) {
        const { name, data } = payload;
  
        return {
          ...state,
          [name]: data
        };
      },  
  },
  effects: (dispatch) => ({
    async getFirebaseData(payload:IFirebasePayload) {
        const db = getFirestore();

        let { colName, name, filter, id } = payload;

        if(colName === 'deliveries'){
          const docRef = doc(db,'deliveries', id);
          const docSnap = await getDoc(docRef);
          if(docSnap.exists()){
            const data = docSnap.data()
            dispatch.Express.setData({name: name, data:  data !== undefined ? data : null })
          }
        }else{
          const querySnapshot = id !== undefined && await getDocs(query(collection(db, colName), where(filter, '==', id))) as any
          const data = querySnapshot?.docs?.map(ref => ref.data())[0];
          dispatch.Express.setData({
                name: name,
                data:  data !== undefined ? data : null 
          })
        }
    },
  }),
});
