import { useEffect, useState, useRef } from 'react' 
import { useHistory, useParams } from "react-router";
import PostErrorAlert from '../../../components/PostErrorAlert';
import BackDrop from "../../../components/BackDrop";
import { updateBilling } from '../../../common/api';
import { onlyNumberKey } from '../../../common/utils';

import { Span, SpanContainer  } from '../../../components/common/Span'
import { Input } from '../../../components/common/Input'
import { Select } from '../../../components/common/Select'
import { Button } from '../../../components/common/Button'
import { InputContainer, GridContainer, Container} from '../../../components/common/Containers'
import { DRIVER, DRIVERGROUP, VEHICLETYPE } from '../../../components/constants/status';
import { RiErrorWarningFill } from "react-icons/ri";

import '../CSS/Driver.css'
import { IoCloseSharp } from 'react-icons/io5' 
import { BsPlusLg } from 'react-icons/bs'
 
import { doc, getDoc, getFirestore, onSnapshot } from "firebase/firestore";
import Loader from "../../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../store";
import { MdDelete } from 'react-icons/md'
import Switch from '../../../components/Switch/Switch' 

type Props = {
    setEditBilling: Function,  
} 
 
export default ({setEditBilling, ...props}: Props) => {
    const dispatch = useDispatch<Dispatch>(); 

    const editBillingId: any = useSelector(
        (state: RootState) => state.UI.forms.editBilling?.id ?? null
      );

    const billings: any[] = useSelector((state: RootState) => state.Table.tables?.billings?.data);
    const billing = billings?.length > 0 && billings?.filter(driver => driver.id === editBillingId)[0] || '';//current driver details
    
    const [openAlert, setOpenAlert] = useState(false);
    const [errMessage, setErrMessage] = useState('');
    const [cancel, setCancel] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
        
    const [billingDetails, setBillingDetails] = useState({
        name: billing?.name ?? '',
        email: billing?.email ?? ''
    }) as any;

    const handleChange = (e: any, ) => {
        const value = e.target.value;
        const name = e.target.name
        setBillingDetails({
            ...billingDetails,
            [name]: value
        })
    }

    const isChangeInData = () => {
            const originalDetails = Object.entries(billing).map(entry => `${entry[0]} ${entry[1]}`);
            const updatedDetails = Object.entries(billingDetails).map(entry => `${entry[0]} ${entry[1]}`);
            const result = updatedDetails.map((item) => originalDetails.includes(item)).some(res => res === false);
            return result;
    };

    const showError = () => {
        setErrMessage('No Changes made!');
        setOpenAlert(true);
    }

    const handleUpdateBilling = async(e) => {
        e.preventDefault();
        const isChange = await isChangeInData();
        if(!isChange){
            return showError();
        }
        try{
            dispatch.UI.setIsLoading(true)
            const res = await updateBilling(editBillingId, billingDetails)
            dispatch.UI.updateFormId({target: "editBilling", value: null});
            dispatch.UI.setAlert({message: 'Billing details updated', type:'Success'})
            dispatch.UI.setIsLoading(false);
            window.location.reload()
        }catch(err: any){
            const errMsg = err?.data?.error ?? 'Unable to update billing';
            setErrMessage(errMsg)
            setOpenAlert(true);
            dispatch.UI.setIsLoading(false);
        }
    }
    return (<>
    {cancel && (
        <>
          <div className="popup_edit">
            <div className="flex">
              <RiErrorWarningFill className="text-xl my-auto text-orange-1" />
              <Span margin="mx-2">
                Discard changes made in the user information?
              </Span>
            </div>

            <div className="flex justify-end py-2">
              <Button
                margin="mx-2"
                bg="white"
                border="lightgrey"
                color="black"
                width="none"
                padding="px-2"
                radius="none"
                onClick={() => setCancel(false)}
              >
                Cancel
              </Button> 
              <Button
                margin="mx-2"
                width="none"
                padding="px-2"
                radius="none"
                onClick={() => {
                    dispatch.UI.updateFormId({
                        target: "editBilling",
                        value: null,
                    });
                }}
              >
                Discard
              </Button> 
            </div>
          </div>
        </>
      )}
    <div className='side-parcel absolute inset-y-0 right-0 px-0'>
    <BackDrop />
        <div className='flex py-2'>
            <IoCloseSharp 
                onClick={() => {
                    dispatch.UI.updateFormId({
                      target: "editBilling",
                      value: null,
                    });
                  }}
                className='my-auto mx-2 text-lg cursor-pointer'
            />
            <Span weight='semi' size='lg'>
                Update Billing
            </Span>
        </div>

        <form onSubmit={handleUpdateBilling} autoComplete='off'>
        <div className='text-left py-4'>
            <Span weight='semi' size='lg' transform='uppercase'>
                Billing Information
            </Span> 
            
            <Span margin='mt-4'>Name</Span>
            <Input placeholder='Name' value={billingDetails.name} onChange={handleChange} name='name' required /> 

            <Span margin='mt-4'>Email</Span>
            <Input placeholder='Email' type='email' value={billingDetails.email} onChange={handleChange} name='email' required />  
        </div>

        <PostErrorAlert openAlert={openAlert} setOpenAlert={setOpenAlert} message={errMessage} />
            
        <div className="flex inset-x-0 bottom-0 py-4 text-center">
            <Button
              margin="mx-2"
              bg="white"
              border="lightgrey"
              color="black"
              onClick={() => dispatch.UI.updateFormId({target: "editBilling", value: null})}
            >
              Cancel
            </Button>
            <Button margin="mx-2" type="submit">
              Update Billing
            </Button>
        </div>    
    </form>
    </div> 
    </>);
}   
