import { useEffect, useMemo, useState } from 'react'
import { Dispatch, RootState } from '../../../store'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker";
import { useLocation } from 'react-router-dom';

import { Container, GridContainer } from '../../../components/common/Containers'
import { Span } from '../../../components/common/Span'
import { Button } from '../../../components/common/Button'
import { Select } from '../../../components/common/Select'
import NoData from '../../../components/common/NoData/NoData'
import { HiOutlineDocumentDownload } from 'react-icons/hi';
import { CSVLink } from 'react-csv';

import { PENDING, APPROVE, DECLINED, TAB } from '../../../components/constants/status';
import Loader from "../../../components/Loader";

import Table from './Table/Table'
import { COLUMNS } from './Table/column'
import DATA from './Table/data.json';

import UpdateModal from './Modal/UpdateModal';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { withdrawalRequestDataToDownload } from '../../../common/utils';

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export default () => {

    const query = useQuery();
    const tab: any = query.get('tab');
    const dispatch = useDispatch<Dispatch>();
    const columns = useMemo(() => COLUMNS, [])
    const [updateModal, setUpdateModal] = useState(false)
    const [btnType, setBtnType] = useState('');
    const [status, setStatus] = useState(TAB[tab]) as any[];
    const [startDate, setStartDate] = useState(new Date(`01-01-${new Date().getFullYear()}`));
    const [endDate, setEndDate] = useState(new Date());
    const [userType, setUserType] = useState(['customer', 'driver']);
    const startD = Math.round(startDate.getTime() / 1000);
    const endD = Math.round(endDate.getTime() / 1000);
    const data: any | [] = useSelector((state: RootState) => state.Table.tables.withdrawal_requests?.data)
        .filter(req => req.createdAt.seconds >= startD && req.createdAt.seconds <= endD)
        .filter(req => userType.includes(req.wallet?.type))
        .filter(req => {
            if (status.includes(req.status)) {
                return req
            }
            return null;
        }).sort((a, b) => { return a.createdAt.seconds - b.createdAt.seconds })

    const handleSubmit = async (e) => {
        setBtnType(e.target.value)
        setUpdateModal(true)
    }

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dispatch.Table.subscribeToFirebase({
            name: 'wallet_withdrawal_requests',
            table: 'withdrawal_requests'
        });
        setIsLoading(false);

        return () => {
            dispatch.Table.UNSUBSCRIBE()
        }
    }, [])
    return (<>
        {isLoading ?
            <Loader />
            : <>
                <Container border='grey' padding='none'>
                    <div className='p-4'>
                        <div className='flex justify-between'>
                            <Span margin='mb-6' weight='bold' size='2xl'>Withdrawal Requests</Span>
                            {status.includes('approved') || status.includes('declined') ? (
                                <>
                                </>
                            ) : (
                                <div className='flex justify-end my-4'>
                                    <Button width='none' margin='mx-2' padding='px-4' onClick={handleSubmit} value='approved'>
                                        Approve
                                    </Button>
                                    <Button width='none' margin='mx-2' padding='px-4' color='red' border='red' bg='white' onClick={handleSubmit} value={'declined'}>
                                        Decline
                                    </Button>
                                </div>
                            )}
                        </div>

                        <div className='flex justify-between'>
                            <div className='flex'>
                                <Link to='/wallet/withdrawalRequest?tab=pending'>
                                    <Button
                                        onClick={() => setStatus(PENDING)}
                                        radius='none' width='none' border={status?.includes('pending') ? 'bottom-grey' : 'bottom'} weight='medium' bg='none' color='black'
                                        padding='px-4'>
                                        Pending
                                    </Button>
                                </Link>
                                <Link to='/wallet/withdrawalRequest?tab=approve'>
                                    <Button
                                        onClick={() => setStatus(APPROVE)}
                                        radius='none' width='none' border={status?.includes('approve') ? 'bottom-grey' : 'bottom'} weight='medium' bg='none' color='black'
                                        padding='px-4'>
                                        Approve
                                    </Button>
                                </Link>
                                <Link to='/wallet/withdrawalRequest?tab=declined'>
                                    <Button
                                        onClick={() => setStatus(DECLINED)}
                                        radius='none' width='none' border={status?.includes('declined') ? 'bottom-grey' : 'bottom'} weight='medium' bg='none' color='black'
                                        padding='px-4'>
                                        Declined
                                    </Button>
                                </Link>
                            </div>
                            <Button width='none' bg='white' color='darkgrey' border='grey' margin='mx-2'>
                                <CSVLink className='flex pr-2' data={withdrawalRequestDataToDownload(data)} filename={`withdrawal_request_${moment(new Date()).format('MMMM Do YYYY, h:mm:ss a')}`}>
                                    <HiOutlineDocumentDownload className='my-auto mx-2 text-2xl' />
                                    Download
                                </CSVLink>
                            </Button>
                        </div>
                    </div>

                    <GridContainer>
                        <div className='col-span-2'>
                            <Span margin='mt-4'>Date</Span>
                            <div className='flex p-1.5 border border-grey-1 rounded'>
                                <DatePicker
                                    className='text-base focus:outline-none w-full'
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    placeholderText="Date From"
                                    dateFormat="MMMM d, yyyy"
                                />
                                <DatePicker
                                    className='text-base focus:outline-none w-full'
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    placeholderText="Date to"
                                    dateFormat="MMMM d, yyyy"
                                />
                            </div>
                        </div>

                        <div>
                            <Span margin='mt-4'>User Type</Span>
                            <Select required onChange={(e: any) => setUserType(e.target.value)}>
                                <option value="" selected disabled hidden> Select User </option>
                                <option value="['customer']"> Customer </option>
                                <option value="['driver']"> Driver </option>
                                <option value="['driver', 'customer']"> All </option>
                            </Select>
                        </div>

                    </GridContainer>

                    <UpdateModal setUpdateModal={setUpdateModal} updateModal={updateModal} btnType={btnType} />

                    <div className='overflow-auto'>
                        {data?.length < 0
                            ? <NoData />
                            : <Table columns={columns} data={data} />
                        }
                    </div>
                </Container>
            </>}
    </>)
}