export const object = [
    {
        name: 'license',
        label: 'Upload Driver License'
    },
    {
        name: 'orcr',
        label: 'Upload OR CR'
    },
    {
        name: 'nbi',
        label: 'Upload NBI Clearance'
    },
    {
        name: 'selfieWithLicense',
        label: 'Upload selfie with License'
    },
    {
        name: 'vehicleWithPlateNumber',
        label: 'Upload Vehicle Photo with Plate Number'
    },
    {
        name: 'vehicleInsurance',
        label: 'Upload Vehicle Insurance'
    },
    {
        name: 'deedOfSale',
        label: 'Upload Deed of Sale'
    },
    {
        name: 'letterOfAuthorization',
        label: 'Upload Letter of Authorization'
    },
    {
        name: 'vaccinCard',
        label: 'Upload Vaccination'
    },
]