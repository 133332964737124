
import { useEffect, useState } from 'react' 
import { useHistory, useParams } from "react-router";
import { addBillingAccount } from '../../../common/api';
import PostErrorAlert from '../../../components/PostErrorAlert';
import BackDrop from "../../../components/BackDrop";
import { onlyNumberKey } from '../../../common/utils';

import { Span, SpanContainer  } from '../../../components/common/Span'
import { Input } from '../../../components/common/Input'
import { Select } from '../../../components/common/Select'
import { Button } from '../../../components/common/Button'
import { InputContainer, GridContainer, Container} from '../../../components/common/Containers'

import '../CSS/Driver.css'
import { IoCloseSharp } from 'react-icons/io5' 
import { BsPlusLg } from 'react-icons/bs'
import { RiErrorWarningFill } from "react-icons/ri";
 
import { doc, getDoc, getFirestore, onSnapshot } from "firebase/firestore";
import Loader from "../../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../store";
import { MdDelete } from 'react-icons/md'
import Switch from '../../../components/Switch/Switch'
import { DRIVERGROUP, VEHICLETYPE } from '../../../components/constants/status';

type Props = {
    setAddBilling: Function,  
} 
 

export default ({setAddBilling, ...props}: Props) => { 

    const dispatch = useDispatch<Dispatch>();

    const [openAlert, setOpenAlert] = useState(false);
    const [errMessage, setErrMessage] = useState('');
    const [cancel, setCancel] = useState(false);
    const [active, setActive] = useState(false);
    const [billingDetails, setBillingDetails] = useState({
        name: '',
        email: '',
    });

    const handleAddBilling = async(e) => {
        e.preventDefault();
        try{
            dispatch.UI.setIsLoading(true);
            const res = await addBillingAccount(billingDetails);
            setAddBilling(false);
            dispatch.UI.setAlert({message: 'Billing added!', type: 'Success'});
            dispatch.UI.setIsLoading(false);
            const TIMEOUT = setTimeout(() => {
              dispatch.Table.getFirebaseData({name: 'billing', table: 'billings'})
              clearTimeout(TIMEOUT)
            },1000);
        }catch(err: any){
            const errMsg = err?.data?.error ?? 'Unable to add billing';
            dispatch.UI.setIsLoading(false);
            setOpenAlert(true);
            setErrMessage(errMsg);
        }
    }

    const handleChange = (e: any, ) => {
        const value = e.target.value;
        const name = e.target.name
        setBillingDetails({
            ...billingDetails,
            [name]: value
        })
    }
    return (<>
    {cancel && (
        <>
          <div className="popup_edit">
            <div className="flex">
              <RiErrorWarningFill className="text-xl my-auto text-orange-1" />
              <Span margin="mx-2">
                Discard changes made in the user information?
              </Span>
            </div>

            <div className="flex justify-end py-2">
              <Button
                margin="mx-2"
                bg="white"
                border="lightgrey"
                color="black"
                width="none"
                padding="px-2"
                radius="none"
                onClick={() => setCancel(false)}
              >
                Cancel
              </Button> 
              <Button
                margin="mx-2"
                width="none"
                padding="px-2"
                radius="none"
                onClick={() => setAddBilling(false)}
              >
                Discard
              </Button> 
            </div>
          </div>
        </>
      )}
    <div className="side-parcel absolute inset-y-0 right-0 px-0">
        <BackDrop />
        <div className='flex py-2'>
            <IoCloseSharp  
                onClick={() => setAddBilling(false)}  
                className='my-auto mx-2 text-lg cursor-pointer'
            />
            <Span weight='semi' size='lg'>
                Add Billing
            </Span>
        </div>

        <form onSubmit={handleAddBilling} autoComplete='off' >
        <div className='text-left py-4'>
            <Span weight='semi' size='lg' transform='uppercase'>
                Billing Information
            </Span> 

            <Span margin='mt-4'>Name</Span>
            <Input placeholder='Name' onChange={handleChange} name='name' required /> 

            <Span margin='mt-4'>Email</Span>
            <Input placeholder='Email' onChange={handleChange} name='email' required type='email' />
        </div>

        <PostErrorAlert openAlert={openAlert} setOpenAlert={setOpenAlert} message={errMessage} />  
            
        <div className="flex inset-x-0 bottom-0 py-4 text-center">
            <Button
              margin="mx-2"
              bg="white"
              border="lightgrey"
              color="black"
              onClick={()=> setAddBilling(false)}
            >
              Cancel
            </Button>
            <Button margin="mx-2" type="submit">
              Add Billing
            </Button>
        </div>    
        </form>
    </div> 
    </>);
}   