import ParcelsViewPage from '../containers/Parcels/ViewParcel/ViewParcel'  
import Header from '../components/Header/Header' 
import SidebarPage from '../components/Sidebar/Sidebar'
import { MainContainer } from '../components/common/Containers'  
 
const ParcelsView = () => {  
    return ( 
        <>  
            <SidebarPage />
            <MainContainer>    
                <Header /> 
                <main> 
                    <ParcelsViewPage/>   
                </main> 
            </MainContainer>
        </>
    )
}

export default ParcelsView
