/* eslint-disable import/no-anonymous-default-export */

import { useState } from 'react'; 
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '../../../store' 
import { createParcel } from '../../../common/api';
import PostErrorAlert from '../../../components/PostErrorAlert';
import BackDrop from "../../../components/BackDrop";

import { Span, SpanContainer } from '../../../components/common/Span'
import { Input } from '../../../components/common/Input'
import { Button } from '../../../components/common/Button'
import { GridContainer, InputContainer } from '../../../components/common/Containers'
import DatePicker from "react-datepicker";
 
import { IoCloseSharp } from 'react-icons/io5'   
import { MdKeyboardArrowDown } from 'react-icons/md'
 
import { onlyNumberKeyAndDot } from '../../../common/utils';

type Props = {
    setNext: Function,
    data: any
    setData: Function,
    onChange: Function
}  

export default ({setNext, data, setData, onChange, ...props}: Props) => {  
    const history = useHistory();
    const dispatch = useDispatch<Dispatch>();  

    const [openAlert, setOpenAlert] = useState(false);
    const [errMessage, setErrMessage] = useState('');

    const handleAddParcel = async(e) => {
        e.preventDefault();
       try{
           const payload = {
               ...data,
               packageDetails: {
                ...data.packageDetails,
                value: parseFloat(data.packageDetails.value),
                height: parseFloat(data.packageDetails.height),
                weight: parseFloat(data.packageDetails.weight),
                width: parseFloat(data.packageDetails.width),
                length: parseFloat(data.packageDetails.length)
               }
           }
            dispatch.UI.setIsLoading(true);
            await createParcel(payload);
            dispatch.UI.setIsLoading(false);
            dispatch.UI.setAlert({message:'New parcel created!', type:'Success'});
            window.location.reload();
       }catch(err: any){
            const errMsg = err?.data?.error === undefined ? 'Unable to add parcel' : err.data.error;
            dispatch.UI.setIsLoading(false);
            setErrMessage(errMsg);
            setOpenAlert(true);
       }
    }

    return (<>   
        <div className='side-parcel absolute inset-y-0 right-0 px-0'>
            <BackDrop />
            <div className='flex py-4 border-b border-grey-6'>
                <IoCloseSharp  
                    onClick={() => window.location.reload()}  
                    className='my-auto mx-2 text-lg cursor-pointer'
                />
                <Span weight='semi' size='lg'>
                    Add Parcel
                </Span>
            </div> 


            <form name="form" onSubmit={handleAddParcel}> 
            <div className='text-left py-4'>
                <Span size='lg' weight='semi'>Parcel Information</Span> 

                <div>
                    <Span margin='mt-4'>Pickup Date:</Span>
                    <InputContainer margin="none"> 
                        <MdKeyboardArrowDown className='text-xl my-auto mx-1 absolute inset-y-0 right-0 cursor-pointer'/>  
                        <DatePicker
                            className='text-base focus:outline-none w-full border border-grey-1 focus:border-grey-2
                            p-2 rounded'
                            selected={data?.pickUpDate}
                            onChange={(date) => setData({...data, pickUpDate: date})} 
                            dateFormat="MMMM d, yyyy"
                            required
                            minDate={new Date()}
                        /> 
                    </InputContainer>
                </div>

                <GridContainer grid='four' padding='none'>
                    {/* <div className='col-span-2'>
                        <Span margin='mt-4'>Package Size:</Span>
                        <Input placeholder='Package Size' type='text' id='size' {...formik.getFieldProps('size')}/>
                        {formik.touched.size && formik.errors.size? <div className="error">{formik.errors.size}</div> : null}
            
                    </div>

                    <div className='col-span-2'>
                        <Span margin='mt-4'>Payment Type:</Span>
                        <Input placeholder='Payment Type' type='text' id='paymentType' {...formik.getFieldProps('paymentType')}/>
                        {formik.touched.paymentType && formik.errors.paymentType? <div className="error">{formik.errors.paymentType}</div> : null} 
                    </div> */}

                    <div className='col-span-2'>
                        <Span margin='mt-4'>Declared Value:</Span> 
                            <Input placeholder='Declared Value' type='text' id='value' required 
                                onChange={(e: any) => onChange(e, 'value', 'packageDetails')}
                            /> 
                    </div>

                    <div className='col-span-2'>
                        <Span margin='mt-4'>Transport Mode:</Span> 
                        <select className='
                            w-full rounded text-base focus:outline-none border border-grey-1 p-2 text-grey-7'
                            id='deliveryType'
                            onChange={(e: any) => onChange(e, 'transportMode', 'packageDetails')}
                            required
                        > 
                            <option value="" selected disabled hidden>Select Transport Mode</option>
                            <option value='land' >Land</option> 
                            <option value='sea' >Sea</option> 
                        </select> 
                    </div>

                    <div className='col-span-2'>
                        <Span margin='mt-4'>Description:</Span>
                        <Input placeholder='Description' type='text' id='itemDescription' required
                            onChange={(e: any) => onChange(e, 'itemDescription', 'packageDetails')}
                        />
                    </div>
                    
                    {/* <div>
                        <Span margin='mt-4'>Length:</Span>
                        <Input placeholder='Enter cm' type='text' id='length' required onKeyPress={onlyNumberKeyAndDot}
                            onChange={(e: any) => onChange(e, 'length', 'packageDetails')}
                        />
                    </div> */}

                    {/* <div>
                        <Span margin='mt-4'>Width:</Span>
                        <Input placeholder='Enter cm ' type='text' id='width' required onKeyPress={onlyNumberKeyAndDot} 
                            onChange={(e: any) => onChange(e, 'width', 'packageDetails')}
                        />
                    </div> */}

                    {/* <div>
                        <Span margin='mt-4'>Height:</Span>
                        <Input placeholder='Enter cm' type='text' id='height' required onKeyPress={onlyNumberKeyAndDot}
                            onChange={(e: any) => onChange(e, 'height', 'packageDetails')}
                        />
                    </div> */}

                    <div className='col-span-2'>
                        <Span margin='mt-4'>Estimated Weight:</Span>
                        <Input placeholder='Enter kg' type='text' id='weight' required onKeyPress={onlyNumberKeyAndDot} 
                            onChange={(e: any) => onChange(e, 'weight', 'packageDetails')}
                        />
                    </div>

                    <div className='col-span-4'>
                        <GridContainer padding='none'>
                        <div>
                            <Span margin='mt-4'>Reference 1:</Span>
                            <Input placeholder='Reference 1 (Optional)' type='text' id='' onChange={(e: any) => onChange(e, 'reference1', 'none')}/>
                        </div>

                        <div>
                            <Span margin='mt-4'>Reference 2:</Span>
                            <Input placeholder='Reference 2 (Optional)' type='text' id='' onChange={(e: any) => onChange(e, 'reference2', 'none')}/>
                        </div>

                        <div>
                            <Span margin='mt-4'>Reference 3:</Span>
                            <Input placeholder='Reference 3 (Optional)' type='text' id='' onChange={(e: any) => onChange(e, 'reference3', 'none')}/>
                        </div>
                    
                        </GridContainer>
                    </div>
            

                    <div className='col-span-4'>
                        <Span margin='mt-4'>Remarks:</Span>
                        <Input placeholder='Remarks (Optional)' type='text' id='' onChange={(e: any) => onChange(e, 'remarks', 'none')}/>
                    </div>
                </GridContainer>  
            </div>   

            <PostErrorAlert openAlert={openAlert} setOpenAlert={setOpenAlert} message={errMessage} />

            <div className='flex inset-x-0 bottom-0 py-4 text-center'> 
                <Button 
                    margin='mx-2' bg='white' border='lightgrey' color='black'
                    onClick={() => setNext(false)}
                >
                    Back
                </Button>
                <Button margin='mx-2'  type="submit"
                >
                    Add Parcel
                </Button>
            </div> 
            </form>
        </div>
 
 
    </>);
}   