import {useState, useEffect} from 'react'

import { ModalContainer } from '../../../components/common/Containers'
import { Button } from '../../../components/common/Button' 
import { Span } from '../../../components/common/Span' 
import { Input } from '../../../components/common/Input' 
 
import warningIcon from '../../../images/Warning_icon.png'
import { RiErrorWarningFill } from 'react-icons/ri'
import { IoCloseSharp } from 'react-icons/io5' 

import { Dispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import { updateParcelStatus } from '../../../common/api';
    
type Props = {
    setOpenSubStatus: Function,
    openSubStatus: boolean,
    data?: any
} 
  
export default ({openSubStatus, setOpenSubStatus, data, ...props}: Props) => { 

    const dispatch = useDispatch<Dispatch>();

    const [subStatus, setSubStatus] = useState('')

    const handleChange = (e) => {
        setSubStatus(e.target.value);
        dispatch.Parcel.updateSubstatus({substatus:subStatus});
    }
 
    const handleSubmit = async(e) => {
        e.preventDefault();
        setOpenSubStatus(false);
        try{
            dispatch.UI.setIsLoading(true);
            await updateParcelStatus(data?.status,data?.parcelId,subStatus)
            dispatch.UI.setAlert({message: 'Status updated!', type:'Success'})
            dispatch.UI.setIsLoading(false);
        }catch(err: any){
            let errMsg = 'Unable to update status!'
            if(err.data.error){
                errMsg = err.data.error
            }
            dispatch.UI.setAlert({message:errMsg, type:'Error'})
            dispatch.UI.setIsLoading(false);
        }
    }
   
    return (<>
    {openSubStatus && (
        <>
            <ModalContainer> 
            <form onSubmit={handleSubmit}>
                <div className="px-4 py-6 whitespace-normal"> 
                        <div className='flex'>
                            
                    <img src={warningIcon} alt={warningIcon} className='h-8 mr-2' />
                        <div className='text-left'>  
                        <Span size='lg' weight='bold'>Sub-Status</Span> 
                        </div>
                    </div>

                    <div className='popup flex'>
                        <RiErrorWarningFill className='text-2xl my-auto mx-2'/> 
                        <Input 
                            border='none' padding='py-2'
                            placeholder="Enter your substatus"
                            onChange={handleChange}
                            required
                        />
                        <IoCloseSharp  
                            className='text-lg my-auto mx-2 text-grey-13 cursor-pointer'
                        />
                    </div>
                    
                    <div className='flex justify-end my-4 mx-2'> 
                        <Button width='none' border='grey' color='black' padding='px-8' bg='white' margin='mx-2'
                            onClick={() => setOpenSubStatus(false)}
                        >
                            Cancel
                        </Button>  
                        
                        <Button width='none' padding='px-8' type='submit'>
                            Submit
                        </Button>
                    </div> 
                </div>
                </form>
            </ModalContainer> 

        <div className="opacity-60 fixed inset-0 z-40 bg-black-1"></div>
        </>
    )}
  </>);
} 