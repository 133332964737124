import React, { useEffect, useRef, useState } from 'react';

import { AiFillCaretRight, AiFillCaretDown } from 'react-icons/ai';
import { Span } from '../common/Span';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { PERMISSIONS } from '../constants';
import Action from './Action';

const Arrow = ({ open, setOpen, checked, setChecked }) => {
  return open ? (
    <AiFillCaretDown
      onClick={() => {
        setOpen(!open);
        setChecked(!checked);
      }}
      className="cursor-pointer mr-2"
    />
  ) : (
    <AiFillCaretRight
      onClick={() => {
        setOpen(!open);
        setChecked(!checked);
      }}
      className="cursor-pointer mr-2"
    />
  );
};
const Feature = ({ permissions, setPermissions, item, index, CP }) => {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const checkboxRef: any = useRef(null);

  const selectedActions = permissions?.filter(
    (feature) => feature?.name === item.name
  )[0]?.action;
  const indeterminate =
    selectedActions?.length < PERMISSIONS?.length && selectedActions?.length > 0
      ? true
      : false;
  const currentPermissions = CP?.map((item) => item?.role);
  const currentActions = CP?.filter((ca) => ca?.role === item?.role)[0];

  useEffect(() => {
    const data = {
      name: item?.name,
      role: item?.role,
      action: [],
    };
    switch (checked) {
      case true:
        return setPermissions({ type: 'ADD_PERMISSION', payload: data });
      case false:
        return setPermissions({ type: 'DELETE_PERMISSION', payload: item });
    }
  }, [checked]);

  useEffect(() => {
    if (currentPermissions?.includes(item?.role)) {
      checkboxRef?.current?.click();
    }
  }, []);

  return (
    <>
      <div
        key={item.name}
        className="mt-2 flex items-center justify-start flex-row"
      >
        <Arrow
          open={open}
          setOpen={setOpen}
          checked={checked}
          setChecked={setChecked}
        />
        <FormControlLabel
          sx={{ height: '20px' }}
          label={item.name}
          control={
            <Checkbox
              ref={checkboxRef}
              // checked={checked[0] && checked[1]}
              indeterminate={CP?.length > 0 ? false : indeterminate}
              onChange={() => {
                setOpen(!open);
                setChecked(!checked);
              }}
              color="default"
            />
          }
        />
      </div>

      {open &&
        PERMISSIONS?.map((action) => (
          <Action
            key={action.name}
            permissions={permissions}
            setPermissions={setPermissions}
            item={action}
            name={item?.name}
            CA={currentActions}
          />
        ))}
    </>
  );
};

export default Feature;
