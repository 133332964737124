import React from 'react'
import { onlyNumberKey, onlyNumberKeyAndDot } from '../../../../../common/utils'
import { GridContainer, InputContainer } from '../../../../../components/common/Containers'
import { Input } from '../../../../../components/common/Input'
import { Select } from '../../../../../components/common/Select'
import { Span, SpanContainer } from '../../../../../components/common/Span'
import { VEHICLE, VEHICLETYPE } from '../../../../../components/constants/status'

const Warehouse = ({ onChange, data, warehouse }) => {

    const firstMileisland = warehouse?.find(w => w.id === data?.pickUpWarehouse)?.island;
    const lastMileisland = warehouse?.find(w => w.id === data?.dropOffWarehouse)?.island;

    return (
        <div className="pt-4">
            <Span margin="mt-4" size="lg" weight="medium">
                Warehouse
            </Span>

            <div>
                <Span margin="mt-4">Pick Up Warehouse</Span>
                <InputContainer margin="none">
                    <Select
                        required
                        onChange={(e) => onChange(e, 'pickUpWarehouse')}
                        value={data?.pickUpWarehouse !== '' ? data?.pickUpWarehouse : null}
                    >
                        <option disabled selected>Choose Warehouse</option>
                        {warehouse?.filter(wh => wh?.island !== lastMileisland)?.map(wh => (
                            <option key={wh?.id} value={wh?.id}>{wh?.name}</option>
                        ))}
                    </Select>
                </InputContainer>
            </div>

            <div>
                <Span margin="mt-4">Drop Off Warehouse</Span>
                <InputContainer margin="none">
                    <Select
                        required
                        onChange={(e) => onChange(e, 'dropOffWarehouse')}
                        value={data?.dropOffWarehouse !== '' ? data?.dropOffWarehouse : null}
                    >
                        <option disabled selected>Choose Warehouse</option>
                        {warehouse?.filter(wh => wh?.island !== firstMileisland)?.map(wh => (
                            <option key={wh?.id} value={wh?.id}>{wh?.name}</option>
                        ))}
                    </Select>
                </InputContainer>
            </div>
        </div>
    )
}

export default Warehouse