import { BiError } from 'react-icons/bi';
import { RiCloseFill } from 'react-icons/ri';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../store';
import classnames from 'classnames';
 
const SnackBar = ({open, setOpen}: any) => {

  const alert = useSelector((state: RootState) => state?.UI?.alert)
  const dispatch = useDispatch<Dispatch>();

  const alertType = () => {
    switch(alert.type){
      case 'Error':
        return ['bg-red-100 dark:bg-red-200', 'text-red-700 dark:text-red-800', 'text-red-700 dark:text-red-800', 'bg-red-100 text-red-500 focus:ring-red-400 hover:bg-red-200 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300']
      case 'Info':
        return  ['bg-blue-100 dark:bg-blue-200', 'text-blue-700 dark:text-blue-800', 'text-blue-700 dark:text-blue-800', 'bg-blue-100 text-blue-500 focus:ring-blue-400 hover:bg-blue-200 dark:bg-blue-200 dark:text-blue-600 dark:hover:bg-blue-300']
      case 'Success':
        return  ['bg-green-100 dark:bg-green-200', 'text-green-700 dark:text-green-800', 'text-green-700 dark:text-green-800', 'bg-green-100 text-green-500 focus:ring-green-400 hover:bg-green-200 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300']
      case 'Warning':
        return  ['bg-yellow-100 dark:bg-yellow-200', 'text-yellow-700 dark:text-yellow-800', 'text-yellow-700 dark:text-yellow-800', 'bg-yellow-100 text-yellow-500 focus:ring-yellow-400 hover:bg-yellow-200 dark:bg-yellow-200 dark:text-yellow-600 dark:hover:bg-yellow-300']
      default:
        return ''
    }
  }
  
  const handleClose = () => {
    setOpen(false);
    dispatch.UI.resetAlert();
  }

  useEffect(() => {
    let TIMEOUT;
    if(alert.message !== ''){
      setOpen(true);
      TIMEOUT = setTimeout(() => {
        handleClose();
      }, 5000);
    }else{
      clearTimeout(TIMEOUT);
    }
  },[alert]);

  return (
    <>
      {open ? (
        <div className={classnames(`flex w-2/4 p-4 mb-4 absolute top-2 right-0 left-0 m-auto z-50`,alertType()[0])} role='alert'>
            <BiError className={classnames('w-5 h-5 flex-shrink-0', alertType()[1])} fill='currentColor' />
            <div className={classnames('ml-3 text-sm font-semi-bold', alertType()[2])}>
              {alert.type}:&nbsp;{alert.message}
            </div>
            <button type="button" className={classnames("ml-auto -mx-1.5 -my-1.5  rounded-lg focus:ring-2  p-1.5  inline-flex h-8 w-8", alertType()[3])} data-collapse-toggle="alert-2" aria-label="Close">
              <span className="sr-only">Close</span>
              <RiCloseFill className='w-5 h-5' onClick={handleClose}/>
              </button>
        </div>
      ):(
        <>
        </>
      )}
    </>
  )
};

export default SnackBar;
