import { useEffect, useRef, useState } from "react";
import '../CSS/Driver.css'

import { useDispatch } from 'react-redux';
import { Dispatch } from "../../../store";

import ApproveModal from '../Modal/Approve'
import PendingModal from '../Modal/Pending'
import RejectModal from '../Modal/Reject'
import edit_icon from '../../../images/edit_icon.png';
import approved_icon from '../../../images/approved_icon.png';
import pending_icon from '../../../images/pending_icon.png';
import reject_icon from '../../../images/reject_icon.png';

import ListenForOutsideClicks from "../../../components/ListenForOutsideClicks";
import { CgMoreVerticalO } from "react-icons/cg";
import { Paper, Zoom } from "@mui/material";
import { Container } from "../../../components/common/Containers";
import DeleteModal from "../../../components/Status/Modal/DeleteModal";
import ChangePasswordModal from "../../../components/Status/Modal/ChangePassword";
import changepassword_icon from '../../../images/changepassword_icon.png';
import delete_icon from '../../../images/delete_icon.png'
import { getUrl } from "../../../common/utils";
import { sendDriverChangePasswordEmail } from "../../../common/api";

const StatusDriver = ({ id, status, email }: any) => {
    const dispatch = useDispatch<Dispatch>();

    const [approveModal, setApproveModal] = useState(false)
    const [pendingModal, setPendingModal] = useState(false)
    const [rejectModal, setRejectModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [changePassword, setChangePassword] = useState(false);

    const [open, setOpen] = useState(false);
    const [listening, setListening] = useState(false);
    const menuRef: any = useRef(null);

    useEffect(ListenForOutsideClicks(listening, setListening, menuRef, setOpen));

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setOpen(!open)
    };

    const handleApprove = (e) => {
        e.preventDefault();
        setApproveModal(true);
    }

    const handlePending = (e) => {
        e.preventDefault();
        setPendingModal(true);
    }

    const handleReject = (e) => {
        e.preventDefault();
        setRejectModal(true);
    }

    const handleEdit = () => {
        dispatch.UI.resetForm('editDriver')
        dispatch.UI.updateFormId({
            target: "editDriver",
            value: id
        })
    }

    const handleChangePassword = async () => {
        const url = await getUrl();
        const body = {
            email,
            url: `${url}changepassword`,
            userId: id,
            userType: 'driver-resetpassword'
        }
        try {
            dispatch.UI.setIsLoading(true);
            const res = await sendDriverChangePasswordEmail(body);
            if (res.isSendEmail) {
                setChangePassword(true);
                dispatch.UI.setIsLoading(false);
            }
        } catch (err: any) {
            const errMsg = err.data.error === undefined ? 'Unable to change password' : err.data.error;
            dispatch.UI.setAlert({ message: errMsg, type: 'Error' });
            dispatch.UI.setIsLoading(false);
        }
    }

    return (
        <>
            <div className='flex items-center justify-center px-4 cursor-pointer relative' onClick={handleClick}>
                <div ref={menuRef}>
                    <CgMoreVerticalO className='text-xl' />
                </div>

                <Zoom in={open} >
                    <Paper elevation={5} className='pop_over_driver z-50'>
                        <Container padding='p-2'>
                            <div className="flex w-full transition-all py-1 hover:scale-105 cursor-pointer" onClick={handleEdit}>
                                <img src={edit_icon} placeholder={edit_icon}
                                    className="status_icon" data-tip data-for='edit'
                                />
                                <span>Edit</span>
                            </div>

                            {status !== 'approved' && (
                                <>
                                    <div className="flex w-full transition-all py-1 hover:scale-105 cursor-pointer" onClick={handleApprove}>
                                        <img src={approved_icon} placeholder={approved_icon}
                                            className="status_icon" data-tip data-for='approved'
                                        />
                                        <span>Approved</span>
                                    </div>

                                    <div className="flex w-full transition-all py-1 hover:scale-105 cursor-pointer" onClick={handlePending}>
                                        <img src={pending_icon} placeholder={pending_icon} data-tip data-for='pending'
                                            className="status_icon"
                                        />
                                        <span>Pending</span>
                                    </div>

                                    <div className="flex w-full transition-all py-1 hover:scale-105 cursor-pointer" onClick={handleReject}>
                                        <img src={reject_icon} placeholder={reject_icon} data-tip data-for='reject'
                                            className="status_icon"
                                        />
                                        <span>Reject</span>
                                    </div>

                                </>)}

                            <div className="flex transition-all hover:scale-105 py-1 cursor-pointer" onClick={handleChangePassword}>
                                <img src={changepassword_icon} placeholder={changepassword_icon}
                                    className="status_icon" data-tip data-for='pw'
                                />
                                <span>Change Password</span>
                            </div>

                            <div className="flex transition-all hover:scale-105 cursor-pointer" onClick={() => setDeleteModal(true)}>
                                <img src={delete_icon} placeholder={delete_icon}
                                    className="status_icon" data-tip data-for='delete'
                                />
                                <span>Delete</span>
                            </div>
                        </Container>
                    </Paper>
                </Zoom>
            </div>

            <ApproveModal setApproveModal={setApproveModal} approvedModal={approveModal} id={id} />
            <PendingModal setPendingModal={setPendingModal} pendingModal={pendingModal} id={id} />
            <RejectModal setRejectModal={setRejectModal} rejectModal={rejectModal} id={id} />
            <DeleteModal setDeleteModal={setDeleteModal} id={id} deleteModal={deleteModal} user='driver' />
            <ChangePasswordModal setChangePassword={setChangePassword} changePassword={changePassword} />
        </>
    )
}

export default StatusDriver

