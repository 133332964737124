import { useEffect, useState, useRef } from 'react';
import { Span } from '../../../components/common/Span'
import ListenForOutsideClicks from '../../../components/ListenForOutsideClicks'

import edit_icon from '../../../images/edit_icon.png';
import delete_icon from '../../../images/delete_icon.png'
import ReactTooltip from 'react-tooltip';

import DeleteModal from '../Modal/DeleteModal'
import { useDispatch } from 'react-redux';

import { Dispatch } from "../../../store";
import { sendAdminChangePasswordEmail } from '../../../common/api';
import { getUrl } from '../../../common/utils';

const WHStatus = ({ id, ...props }: any) => {
    const dispatch = useDispatch<Dispatch>();

    const [user_status, setUser_Status] = useState(false);

    const handleUserStatus = (e) => {
        e.preventDefault();
        setUser_Status(!user_status);
    }

    const [changePassword, setChangePassword] = useState(false);

    const [deleteModal, setDeleteModal] = useState(false)
    const handleDelete = (e) => {
        e.preventDefault();
        setDeleteModal(true);
    }

    const menuRef = useRef(null);
    const [listening, setListening] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    useEffect(ListenForOutsideClicks(listening, setListening, menuRef, setIsOpen));

    return (
        <>
            <div className='flex my-auto'>
                <img src={edit_icon} placeholder={edit_icon}
                    className="status_icon" data-tip data-for='edit'
                    onClick={() => {
                        dispatch.UI.updateFormId({
                            target: 'editWarehouse',
                            value: id,
                        });
                    }}
                />
                <ReactTooltip id='edit' backgroundColor="#8d8d8d">
                    <span>Edit</span>
                </ReactTooltip>

                <img src={delete_icon} placeholder={delete_icon}
                    className="status_icon" data-tip data-for='delete'
                    onClick={handleDelete}
                />
                <ReactTooltip id='delete' backgroundColor="#8d8d8d">
                    <span>Delete</span>
                </ReactTooltip>
                {deleteModal && <DeleteModal setDeleteModal={setDeleteModal} id={id} />}
            </div>
        </>
    )
}

export default WHStatus

