import { Button, Divider, Stack, useTheme } from '@mui/material';
import React, { useState } from 'react'
import { Span } from '../../components/common/Span';
import { BiDetail } from 'react-icons/bi';
import { IoDocumentAttachOutline } from 'react-icons/io5';

type Props = {
    isDetails: boolean;
    setIsDetails: Function;
    isDocuments: boolean;
    setIsDocuments: Function;
}

const Step = ({ isDetails, setIsDetails, isDocuments, setIsDocuments }: Props) => {
    const theme = useTheme();

    return (
        <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
            sx={{ padding: '0 10px', borderBottom: `1px solid ${theme.palette.divider}` }}
        >
            <Button fullWidth size='large' startIcon={<BiDetail style={{ color: isDetails ? '#4CAF50' : '#9E9E9E' }} />}>
                <Span>Personal Details</Span>
            </Button>
            <Button fullWidth size='large' startIcon={<IoDocumentAttachOutline style={{ color: isDocuments ? '#4CAF50' : '#9E9E9E' }} />}>
                <Span>Documents</Span>
            </Button>
        </Stack>
    )
}

export default Step