import { useHistory } from 'react-router-dom';
import { Dispatch } from '../../../store';
import { useDispatch } from 'react-redux';

import { updateExpressStatus, updateInterislandToNextStatus } from '../../../common/api';
import { ModalContainer } from '../../../components/common/Containers'
import { Button } from '../../../components/common/Button'
import { Span } from '../../../components/common/Span'
import questionIcon from '../../../images/Qmark_icon.png'
import { EXPRESS } from '../../../components/constants/status';

type Props = {
    setUpdateModal: Function,
    updateModal: boolean,
    orderId: string,
    nextStatus: string
}

export default ({ updateModal, setUpdateModal, orderId, nextStatus, ...props }: Props) => {
    const dispatch = useDispatch<Dispatch>();

    const handleUpdateStatus = async () => {
        setUpdateModal(false);
        try {
            dispatch.UI.setIsLoading(true);
            const res = await updateInterislandToNextStatus(orderId, nextStatus);
            if (res.isSuccess) {
                dispatch.UI.setIsLoading(false);
                dispatch.UI.setAlert({ message: `Status updated`, type: 'Success' })
            }
        } catch (err: any) {
            dispatch.UI.setIsLoading(false);
            const errMsg = err?.response?.data?.error ?? 'Unable to update booking!';
            dispatch.UI.setAlert({ message: errMsg, type: 'Error' })
        }
    }

    return (
        <>
            {updateModal && (
                <>
                    <ModalContainer>
                        <div className="px-4 py-6 whitespace-normal">
                            <div className='flex'>
                                <img src={questionIcon} alt={questionIcon} className='h-8 mr-2' />
                                <div className='text-left'>
                                    <div className='flex'>
                                        <Span size='lg' weight='bold'>Are you sure you want to change the status&nbsp;</Span>
                                        <Span size='lg' weight='bold' color='brown'>{EXPRESS[nextStatus]}</Span>
                                        <Span weight='bold'> ?</Span>
                                    </div>

                                    <Span color='lightgrey'>
                                        Note: You cannot undo this action
                                    </Span>
                                </div>
                            </div>


                            <div className='flex justify-end my-4 mx-2'>
                                <Button width='none' border='lightgrey' color='darkgrey' padding='px-8' bg='white' margin='mx-2'
                                    onClick={() => setUpdateModal(false)}
                                >
                                    No
                                </Button>

                                <Button width='none' padding='px-8' onClick={handleUpdateStatus}>
                                    Yes
                                </Button>
                            </div>
                        </div>
                    </ModalContainer>

                    <div className="opacity-60 fixed inset-0 z-40 bg-black-1"></div>
                </>
            )}
        </>
    );
}   