import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '../../../../store'

import { Span } from '../../../../components/common/Span'
import { Container } from '../../../../components/common/Containers'

import AddRateSetting from './Add/AddRateSetting';

import Table from '../Table/TableView'
import { COLUMNS } from '../Table/columnExpressRate'
import { Link, useParams } from 'react-router-dom'

import ExpressRateView from './Update/ExpressRate'
import { ImArrowLeft2 } from 'react-icons/im'
import { Button } from '../../../../components/common/Button'
import { BiPlusCircle } from 'react-icons/bi'

export default () => {
    const dispatch = useDispatch<Dispatch>();

    let { rateExpressId }: any = useParams();
    let { id }: any = useParams();

    const express_rate: any | [] = useSelector((state: RootState) => state.Table.tables.express_rate.data);
    const rate = express_rate.filter(d => d.rateExpressId === rateExpressId)[0];
    const data: any | [] = express_rate.filter(d => d.rateExpressId === rateExpressId)[0].settings;

    const [rateSetting, setAddRateSetting] = useState(false);

    const columns = useMemo(() => COLUMNS, []);

    useEffect(() => {
        dispatch.Table.subscribeToFirebase({name: 'rate_express', table: 'express_rate'})
        dispatch.Table.getFirebaseData({ name: 'warehouse', table: 'warehouse' })

        return () => {
            dispatch.Table.UNSUBSCRIBE()
        }
    }, []);


    return (<>
        <Container border='grey' padding='none'>
            <div className='flex justify-between items-center p-4'>
                <div className='flex py-4'>
                    <Link to='/rate/expressRate' className='my-auto text-grey-14'>
                        <ImArrowLeft2 className='my-auto' />
                    </Link>
                    <Span color='brown' weight='bold' size='xl' padding='px-4' margin='my-auto'>
                        {rate.name}
                    </Span>

                    <Span color='brown' bg='brown' border='brown' radius='xl' padding='px-4' margin='my-auto'>
                        {rate.isDefault ? 'Default' : 'Custom'}
                    </Span>
                </div>
                <div className='flex justify-end px-4 my-4'>
                    <Button width='none' onClick={() => { setAddRateSetting(true); }}>
                        <div className='flex pr-2'>
                            <BiPlusCircle className='my-auto mx-2 text-2xl' />
                            Add Rate
                        </div>
                    </Button>
                </div>
            </div>

            <div className='overflow-auto'>
                <Table columns={columns} data={data} rateExpressId={rateExpressId} />
            </div>
        </Container>

        {id && <>
            <ExpressRateView rateExpressId={rateExpressId} />
        </>}

        <AddRateSetting setAddRateSetting={setAddRateSetting} rateSetting={rateSetting} />
    </>
    )
}
