import Header from '../components/Header/Header';
import SidebarPage from '../components/Sidebar/Sidebar';
import { MainContainer } from '../components/common/Containers';

const NotFound = () => {
  return (
    <>
      <SidebarPage />
      <MainContainer>
        <Header />
        <main className="text-center mt-16">
          <h1 className="text-4xl sm:text-6xl">404 PAGE NOT FOUND</h1>
          <p className="text-lg pt-7 sm:text-2xl">
            Page does not exist or you might not have the permission to access
            this page
          </p>
        </main>
      </MainContainer>
    </>
  );
};

export default NotFound;
