import { useState } from "react";   
import '../../CSS/Driver.css'
import { useDispatch } from 'react-redux';
import { Dispatch } from "../../../../store"; 
import delete_icon from '../../../../images/delete_icon.png'
import ReactTooltip from 'react-tooltip'; 
import DeleteModal from '../Modal/DeleteModal';

const StatusDriver = ({id, status}:any) => {  
    const dispatch = useDispatch<Dispatch>(); 
    const [deleteModal, setDeleteModal] = useState(false)

    const handleDelete = () => {
        setDeleteModal(true)
    }

    return (<>   
        <div className='flex my-auto'>     
             
            <img src={delete_icon} placeholder={delete_icon} 
                className="status_icon" data-tip data-for='edit'
                onClick={handleDelete}
            /> 
            <ReactTooltip id='edit' backgroundColor="#8d8d8d">
                <span>Delete</span>
            </ReactTooltip>  

            <DeleteModal setDeleteModal={setDeleteModal} id={id} deleteModal={deleteModal} user='1'/>
        </div>    
    </>)
}

export default StatusDriver

            