import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { RootState, Dispatch } from "../../../store";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import {
  GridContainer,
  SubContainer,
} from "../../../components/common/Containers";
import { Span, SpanContainer } from "../../../components/common/Span";
import { Button } from "../../../components/common/Button";

import { ImArrowLeft2 } from "react-icons/im";
import { Link } from "react-router-dom";

import pickup from "../../../images/pickup_icon.png";
import dropoff from "../../../images/drop-off_icon.png";
import payment from "../../../images/payment_icon.png";
import hubLocation from '../../../images/Hub_icon.png';
import mapIcon from "../../../images/map_icon.png";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

import Pickup from "./Dropdown/Pickup";
import DropOff from "./Dropdown/DropOff";
import Map from "./Dropdown/Map";

import AssignedDriver from "../Modal/AssignedDriver";

import {
  EXPRESS,
  VEHICLE,
  VEHICLETYPE,
} from "../../../components/constants/status";

import StatusExpress from "./Content/StatusInterisland";
import ShareLink from "./Content/ShareLink";
import HistoryStatus from "./HistoryStatus/HistoryStatus";
import PickupHub from "./Dropdown/PickupHub";
import DropOffHub from "./Dropdown/DropOffHub";
import { getCustomerAndWarehouse } from "../../../common/api";

let UNSUBSCRIBE: any;

export default ({ id, ...props }: any) => {
  const { tab } = useParams();

  const [data, setData] = useState<any>();
  const [warehouse, setWarehouse] = useState() as any;
  const [customer, setCustomer] = useState() as any;
  const elem = useRef(null) as any;

  const { totalCharge, orderCode, itemDescription, notes, status } = data !== undefined && data;

  const datePlace = new Date(data?.createdAt?.seconds * 1000);
  const placeScheduled = moment(datePlace).format("MMMM Do YYYY | h:mm:ss a");

  const dateSchedule = new Date(data?.scheduleStartTime);
  const scheduledAt = moment(dateSchedule).format("MMMM Do YYYY | h:mm:ss a");
  const scheduleTime = data?.scheduleStartTime;

  const [pickUp, setPickUp] = useState(true);
  const togglePickUp = (e: any) => {
    e.preventDefault();
    setPickUp(!pickUp);
  };

  const [pickUpHub, setPickUpHub] = useState(true);
  const togglePickUpHub = (e: any) => {
    e.preventDefault();
    setPickUpHub(!pickUpHub);
  };

  const [dropOff, setDropOff] = useState(true);
  const toggleDropOff = (e: any) => {
    e.preventDefault();
    setDropOff(!dropOff);
  };

  const [dropOffHub, setDropOffHub] = useState(true);
  const toggleDropOffHub = (e: any) => {
    e.preventDefault();
    setDropOffHub(!dropOffHub);
  };

  const [map, setMap] = useState(true);
  const toggleMap = (e: any) => {
    e.preventDefault();
    setMap(!map);
  };

  const db = getFirestore();
  const getData = async () => {
    if (UNSUBSCRIBE) UNSUBSCRIBE();

    UNSUBSCRIBE = onSnapshot(doc(db, "interisland", id), (doc) => {
      if (doc.exists()) {
        setData(doc.data());
      } else {
        setData("No such document!");
      }
    });
  };

  const getCusWareData = async () => {
    try {
      const res = await getCustomerAndWarehouse(id);
      if (res) {
        setCustomer(res?.customer)
        setWarehouse({
          warehouseA: res?.warehouseA,
          warehouseB: res?.warehouseB
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getCusWareData()
  }, [data]);

  useEffect(() => {
    elem.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, [status])

  useEffect(() => {
    getData();

    return () => {
      if (UNSUBSCRIBE) UNSUBSCRIBE();
    }
  }, []);

  return (
    <>
      <GridContainer grid="five" padding="none">
        <div>
          <SubContainer border="grey">
            <Link to={`/interisland?tab=${tab}`} className="my-auto text-grey-14">
              <ImArrowLeft2 />
            </Link>
            <div className="bg-brown-1 p-8 text-center rounded-md my-4" ref={elem}>
              <Span weight="semi" size="2xl" color="white">
                ₱
                {parseFloat(totalCharge)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Span>
              <Span color="white">Total Amount</Span>
            </div>
            <SpanContainer weight="semi" padding="pr-2">
              Status:
            </SpanContainer>
            <SpanContainer color="brown">{EXPRESS[status]}</SpanContainer>

            {/* <ShareLink shareAuth={shareAuth} id={id}/>  */}
          </SubContainer>

          <SubContainer border="grey">
            <Span weight="semi" color="brown" transform="uppercase">
              Customer Details
            </Span>
            <Span weight="semi" margin="mt-4" size="xl" transform="capitalize">
              {customer?.firstName}&nbsp;{customer?.lastName}
            </Span>

            <Span margin="mt-4" color="grey">
              Order No:
            </Span>
            <Span>{orderCode}</Span>

            <Span margin="mt-4" color="grey">
              Contact No:
            </Span>
            <Span>{customer?.phoneNumber}</Span>

            <Span margin="mt-4" color="grey">
              Order Placed:
            </Span>
            <Span>{placeScheduled}</Span>

            <Span margin="mt-4" color="grey">
              Item Description:
            </Span>
            <Span>{itemDescription}</Span>

            <Span margin="mt-4" color="grey">
              Notes:
            </Span>
            <Span>{notes}</Span>

            {data?.orderType === "scheduled" ||
              data?.orderType === "full day" ? (
              <>
                <Span margin="mt-4" color="grey">
                  Delivery at:
                </Span>
                <Span>{scheduledAt}</Span>
              </>
            ) : (
              <>
                <Span margin="mt-4" color="grey">
                  Service Type:
                </Span>
                <Span>Deliver Now</Span>
              </>
            )}

            {/* <hr className="my-4" /> */}
          </SubContainer>
        </div>

        <div className="col-span-3">
          <SubContainer border="grey" padding="none">
            <div className="flex justify-between py-2 px-10 border-2 border-grey-1 rounded-md">
              <Span> Vehicle </Span>
              <Span transform="uppercase" weight="semi">
                {VEHICLE[data?.vehicleType]}
              </Span>
              <Span> | </Span>
              <Span> Distance </Span>
              <Span transform="uppercase" weight="semi">
                {" "}
                {data?.distance}Km{" "}
              </Span>
            </div>

            <div
              className="flex justify-between bg-grey-9 p-4 rounded-lg cursor-pointer my-4"
              onClick={togglePickUp}
            >
              <div className="flex my-auto">
                <img src={pickup} alt="" className="mx-4 h-8" />
                <Span margin="my-auto">Pickup Location</Span>
              </div>

              {pickUp ? (
                <IoIosArrowDown className="mx-4 my-auto text-2xl" />
              ) : (
                <IoIosArrowForward className="mx-4 my-auto text-2xl" />
              )}
            </div>

            {pickUp && (
              <Pickup pickUp={data?.pickup} data={data} id={id} />
            )}

            <div
              className="flex justify-between bg-grey-9 p-4 rounded-lg cursor-pointer my-4"
              onClick={togglePickUpHub}
            >
              <div className="flex my-auto">
                <img src={hubLocation} alt="" className="mx-4 h-8" />
                <Span margin="my-auto">Pickup Hub Location</Span>
              </div>
              {pickUpHub ? (
                <IoIosArrowDown className="mx-4 my-auto text-2xl" />
              ) : (
                <IoIosArrowForward className="mx-4 my-auto text-2xl" />
              )}
            </div>

            {pickUpHub && (
              <PickupHub pickUp={warehouse?.warehouseA} status={data?.status} />
            )}

            <div
              className="flex justify-between bg-grey-9 p-4 rounded-lg cursor-pointer my-4"
              onClick={toggleDropOffHub}
            >
              <div className="flex my-auto">
                <img src={hubLocation} alt="" className="mx-4 h-8" />
                <Span margin="my-auto">{`Drop-off Hub Location - ${warehouse?.warehouseB?.name ?? ''}`}</Span>
              </div>
              {dropOffHub ? (
                <IoIosArrowDown className="mx-4 my-auto text-2xl" />
              ) : (
                <IoIosArrowForward className="mx-4 my-auto text-2xl" />
              )}
            </div>

            {dropOffHub && (
              <DropOffHub dropOff={warehouse?.warehouseB} status={data?.status} />
            )}

            <div
              className="flex justify-between bg-grey-9 p-4 rounded-lg cursor-pointer my-4"
              onClick={toggleDropOff}
            >
              <div className="flex my-auto">
                <img src={dropoff} alt="" className="mx-4 h-8" />
                <Span margin="my-auto">Drop-off Location</Span>
              </div>
              {dropOff ? (
                <IoIosArrowDown className="mx-4 my-auto text-2xl" />
              ) : (
                <IoIosArrowForward className="mx-4 my-auto text-2xl" />
              )}
            </div>

            {dropOff ? (
              <DropOff
                stops={data?.stops}
                expressId={id}
                data={data}
              />
            ) : null}

            <div
              className="flex justify-between bg-grey-9 p-4 rounded-lg cursor-pointer my-4"
              onClick={toggleMap}
            >
              <div className="flex my-auto">
                <img src={payment} alt="" className="mx-4 h-8" />
                <Span margin="my-auto">Payment</Span>
              </div>
              {map ? (
                <IoIosArrowDown className="mx-4 my-auto text-2xl" />
              ) : (
                <IoIosArrowForward className="mx-4 my-auto text-2xl" />
              )}
            </div>

            {map ? <Map data={data} /> : null}
          </SubContainer>

          <StatusExpress status={status} orderId={id} />

        </div>

        <div className=" ">
          <SubContainer border='grey'>
            <HistoryStatus express={data} />
          </SubContainer>
        </div>
      </GridContainer>
    </>
  );
};
