import { ColumnFilter } from "../../../../components/Table/ColumnFilter";    
import { VEHICLE } from '../../../../components/constants/status'
import StatusDeleteRate from "./StatusDeleteRate";
import { Span } from "../../../../components/common/Span";
import { store } from "../../../../store";

export const COLUMNS = [    
    {
        Header: 'Warehouse',
        Filter: ColumnFilter,
        accessor: 'warehouse',
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            const warehouse = store.getState().Table.tables.warehouse.data;
            const warehouseName = warehouse?.find(w => w.id === d?.warehouse)?.name;
            return (
                <div className="m-auto">
                    <Span>{warehouseName}</Span>
                </div>
            );
        }
    },
    {   
        Header: 'Vehicle Type',
        Filter: ColumnFilter, 
        accessor: 'vehicleType', 
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return ( 
                <span>{VEHICLE[d.vehicleType]}</span>  
            );
        }
    },
    {   
        Header: 'Base Price ₱',
        Filter: ColumnFilter, 
        accessor: 'basePrice', 
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
                <div className="m-auto"> 
                    <span>{parseFloat(d.baseFare).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </div>
            );
        } 
    }, 
    {   
        Header: 'Price per KM ₱',
        Filter: ColumnFilter, 
        accessor: 'priceperkm', 
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
                <div className="m-auto"> 
                    <span>{parseFloat(d.standardPricePerKM).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </div>
            );
        }
    },
    {   
        Header: 'Additional Stop ₱',
        Filter: ColumnFilter, 
        accessor: 'additionalStop', 
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
                <div className="m-auto"> 
                    <span>{parseFloat(d.additionalStopPrice).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </div>
            );
        }
    }, 
    {   
        Header: 'Commision %',
        Filter: ColumnFilter, 
        accessor: 'commision', 
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
                <div className="m-auto"> 
                    <span>{parseFloat(d.commission).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </div>
            )
        }
    },  
    {   
        Header: 'Driver Share %',
        Filter: ColumnFilter, 
        accessor: 'driverShare', 
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
                <div className="m-auto"> 
                    <span>{parseFloat(d.driverShare).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </div>
            )
        }
    },
    {
        accessor: ' ',
        Header: ' ', 
        Filter: ColumnFilter,
        disableFilters: true, 
        disableSortBy: true,
        isVisible: true, 
        Cell: data => <StatusDeleteRate id={data.row.original.id} /> 
    }
]