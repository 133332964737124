import { ColumnFilter } from "../../../../components/Table/ColumnFilter";
import { VEHICLE } from '../../../../components/constants/status'
import StatusDeleteRate from "./StatusDeleteRate";
import { Span } from "../../../../components/common/Span";
import { store } from "../../../../store";

export const COLUMNS = [
    {
        Header: 'Base Fare ₱',
        Filter: ColumnFilter,
        accessor: 'basePrice',
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
                <div className="m-auto">
                    <span>{parseFloat(d.baseFare).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </div>
            );
        }
    },
    {
        Header: 'Min Weight Kg',
        Filter: ColumnFilter,
        accessor: 'minWeight',
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
                <span>{d?.minWeight >= 0 ? `${d?.minWeight}Kg` : ''}</span>
            );
        }
    },
    {
        Header: 'Max Weight Kg',
        Filter: ColumnFilter,
        accessor: 'maxWeight',
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
                <span>{d?.maxWeight ? `${d?.maxWeight}Kg` : ''}</span>
            );
        }
    },
    {
        Header: 'Pick Up Warehouse',
        Filter: ColumnFilter,
        accessor: 'pickUpWarehouse',
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            const warehouse = store.getState().Table.tables.warehouse.data;
            const warehouseName = warehouse?.find(w => w.id === d?.pickUpWarehouse)?.name;
            return (
                <div className="m-auto">
                    <Span>{warehouseName}</Span>
                </div>
            );
        }
    },
    {
        Header: 'Drop Off Warehouse',
        Filter: ColumnFilter,
        accessor: 'dropOffWarehouse',
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            const warehouse = store.getState().Table.tables.warehouse.data;
            const warehouseName = warehouse?.find(w => w.id === d?.dropOffWarehouse)?.name;
            return (
                <div className="m-auto">
                    <Span>{warehouseName}</Span>
                </div>
            );
        }
    },
    {
        Header: 'Commision %',
        Filter: ColumnFilter,
        accessor: 'commision',
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
                <div className="m-auto">
                    <span>{parseFloat(d.commission).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </div>
            )
        }
    },
    {
        Header: 'Driver Share %',
        Filter: ColumnFilter,
        accessor: 'driverShare',
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
                <div className="m-auto">
                    <span>{parseFloat(d.driverShare).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </div>
            )
        }
    },
    {
        accessor: ' ',
        Header: ' ',
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
        isVisible: true,
        Cell: data => <StatusDeleteRate id={data.row.original.id} />
    }
]