import AlertSnackBar from '../AlertSnackBar';
import React, { useEffect, useState, useRef } from "react";
import ListenForOutsideClicks from '../ListenForOutsideClicks'
import BackDrop from '../BackDrop';
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../store";
import { FaBars } from 'react-icons/fa';
import face from '../../assets/images/face_icon.png'; 
import { Button } from '../common/Button'
import { AiOutlineKey, AiOutlineLogout } from 'react-icons/ai'
import ChangePasswordModal from '../../containers/ChangePassword/Modal/ChangePasswordModal'
import { sendAdminChangePasswordEmail } from '../../common/api';
import { getUrl } from '../../common/utils';

// import { InfoBox } from '../InfoBox';

export default function Header() {

    const [open, setOpen] = useState(false);
    const dispatch = useDispatch<Dispatch>();
    const [header, setHeader] = useState(false);
    const toggleHeader = (e: any) => {
        e.preventDefault();
        setHeader(!header);
    };

    const user = useSelector((state: RootState) => state.User?.userInfo) as any;

    const [changePassword, setChangePassword] = useState(false)

    const menuRef = useRef(null);
    const [listening, setListening] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    useEffect(ListenForOutsideClicks(listening, setListening, menuRef, setIsOpen));

    const handleChangePassword = async () => {
        const url = await getUrl();
        const body = {
            email: user.email,
            url: `${url}changepassword`,
            userId: user.userId,
            userType: 'useradmin-resetpassword'
        }
        try {
            dispatch.UI.setIsLoading(true);
            const res = await sendAdminChangePasswordEmail(body)
            if (res.isSendEmail) {
                setChangePassword(true);
                dispatch.UI.setIsLoading(false);
            }
        } catch (err: any) {
            const errMsg = err.data.error === undefined ? 'Unable to change password' : err.data.error;
            dispatch.UI.setAlert({ message: errMsg, type: 'Error' });
            dispatch.UI.setIsLoading(false);
        }
    }

    return (
        <header>
            <BackDrop />
            <AlertSnackBar open={open} setOpen={setOpen} />
            <label htmlFor="nav-toggle">
                <FaBars className='cursor-pointer' />
            </label>

            <div className='flex' >
                <span className='mx-1'>{user.email}</span>

                <div ref={menuRef}>
                    <img src={face} className='h-6 mx- cursor-pointer' onClick={toggle} />
                </div>
            </div>

            {changePassword &&
                <ChangePasswordModal setChangePassword={setChangePassword} />
            }

            {isOpen &&
                <>
                    <div className='header_popup'>
                        <div className='flex text-left'>
                            <AiOutlineKey className='my-auto mx-2 text-xl' />
                            <Button
                                bg='white' color='black' padding='py-1' weight='semi'
                                onClick={handleChangePassword}
                            >
                                Change Password
                            </Button>
                        </div>

                        <div className='flex text-left'>
                            <AiOutlineLogout className='my-auto mx-2 text-xl' />
                            <Button
                                onClick={() => {
                                    dispatch.User.logout(true);
                                }}
                                bg='white' color='black' padding='py-1' weight='semi'
                                align='left'>
                                Logout
                            </Button>
                        </div>
                    </div>
                </>}
        </header>
    )
}

