import { createModel } from "@rematch/core";
import { collection, getDocs, getFirestore, onSnapshot, orderBy, query, where } from "firebase/firestore";
import moment from "moment";
import { AlertType } from "react-alert";
import { RootModel } from ".";
import { INCLUDEID } from "../components/constants/status";

var TABLE_UNSUBSCRIBE: any;

interface ICustomerPayload {
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string
}

interface IErrorMessagePayload {
  table: string;
  value: string;
}

interface IAlertPayload {
  message: string;
  type: AlertType;
}

interface IModalIsRequestingPayload {
  table: string;
  value: boolean;
}


interface IFirebaseTablePayload {
  table: string;
  name: string;
}

interface ITableUnsubscribePayload {
  table: string;
  data: any;
}


interface ITableDataPayload {
  table: string;
  data: any;
}


export interface IFilterValue {
  field: string;
  operation: string;
  value: any;
}



interface IFilterPayload {
  table: string;
  key: string;
  filter: IFilterValue;
}

interface IData {
  table: string;
  id: string;
}

let defaultTableValue = {
  filters: {},
  limit: 10,
  page: 1,
  sort: '',
  unsubscribe: null,
  data: []
}


const initialState = {
  tables: {
    rate_interisland: defaultTableValue,
    orders: defaultTableValue,
    drivers: defaultTableValue,
    express: defaultTableValue,
    parcels: defaultTableValue,
    customers: defaultTableValue,
    users: defaultTableValue,
    rates: defaultTableValue,
    express_rate: defaultTableValue,
    parcel_rate: defaultTableValue,
    partial_rate: defaultTableValue,
    transaction_log: defaultTableValue,
    withdrawal_requests: defaultTableValue,
    wallet: defaultTableValue,
    billings: defaultTableValue,
    roles: defaultTableValue,
    warehouse: defaultTableValue,
    interisland: defaultTableValue
  },
  isLoading: false,
  currentRoute: null,
} as any;

export const Table = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState }
    },
    /**
     * @name resetForm
     * @description resets from by passing table property underform
     * @param table
     */
    resetTable(state, table: string) {
      return {
        ...state,
        tables: { ...state.tables, [table]: { ...initialState.tables[table] } },
      };
    },
    setFilterValue(state, payload: IFilterPayload) {
      const { table, key, filter } = payload;

      return {
        ...state,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            filters: { ...state.tables[table].filters, [key]: filter },
          },
        },
      };
    },
    setTableData(state, payload: ITableDataPayload) {
      const { table, data } = payload;

      return {
        ...state,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            data,
          },
        },
      };
    },
    setTableUnsubscribe(state, payload: ITableUnsubscribePayload) {
      const { table, data } = payload;
      console.log("here", data)

      return {
        ...state,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            unsubscribe: data
          },
        },
      };
    },
    deleteData(state, payload: IData){
      const { table, id } = payload;

      return {
        ...state,
        tables: {
          ...state.tables,
          [table]: {
            ...state.tables[table],
            data: [...state.tables[table].data.filter((d) => d.id !== id)]
          }
        }
      }
    }
  },
  effects: (dispatch) => ({
    async getFirebaseData(payload: IFirebaseTablePayload, rootState) {
      const db = getFirestore();

      let { name, table } = payload

      let { filters } = rootState.Table.tables[table]


      let q = query(collection(db, name));

      if (filters) {
        for (const key in filters) {
          let { field, operation, value } = filters[key]

          if (value) {
            q = query(q, where(field, operation, value))
          }
        }
      }


      let querySnapshot = await getDocs(q);


      const listData = (querySnapshot?.docs || []).map((ref) => {
        const data = ref.data() as any;
        return data
      }) as any[];
      
      dispatch.Table.setTableData({
        table: table,
        data: listData
      })

    },
    
    async subscribeToFirebase(payload: IFirebaseTablePayload, rootState) {
      const db = getFirestore();

      let { name, table } = payload

      let { filters } = rootState.Table.tables[table]

      if (TABLE_UNSUBSCRIBE) TABLE_UNSUBSCRIBE();

      let q = query(collection(db, name));

      if (filters) {
        for (const key in filters) {
          let { field, operation, value } = filters[key]

          if (value) {
            q = query(q, where(field, operation, value))
          }
        }
      }

      TABLE_UNSUBSCRIBE = onSnapshot(q, (doc) => {
        const listData = (doc?.docs || []).map((ref) => {
          const data = INCLUDEID.includes(name) ? { ...ref.data(), id: ref.id } : ref.data() as any;
          return data
        }) as any[];

        dispatch.Table.setTableData({
          table: table,
          data: listData
        })

      })

    },

    async UNSUBSCRIBE(){
      if (TABLE_UNSUBSCRIBE) TABLE_UNSUBSCRIBE();
    }
  }),
});
