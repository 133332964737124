import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Dispatch } from '../../../store';
import { useDispatch } from 'react-redux';

import { cancelBookingByOrderId, cancelInterislandBookingByOrderId } from '../../../common/api';
import { ModalContainer } from '../../../components/common/Containers'
import { Button } from '../../../components/common/Button'
import { Span } from '../../../components/common/Span'
import questionIcon from '../../../images/Qmark_icon.png'

type Props = {
    setCancelModal: Function,
    cancelModal: boolean,
    orderId: string
}

export default ({ setCancelModal, cancelModal, orderId, ...props }: Props) => {

    const history = useHistory();
    const dispatch = useDispatch<Dispatch>();

    const handleCancel = async () => {
        setCancelModal(false);
        try {
            dispatch.UI.setIsLoading(true);
            const res = await cancelInterislandBookingByOrderId(orderId);
            if (res.isSuccess) {
                dispatch.UI.setIsLoading(false);
                dispatch.UI.setAlert({ message: `Order Number Cancelled`, type: 'Success' })
            }
        } catch (err: any) {
            dispatch.UI.setIsLoading(false);
            const errMsg = err?.response?.data?.error ?? 'Unable to cancel booking!';
            dispatch.UI.setAlert({ message: errMsg, type: 'Error' })
        }
    }

    return (<>
        {cancelModal && (<>
            <ModalContainer>
                <div className="px-4 py-6 whitespace-normal">
                    <div className='flex'>
                        <img src={questionIcon} alt={questionIcon} className='h-8 mr-2' />
                        <div className='text-left'>
                            <Span size='lg' weight='bold'>Are you sure you want to cancel this booking?</Span>

                            <Span color='lightgrey'>
                                Note: You cannot undo this action
                            </Span>
                        </div>
                    </div>


                    <div className='flex justify-end my-4 mx-2'>
                        <Button width='none' border='lightgrey' color='darkgrey' padding='px-8' bg='white' margin='mx-2'
                            onClick={() => setCancelModal(false)}
                        >
                            No
                        </Button>

                        <Button width='none' padding='px-8' onClick={handleCancel}>
                            Yes
                        </Button>
                    </div>
                </div>
            </ModalContainer>

            <div className="opacity-60 fixed inset-0 z-40 bg-black-1"></div>
        </>)}
    </>);
}   