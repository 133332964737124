import { useEffect, useMemo, useState} from 'react' 
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../../store";
import { useHistory, Link } from 'react-router-dom' 

import { Span } from '../../components/common/Span' 
import { Button } from '../../components/common/Button' 
import { Container } from '../../components/common/Containers';

import { BiPlusCircle } from 'react-icons/bi'

import Table from './Table/Table' 
import { COLUMNS } from './Table/column'  
import NoData from '../../components/common/NoData/NoData';

import Loader from "../../components/Loader"; 
import EditBilling from './Update/EditBilling'
import AddBilling from './Add/AddBilling' 
import data from './Table/data.json'

export default () => {   
    
    const dispatch = useDispatch<Dispatch>();
    const [isLoading, setIsLoading] = useState(true);
    const editBillingId: any = useSelector((state: RootState) => state.UI.forms.editBilling?.id);   
    const data: any[] = useSelector((state: RootState) => state.Table.tables?.billings?.data)
        .sort((a, b) => {return a.createdAt.seconds - b.createdAt.seconds});
        
    const [addBilling, setAddBilling] = useState(false);     
    
    const columns = useMemo(() => COLUMNS, []);

    useEffect(() => {
        dispatch.Table.getFirebaseData({
            name: 'billing',   
            table: 'billings'
        })
        setIsLoading(false);
    },[]);
    return (<>
        {isLoading ? (
            <Loader />
        ):(
            <Container border='grey' padding='none'> 
                <div className='p-4'>
                    <div className='flex justify-between'>
                        <Span margin='mt-4' weight='bold' size='2xl'>Billing List</Span>  
                        <div className='flex justify-end px-4 my-4'>
                            <Button 
                                width='none'  
                                onClick={() => {setAddBilling(true);}}
                            >
                                <div className='flex pr-2'>
                                    <BiPlusCircle className='my-auto mx-2 text-2xl'/> 
                                    Add Billing
                                </div>
                            </Button>
                        </div>  
                    </div>  
                </div>   
                <div className='overflow-auto'>
                    {data?.length < 0 
                        ? <NoData />
                        : <Table columns={columns} data={data}/>
                    } 
                </div>
            </Container> 
        )}

        {editBillingId && <>
            <EditBilling setEditBilling={editBillingId}/>
        </>}  

        {addBilling && <>
            <AddBilling setAddBilling={setAddBilling}/>
        </>}  

    </>)
}