import { get, put } from "../core";

export const getCustomerAndWarehouse = async (deliveryId) => {
    try {
        const res = await get(`/interisland/customer-and-warehouse/${deliveryId}`);
        return res.data;
    } catch (err) {
        return Promise.reject(err);
    }
}

export const assignDriver = async ({ orderId, driver }: any) => {
    try {
        const res = await put(`/assign/interisland-delivery/${orderId}`, driver);
        return res.data;
    } catch (err) {
        return Promise.reject(err);
    }
}

export const reAssignDriver = async ({ orderId, driver }: any) => {
    try {
        const res = await put(`/re-assign/interisland-delivery/${orderId}`, driver)
        return res.data;
    } catch (err) {
        return Promise.reject(err);
    }
}

export const updateInterislandToNextStatus = async (orderId: string, nextStatus: string) => {
    try {
        const res = await put(`/admin/interisland-orders/update-status/${orderId}`, { status: nextStatus });
        return res.data;
    } catch (err) {
        return Promise.reject(err);
    }
}

export const cancelInterislandBookingByOrderId = async (orderId: string) => {
    try {
        const res = await put(`/admin/interisland-orders/${orderId}/cancel`);
        return res.data;
    } catch (err) {
        return Promise.reject(err)
    }
}