import { useEffect, useMemo, useState} from 'react' 
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '../../../../store'

import { Span } from '../../../../components/common/Span' 
import { Button } from '../../../../components/common/Button'
import { Container } from '../../../../components/common/Containers'

import { BiPlusCircle } from 'react-icons/bi' 

import Table from '../Table/TableView' 
import { COLUMNS } from '../Table/columnParcel'   
import { Link, useParams } from 'react-router-dom' 
import { ImArrowLeft2 } from 'react-icons/im'
import AddRateSetting from './Add/ParcelRate';
import ParcelRate from './Update/ParcelRate'

export default () => {    

    const dispatch = useDispatch<Dispatch>();

    let {rateParcelId}:any = useParams();
    let {id}:any = useParams();

    const express_rate : any | [] = useSelector((state: RootState) => state.Table.tables.parcel_rate.data);
    const rate = express_rate.filter(d => d.rateParcelId === rateParcelId)[0]; 
    const data: any | [] = express_rate.filter(d => d.rateParcelId === rateParcelId)[0].settings;

    const [rateSetting, setAddRateSetting] = useState(false);
    
    const columns = useMemo(() => COLUMNS, []);

    useEffect(() => {
        dispatch.Table.subscribeToFirebase({name: 'rate_parcel', table: 'parcel_rate'})

        return () => {
            dispatch.Table.UNSUBSCRIBE()
        }
    },[]);
    
    return (<> 
        <Container border='grey' padding='none'> 
            <div className='flex p-4 justify-between items-center'> 
                <div className='flex py-4'>
                    <Link to='/rate/parcelRate' className='my-auto text-grey-14'>
                        <ImArrowLeft2 className='my-auto'/>
                    </Link>
                    <Span color='brown'  weight='bold' size='xl' padding='px-4' margin='my-auto'>
                        {rate.name}
                    </Span> 

                    <Span color='brown' bg='brown' border='brown' radius='xl'  padding='px-4' margin='my-auto'>
                        {rate.isDefault ? 'Default' : 'Custom'} 
                    </Span> 
                </div>   
                <div className='flex justify-end px-4 my-4'>
                    <Button width='none' onClick={() => { setAddRateSetting(true); }}>
                        <div className='flex pr-2'>
                            <BiPlusCircle className='my-auto mx-2 text-2xl' />
                            Add Rate
                        </div>
                    </Button>
                </div>
            </div> 
            
            <div className='overflow-auto'>
                <Table columns={columns} data={data} rateParcelId={rateParcelId}/> 
            </div>
        </Container>     

        {id && <ParcelRate rateParcelId={rateParcelId}/>}   
        {rateSetting && <AddRateSetting setAddRateSetting={setAddRateSetting} />}
        
    </>
    )
}
