import { useDispatch } from 'react-redux';
import { Dispatch } from "../../../store";
import StatusComponent from "../../../components/Status/StatusComponent";

const UserStatus = ({ id, email, ...props }: any) => {

    const dispatch = useDispatch<Dispatch>();

    const handleEdit = () => {
        dispatch.UI.updateFormId({
            target: "editUser",
            value: id
        })
    }

    return (
        <>
            <StatusComponent id={id} email={email} handleEdit={handleEdit} user='userAdmin' />
        </>
    )
}

export default UserStatus

