import axios from "axios";
import { getUrl } from "../../utils";
import { post, get, put, remove } from "../core";

export const requestOTP = async (mobile: string) => {
  try {
    let res = await post(`/customers/web/alleasy/register-request-otp`, {
      mobile,
    });
    return res.data;
  } catch (err) {
    return false;
  }
};

export const validateOTP = async (mobile: string, otp: string) => {
  try {
    let res = await post(`/customers/web/alleasy/register-validate-otp`, {
      mobile,
      otp,
    });
    return res.data;
  } catch (err: any) {
    return err;
  }
};

export const createAdminUser = async (body: any) => {
  try {
    body = {
      ...body,
      phoneNumber: `+63${body.phoneNumber}`,
      url: `${getUrl()}verifyemailandresetpassword`
    }
    let res = await post(`/admin/create/user-account`, body);
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateAdminUser = async ({ id, payload }: any) => {
  try {
    payload.phoneNumber = `+63${payload.phoneNumber}`
    const res = await put(`/admin/update/user-account/${id}`, payload)
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteAdminUser = async (id: string) => {
  try {
    let res = await remove(`/admin/delete/user-account/${id}`);

    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const registerUser = async (body: any) => {
  try {
    let res = await post(`/customers/web/alleasy/register`, body);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const authenticateViaPassword = async ({ email, password }: any) => {
  try {
    let res = await post(`/admin/web/login`, {
      email,
      password,
    });
    return res.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};

export const getUserCredentials = async () => {
  try {
    let res = await get(`/admin/me`);

    return res.data;
  } catch (err) {
    throw err;
  }
};

export const updateUserProfile = async (body: any) => {
  try {
    const { data } = await put(`/profiles`, body);
    return {
      isSuccess: true,
    };
  } catch (err) {
    throw err;
  }
};


export const setAdminUserPassword = async (userId: any, password: any) => {
  try {
    const res = await put(`/admin/set-user-password/${userId}`, password);
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
}

export const verifyAdminUserSetPassword = async (userId: any) => {
  try {
    const res = await get(`/admin/check-user-password/${userId}`)
    return res.data;
  } catch (err) {
    return Promise.reject(err)
  }
}

export const sendAdminChangePasswordEmail = async (body: any) => {
  try {
    const res = await post('/admin/user/reset-password', body)
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
}