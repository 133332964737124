import moment, { unix } from 'moment';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore'
import { useState } from 'react'
import { RcFile } from 'antd/lib/upload';
import { message } from 'antd';

export const formatDateV2 = (date) => {
	const d = !date?.seconds ? new Date(date) : new Date(date?.seconds * 1000);
	const formattedDate = moment(d).format('MMMM Do YYYY | h:mm:ss a')
	return formattedDate;
}

export const checkValidFile = (file: RcFile, limit?: number | boolean) => {
	if (limit === false) return true;
	const _limit = limit || 2;
	const isLt = file.size / 1024 / 1024 < _limit;
	if (!isLt) {
		message.error(`Image must smaller than ${_limit}MB!`);
		return false;
	}
	return true;
};

export const withdrawalRequestDataToDownload = (data) => {
	const arr = data?.map(item => {
		const d = {
			...item,
			updatedAt: moment(new Date(item?.updatedAt?.seconds * 1000)).format('MMMM Do YYYY, h:mm:ss a'),
			createdAt: moment(new Date(item?.createdAt?.seconds * 1000)).format('MMMM Do YYYY, h:mm:ss a'),
			wallet_id: item?.wallet?.id,
			wallet_ballance: item?.wallet?.balance,
			wallet_email: item?.wallet?.email,
			wallet_phoneNumber: `"${item?.wallet?.phoneNumber}`,
			wallet_type: item?.wallet?.type,
			wallet_updatedAt: moment(new Date(item?.wallet?.updatedAt?.seconds * 1000)).format('MMMM Do YYYY, h:mm:ss a'),
			wallet_createdAt: moment(new Date(item?.wallet?.createdAt?.seconds * 1000)).format('MMMM Do YYYY, h:mm:ss a'),
			bank_accountName: item?.bank?.accountName,
			bank_bankName: item?.bank?.bankName,
			bank_accountNumber: item?.bank?.accountNumber,
			bank_accountType: item?.bank?.accountType,
			bank: '',
			wallet: ''
		}
		return d;
	})
	return arr;
}

export const toObject1 = (arr) => {
	let action = {}
	arr?.forEach(a => {
		action[a.action] = true;
	})
	return action;
}

export const toObject = (permissions) => {
	let object = {};
	permissions?.forEach((r: any) => {
		object[r.role] = toObject1(r?.action);
	})
	return object;
}

export const isNumber = (value: any) => {
	return !isNaN(Number(value));
}

export const currencyCommaFormat = (value: any, decimal = 0, hasPesoSign: boolean = false) => {
	if (isNumber(value)) {
		return Number(value).toFixed(decimal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	} else {
		return `${hasPesoSign ? '₱ ' : ''}${String(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
	}
};

interface DateTimeProps {
	date: string;
	time: string;
}

const dateFormatToday = 'MMM DD [(Today)]', dateFormat = 'MMM DD (ddd)', timeDefaultFormat = 'h:mm A';

export const formatDeliveryDate = (date: any, dateFromat?: string, timeFormat?: string) => {
	let defaultFormat = dateFromat ? dateFromat : dateFormat;
	let defaultTodayFormat = dateFromat ? dateFromat : dateFormatToday;
	timeFormat = timeFormat ? timeFormat : timeDefaultFormat;

	if (!date && date === '') return '';

	const value = {
		date: (moment(date).isSame(moment(), 'date')) ? moment(date).format(defaultTodayFormat) : moment(date).format(defaultFormat),
		time: moment(date).format(timeFormat)
	} as DateTimeProps;

	return value;
}


export const onlyNumberKey = (evt: any) => {
	const ASCIICode = (evt.which) ? evt.which : evt.keyCode
	if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
		return evt.preventDefault();
	}
	return true;
}

export const onlyNumberKeyAndDot = (evt: any) => {
	const ASCIICode = (evt.which) ? evt.which : evt.keyCode
	if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
		if (ASCIICode === 46) {
			return true
		}
		return evt.preventDefault();
	}
	return true;
}

export const getUrl = () => {
	if (process.env.NODE_ENV === 'development') {
		const link = `http://localhost:3000/`
		return link;
	} else {
		const link = `${process.env.REACT_APP_BASE_URL}/`
		return link;
	}
}

export const onlyNumberKeyDotAndNegative = (evt: any) => {
	const ASCIICode = (evt.which) ? evt.which : evt.keyCode
	if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
		if (ASCIICode === 46 || ASCIICode === 45) {
			return true
		}
		return evt.preventDefault();
	}
	return true;
}

export const validateFMLM = (data, rateSettings) => {
	const existingFMLM = rateSettings?.map(rate => { return { firstMile: rate?.firstMile, lastMile: rate?.lastMile } })
	const isSelectedFMLMExistResArray = existingFMLM?.map(({ firstMile, lastMile }) => {
		if (data?.firstMile === firstMile && data?.lastMile === lastMile) {
			return true
		}
		return false
	})

	return isSelectedFMLMExistResArray.includes(true) ? 'invalid' : 'valid';
}

export const isChangeInDriverData = (CDD, driverDetails, active) => {
	if (CDD) {
		const oodriverDetails = Object.entries(CDD)
			.filter((entry) => {
				if (['deliveryArea', 'driverVehicle'].includes(entry[0])) {
					return null;
				}
				return entry;
			})
			.map((entry) => `${entry[0]} ${entry[1]}`);
		const originalDeliveryArea = Object.entries(CDD.deliveryArea).map((entry) => `${entry[0]} ${entry[1]}`);
		const irginalDriverVehicle = CDD?.driverVehicle?.map((v) => v);

		const originalDetails = originalDeliveryArea.concat(irginalDriverVehicle, oodriverDetails);

		const uudriverDetails = Object.entries({
			...driverDetails,
			systemStatus: active ? 'active' : 'inactive',
			phoneNumber: `+63${driverDetails.phoneNumber}`,
		})
			.filter((entry) => {
				if (['deliveryArea', 'driverVehicle'].includes(entry[0])) {
					return null;
				}
				return entry;
			})
			.map((entry) => `${entry[0]} ${entry[1]}`);

		const updatedDeliveryArea = Object.entries(driverDetails.deliveryArea).map((entry) => `${entry[0]} ${entry[1]}`);
		const updatedDriverVehicle = driverDetails?.driverVehicle?.map((v) => v);
		const updatedDetails = updatedDeliveryArea.concat(updatedDriverVehicle,uudriverDetails);

		const result = updatedDetails
			.map((item) => originalDetails.includes(item))
			.some((res) => res === false);
		return result;
	}
};