/**
 * @name validateEmail
 * @description check if email is valid
 * @param {string} param
 * @return boolean
 */

export const validatePhone = (contactPhone: string) => {
  const re = /9[0-9]{9}/;
  if (re.test(contactPhone)) {
    if (contactPhone.length <= 10) {
      return true
    }
  }
  return false
};

export const validateEmail = (input: string) => {
  const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (re.test(input)) {
    return true;
  }
  return false;
};

export const validatePin = (pin: string) => {
  return (pin ?? "").length === 6;
};