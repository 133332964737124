import moment from 'moment';
import { Span } from '../../../components/common/Span';
import { ROLE_STATUS } from '../../../components/constants/status';

import { ColumnFilter } from "../../../components/Table/ColumnFilter";
import WHStatus from './WHStatus'

export const COLUMNS = [
    {
        accessor: data => `${data.firstName} ${data.lastName}`,
        Header: 'Name',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
                <span className='capitalize'>
                    {`${d?.name ?? ''}`}
                </span>
            )
        }
    },
    {
        accessor: data => data?.location?.address,
        Header: 'Address',
        Filter: ColumnFilter,
        isVisible: true,
    },
    {
        accessor: data => JSON.stringify(data?.serviceArea),
        Header: 'Service Area',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
                <>
                    {(d?.serviceArea || []).map((area, index) => (
                        <span key={index} className='capitalize'>
                           {`${area}, `}
                        </span>
                    ))}
                </>
            )
        }
    },
    {
        accessor: 'island',
        Header: 'Island',
        Filter: ColumnFilter,
        isVisible: true,
    },
    {
        accessor: data => {
            const date = new Date(data?.createdAt?.seconds * 1000);
            const formatedDate = moment(date).format('MMMM Do YYYY, h:mm:ss a')
            return formatedDate;
        },
        Header: 'Date Created',
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            let date = new Date(1000);

            if (d?.createdAt?.seconds) {
                date = new Date(d?.createdAt?.seconds * 1000);
            }

            return <>{moment(date).format('MMMM Do YYYY, h:mm:ss a')}</>
        }
    },
    {
        accessor: ' ',
        Header: ' ',
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
        isVisible: true,
        Cell: data => <WHStatus id={data.row.original.id} />
    }
]