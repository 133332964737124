import { useState, useEffect } from 'react';
import { ModalContainer } from '../../../components/common/Containers';
import { Button } from '../../../components/common/Button';
import { Span } from '../../../components/common/Span';
import warningIcon from '../../../images/Warning_icon.png';
import { Input } from '../../../components/common/Input';

import { Dispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import { updateDriverStatus } from '../../../common/api';

type Props = {
  setPendingModal: Function;
  pendingModal: boolean;
  id: string;
};

export default ({ setPendingModal, pendingModal, id, ...props }: Props) => {
  const dispatch = useDispatch<Dispatch>();

  const body = {
    driverApprovalStatus: 'pending',
  };

  const handlePending = async (e) => {
    e.preventDefault();
    setPendingModal(false);
    try {
      dispatch.UI.setIsLoading(true);
      const res = await updateDriverStatus({ body, id });
      if (res?.isSuccess) {
        dispatch.UI.setAlert({ message: 'Status updated', type: 'Success' });
        dispatch.UI.setIsLoading(false);
        dispatch.Table.getFirebaseData({ name: 'driver_profile', table: 'drivers' })
      }
    } catch (err: any) {
      const errMsg = err?.data?.error ?? 'Unable to update status';
      dispatch.UI.setAlert({ message: errMsg, type: 'Error' });
      dispatch.UI.setIsLoading(false);
    }
  };

  return (
    <>
      {pendingModal && (
        <>
          <ModalContainer>
            <div className="px-4 py-6 whitespace-normal">
              <div className="flex">
                <img src={warningIcon} alt={warningIcon} className="h-8 mr-2" />
                <div className="text-left">
                  <Span size="lg" weight="bold">
                    Approval Status
                  </Span>
                </div>
              </div>

              <div className="text-left">
                <Span margin="mt-4">Pending Note</Span>
                <Input type="text" />
              </div>

              <div className="flex justify-end my-4 mx-2">
                <Button
                  width="none"
                  border="lightgrey"
                  color="grey"
                  padding="px-8"
                  bg="white"
                  margin="mx-2"
                  onClick={() => setPendingModal(false)}
                >
                  Close
                </Button>

                <Button
                  width="none"
                  padding="px-8"
                  bg="red"
                  onClick={handlePending}
                >
                  Yes
                </Button>
              </div>
            </div>
          </ModalContainer>

          <div className="opacity-60 fixed inset-0 z-40 bg-black-1"></div>
        </>
      )}
    </>
  );
};
