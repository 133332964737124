import TransactionLogPage from '../containers/Wallet/TransactionLog/TransactionLog'  
import Header from '../components/Header/Header' 
import SidebarPage from '../components/Sidebar/Sidebar'
import { MainContainer } from '../components/common/Containers' 
 
const TransactionLog = () => {
    return ( 
        <>  
            <SidebarPage />
            <MainContainer>    
                <Header /> 
                <main> 
                    <TransactionLogPage/>  
                </main> 
            </MainContainer>
        </>
    )
}

export default TransactionLog
