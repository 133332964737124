import { useHistory } from 'react-router-dom';
import { Dispatch, RootState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';

import { updateRequestWalletStatus } from '../../../../common/api';
import { ModalContainer } from '../../../../components/common/Containers'
import { Button } from '../../../../components/common/Button'
import { Span } from '../../../../components/common/Span'
import questionIcon from '../../../../images/Qmark_icon.png'
import { useState } from 'react';
import { DECLINED_REASON } from '../../../../components/constants/status';
import { Select } from '../../../../components/common/Select';

type Props = {
    setUpdateModal: Function,
    updateModal: boolean,
    btnType: string,
}

export default ({ setUpdateModal, updateModal, btnType, ...props }: Props) => {

    const history = useHistory();
    const dispatch = useDispatch<Dispatch>();

    const selectedRows: any[] = useSelector((state: RootState) => state.UI.forms.editRequestWallet?.id)
    const [reason, setReason] = useState('');

    const handleUpdate = async () => {
        console.log(reason);
        setUpdateModal(false);
        if (selectedRows?.length < 1) {
            return dispatch.UI.setAlert({ message: 'Please select atleast 1 item', type: 'Error' })
        }
        try {
            dispatch.UI.setIsLoading(true)
            const res = await updateRequestWalletStatus(btnType, { id: selectedRows, reason })
            if (res.isSuccess) {
                dispatch.UI.setAlert({ message: 'Update wallet status', type: 'Success' })
                dispatch.UI.setIsLoading(false)
            }
        } catch (err: any) {
            const errMsg = err?.data?.error === undefined ? 'Unable to update status' : err.data.error;
            dispatch.UI.setAlert({ message: errMsg, type: 'Error' })
            dispatch.UI.setIsLoading(false)
        }
    }

    return (<>
        {updateModal && (
            <>
                <ModalContainer>
                    <div className="px-8 py-6 whitespace-normal">
                        <form onSubmit={handleUpdate}>
                            <div className='flex'>
                                <img src={questionIcon} alt={questionIcon} className='h-8 mr-2' />
                                <div className='text-left'>
                                    <div className='flex'>
                                        <Span size='lg' weight='bold'>Are you sure you want to update status into&nbsp;</Span>
                                        <Span size='lg' weight='bold' color='brown'>{btnType}</Span>
                                        <Span weight='bold'> ?</Span>
                                    </div>

                                    <Span color='lightgrey'>
                                        Note: You cannot undo this action
                                    </Span>

                                </div>
                            </div>

                            {btnType === 'declined' && (
                                <div className='pl-10 pr-2 mt-2'>
                                    <Select onChange={(e: any) => setReason(e.target.value)} required >
                                        <option value="" selected disabled hidden> Please select the reason </option>
                                        {DECLINED_REASON?.map((val, index) => (
                                            <option key={index} value={val}>{val}</option>
                                        ))}
                                    </Select>
                                </div>
                            )}

                            <div className='flex justify-end my-4 mx-2'>
                                <Button width='none' border='lightgrey' color='darkgrey' padding='px-8' bg='white' margin='mx-2'
                                    onClick={() => setUpdateModal(false)}
                                >
                                    No
                                </Button>

                                <Button width='none' padding='px-8' type='submit'>
                                    Yes
                                </Button>
                            </div>

                        </form>
                    </div>
                </ModalContainer>

                <div className="opacity-60 fixed inset-0 z-40 bg-black-1"></div>
            </>
        )}
    </>);
}   