import { post, get, put, getBlob, postMultipart } from "../core";
import fileDownload from "js-file-download";

export const updateParcelStatus = async (
  status: any,
  parcelId: string,
  subStatus?: string
) => {
  try {
    await put(`/update/parcel/status/${parcelId}`, {
      status: status,
      subStatus: subStatus,
    });

    return {
      isSuccess: true,
    };
  } catch (err) {
    throw err;
  }
};

export const syncParcel = async (parcelId: string) => {
  try {
    const res = await post(`/sync-parcel-to-odoo`, { parcelId: parcelId });
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const generatePdf = async (parcelId: string) => {
  try {
    let res = await getBlob("/generate/pdf/waybill", { parcelId: [parcelId] });
    fileDownload(res.data, `${Date.now()}-${parcelId}$.pdf`);
  } catch (err) {
    console.log("error", err);
    throw err;
  }
};

export const createParcel = async (parcel: any) => {
  try {
    const body = {
      ...parcel,
      consignee: {
        ...parcel.consignee,
        phoneNumber: `+63${parcel.consignee.phoneNumber}`,
      },
      shipper: {
        ...parcel.shipper,
        phoneNumber: `+63${parcel.shipper.phoneNumber}`,
      },
    };
    const res = await post(`/admin/create/parcel`, body);
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const cancelParcel = async (parcelId: any, reason: any) => {
  try {
    const res = await put(`/cancel/parcel/${parcelId}`, reason);
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const uploadParcelPod = async (parcelId: any, file: any) => {
  try {
    const body = new FormData();
    body.append("file", file);
    body.append("name", "Proof-Of-Delivery");

    const res = await postMultipart(
      `/admin/parcel/${parcelId}/upload/proof-of-delivery`,
      body
    );
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
