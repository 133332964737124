import { post, put, remove } from "../core";

export const deleteRole = async (id: any) => {
    try {
        const res = await remove(`/role/delete/${id}`)
        return res.data;
    } catch (err) {
        return Promise.reject(err);
    }
}

export const createRole = async (body: any) => {
    try {
        const res = await post(`/role`, body)
        return res.data;
    } catch (err) {
        return Promise.reject(err)
    }
}

export const updateRole = async ({ id, payload }: any) => {
    try {
        const res = await put(`/role/edit/${id}`, payload);
        return res.data;
    } catch (err) {
        return Promise.reject(err);
    }
}