import { GridContainer } from "../../../../components/common/Containers";
import { Span } from "../../../../components/common/Span";
import { BsDot } from "react-icons/bs";

export default ({ data }: any) => {
  // const total = deliveries?.fee?.detail?.map(fee => fee?.amount).reduce((a, b) => a + b);

  const paymentM = {
    cash: "Cash",
    wallet: "Wallet",
  };

  return (
    <>
      <div className="py-4 px-8">
        {data?.fareDetails?.baseDeliveryFee ? (
          <div className="flex justify-between">
            <Span>Base Delivery Fee</Span>
            <Span>
              {`₱ ${parseFloat(data?.fareDetails?.baseDeliveryFee)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
            </Span>
          </div>
        ) : (
          <></>
        )}

        {data?.fareDetails?.specialHelpFee ? (
          <div className="flex justify-between">
            <Span>Special Help Fee</Span>
            <Span>
              {`₱ ${parseFloat(data?.fareDetails?.specialHelpFee)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
            </Span>
          </div>
        ) : (
          <></>
        )}

        {data?.fareDetails?.extraHelpFee ? (
          <div className="flex justify-between">
            <Span>Extra Help Fee</Span>
            <Span>
              {`₱ ${parseFloat(data?.fareDetails?.extraHelpFee)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
            </Span>{" "}
          </div>
        ) : (
          <></>
        )}

        {data?.fareDetails?.customerStaffFee ? (
          <div className="flex justify-between">
            <Span>{`Customer Staff Fee - (${data?.customerStaffCount})`}</Span>
            <Span>
              {`₱ ${parseFloat(data?.fareDetails?.customerStaffFee)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
            </Span>{" "}
          </div>
        ) : (
          <></>
        )}

        {data?.fareDetails?.safetyGlovesFee ? (
          <div className="flex justify-between">
            <Span>Safety Gloves Fee</Span>
            <Span>
              {`₱ ${parseFloat(data?.fareDetails?.safetyGlovesFee)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
            </Span>{" "}
          </div>
        ) : (
          <></>
        )}

        {data?.fareDetails?.hardHatFee ? (
          <div className="flex justify-between">
            <Span>Hard Hat Fee</Span>
            <Span>
              {`₱ ${parseFloat(data?.fareDetails?.hardHatFee)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
            </Span>{" "}
          </div>
        ) : (
          <></>
        )}

        {/* {data?.fareDetails?.tip ? (
          <div className="flex justify-between">
            <Span>Tip</Span>
            <Span>
              {`₱ ${parseFloat(data?.fareDetails?.tip)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
            </Span>{" "}
          </div>
        ) : (
          <></>
        )} */}

        {/* {data?.otherFee ? (
          <div className="flex justify-between">
            <Span>Other Fee</Span>
            <Span>
              {`₱ ${parseFloat(data?.otherFee)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
            </Span>{" "}
          </div>
        ) : (
          <></>
        )} */}

        <div className="flex justify-between">
          <Span color="brown" margin="mt-4" size="lg">
            Grand Total
          </Span>
          <Span color="brown" size="xl" weight="semi" margin="mt-4">
            ₱{" "}
            {parseFloat(data?.totalCharge)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Span>
        </div>

        <div className="flex justify-between">
          <Span color="brown" size="lg">
            Payment Method
          </Span>
          <Span color="brown" size="xl" weight="semi">
            {paymentM[data?.paymentMethod]}
          </Span>
        </div>
        {/* <div className='flex justify-between'>
            <Span>Additional Stop Fee</Span> 
            <Span>₱ {parseFloat(data?.additionalStopFee).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Span>
        </div>

        <div className='flex justify-between'>
            <Span>Add Ons Fee</Span> 
            <Span>₱ {parseFloat(data?.addOnsFee).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Span>
        </div>

        <div className='flex justify-between'>
            <Span>Base Delivery Fee</Span> 
            <Span>₱ {parseFloat(data?.fareDetails.baseDeliveryFee).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Span>
        </div>

        <div className='flex justify-between'>
            <Span>Other Fee</Span> 
            <Span>₱ {parseFloat(data?.otherFee).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Span>
        </div>

        <div className='flex justify-between'>
            <Span margin='mt-4'>Purchase Service Fee</Span> 
            <Span margin='mt-4'>₱ {parseFloat(data?.fareDetails.purchaseServiceFee).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Span>
        </div>

        <div className='flex justify-between'>
            <Span color='brown' margin='mt-4'>Grandtotal:</Span> 
            <Span color='brown' size='xl' weight='semi' margin='mt-4'>
                ₱ {parseFloat(data?.totalCharge).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Span>
        </div>

        <div className='flex justify-between'>
            <Span color='brown'>{paymentM[data?.paymentMethod]}</Span> 
            <Span color='brown' size='xl' weight='semi'>
                ₱ {parseFloat(data?.totalCharge).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Span>
        </div> */}
      </div>
    </>
  );
};
