import { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../../store";
import { useHistory, Link } from 'react-router-dom'

import { Span } from '../../components/common/Span'
import { Button } from '../../components/common/Button'
import { Container } from '../../components/common/Containers';

import { BiPlusCircle } from 'react-icons/bi'

import Table from '../../components/Table/Table'
import { COLUMNS } from './Table/column'
import NoData from '../../components/common/NoData/NoData';

import Loader from "../../components/Loader";
import EditDriver from './Update/EditDriver'
import AddDriver from './Add/AddDriver'

export default () => {

    const dispatch = useDispatch<Dispatch>();
    const [isLoading, setIsLoading] = useState(true);
    const editDriverId: any = useSelector((state: RootState) => state.UI.forms.editDriver?.id);
    const filters: any = useSelector((state: RootState) => state.Table.tables.drivers?.filters);
    const data: any[] = useSelector((state: RootState) => state.Table.tables?.drivers?.data)
        .sort((a, b) => { return a.createdAt.seconds - b.createdAt.seconds });

    const [addDriver, setAddDriver] = useState(false);

    const columns = useMemo(() => COLUMNS, []);

    useEffect(() => {
        dispatch.Table.getFirebaseData({ name: 'driver_profile', table: 'drivers' })
        setIsLoading(false);
    }, [filters]);

    return (<>
        {isLoading ? (
            <Loader />
        ) : (
            <Container border='grey' padding='none'>
                <div className='p-4'>
                    <div className='flex justify-between'>
                        <Span margin='mt-4' weight='bold' size='2xl'>All Drivers</Span>
                        <div className='flex justify-end px-4 my-4'>
                            <Button
                                width='none'
                                onClick={() => { setAddDriver(true); }}
                            >
                                <div className='flex pr-2'>
                                    <BiPlusCircle className='my-auto mx-2 text-2xl' />
                                    Add Driver
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
                <div className='overflow-auto'>
                    {data?.length < 0
                        ? <NoData />
                        : <Table columns={columns} data={data} />
                    }
                </div>
            </Container>
        )}

        {editDriverId && <>
            <EditDriver setEditDriver={editDriverId} />
        </>}

        {addDriver && <>
            <AddDriver setAddDriver={setAddDriver} />
        </>}

    </>)
}