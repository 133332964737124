import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { RootState, Dispatch } from "../../../store";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import {
  GridContainer,
  SubContainer,
} from "../../../components/common/Containers";
import { Span, SpanContainer } from "../../../components/common/Span";
import { Button } from "../../../components/common/Button";

import { ImArrowLeft2 } from "react-icons/im";
import { Link } from "react-router-dom";

import pickup from "../../../images/pickup_icon.png";
import dropoff from "../../../images/drop-off_icon.png";
import payment from "../../../images/payment_icon.png";
import mapIcon from "../../../images/map_icon.png";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

import Pickup from "./Dropdown/Pickup";
import DropOff from "./Dropdown/DropOff";
import Map from "./Dropdown/Map";

import AssignedDriver from "../Modal/AssignedDriver";

import {
  EXPRESS,
  VEHICLE,
  VEHICLETYPE,
} from "../../../components/constants/status";

import StatusExpress from "./Content/StatusExpress";
import ShareLink from "./Content/ShareLink";
import HistoryStatus from "./HistoryStatus/HistoryStatus";

let UNSUBSCRIBE: any;

export default ({ id, ...props }: any) => {
  const { tab } = useParams();
  const [data, setData] = useState<any>();

  const dispatch = useDispatch<Dispatch>();
  const { customer } = useSelector((state: RootState) => state.Express);
  const driversProfilePre = data?.driver;
  const {
    totalCharge,
    orderCode,
    itemDescription,
    driverId,
    customerId,
    notes,
    status,
    deliveryId,
    shareAuth,
  } = data !== undefined && data;
  const isAssignOrReAssign =
    driversProfilePre?.firstName != null && driversProfilePre?.lastName != null
      ? "Reassign Driver"
      : "Assign Driver";

  const datePlace = new Date(data?.createdAt?.seconds * 1000);
  const placeScheduled = moment(datePlace).format("MMMM Do YYYY | h:mm:ss a");

  const dateSchedule = new Date(data?.scheduleStartTime);
  const scheduledAt = moment(dateSchedule).format("MMMM Do YYYY | h:mm:ss a");
  const scheduleTime = data?.scheduleStartTime;

  const [pickUp, setPickUp] = useState(true);
  const togglePickUp = (e: any) => {
    e.preventDefault();
    setPickUp(!pickUp);
  };

  const [dropOff, setDropOff] = useState(true);
  const toggleDropOff = (e: any) => {
    e.preventDefault();
    setDropOff(!dropOff);
  };

  const [map, setMap] = useState(true);
  const toggleMap = (e: any) => {
    e.preventDefault();
    setMap(!map);
  };

  const [assignedDriverModal, setAssignedDriverModal] = useState(false);
  const handleAssignedDriver = (e) => {
    e.preventDefault();
    setAssignedDriverModal(true);
  };

  const db = getFirestore();
  const getData = async () => {
    if (UNSUBSCRIBE) UNSUBSCRIBE();

    UNSUBSCRIBE = onSnapshot(doc(db, "orders", id), (doc) => {
      if (doc.exists()) {
        setData(doc.data());
      } else {
        setData("No such document!");
      }
    });
  };

  useEffect(() => {
    // dispatch.Express.getFirebaseData({name: 'driversProfile', colName: 'driver_profile', filter: 'uid', id: driverId})
    dispatch.Express.getFirebaseData({
      name: "customer",
      colName: "customer_profile",
      filter: "id",
      id: customerId,
    });
  }, [data]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <GridContainer grid="five" padding="none">
        <div>
          <SubContainer border="grey">
            <Link to={`/express?tab=${tab}`} className="my-auto text-grey-14">
              <ImArrowLeft2 />
            </Link>
            <div className="bg-brown-1 p-8 text-center rounded-md my-4">
              <Span weight="semi" size="2xl" color="white">
                ₱
                {parseFloat(totalCharge)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Span>
              <Span color="white">Total Amount</Span>
            </div>
            <SpanContainer weight="semi" padding="pr-2">
              Status:
            </SpanContainer>
            <SpanContainer color="brown">{EXPRESS[status]}</SpanContainer>

            {/* <ShareLink shareAuth={shareAuth} id={id}/>  */}
          </SubContainer>

          <SubContainer border="grey">
            <Span weight="semi" color="brown" transform="uppercase">
              Customer Details
            </Span>
            <Span weight="semi" margin="mt-4" size="xl" transform="capitalize">
              {customer?.firstName}&nbsp;{customer?.lastName}
            </Span>

            <Span margin="mt-4" color="grey">
              Order No:
            </Span>
            <Span>{orderCode}</Span>

            <Span margin="mt-4" color="grey">
              Contact No:
            </Span>
            <Span>{customer?.phoneNumber}</Span>

            <Span margin="mt-4" color="grey">
              Order Placed:
            </Span>
            <Span>{placeScheduled}</Span>

            <Span margin="mt-4" color="grey">
              Item Description:
            </Span>
            <Span>{itemDescription}</Span>

            <Span margin="mt-4" color="grey">
              Notes:
            </Span>
            <Span>{notes}</Span>

            {data?.orderType === "scheduled" ||
              data?.orderType === "full day" ? (
              <>
                <Span margin="mt-4" color="grey">
                  Delivery at:
                </Span>
                <Span>{scheduledAt}</Span>
              </>
            ) : (
              <>
                <Span margin="mt-4" color="grey">
                  Service Type:
                </Span>
                <Span>Deliver Now</Span>
              </>
            )}

            <hr className="my-4" />

            <Span
              weight="semi"
              color="brown"
              transform="uppercase"
              margin="my-4"
            >
              Driver Details
            </Span>
            {EXPRESS[status] !== "New" && (
              <>
                <Span weight="semi" margin="mt-4" size="xl">
                  {driversProfilePre?.firstName}&nbsp;
                  {driversProfilePre?.lastName}
                </Span>

                <Span margin="mt-4" color="grey">
                  Contact No:
                </Span>
                <Span>{driversProfilePre?.phoneNumber}</Span>
              </>
            )}

            {["delivered", "canceled", "failed_delivery"].includes(status) ? (
              <></>
            ) : (
              <Button bg="green" margin="mt-4" onClick={handleAssignedDriver}>
                {isAssignOrReAssign}
              </Button>
            )}
          </SubContainer>
        </div>

        <div className="col-span-3">
          <SubContainer border="grey" padding="none">
            <div className="flex justify-between py-2 px-10 border-2 border-grey-1 rounded-md">
              <Span> Vehicle </Span>
              <Span transform="uppercase" weight="semi">
                {VEHICLE[data?.vehicleType]}
              </Span>
              <Span> | </Span>
              <Span> Distance </Span>
              <Span transform="uppercase" weight="semi">
                {" "}
                {data?.distance}Km{" "}
              </Span>
            </div>

            <div
              className="flex justify-between bg-grey-9 p-4 rounded-lg cursor-pointer my-4"
              onClick={togglePickUp}
            >
              <div className="flex my-auto">
                <img src={pickup} alt="" className="mx-4 h-8" />
                <Span margin="my-auto">Pickup Location</Span>
              </div>

              {pickUp ? (
                <IoIosArrowDown className="mx-4 my-auto text-2xl" />
              ) : (
                <IoIosArrowForward className="mx-4 my-auto text-2xl" />
              )}
            </div>

            {pickUp ? (
              <Pickup pickUp={data?.pickup} status={data?.status} />
            ) : null}

            <div
              className="flex justify-between bg-grey-9 p-4 rounded-lg cursor-pointer my-4"
              onClick={toggleDropOff}
            >
              <div className="flex my-auto">
                <img src={dropoff} alt="" className="mx-4 h-8" />
                <Span margin="my-auto">Drop-off Location</Span>
              </div>
              {dropOff ? (
                <IoIosArrowDown className="mx-4 my-auto text-2xl" />
              ) : (
                <IoIosArrowForward className="mx-4 my-auto text-2xl" />
              )}
            </div>

            {dropOff ? (
              <DropOff
                stops={data?.stops}
                expressId={id}
                status={data?.status}
              />
            ) : null}

            <div
              className="flex justify-between bg-grey-9 p-4 rounded-lg cursor-pointer my-4"
              onClick={toggleMap}
            >
              <div className="flex my-auto">
                <img src={payment} alt="" className="mx-4 h-8" />
                <Span margin="my-auto">Payment</Span>
              </div>
              {map ? (
                <IoIosArrowDown className="mx-4 my-auto text-2xl" />
              ) : (
                <IoIosArrowForward className="mx-4 my-auto text-2xl" />
              )}
            </div>

            {map ? <Map data={data} /> : null}
          </SubContainer>

          <StatusExpress status={status} orderId={id} />

          <AssignedDriver
            setAssignedDriverModal={setAssignedDriverModal}
            assignedDriverModal={assignedDriverModal}
            orderId={id}
            isAssignOrReAssign={isAssignOrReAssign}
            vehicleType={data?.vehicleType}
          />
        </div>

        <div className="">
          <SubContainer border='grey'>
            <HistoryStatus express={data} />
          </SubContainer>
        </div>
      </GridContainer>
    </>
  );
};
