import React from 'react'
import { onlyNumberKey, onlyNumberKeyAndDot } from '../../../../../common/utils'
import { GridContainer, InputContainer } from '../../../../../components/common/Containers'
import { Input } from '../../../../../components/common/Input'
import { Select } from '../../../../../components/common/Select'
import { Span, SpanContainer } from '../../../../../components/common/Span'
import { VEHICLE, VEHICLETYPE } from '../../../../../components/constants/status'

const BasicFare = ({ onChange, data }) => {
    return (
        <div className="pt-4">
            <Span margin="mt-4" size="lg" weight="medium">
                Basic Fare
            </Span>
            <GridContainer grid="two" padding="none">
                <div>
                    <Span margin="mt-4">Vehicle Type</Span>
                    <InputContainer margin="none">
                        <Select
                            required
                            onChange={(e) => onChange(e, 'vehicleType')}
                            value={data?.vehicleType !== '' ? data?.vehicleType : null}
                        >
                            <option disabled selected>Choose Vehicle</option>
                            {VEHICLETYPE.map(v => (
                                <option value={v} key={v}>{VEHICLE[v]}</option>
                            ))}
                        </Select>
                    </InputContainer>
                </div>

                <div>
                    <Span margin="mt-4">Basic Rate</Span>
                    <InputContainer margin="none">
                        <SpanContainer
                            padding="normal"
                            position="absolute"
                            border="right"
                            weight="semi"
                        >₱</SpanContainer>
                        <Input
                            padding="pl-12"
                            type="text"
                            border="grey"
                            pattern="^[0-9\.]*$"
                            onKeyPress={onlyNumberKeyAndDot}
                            onChange={(e) => onChange(e, 'baseFare')}
                            value={data?.baseFare ?? 0}
                            required
                        />
                    </InputContainer>
                </div>

                <div>
                    <Span>Price per Km</Span>
                    <InputContainer margin="none">
                        <SpanContainer
                            padding="normal"
                            position="absolute"
                            border="right"
                            weight="semi"
                        >₱</SpanContainer>
                        <Input
                            padding="pl-12"
                            type="text"
                            border="grey"
                            pattern="^[0-9\.]*$"
                            onKeyPress={onlyNumberKeyAndDot}
                            onChange={(e) => onChange(e, 'standardPricePerKM')}
                            value={data?.standardPricePerKM ?? 0}
                            required
                        />
                    </InputContainer>
                </div>

                <div>
                    <Span>Day Rate</Span>
                    <InputContainer margin="none">
                        <SpanContainer
                            padding="normal"
                            position="absolute"
                            border="right"
                            weight="semi"
                        >₱</SpanContainer>
                        <Input
                            padding="pl-12"
                            type="text"
                            border="grey"
                            pattern="^[0-9\.]*$"
                            onKeyPress={onlyNumberKeyAndDot}
                            onChange={(e) => onChange(e, 'dayRate')}
                            value={data?.dayRate ?? 0}
                            required
                        />
                    </InputContainer>
                </div>

                <div>
                    <Span>Commission</Span>
                    <InputContainer margin="none">
                        <SpanContainer
                            padding="normal"
                            position="absolute"
                            border="right"
                            weight="semi"
                        >%</SpanContainer>
                        <Input
                            padding="pl-14"
                            type="text"
                            border="grey"
                            pattern="^[0-9\.]*$"
                            onKeyPress={onlyNumberKey}
                            onChange={(e) => onChange(e, 'commission')}
                            value={data?.commission ?? 0}
                            required
                        />
                    </InputContainer>
                </div>

                <div>
                    <Span>Driver Share</Span>
                    <InputContainer margin="none">
                        <SpanContainer
                            padding="normal"
                            position="absolute"
                            border="right"
                            weight="semi"
                        >%</SpanContainer>
                        <Input
                            padding="pl-14"
                            type="text"
                            border="grey"
                            pattern="^[0-9\.]*$"
                            onKeyPress={onlyNumberKey}
                            onChange={(e) => onChange(e, 'driverShare')}
                            value={data?.driverShare ?? 0}
                            required
                        />
                    </InputContainer>
                </div>

                <div>
                    <Span>Consolidation Discount</Span>
                    <InputContainer margin="none">
                        <SpanContainer
                            padding="normal"
                            position="absolute"
                            border="right"
                            weight="semi"
                        >%</SpanContainer>
                        <Input
                            padding="pl-14"
                            type="text"
                            border="grey"
                            pattern="^[0-9\.]*$"
                            onKeyPress={onlyNumberKey}
                            onChange={(e) => onChange(e, 'consolidationDiscount')}
                            value={data?.consolidationDiscount ?? 0}
                            required
                        />
                    </InputContainer>
                </div>

                {/* <div>
                    <Span>Base branch Rate</Span>
                    <InputContainer margin="none">
                        <SpanContainer
                            padding="normal"
                            position="absolute"
                            border="right"
                            weight="semi"
                        >₱</SpanContainer>
                        <Input
                            padding="pl-12"
                            type="text"
                            border="grey"
                            pattern="^[0-9\.]*$"
                            onKeyPress={onlyNumberKeyAndDot}
                            onChange={(e) => onChange(e, 'baseBranchRate')}
                            value={data?.baseBranchRate ?? 0}
                            required
                        />
                    </InputContainer>
                </div> */}
            </GridContainer>
        </div>
    )
}

export default BasicFare