import { useMemo, useState, useEffect} from 'react' 
import { useDispatch, useSelector } from 'react-redux'
import { RootState, Dispatch } from '../../../store'

import { Span } from '../../../components/common/Span'  
import { Container } from '../../../components/common/Containers'
 
import AddCustomer from './AddCustomer/AddCustomer'

import Table from './Table/Table' 
import { COLUMNS } from './Table/column';
import { Link, useParams } from 'react-router-dom' 
import Loader from "../../../components/Loader"; 
import data from './Table/data.json';
import { Button } from '../../../components/common/Button' 
import { BiPlusCircle } from 'react-icons/bi'
import { ImArrowLeft2 } from 'react-icons/im' 
import {doc, getFirestore, onSnapshot} from 'firebase/firestore'
import { BRAND_NAME } from '../../../components/constants'

let UNSUBSCRIBE: any;

export default () => {      

    const {id}:any = useParams();
    const dispatch = useDispatch<Dispatch>();

    const [billing, setBilling] = useState() as any;
    const data: any[] = billing?.customer ?? []; 

    const [addCustomer, setAddCustomer] = useState(false); 
    const [isLoading, setIsLoading] = useState(true);
    
    const columns = useMemo(() => COLUMNS, []);

    const db = getFirestore();
    const getData = async() => {
        if(UNSUBSCRIBE) UNSUBSCRIBE();

        UNSUBSCRIBE = onSnapshot(doc(db, 'billing', id), (doc) => {
            if(doc.exists()){
                setBilling(doc.data())
            }else{
                setBilling("No such document!")
            }
        })
    }

    useEffect(() => {
        dispatch.Table.getFirebaseData({name: 'customer_profile', table: 'customers'})
        setIsLoading(false);
        getData();
    },[]);
    return (<> 
            {isLoading ? (
                <Loader />
            ): (
            <Container border='grey' padding='none'> 
                <div className='p-4'> 
                    <div className='flex py-4 justify-between'>
                        <div className='flex py-4'>
                            <Link to='/billing' className='my-auto text-grey-14'>
                                <ImArrowLeft2 className='my-auto'/>
                            </Link>
                            <Span color='brown'  weight='bold' size='xl' padding='px-4' margin='my-auto'>
                            {billing?.name ?? BRAND_NAME}
                            </Span> 
                        </div>
                        <div className='flex justify-end px-4 my-4'>
                            <Button 
                                width='none'  
                                onClick={() => {setAddCustomer(true);}}
                            >
                                <div className='flex pr-2'>
                                    <BiPlusCircle className='my-auto mx-2 text-2xl'/> 
                                    Add Customer
                                </div>
                            </Button>
                        </div>  
                    </div>   
                </div> 
                
                <div className='overflow-auto'>
                    <Table columns={columns} data={data} id={id}/> 
                </div>
            </Container>    
            )}

            {addCustomer && (
            <AddCustomer setAddCustomer={setAddCustomer} addCustomer={addCustomer}/>    
            )}
    </>
    )
}
