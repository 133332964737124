import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../../store';
import { Button } from '../../../components/common/Button';

import { STATUS } from '../../../components/constants/status';

import { SUB_STATUS } from '../../../components/constants/status';
import { cancelParcel, updateParcelStatus } from '../../../common/api';
import './css/Parcels.css';
import SubStatus from '../Modal/SubStatus';

export default ({ parcelId, status }) => {
  const dispatch = useDispatch<Dispatch>();
  const [substatus, setSubstatus] = useState({
    substatus: '',
  });
  const [openSubStatus, setOpenSubStatus] = useState(false);
  const [data, setData] = useState({
    status: '',
    parcelId: '',
  });

  const updateStatus = async (
    status: string,
    parcelId: string,
    subStatus?: string
  ) => {
    if (SUB_STATUS.includes(STATUS[status]) && subStatus === 'openModal') {
      setData({ status, parcelId });
      setOpenSubStatus(true);
      return;
    }
    try {
      dispatch.UI.setIsLoading(true);
      await updateParcelStatus(status, parcelId, subStatus);
      setSubstatus({ substatus: '' });
      dispatch.UI.setAlert({ message: 'Status updated!', type: 'Success' });
      dispatch.UI.setIsLoading(false);
    } catch (err: any) {
      let errMsg = 'Unable to update status!';
      if (err?.data?.error) {
        errMsg = err.data.error;
      }
      dispatch.UI.setAlert({
        message: errMsg,
        type: 'Error',
      });
      dispatch.UI.setIsLoading(false);
    }
  };

  const cancelDelivery = async () => {
    try {
      dispatch.UI.setIsLoading(true);
      await cancelParcel(parcelId, { cancelReason: '' });
      dispatch.UI.setAlert({ message: 'Status updated!', type: 'Success' });
      dispatch.UI.setIsLoading(false);
    } catch (err: any) {
      let errMsg = 'Unable to update status!';
      if (err?.data?.error) {
        errMsg = err.data.error;
      }
      dispatch.UI.setAlert({ message: errMsg, type: 'Error' });
      dispatch.UI.setIsLoading(false);
    }
  };

  return (
    <>
      <SubStatus
        openSubStatus={openSubStatus}
        setOpenSubStatus={setOpenSubStatus}
        data={data}
      />
      {STATUS[status] != 'Canceled' && STATUS[status] != 'Delivered' && (
        <Button
          onClick={cancelDelivery}
          width="none"
          padding="px-4"
          border="lightgrey"
          bg="white"
          color="grey"
          height="2/4"
        >
          Cancel Delivery
        </Button>
      )}

      <div>
        {STATUS[status] === 'New' && (
          <Button
            onClick={() => {
              updateStatus('for_pickup', parcelId);
            }}
            width="none"
            padding="px-4"
            border="lightgrey"
          >
            For Pick Up
          </Button>
        )}

        {STATUS[status] === 'For Pickup' && (
          <Button
            onClick={() => {
              updateStatus('picked_up', parcelId);
            }}
            width="none"
            padding="px-4"
            border="lightgrey"
          >
            Picked up
          </Button>
        )}

        {STATUS[status] === 'Picked up' && (
          <>
            <Button
              onClick={() => {
                updateStatus('in_transit', parcelId, 'openModal');
              }}
              width="none"
              padding="px-4"
              border="lightgrey"
              bg="white"
              color="grey"
              margin="mx-4"
            >
              Transfer
            </Button>

            <Button
              onClick={() => {
                updateStatus('out_for_delivery', parcelId);
              }}
              width="none"
              padding="px-4"
              border="lightgrey"
            >
              For Delivery
            </Button>
          </>
        )}

        {STATUS[status] === 'In Transit' && (
          <>
            <Button
              onClick={() => {
                updateStatus('in_transit', parcelId, 'openModal');
              }}
              width="none"
              padding="px-4"
              border="lightgrey"
              bg="white"
              color="grey"
              margin="mx-4"
            >
              Transfer
            </Button>

            <Button
              onClick={() => {
                updateStatus('out_for_delivery', parcelId);
              }}
              width="none"
              padding="px-4"
              border="lightgrey"
            >
              For Delivery
            </Button>
          </>
        )}

        {STATUS[status] === 'Failed Delivery' && (
          <>
            <Button
              onClick={() => {
                updateStatus('in_transit', parcelId, 'openModal');
              }}
              width="none"
              padding="px-4"
              border="lightgrey"
              bg="white"
              color="grey"
              margin="mx-4"
            >
              Transfer
            </Button>

            <Button
              onClick={() => {
                updateStatus('out_for_delivery', parcelId);
              }}
              width="none"
              padding="px-4"
              border="lightgrey"
            >
              For Delivery
            </Button>
          </>
        )}

        {STATUS[status] === 'Out for Delivery' && (
          <>
            <Button
              onClick={() => {
                updateStatus('failed_delivery', parcelId, 'openModal');
              }}
              width="none"
              padding="px-4"
              border="lightgrey"
              bg="white"
              color="grey"
              margin="mx-4"
            >
              Failed Delivery
            </Button>

            <Button
              onClick={() => {
                updateStatus('delivered', parcelId);
              }}
              width="none"
              padding="px-4"
              border="lightgrey"
            >
              Delivered
            </Button>
          </>
        )}
      </div>
    </>
  );
};
