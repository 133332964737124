
import { useState, useEffect, useReducer } from 'react';
import { useHistory, useParams } from 'react-router';
import { Dispatch } from '../../../store';
import { useDispatch } from 'react-redux'
import PostErrorAlert from '../../../components/PostErrorAlert';
import BackDrop from "../../../components/BackDrop";
import { onlyNumberKey, toObject } from '../../../common/utils';

import { Span, SpanContainer } from "../../../components/common/Span";
import { Input } from '../../../components/common/Input'
import { Button } from '../../../components/common/Button'
import { Container, InputContainer } from "../../../components/common/Containers";

import '../Customer.css'
import { IoCloseSharp } from 'react-icons/io5'
import { RiErrorWarningFill } from 'react-icons/ri'
import { createCustomer, createRole } from "../../../common/api";
import { Form } from "react-bootstrap";
import Switch from '../../../components/Switch/Switch';
import Permission from '../../../components/Permissions/Permission';

type Props = {
    setAddRole: Function,
}

const reducer = (permissions, action) => {
    switch (action.type) {
        case "ADD_PERMISSION":
            return [...permissions, action.payload];
        case "DELETE_PERMISSION":
            return [...permissions.filter(r => r.name !== action?.payload?.name)]
        case "ADD_ACTION":
            return [...permissions.map(f => f.name === action?.payload?.name ? { ...f, action: [...f?.action, action?.payload?.data] } : f)]
        case "DELETE_ACTION":
            return [...permissions.map(f => f.name === action?.payload ? { ...f, action: [...f?.action.filter(a => a.name !== action?.payload)] } : f)]
        default:
            return [...permissions];
    }
}

export default ({ setAddRole, ...props }: Props) => {
    const dispatch = useDispatch<Dispatch>();

    const [openAlert, setOpenAlert] = useState(false);
    const [errMessage, setErrMessage] = useState('');
    const [active, setActive] = useState(false);
    const [permissions, setPermissions] = useReducer(reducer, []) as any;

    const objectpermissions = toObject(permissions);

    const [data, setData] = useState({
        roleName: '',
        roleDescription: '',
    });

    const handleChange = async (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        const newData = { ...data, [name]: value };
        setData({ ...newData });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            ...data,
            status: active ? 'active' : 'inactive',
            permissions: objectpermissions
        }

        try {
            dispatch.UI.setIsLoading(true);
            const res = await createRole(payload);
            if (res.isSuccess) {
                const timeout = setTimeout(() => {
                    dispatch.UI.setAlert({ message: 'Role added!', type: 'Success' })
                    dispatch.UI.setIsLoading(false);
                    setAddRole(false)
                    dispatch.Table.getFirebaseData({ name: 'role', table: 'roles' })
                    clearTimeout(timeout)
                }, 1000)
            }
        } catch (err: any) {
            const errMsg = err?.data?.error ?? 'Unable to add role!';
            dispatch.UI.setIsLoading(false);
            setOpenAlert(true);
            setErrMessage(errMsg)
        }
    };

    return (<>
        <div className='side absolute inset-y-0 right-0 px-0'>
            <BackDrop />
            <div className='flex py-2 border-b border-grey-6'>
                <IoCloseSharp
                    // onClick={() => history.push('/customers/')}  
                    onClick={() => setAddRole(false)}
                    className='my-auto mx-2 text-lg cursor-pointer'
                />
                <Span weight='semi' size='lg'>
                    Add Role
                </Span>
            </div>

            <Form className="mt-4" onSubmit={handleSubmit}>
                <div className='text-left py-4 mb-32'>
                    <Span weight='semi' size='lg'>
                        Role Information
                    </Span>

                    <Span margin='mt-4'>Role Name</Span>
                    <Input placeholder='Role Name' name='roleName' onChange={handleChange} required />

                    <Span margin='mt-4'>Role Description</Span>
                    <Input placeholder='Description' name='roleDescription' onChange={handleChange} required />

                    <div className='flex justify-between mt-8'>
                        <Span weight='semi' margin='my-auto'>
                            Status
                        </Span>

                        <Switch refSwitch={null} active={active} setActive={setActive} />
                    </div>

                    <Permission permissions={permissions} setPermissions={setPermissions} />

                    <PostErrorAlert openAlert={openAlert} setOpenAlert={setOpenAlert} message={errMessage} />

                </div>

                <div className='flex inset-x-0 bottom-0 py-4'>
                    <Button
                        margin='mx-2' bg='white' border='lightgrey' color='black'
                        onClick={() => setAddRole(false)}
                    >
                        Cancel
                    </Button>
                    <Button margin='mx-2' type="submit">Add Role</Button>
                </div>
            </Form>
        </div>
    </>);
}   