import { Box, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react';
import { MdOutlineError } from 'react-icons/md'

const LinkExpired = () => {

    const [count, setCount] = useState(5);

    const stackProps = {
        spacing: 3,
        sx: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '5rem 2rem',
        }
    }

    useEffect(() => {
        let timer = setInterval(() => {
            setCount(count - 1);
        }, 1000);
        if (count === 0) {
            clearInterval(timer)
            return window.close();
        }
    }, [count]);

    return (
        <Stack {...stackProps}>
            <MdOutlineError style={{ fontSize: '60px', color: 'red' }} />
            <Typography variant='h4' textAlign='center'>This link has Expired!</Typography>
            <Typography variant='body2' color='GrayText'>{`Page will close in ${count}...`}</Typography>
        </Stack>
    )
}

export default LinkExpired