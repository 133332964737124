import { FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material'

const Address = ({ values, handleBlur, errors, handleChange, touched }) => {

    return (
        <>
            <Typography variant='h6' sx={{ fontWeight: '700', marginBottom: '.5rem' }}>ADDRESS</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor='province'><span style={{ color: '#F44336' }}>*</span>Province</InputLabel>
                        <OutlinedInput
                            name='province'
                            placeholder='Province'
                            id='province'
                            onBlur={handleBlur}
                            fullWidth
                            error={Boolean(touched.province && errors.province)}
                            value={values.province}
                            onChange={handleChange('province')}
                            inputProps={{
                                sx: { textTransform: 'capitalize' }
                            }}
                            sx={{
                                height: 40,
                                '& legend': {
                                    display: 'none'
                                },
                                '& fieldset': {
                                    top: 0
                                }
                            }}
                        />
                        {touched.province && errors.province && (
                            <FormHelperText error id='province'>
                                {errors.province}
                            </FormHelperText>
                        )}
                    </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor='city'><span style={{ color: '#F44336' }}>*</span>City</InputLabel>
                        <OutlinedInput
                            name='city'
                            placeholder='City'
                            id='city'
                            onBlur={handleBlur}
                            fullWidth
                            error={Boolean(touched.city && errors.city)}
                            value={values.city}
                            onChange={handleChange('city')}
                            inputProps={{
                                sx: { textTransform: 'capitalize' }
                            }}
                            sx={{
                                height: 40,
                                '& legend': {
                                    display: 'none'
                                },
                                '& fieldset': {
                                    top: 0
                                }
                            }}
                        />
                        {touched.city && errors.city && (
                            <FormHelperText error id='city'>
                                {errors.city}
                            </FormHelperText>
                        )}
                    </Stack>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor='address'><span style={{ color: '#F44336' }}>*</span>Street, House No., Barangay</InputLabel>
                        <OutlinedInput
                            name='address'
                            placeholder='Street, House No., Barangay'
                            id='address'
                            onBlur={handleBlur}
                            fullWidth
                            error={Boolean(touched.address && errors.address)}
                            value={values.address}
                            onChange={handleChange('address')}
                            inputProps={{
                                sx: { textTransform: 'capitalize' }
                            }}
                            sx={{
                                height: 40,
                                '& legend': {
                                    display: 'none'
                                },
                                '& fieldset': {
                                    top: 0
                                }
                            }}
                        />
                        {touched.address && errors.address && (
                            <FormHelperText error id='address'>
                                {errors.address}
                            </FormHelperText>
                        )}
                    </Stack>
                </Grid>

            </Grid>
        </>
    )
}

export default Address