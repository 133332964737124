import { useEffect, useMemo, useState} from 'react' 
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '../../../store'

import { Span } from '../../../components/common/Span' 
import { Button } from '../../../components/common/Button'
import { Container } from '../../../components/common/Containers'

import { BiPlusCircle } from 'react-icons/bi'
import RateCard from './Add/RateCard'

import Table from './Table/Table' 
import { COLUMNS } from './Table/column'     
import NoData from '../../../components/common/NoData/NoData'
 
import EditRate from './Update/EditRate'
import Loader from "../../../components/Loader"; 

export default () => {     

    const dispatch = useDispatch<Dispatch>();

    const [rateCard, setRateCard] = useState(false); 
    const [isLoading, setIsLoading] = useState(true);
    const columns = useMemo(() => COLUMNS, []); 

    const filters: any = useSelector((state: RootState) => state.Table.tables.parcel_rate.filters);
    const editParcelRateId: any = useSelector((state: RootState) => state.UI.forms.editParcelRate?.id);
    const data: any[] = useSelector((state: RootState) => state.Table.tables.parcel_rate.data)
        .sort((a, b) => {return a.createdAt.seconds - b.createdAt.seconds}); 

    useEffect(() => {
        dispatch.Table.subscribeToFirebase({name: 'rate_parcel', table: 'parcel_rate'})
        setIsLoading(false)

        return () => {
            dispatch.Table.UNSUBSCRIBE()
        }
    },[filters]);
    
    return (<> 
    {isLoading ? (
        <Loader />
    ):(
        <Container border='grey' padding='none'> 
            <div className='p-4'>
                <div className='flex justify-between'>
                    <Span margin='mt-4' weight='bold' size='2xl'>Parcel Rate</Span>  
                    <div className='flex justify-end px-4 my-4'>
                        <Button width='none' onClick={() => {setRateCard(true);}}>
                            <div className='flex pr-2'>
                                <BiPlusCircle className='my-auto mx-2 text-2xl'/> 
                                Add Rate Card
                            </div>
                        </Button>
                    </div>  
                </div> 
            </div> 
            
            <div className='overflow-auto'>
                {data?.length < 0 
                    ? <NoData />
                    : <Table columns={columns} data={data}/> 
                } 
            </div>
        </Container>   
    )}

            <RateCard setRateCard={setRateCard} rateCard={rateCard}/> 

            {editParcelRateId && <>
                <EditRate setEditRate={editParcelRateId}/>
            </>}   
    </>
    )
}
