import { useState, useEffect } from 'react';
import { ModalContainer } from '../../../components/common/Containers';
import { Button } from '../../../components/common/Button';
import { Span } from '../../../components/common/Span';
import { Select } from '../../../components/common/Select';
import warningIcon from '../../../images/Warning_icon.png';

import { Dispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import { updateDriverStatus } from '../../../common/api';

type Props = {
  setRejectModal: Function;
  rejectModal: boolean;
  id: string;
};

export default ({ setRejectModal, rejectModal, id, ...props }: Props) => {
  const dispatch = useDispatch<Dispatch>();

  const body = {
    driverApprovalStatus: 'reject',
  };

  const handleReject = async (e) => {
    e.preventDefault();
    setRejectModal(false);
    try {
      dispatch.UI.setIsLoading(true);
      const res = await updateDriverStatus({ body, id });
      if (res?.isSuccess) {
        dispatch.UI.setAlert({ message: 'Status updated', type: 'Success' });
        dispatch.UI.setIsLoading(false);
        dispatch.Table.getFirebaseData({ name: 'driver_profile', table: 'drivers' })
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err?.data?.error ? err.data.error : 'Unable to update status',
        type: 'Error',
      });
      dispatch.UI.setIsLoading(false);
    }
  };

  return (
    <>
      {rejectModal && (
        <>
          <ModalContainer>
            <form onSubmit={handleReject}>
              <div className="px-4 py-6 whitespace-normal">
                <div className="flex">
                  <img
                    src={warningIcon}
                    alt={warningIcon}
                    className="h-8 mr-2"
                  />
                  <div className="text-left">
                    <Span size="lg" weight="bold">
                      Approval Status
                    </Span>
                  </div>
                </div>

                <div className="text-left">
                  <Span margin="mt-4">Reason of Reject</Span>
                  <Select required>
                    <option value="" selected disabled hidden>
                      {' '}
                    </option>
                    <option>Incomplete Requirement</option>
                  </Select>
                </div>

                <div className="flex justify-end my-4 mx-2">
                  <Button
                    width="none"
                    border="grey"
                    color="black"
                    padding="px-8"
                    bg="white"
                    margin="mx-2"
                    onClick={() => setRejectModal(false)}
                  >
                    No
                  </Button>

                  <Button width="none" padding="px-8" type="submit">
                    Yes
                  </Button>
                </div>
              </div>
            </form>
          </ModalContainer>

          <div className="opacity-60 fixed inset-0 z-40 bg-black-1"></div>
        </>
      )}
    </>
  );
};
