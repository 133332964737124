import CustomersPage from '../containers/Customer/Customer'  
import Header from '../components/Header/Header' 
import SidebarPage from '../components/Sidebar/Sidebar'
import { MainContainer } from '../components/common/Containers' 
 
const Customers = () => {
    return ( 
        <>  
            <SidebarPage />
            <MainContainer>    
                <Header /> 
                <main> 
                    <CustomersPage/>  
                </main> 
            </MainContainer>
        </>
    )
}

export default Customers
