import {getAuth, signInWithCustomToken, setPersistence, browserSessionPersistence} from "firebase/auth"

export const getFirebaseToken = async (customToken: string) => {
    
    try {
        const auth = getAuth();
        const firebaseResponse = await signInWithCustomToken(auth, customToken);
        const userToken = await auth.currentUser?.getIdToken(true);
        return { firebaseResponse, userToken}
    } catch (err) {
        throw err
    }
};


export const persistFirebaseAuth = async (customToken: string)  => {
    
    const auth = getAuth();
                
    setPersistence(auth, browserSessionPersistence)
    .then(() => {
        return signInWithCustomToken(auth, customToken);
    }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
    });
}