import React from 'react'
import Feature from './Feature';
import { FEATURE } from '../constants'

const Permission = ({ permissions, setPermissions, currentPermissions = [] }) => {

  const objectToArray = (obj) => {
    const arr = Object?.entries(obj)?.map(entry => entry[0]);
    return arr;
  } 

  const CP = Object?.entries(currentPermissions)?.map(entry => {return{role: entry[0], action: objectToArray(entry[1])}});
  return (
    <div className='mt-8'>
      {
        FEATURE?.map((item, index) => (
          <div>
            <Feature key={index} permissions={permissions} setPermissions={setPermissions} item={item} index={index} CP={CP} />
          </div>
        ))
      }
    </div>
  )
}

export default Permission;