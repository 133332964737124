import * as React from 'react';
import classnames from 'classnames'

type Props = {
  children: React.ReactNode,
  align?: 'center' | 'end',
  flex?: 'none',
  grid?: 'two' | 'four' | 'five',
  gap?: 'none' | 'two',
  border?: 'right' | 'left' | 'grey' | 'top' | 'darkgrey',
  bg?: 'grey',
  cols?: 'span-2' | 'span-3',
  margin?: 'mt-4' | 'none',
  padding?: 'none' | 'px-8' | 'py-4' | 'p-2',
  radius?: 'lg' | 'none'
}

let getAlign = (align: Props['align']) => {
  switch (align) {
    case 'end':
      return 'items-center justify-end';
    case 'center':
      return 'items-center justify-center';
    default:
      return 'items-start justify-start';
  }
}

let getFlex = (flex: Props['flex']) => {
  switch (flex) {
    case 'none':
      return 'flex-none';
    default:
      return 'flex';
  }
}

let getGrid = (grid: Props['grid']) => {
  switch (grid) {
    case 'five':
      return 'lg:grid-cols-5 md:grid-cols-5';
    case 'four':
      return 'lg:grid-cols-4 md:grid-cols-4';
    case 'two':
      return 'lg:grid-cols-2 md:grid-cols-2';
    default:
      return 'lg:grid-cols-3 md:grid-cols-3';
  }
}

let getGap = (gap: Props['gap']) => {
  switch (gap) {
    case 'none':
      return 'gap-0';
    case 'none':
      return 'gap-2';
    default:
      return 'gap-5';
  }
}

let getBorder = (border: Props['border']) => {
  switch (border) {
    case 'darkgrey':
      return 'border border-grey-8';
    case 'grey':
      return 'border border-grey-1';
    case 'top':
      return 'border-t border-grey-8';
    case 'right':
      return 'border border-grey-8 border-r-0';
    case 'left':
      return 'border border-grey-8 border-l-0';
    default:
      return 'border-none';
  }
}

let getBg = (bg: Props['bg']) => {
  switch (bg) {
    case 'grey':
      return 'bg-grey-9';
    default:
      return 'bg-white';
  }
}

let getCols = (cols: Props['cols']) => {
  switch (cols) {
    case 'span-3':
      return 'col-span-3';
    case 'span-2':
      return 'col-span-2';
    default:
      return '';
  }
}

let getMargin = (margin: Props['margin']) => {
  switch (margin) {
    case 'none':
      return 'm-0'
    case 'mt-4':
      return 'mt-4';
    default:
      return 'my-4';
  }
}

let getPadding = (padding: Props['padding']) => {
  switch (padding) {
    case 'none':
      return 'p-0';
    case 'px-8':
      return 'py-4 px-8';
    case 'py-4':
      return 'py-4';
    case 'p-2':
      return 'p-2';
    default:
      return 'p-4';
  }
}

let getRadius = (radius: Props['radius']) => {
  switch (radius) {
    case 'lg':
      return 'rounded-lg';
    case 'none':
      return 'rounded-none';
    default:
      return 'rounded';
  }
}


export const CenterContainer = ({ align, flex, children }: Props) => {
  return <div className={classnames("min-h-screen justify-center items-center",
    getAlign(align), getFlex(flex))}>
    <div className='centercontainer'>{children}</div>
  </div>
}

export const Container = ({ border, bg, cols, radius, padding, children }: Props) => {
  return <div className={classnames(
    getBorder(border), getBg(bg), getCols(cols), getRadius(radius), getPadding(padding)
  )}> {children}
  </div>
}


export const InputContainer = ({ border, flex, radius, margin, children }: Props) => {
  return <div className={classnames("w-full relative h-10 pr-0",
    getBorder(border), getFlex(flex), getRadius(radius), getMargin(margin))}>
    {children}
  </div>
}

export const MainContainer = ({ children }: Props) => {
  return <div className={classnames('main-content relative')}>
    {children}
  </div>
}

export const GridContainer = ({ grid, gap, padding, border, margin, children }: Props) => {
  return <div className={classnames("grid sm:grid-cols-1", getGrid(grid), getGap(gap), getPadding(padding),
    getBorder(border), getMargin(margin)
  )}>
    {children}
  </div>
}

export const ModalContainer = ({ bg, radius, children }: Props) => {
  return <div className={classnames("flex fixed inset-0 z-50 outline-none focus:outline-none items-center justify-center")}>
    <div className={classnames("lg:w-5/12 md:w-11/12")}>
      <div className={classnames("text-white shadow-lg relative flex flex-col w-full outline-none focus:outline-none",
        getBg(bg), getRadius(radius))}>
        {children}
      </div>
    </div>
  </div>
}

export const SubContainer = ({ border, margin, padding, children }: Props) => {
  return <div className={classnames("rounded-lg",
    getBorder(border), getMargin(margin), getPadding(padding)
  )}>
    {children}
  </div>
}