import { useDispatch } from 'react-redux';
import { Dispatch } from "../../../../store";
import edit_icon from '../../../../images/edit_icon.png';
import delete_icon from '../../../../images/delete_icon.png'
import ReactTooltip from 'react-tooltip';
import { useState } from 'react';
import DeleteModal from '../../../../components/Modals/DeleteModal';

const StatusCustomer = ({ id, ...props }: any) => {
    const dispatch = useDispatch<Dispatch>();

    const [deleteModal, setDeleteModal] = useState(false);

    return (
        <>
            <div className='flex my-auto'>
                <img src={edit_icon} placeholder={edit_icon}
                    className="status_icon" data-tip data-for='edit'
                    onClick={() => {
                        dispatch.UI.updateFormId({
                            target: "editExpressRate",
                            value: id
                        })
                    }}
                />
                <ReactTooltip id='edit' backgroundColor="#8d8d8d">
                    <span>Edit</span>
                </ReactTooltip>

                <img src={delete_icon} placeholder={delete_icon}
                    className="status_icon" data-tip data-for='delete'
                    onClick={() => setDeleteModal(true)}
                />
                <ReactTooltip id='delete' backgroundColor="#8d8d8d">
                    <span>Delete</span>
                </ReactTooltip>
            </div>

            {deleteModal && <DeleteModal setDeleteModal={setDeleteModal} id={id} dataToDelete='express_rate_card' />}
        </>
    )
}

export default StatusCustomer

