import { useState, useEffect } from 'react';
import { Span } from '../../../../components/common/Span';
import { Input } from '../../../../components/common/Input';
import { Button } from '../../../../components/common/Button';
import { Textarea } from '../../../../components/common/Textarea';
import { IoCloseSharp } from 'react-icons/io5';

import PostErrorAlert from '../../../../components/PostErrorAlert';
import BackDrop from '../../../../components/BackDrop';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../../store';

import '../../CSS/Rate.css';

import Switch from 'react-switch';
import { updateExpressRateCard, updateinterislandRateCard } from '../../../../common/api';

type Props = {
  setEditRate: Function;
};

export default ({ setEditRate, ...props }: Props) => {
  const dispatch = useDispatch<Dispatch>();

  const rateInterislandId = useSelector((state: RootState) => state.UI.forms.editInterislandRate.id);
  const card: any | [] = useSelector((state: RootState) => state.Table.tables.rate_interisland?.data).find((card) => card.rateInterislandId === rateInterislandId);

  const [openAlert, setOpenAlert] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  const [data, setData] = useState({
    name: card?.name,
    description: card?.description,
    isDefault: card?.isDefault,
  });

  const onChange = (e, name) => {
    setData({
      ...data,
      [name]: e.target.value,
    });
  };

  const isDataChange = () => {
    const originalData = Object.entries(card).map(
      (entry) => `${entry[0]} ${entry[1]}`
    );
    const updatedData = Object.entries(data).map(
      (entry) => `${entry[0]} ${entry[1]}`
    );
    const result = updatedData
      .map((d) => originalData.includes(d))
      .some((res) => res === false);
    return result;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isDataChange()) {
      setOpenAlert(true);
      setErrMessage('No changes made!');
      return;
    }
    try {
      dispatch.UI.setIsLoading(true);
      const res = await updateinterislandRateCard(rateInterislandId, data);
      if (res.isSuccess) {
        dispatch.UI.updateFormId({ target: 'editInterislandRate', value: null });
        dispatch.UI.setAlert({ message: 'Update rate card', type: 'Success' });
        dispatch.UI.setIsLoading(false);
      }
    } catch (err: any) {
      const errMsg =
        err?.data?.error === undefined ? 'Unable to update' : err.data.error;
      setOpenAlert(true);
      setErrMessage(errMsg);
      dispatch.UI.setIsLoading(false);
    }
  };

  return (
    <>
      <div className="side-rate">
        <BackDrop />
        <form onSubmit={handleSubmit}>
          <div className="flex py-4 border-b border-grey-6">
            <IoCloseSharp
              onClick={() => {
                dispatch.UI.updateFormId({
                  target: 'editInterislandRate',
                  value: null,
                });
              }}
              className="my-auto mx-2 text-lg cursor-pointer"
            />
            <Span weight="semi" size="lg">
              Update Rate
            </Span>
          </div>

          <div>
            <Span margin="mt-4">Name</Span>
            <Input
              required
              placeholder="Name"
              type="text"
              value={data?.name}
              id="name"
              onChange={(e) => onChange(e, 'name')}
            />
          </div>

          <div>
            <Span margin="mt-4">Description</Span>
            <Textarea
              placeholder="Description"
              value={data?.description}
              onChange={(e) => onChange(e, 'description')}
            />
          </div>

          <div className="flex justify-between">
            <Span margin="mt-4">Default Card</Span>

            <Switch
              onChange={() => setData({ ...data, isDefault: !data.isDefault })}
              checked={data?.isDefault}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#9A7F3E"
            />
          </div>

          <PostErrorAlert
            openAlert={openAlert}
            setOpenAlert={setOpenAlert}
            message={errMessage}
          />

          <div className="flex inset-x-0 bottom-0 py-4 text-center">
            <Button
              margin="mx-2"
              bg="white"
              border="lightgrey"
              color="black"
              onClick={() => {
                dispatch.UI.updateFormId({
                  target: 'editInterislandRate',
                  value: null,
                });
              }}
            >
              Cancel
            </Button>
            <Button margin="mx-2" type="submit">
              Update
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};
