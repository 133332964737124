import WalletRequestPage from '../containers/Wallet/WithdrawalRequest/WithdrawalRequest'  
import Header from '../components/Header/Header' 
import SidebarPage from '../components/Sidebar/Sidebar'
import { MainContainer } from '../components/common/Containers'
 
const WalletRequest = () => {
    return ( 
        <>  
            <SidebarPage />
            <MainContainer>
                <Header /> 
                <main> 
                    <WalletRequestPage/>  
                </main> 
            </MainContainer>
        </>
    )
}

export default WalletRequest
