import moment from "moment";
import React, { useState } from "react";
import { BsDot } from "react-icons/bs";
import { MdPayments } from "react-icons/md";
import { useDispatch } from "react-redux";
import { SpinnerCircularFixed } from "spinners-react";
import { uploadExpressPod } from "../../../../common/api";
import { Button } from "../../../../components/common/Button";
import { GridContainer } from "../../../../components/common/Containers";
import { Span } from "../../../../components/common/Span";
import {
  EXPRESS,
  PACKAGE_STATUS,
} from "../../../../components/constants/status";
import { Dispatch } from "../../../../store";
import AssignedDriver from "../../Modal/AssignedDriver";

function Stops({ expressId, dropOff, data, index }: any) {
  const dispatch = useDispatch<Dispatch>();
  const [isLoading, setIsLoading] = useState(false);
  const [assignedDriverModal, setAssignedDriverModal] = useState(false);
  const handleAssignedDriver = (e) => {
    e.preventDefault();
    setAssignedDriverModal(true);
  };

  const isAssignOrReAssign =
    data?.dropOff?.driver?.firstName != null && data?.dropOff?.driver?.lastName != null
      ? "Reassign Driver"
      : "Assign Driver";

  const handlePOD = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const file = e.target.files[0];
    const fileType = file.type;
    if (
      ![
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/svg",
        "image/webp",
      ].includes(fileType)
    ) {
      dispatch.UI.setAlert({ message: "Invalid file type!", type: "Error" });
      setIsLoading(false);
      return;
    }
    try {
      const res = await uploadExpressPod(expressId, dropOff?.id, file);
      if (res.isSuccess) {
        setIsLoading(false);
        dispatch.UI.setAlert({
          message: res?.message ?? "Successfully uploaded",
          type: "Success",
        });
      }
    } catch (err: any) {
      setIsLoading(false);
      const errMsg = err?.data?.error ?? "Unable to upload file";
      dispatch.UI.setAlert({ message: errMsg, type: "Error" });
    }
  };

  const datedelivered = new Date(dropOff?.deliveredAt?.seconds * 1000);
  const deliveredAt = moment(datedelivered).format("MMMM Do YYYY h:mm:ss a");

  return (
    <GridContainer grid="four" padding="px-8" gap="two">
      <div>
        <Span weight="semi"> Address: </Span>
        <Span>{dropOff?.location?.address}</Span>
        <p className="flex">
          <BsDot className="text-grey-11 my-auto" />
          <Span color="lightgrey" size="sm">
            {dropOff?.locationDetails}
          </Span>
        </p>
      </div>

      <div>
        <Span weight="semi"> Contact Person </Span>
        <Span>{dropOff?.contactName}</Span>
      </div>

      <div>
        <Span weight="semi"> Contact Number </Span>
        <Span>{dropOff?.contactPhone}</Span>
      </div>

      <div>
        {index === 0 && ["assigned_delivery", "transferred", "for_delivery", "ongoing_delivery", "delivered", "failed_delivery", "canceled"].includes(data?.status) && (
          <>
            {data?.dropOff?.driver?.firstName && (
              <>
                <Span
                  weight="semi"
                  color="brown"
                  transform="uppercase"
                >
                  Driver Details
                </Span>

                <Span weight="semi" margin="mt-4" size="xl">
                  {data?.dropOff?.driver?.firstName}&nbsp;
                  {data?.dropOff?.driver?.lastName}
                </Span>

                <Span margin="mt-4" color="grey">
                  Contact No:
                </Span>
                <Span>{data?.dropOff?.driver?.phoneNumber}</Span>
              </>
            )}

            {["assigned_delivery", "transferred"].includes(data?.status) && (
              <Button bg="green" margin="mt-4" onClick={handleAssignedDriver}>
                {isAssignOrReAssign}
              </Button>
            )}
          </>
        )}
      </div>

      <AssignedDriver
        setAssignedDriverModal={setAssignedDriverModal}
        assignedDriverModal={assignedDriverModal}
        orderId={expressId}
        isAssignOrReAssign={isAssignOrReAssign}
        vehicleType={data?.vehicleType}
      />

      {dropOff?.isReliableFP && (
        <div className="flex items-center gap-3 col-span-2">
          <Span color="red">
            <MdPayments />
          </Span>
          <Span color="red">Reliable For Payment</Span>
        </div>
      )}

      {/* <div>
        <Span weight="semi"> Item Category </Span>
        <Span>{dropOff?.itemCategory}</Span>

        <Span weight="semi" margin="mt-4">
          {" "}
          Driver Instruction{" "}
        </Span>
        <Span>{dropOff?.deliveryInstruction}</Span>
      </div> */}

      <div className="col-span-4">
        <Span weight="semi" margin="mt-4">
          {" "}
          Proof of Delivery:{" "}
        </Span>
        {isLoading ? (
          <SpinnerCircularFixed className="text-brown-1 h-6 w-6" />
        ) : (
          <>
            <a href={dropOff?.proofOfDelivery} target="_blank">
              <Span color="blue">{dropOff?.proofOfDelivery}</Span>
            </a>
            {dropOff?.proofOfDelivery == undefined && data?.status === "delivered" ? (
              <>
                <input
                  type="file"
                  name="file"
                  id="file"
                  onChange={handlePOD}
                  accept="image/*"
                />
                <label htmlFor="file" className="cursor-pointer">
                  <Span color="brown" weight="semi">
                    Upload POD
                  </Span>
                </label>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </div>

      <div className="col-span-4">
        {dropOff?.deliveredAt != undefined ? (
          <>
            <Span align="right"> Completed as of {deliveredAt}</Span>
          </>
        ) : (
          <></>
        )}
      </div>

      {/* <div>
            <Span weight='semi'> Package Status </Span>
            <Span color='brown'>{PACKAGE[data?.status]}</Span>  
        </div>  */}
    </GridContainer >
  );
}

export default Stops;
