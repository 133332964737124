import { STATUS } from '../../../components/constants/status'
import { Span } from '../../../components/common/Span'

import { ColumnFilter } from "./filter";   
import moment from 'moment';

export const COLUMNS = [
    {
        id: "bookingNumber",
        field: "bookingNumber",
        accessor: 'bookingNumber',
        Header: 'Booking Number',
        Filter: ColumnFilter, 
        isVisible: true,
    },
    {
        id: "trackingNumber",
        field: "trackingNumber",
        accessor: 'trackingNumber',
        Header: 'Tracking Number',
        Filter: ColumnFilter, 
        isVisible: true,
    },
    {
        id: "consigneeName",
        field: "consignee.consigneeName",
        accessor: 'consignee.consigneeName',
        Header: 'Consignee Name',
        Filter: ColumnFilter,
        isVisible: true,   
        Cell: data => {
            const d = data.row.original?.consignee;
            return( 
                <span className='capitalize'>
                    {d?.consigneeName}
                </span>   
            )
        }
    }, 
    {
        accessor: data => {
            const date = new Date(data?.createdAt?.seconds * 1000);
            const formatedDate = moment(date).format('MMMM Do YYYY, h:mm:ss a')
            return formatedDate;
        },
        id: "createdAt",
        field: "createdAt",
        Header: 'Date Created',
        isVisible: true,
        Cell: data => {
        const d = data.row.original;
        let date = null;
        if(d?.createdAt?.toDate){
            date = d?.createdAt?.toDate();
        }
        return <>{moment(date).format('MMMM Do YYYY, h:mm:ss a')}</>   }
    },  
    // {
    //     id: "consigneeCity",
    //     field: "consignee.locationInfo.administrative_area_2",
    //     accessor: 'consignee.locationInfo.administrative_area_2',
    //     Header: 'City',
    //     Filter: ColumnFilter,
    //     isVisible: true,        
    // }, 
    {
        id: "deliveryFee",
        field: "deliveryFee",
        accessor: 'deliveryFee',
        Header: 'Delivery Fee',
        Filter: ColumnFilter,
        isVisible: true,   
        Cell: data => {
            const d = data.row.original;
            const fee: any = d?.deliveryFee ?? 0;
            return (
            <span className='text-brown-1 text-base'> 
                ₱ {parseFloat(fee).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </span>    
            ); 
        }        
    }, 
    {
        accessor: 'status',
        id: "status",
        field: "status",
        Header: 'Status',
        Filter: ColumnFilter,
        isVisible: true, 
        Cell: data => {
            const d = data.row.original;
            return(
                <div className="flex justify-center whitespace-nowrap">
                    <Span border='red' bg='red' padding='px-4' width='full' margin='mx-4' color='red' radius='xl' 
                    size='sm' transform='capitalize'>
                        {STATUS[d?.status]}
                    </Span>  
                </div>
            )
        }
    }
]