import { Span } from '../../../../components/common/Span'  
import { GridContainer, Container } from '../../../../components/common/Containers' 

const Consignee = ({data}:any) => {
    return (
        <>
        <Span weight='semi' size='lg'>Consignee Information</Span>
            <GridContainer gap='none' padding='py-4'>
                <Container border='right' bg='grey' margin='none' radius='none' padding='p-2'>
                    <Span size='sm'>Consignee Name</Span>
                </Container>  
                <Container border='left' cols='span-2' radius='none' padding='p-2'> 
                    <Span transform='capitalize'>{data?.consignee?.consigneeName}</Span> 
                </Container>

                <Container border='right' bg='grey' margin='none' radius='none' padding='p-2'>
                    <Span size='sm'>Drop Off Area</Span>
                </Container>  
                <Container border='left' cols='span-2' radius='none' padding='p-2'> {data?.consignee?.dropOffArea} </Container>

                <Container border='right' bg='grey' margin='none' radius='none' padding='p-2'>
                    <Span size='sm'>Address</Span>
                </Container>  
                <Container border='left' cols='span-2' radius='none' padding='p-2'> {data?.consignee?.locationAddress}</Container>

                <Container border='right' bg='grey' margin='none' radius='none' padding='p-2'>
                    <Span size='sm'>Email</Span>
                </Container>  
                <Container border='left' cols='span-2' radius='none' padding='p-2'>{data?.consignee?.email} </Container>

                <Container border='right' bg='grey' margin='none' radius='none' padding='p-2'>
                    <Span size='sm'>Mobile</Span>
                </Container>  
                <Container border='left' cols='span-2' radius='none' padding='p-2'>{`${data?.consignee?.phoneNumber}`} </Container>
            </GridContainer>
        </>
  );
};

export default Consignee;