import { useEffect, useState, useRef } from 'react';
import PostErrorAlert from '../../../components/PostErrorAlert';
import BackDrop from '../../../components/BackDrop';
import { updateDriver } from '../../../common/api';
import { isChangeInDriverData, onlyNumberKey } from '../../../common/utils';
import { Span, SpanContainer } from '../../../components/common/Span';
import { Input } from '../../../components/common/Input';
import { Select } from '../../../components/common/Select';
import { Button } from '../../../components/common/Button';
import { InputContainer, Container } from '../../../components/common/Containers';
import { DRIVERGROUP, VEHICLETYPE } from '../../../components/constants/status';
import { RiErrorWarningFill } from 'react-icons/ri';
import '../CSS/Driver.css';
import { IoCloseSharp } from 'react-icons/io5';
import { BsPlusLg } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../store';
import Switch from '../../../components/Switch/Switch';
import { List } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import SingleInput from '../../../components/AutoComplete/SingleInput';
import { provinceData } from '../../../components/Location/location_json/province';
import { cityData } from '../../../components/Location/location_json/city';
import { area, clustercities, clusterData } from '../../../components/Location/location_json/cluster';
import Vehicle from '../Vehicle';

type Props = {
  setEditDriver: Function;
};

export default ({ setEditDriver, ...props }: Props) => {
  const dispatch = useDispatch<Dispatch>();

  const editDriverId: any = useSelector((state: RootState) => state.UI.forms.editDriver?.id);
  const drivers = useSelector((state: RootState) => state.Table.tables.drivers.data);
  const CDD = drivers?.find(d => d.id === editDriverId) //current driver details

  const [openAlert, setOpenAlert] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [cancel, setCancel] = useState(false);
  const [active, setActive] = useState(CDD?.systemStatus === 'active' ? true : false);

  const refSwitch: any = useRef(null);

  const [driverDetails, setDriverDetails] = useState({
    firstName: CDD?.firstName,
    middleName: CDD?.middleName,
    lastName: CDD?.lastName,
    phoneNumber: CDD?.phoneNumber?.slice(3),
    email: CDD?.email,
    province: CDD?.province,
    city: CDD?.city,
    address: CDD?.address,
    driverStatus: CDD?.driverStatus,
    EmergecyName: CDD?.EmergecyName,
    EmergencyContactNumber: CDD?.EmergencyContactNumber?.slice(3),
    driverVehicle: CDD?.driverVehicle ?? [],
    deliveryArea: {
      driverGroup: CDD?.deliveryArea?.driverGroup,
      cluster: CDD?.deliveryArea?.cluster,
      clusterCity: CDD?.deliveryArea?.clusterCity,
      clusterArea: CDD?.deliveryArea?.clusterArea,
    },
    driverLicenseRestriction: CDD.driverLicenseRestriction,
  }) as any;

  const isShowAddVehicle = driverDetails.driverVehicle?.length !== VEHICLETYPE?.length;
  const isShowDeleteVehicle = driverDetails.driverVehicle?.length > 1;

  const handleChange = (name: string, e: any, dest: any, index?: any) => {
    const value = e.target.value;
    switch (dest) {
      case 'none':
        setDriverDetails({
          ...driverDetails,
          [name]: value,
        });
        break;
      case 'driverVehicle':
        const payload = {
          ...driverDetails,
          [dest]: [...driverDetails[dest]?.map((v, i) => i === index ? { ...v, [name]: value } : (name === 'status' && value === 'active') ? { ...v, status: 'inactive' } : v)]
        }
        setDriverDetails(payload);
        break;
      default:
        setDriverDetails({
          ...driverDetails,
          [dest]: {
            ...driverDetails[dest],
            [name]: value,
          },
        });
        return;
    }
  };

  const addVehicle = (e) => {
    e.preventDefault();
    const newVehicle = {
      vehicleType: '',
      plateNumber: '',
      vehicleModel: '',
      status: '',
    }
    setDriverDetails({
      ...driverDetails,
      driverVehicle: [...driverDetails?.driverVehicle, newVehicle]
    })
  }

  const removeVehicle = (index) => {
    setDriverDetails({
      ...driverDetails,
      driverVehicle: [...driverDetails?.driverVehicle.filter((v, i) => i !== index)]
    })
  }

  const showError = () => {
    setErrMessage('No Changes made!');
    setOpenAlert(true);
  };

  const handleUpdateDriver = async (e) => {
    e.preventDefault();
    const isChange = isChangeInDriverData(CDD, driverDetails, active);
    const updatedDetails = {
      ...driverDetails,
      systemStatus: active ? 'active' : 'inactive',
    };

    if (!isChange) {
      return showError();
    }

    try {
      dispatch.UI.setIsLoading(true);
      const res = await updateDriver({ updatedDetails, editDriverId });
      if (res?.isSuccess) {
        dispatch.UI.updateFormId({ target: 'editDriver', value: null });
        dispatch.UI.setAlert({ message: 'Driver details updated', type: 'Success', });
        dispatch.UI.setIsLoading(false);
        dispatch.Table.getFirebaseData({ name: 'driver_profile', table: 'drivers' })
      }
    } catch (err: any) {
      const errMsg = err?.data?.error ?? 'Unable to update driver';
      dispatch.UI.setIsLoading(false);
      setErrMessage(errMsg);
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    if (active) {
      refSwitch.current.click();
    }
  }, []);

  return (
    <>
      {cancel && (
        <>
          <div className="popup_edit">
            <div className="flex">
              <RiErrorWarningFill className="text-xl my-auto text-orange-1" />
              <Span margin="mx-2">
                Discard changes made in the user information?
              </Span>
            </div>

            <div className="flex justify-end py-2">
              <Button
                margin="mx-2"
                bg="white"
                border="lightgrey"
                color="black"
                width="none"
                padding="px-2"
                radius="none"
                onClick={() => setCancel(false)}
              >
                Cancel
              </Button>
              <Button
                margin="mx-2"
                width="none"
                padding="px-2"
                radius="none"
                onClick={() => {
                  dispatch.UI.updateFormId({
                    target: 'editDriver',
                    value: null,
                  });
                }}
              >
                Discard
              </Button>
            </div>
          </div>
        </>
      )}
      <div className="side-parcel absolute inset-y-0 right-0 px-0">
        <BackDrop />
        <div className="flex py-2">
          <IoCloseSharp
            onClick={() => {
              dispatch.UI.updateFormId({
                target: 'editDriver',
                value: null,
              });
            }}
            className="my-auto mx-2 text-lg cursor-pointer"
          />
          <Span weight="semi" size="lg">
            Update Driver
          </Span>
        </div>

        <Container border="grey">
          <div className="flex justify-between">
            <Span weight="semi" color="brown" margin="my-auto">
              System Status
            </Span>

            <Switch
              refSwitch={refSwitch}
              active={active}
              setActive={setActive}
            />
          </div>

          <div className="flex justify-between mt-4">
            <Span weight="semi" color="brown" margin="my-auto">
              Driver Status
            </Span>

            <Span
              border={
                driverDetails?.driverStatus === 'active' ? 'green' : 'red'
              }
              bg={driverDetails?.driverStatus === 'active' ? 'green' : 'red'}
              padding="px-4"
              width="full"
              color={driverDetails?.driverStatus === 'active' ? 'green' : 'red'}
              radius="xl"
            >
              {driverDetails?.driverStatus === 'active' ? 'Active' : 'Inactive'}
            </Span>
          </div>
        </Container>

        <form onSubmit={handleUpdateDriver}>
          <div className="text-left py-4">
            <Span weight="semi" size="lg" transform="uppercase">
              Profile
            </Span>

            <Span margin="mt-4">First Name</Span>
            <Input
              placeholder="First Name"
              value={driverDetails.firstName}
              onChange={(e) => handleChange('firstName', e, 'none')}
              required
            />

            <Span margin="mt-4">Middle Name (Optional)</Span>
            <Input
              placeholder="Middle Name"
              value={driverDetails.middleName}
              onChange={(e) => handleChange('middleName', e, 'none')}
            />

            <Span margin="mt-4">Last Name</Span>
            <Input
              placeholder="Last Name"
              value={driverDetails.lastName}
              onChange={(e) => handleChange('lastName', e, 'none')}
              required
            />
          </div>

          <div className="text-left py-4">
            <Span weight="semi" size="lg" transform="uppercase">
              Contact Details
            </Span>

            <Span margin="mt-4">Phone Number</Span>
            <InputContainer margin="none">
              <SpanContainer padding="p-2" position="absolute">
                +63
              </SpanContainer>
              <Input
                padding="pl-10"
                type="text"
                maxLength={10}
                placeholder="Phone Number"
                value={driverDetails.phoneNumber}
                onChange={(e) => handleChange('phoneNumber', e, 'none')}
                required
                onKeyPress={onlyNumberKey}
              />
            </InputContainer>

            <Span margin="mt-4">Email</Span>
            <Input
              placeholder="Email"
              value={driverDetails.email}
              onChange={(e) => handleChange('email', e, 'none')}
              required
            />
          </div>

          <div className="text-left py-4">
            <Span weight="semi" size="lg" transform="uppercase">
              EMERGECY CONTACT
            </Span>

            <Span margin="mt-4">Name</Span>
            <Input
              placeholder="name"
              value={driverDetails.EmergecyName}
              onChange={(e) => handleChange('EmergecyName', e, 'none')}
              required
            />

            <Span margin="mt-4">Phone Number</Span>
            <InputContainer margin="none">
              <SpanContainer padding="p-2" position="absolute">
                +63
              </SpanContainer>
              <Input
                padding="pl-10"
                type="text"
                maxLength={10}
                placeholder="Contact Number"
                value={driverDetails.EmergencyContactNumber}
                onChange={(e) => handleChange('EmergencyContactNumber', e, 'none')}
                required
                onKeyPress={onlyNumberKey}
              />
            </InputContainer>
          </div>

          <div className="text-left py-4">
            <Span weight="semi" size="lg" transform="uppercase">
              Address
            </Span>

            <Span margin="mt-4">Province</Span>
            <SingleInput
              value={driverDetails?.province}
              onChange={(e) => handleChange('province', e, 'none')}
              options={provinceData}
              placeholder='Select Province...'
            />

            <Span margin="mt-4">City</Span>
            <SingleInput
              value={driverDetails?.city}
              onChange={(e) => handleChange('city', e, 'none')}
              options={cityData[driverDetails?.province] ?? []}
              placeholder='Select City...'
              disabled={driverDetails?.province === ''}
            />

            <Span margin="mt-4">Street, Brgy</Span>
            <Input
              placeholder="Street, Brgy"
              value={driverDetails.address}
              onChange={(e) => handleChange('address', e, 'none')}
              required
            />
          </div>

          <div className="text-left py-4">
            <Span weight="semi" size="lg" transform="uppercase">
              Delivery Details
            </Span>

            <Span margin="mt-4">Driver Group</Span>
            <InputContainer border='grey'>
              <Select
                margin='mr-3'
                border='none'
                defaultValue={driverDetails.deliveryArea.driverGroup}
                onChange={(e) => handleChange('driverGroup', e, 'deliveryArea')}
                required
              >
                {DRIVERGROUP?.map((type, index) => (
                  <option key={index} value={type.value}>
                    {type.name}
                  </option>
                ))}
              </Select>
            </InputContainer>

            <Span margin="mt-4">Cluster</Span>
            <SingleInput
              value={driverDetails?.deliveryArea?.cluster}
              onChange={(e) => handleChange('cluster', e, 'deliveryArea')}
              options={clusterData}
              placeholder='Select Cluster...'
            />

            <Span margin="mt-4">Area</Span>
            <SingleInput
              value={driverDetails?.deliveryArea?.clusterArea}
              onChange={(e) => handleChange('clusterArea', e, 'deliveryArea')}
              options={area[driverDetails?.deliveryArea?.cluster] ?? []}
              placeholder='Select Area...'
              disabled={driverDetails?.deliveryArea?.cluster == ''}
            />

            <Span margin="mt-4">City</Span>
            <SingleInput
              value={driverDetails?.deliveryArea?.clusterCity}
              onChange={(e) => handleChange('clusterCity', e, 'deliveryArea')}
              options={clustercities[driverDetails?.deliveryArea?.clusterArea] ?? []}
              placeholder='Select City...'
              disabled={driverDetails?.deliveryArea?.clusterArea === ''}
            />
          </div>

          <div className="text-left py-4">
            <Span weight="semi" size="lg" transform="uppercase">
              Registered Vehicle
            </Span>

            <Span margin="mt-4">Driver's License Restriction</Span>
            <Input
              value={driverDetails.driverLicenseRestriction}
              onChange={(e) =>
                handleChange('driverLicenseRestriction', e, 'none')
              }
              required
              onKeyPress={onlyNumberKey}
              maxLength={1}
            />
          </div>

          <Container border="grey">
            <div className="flex justify-between">
              <Span weight="semi" color="brown">
                Vehicle
              </Span>
              {/* <MdDelete className='text-red-2 border border-red-2 rounded-full text-2xl px-0.5'/> */}
            </div>

            {driverDetails?.driverVehicle?.map((vehicle, index) => (
              <Vehicle isShowDeleteVehicle={isShowDeleteVehicle} removeVehicle={removeVehicle} handleChange={handleChange} driverDetails={driverDetails} index={index} key={index} />
            ))}

            <div className='mt-4 border-t border-grey-1'>
              {isShowAddVehicle && (
                <Button width='none' bg='white' color='grey' border='lightgrey' margin='mt-4'
                  onClick={addVehicle}
                >
                  <div className='flex pr-2'>
                    <BsPlusLg className='my-auto mx-2' />
                    Add Vehicle
                  </div>
                </Button>
              )}
            </div>

          </Container>

          {CDD?.appDocs && (
            <div className='text-left py-4'>
              <Span weight='semi' size='lg' transform='uppercase'>
                DRIVER DOCUMENTS
              </Span>

              <List
                size='small'
                bordered={true}
                dataSource={Object.entries(CDD?.appDocs).map(([name, doc]) => { return { name, doc } })}
                renderItem={(item: any) => (
                  <a href={item?.doc} target='_blank' className=' text-black-2'>
                    <div className='flex flex-row justify-between items-center px-2 py-1 cursor-pointer hover:text-primary-main transition-all'>
                      <span className='text-base capitalize'>
                        {item?.name.replace(/([A-Z])/g, ' $1').trim()}
                      </span>
                      <LinkOutlined className='text-base' />
                    </div>
                  </a>
                )}
              />
            </div>
          )}

          <PostErrorAlert
            openAlert={openAlert}
            setOpenAlert={setOpenAlert}
            message={errMessage}
          />

          <div className="flex inset-x-0 bottom-0 py-4 text-center">
            <Button
              margin="mx-2"
              bg="white"
              border="lightgrey"
              color="black"
              onClick={() =>
                dispatch.UI.updateFormId({ target: 'editDriver', value: null })
              }
            >
              Cancel
            </Button>
            <Button margin="mx-2" type="submit">
              Update Driver
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};
