/* eslint-disable import/no-anonymous-default-export */
import { createAdminUser } from "../../common/api";
import { useState, useEffect, useRef } from "react";
import { Span, SpanContainer } from "../../components/common/Span";
import { Input } from "../../components/common/Input";
import { Button } from "../../components/common/Button";
import { InputContainer } from "../../components/common/Containers";
import PostErrorAlert from "../../components/PostErrorAlert";
import { Dispatch } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import BackDrop from "../../components/BackDrop";
import { onlyNumberKey } from "../../common/utils";

import { IoCloseSharp } from "react-icons/io5";
import { RiErrorWarningFill } from "react-icons/ri";
import { RootState } from "../../store";
import Switch from "../../components/Switch/Switch";

type Props = {
  setAddUser: Function;
};

export default ({ setAddUser, ...props }: Props) => {
  const [cancel, setCancel] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [active, setActive] = useState(true);

  const dispatch = useDispatch<Dispatch>();
  const refSwitch: any = useRef(null);

  const roles = useSelector(
    (state: RootState) => state.Table.tables.roles.data
  );
  // const roles_names = roles?.length > 0 && roles?.map(role => role.roleName);

  const [user, setUser] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    suffix: "",
    email: "",
    phoneNumber: "",
    role: "",
  });

  const handleChange = async (name: string, event: any) => {
    let value = event.target.value;
    let newUser = { ...user, [name]: value };
    setUser({ ...newUser });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      ...user,
      status: active ? "active" : "inactive",
      role: roles?.filter((Role) => Role?.id === user?.role)[0],
      roleId: user.role,
    };

    try {
      dispatch.UI.setIsLoading(true);
      const res = await createAdminUser(payload);
      if (res?.isSuccess) {
        dispatch.UI.setAlert({ message: "Admin user added!", type: "Success" });
        dispatch.UI.setIsLoading(false);
        setAddUser(false);
        dispatch.Table.getFirebaseData({ name: 'admin_profile', table: 'users' })
      }
    } catch (err: any) {
      const errMsg = err?.data?.error ?? "Unable to add user";
      dispatch.UI.setIsLoading(false);
      setOpenAlert(true);
      setErrMessage(errMsg);
    }
  };
  
  useEffect(() => {
    dispatch.Table.getFirebaseData({ name: "role", table: "roles" });
  }, []);

  useEffect(() => {
    if (active) {
      refSwitch?.current?.click();
    }
  }, []);

  return (
    <>
      {cancel && (
        <>
          <div className="popup_edit">
            <div className="flex">
              <RiErrorWarningFill className="text-xl my-auto text-orange-1" />
              <Span margin="mx-2">
                Discard changes made in the user information?
              </Span>
            </div>

            <div className="flex justify-end py-2">
              <Button
                margin="mx-2"
                bg="white"
                border="lightgrey"
                color="black"
                width="none"
                padding="px-2"
                radius="none"
                onClick={() => setCancel(false)}
              >
                Cancel
              </Button>
              <Button
                margin="mx-2"
                width="none"
                padding="px-2"
                radius="none"
                onClick={() => window.location.reload()}
              >
                Discard
              </Button>
            </div>
          </div>
        </>
      )}

      <div className="side absolute inset-y-0 right-0 px-0">
        <BackDrop />
        <div className="flex py-4 border-b border-grey-6">
          <IoCloseSharp
            // onClick={() => history.push('/users/')}
            onClick={() => setAddUser(false)}
            className="my-auto mx-2 text-lg cursor-pointer"
          />
          <Span weight="semi" size="lg">
            Add User
          </Span>
        </div>
        <Form className="mt-4" onSubmit={handleSubmit}>
          <div className="text-left py-4">
            <Span weight="semi" size="lg">
              Profile
            </Span>

            <Span margin="mt-4">First Name</Span>
            <Input
              onChange={(e) => handleChange("firstName", e)}
              placeholder="First Name"
              required
            />

            <Span margin="mt-4">Middle Name</Span>
            <Input
              placeholder="Middle Name (optional)"
              onChange={(e) => handleChange("middleName", e)}
            />

            <Span margin="mt-4">Last Name</Span>
            <Input
              onChange={(e) => handleChange("lastName", e)}
              placeholder="Last Name"
              required
            />

            <Span margin="mt-4">Suffix</Span>
            <Input
              placeholder="Suffix (optional)"
              onChange={(e) => handleChange("suffix", e)}
            />
          </div>

          <div className="text-left py-4 mt-2">
            <Span weight="semi" size="lg">
              Contact Details
            </Span>

            <Span margin="mt-4">Phone Number</Span>
            <InputContainer margin="mt-4">
              <SpanContainer padding="p-2" position="absolute">
                +63
              </SpanContainer>
              <Input
                padding="pl-10"
                type="phone"
                maxLength={10}
                minLength={10}
                placeholder="Phone Number"
                onChange={(e) => handleChange("phoneNumber", e)}
                required
                onKeyPress={onlyNumberKey}
              />
            </InputContainer>

            <Span margin="mt-4">Email Address</Span>
            <Input
              onChange={(e) => handleChange("email", e)}
              type="email"
              placeholder="Email Address"
              required
            />

            <Span margin="mt-4">Role</Span>
            <select
              className="w-full rounded text-base focus:outline-none border border-grey-1 p-2 text-grey-7"
              onChange={(e) => handleChange("role", e)}
            >
              <option disabled selected>
                Choose role
              </option>
              {(roles || []).map((role, index) => (
                <option value={role?.id} key={index}>
                  {role?.roleName}
                </option>
              ))}
            </select>

            <PostErrorAlert
              openAlert={openAlert}
              setOpenAlert={setOpenAlert}
              message={errMessage}
            />

            <Span margin="mt-4">Status</Span>

            {/* <Span margin='mt-4' border='red' color='red' padding='p-2' radius='rounded'>Inactive</Span> */}
            <Switch
              refSwitch={refSwitch}
              active={active}
              setActive={setActive}
            />
          </div>

          <div className="flex inset-x-0 bottom-0 py-4">
            <Button
              margin="mx-2"
              bg="white"
              border="lightgrey"
              color="black"
              onClick={() => setAddUser(false)}
            >
              Cancel
            </Button>
            <Button margin="mx-2" type="submit">
              Add User
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
