import { useEffect, useReducer, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import { Span } from '../../../components/common/Span';
import { Input } from '../../../components/common/Input';
import { Button } from '../../../components/common/Button';
import { onlyNumberKey, toObject } from '../../../common/utils';
import {
  getUserCredentials,
  updateCustomer,
  updateRole,
  verifyCustomerSetPassword,
} from '../../../common/api';

import PostErrorAlert from '../../../components/PostErrorAlert';
import BackDrop from '../../../components/BackDrop';
import '../Customer.css';
import { IoCloseSharp } from 'react-icons/io5';
import { RiErrorWarningFill } from 'react-icons/ri';

import Loader from '../../../components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../store';
import moment from 'moment';
import Switch from '../../../components/Switch/Switch';
import Permission from '../../../components/Permissions/Permission';

type Props = {
  setEditRole: Function;
};

const reducer = (permissions, action) => {
  switch (action.type) {
    case 'ADD_PERMISSION':
      return [...permissions, action.payload];
    case 'DELETE_PERMISSION':
      console.log(action.payload);
      return [...permissions.filter((r) => r.name !== action?.payload?.name)];
    case 'ADD_ACTION':
      return [
        ...permissions.map((f) =>
          f.name === action?.payload?.name
            ? { ...f, action: [...f?.action, action?.payload?.data] }
            : f
        ),
      ];
    case 'DELETE_ACTION':
      return [
        ...permissions.map((f) =>
          f.name === action?.payload?.name
            ? {
              ...f,
              action: [
                ...f?.action.filter(
                  (a) => a.name !== action?.payload?.data.name
                ),
              ],
            }
            : f
        ),
      ];
    default:
      return [...permissions];
  }
};

export default ({ setEditRole, ...props }: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const refSwitch: any = useRef(null);
  const editRoleId: any = useSelector(
    (state: RootState) => state.UI.forms.editRole?.id ?? null
  );
  const roles = useSelector(
    (state: RootState) => state.Table.tables.roles.data
  );
  const data =
    roles.length > 0 && roles?.filter((role: any) => role.id === editRoleId)[0];

  const [openAlert, setOpenAlert] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [active, setActive] = useState(
    data?.status === 'active' ? true : false
  );
  const [permissions, setPermissions] = useReducer(reducer, []) as any;

  const objectpermissions = toObject(permissions);

  const [updatedRole, setUpdatedRole] = useState({
    roleName: data?.roleName ?? '',
    roleDescription: data?.roleDescription ?? '',
  });

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    setUpdatedRole({
      ...updatedRole,
      [name]: value,
    });
  };

  // const showError = () => {
  //     setErrMessage('No Changes made!');
  //     setOpenAlert(true);
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      ...updatedRole,
      status: active ? 'active' : 'inactive',
      permissions: objectpermissions,
    };
    try {
      dispatch.UI.setIsLoading(true);
      const res = await updateRole({ id: editRoleId, payload });
      if (res.isSuccess) {
        const timeout = setTimeout(() => {
          dispatch.UI.updateFormId({ target: 'editRole', value: null });
          dispatch.UI.setAlert({ message: 'Role updated', type: 'Success' });
          dispatch.Table.getFirebaseData({ name: 'role', table: 'roles' })
          dispatch.UI.setIsLoading(false);
          clearTimeout(timeout)
        }, 1000)
      }
    } catch (err: any) {
      let errMsg = err?.data?.error ?? 'Unable to update role';
      dispatch.UI.setIsLoading(false);
      setOpenAlert(true);
      setErrMessage(errMsg);
    }
  };

  useEffect(() => {
    if (active) {
      refSwitch?.current?.click();
    }
  }, []);

  return (
    <>
      <div className="side absolute inset-y-0 right-0 px-0">
        <BackDrop />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <form onSubmit={handleSubmit}>
              <div className="flex py-2 border-b border-grey-6">
                <IoCloseSharp
                  onClick={() => {
                    dispatch.UI.updateFormId({
                      target: 'editRole',
                      value: null,
                    });
                  }}
                  className="my-auto mx-2 text-lg cursor-pointer"
                />
                <Span weight="semi" size="lg">
                  Update Role
                </Span>
              </div>

              <div className="text-left py-4 mb-32">
                <Span weight="semi" size="lg">
                  Role Information
                </Span>

                <Span margin="mt-4">Role Name</Span>
                <Input
                  placeholder="Role Name"
                  value={updatedRole?.roleName}
                  name="roleName"
                  onChange={handleChange}
                  required
                />

                <Span margin="mt-4">Role Description</Span>
                <Input
                  placeholder="Description"
                  value={updatedRole?.roleDescription}
                  name="roleDescription"
                  onChange={handleChange}
                  required
                />

                <div className="flex justify-between mt-8">
                  <Span weight="semi" margin="my-auto">
                    Status
                  </Span>

                  <Switch
                    refSwitch={refSwitch}
                    active={active}
                    setActive={setActive}
                  />
                </div>

                <Permission
                  permissions={permissions}
                  setPermissions={setPermissions}
                  currentPermissions={data?.permissions}
                />

                <PostErrorAlert
                  openAlert={openAlert}
                  setOpenAlert={setOpenAlert}
                  message={errMessage}
                />
              </div>

              <div className="flex inset-x-0 bottom-0 py-4">
                <Button
                  margin="mx-2"
                  bg="white"
                  border="lightgrey"
                  color="black"
                  onClick={() => {
                    dispatch.UI.updateFormId({
                      target: 'editRole',
                      value: null,
                    });
                  }}
                >
                  Cancel
                </Button>
                <Button margin="mx-2" type="submit">
                  Update Role
                </Button>
              </div>
            </form>
          </>
        )}
      </div>

      {edit && (
        <>
          <div className="popup_edit">
            <div className="flex">
              <RiErrorWarningFill className="text-xl my-auto text-orange-1 cursor-pointer" />
              <Span margin="mx-2">
                Discard changes made in the user information?
              </Span>
            </div>

            <div className="flex justify-end py-2">
              <Button
                margin="mx-2"
                bg="white"
                border="lightgrey"
                color="black"
                width="none"
                padding="px-2"
                radius="none"
                onClick={() => setEdit(false)}
              >
                Cancel
              </Button>
              {/* Link to='onClick={() => history.push("/customers/")} */}
              <Button
                margin="mx-2"
                width="none"
                padding="px-2"
                radius="none"
                onClick={() => window.location.reload()}
              >
                Discard
              </Button>
              {/* </Link> */}
            </div>
          </div>
        </>
      )}
    </>
  );
};
