export const cityData = {
  'Ilocos Norte': [
    'Adams',
    'Bacarra',
    'Badoc',
    'Bangui',
    'Batac',
    'Burgos',
    'Carasi',
    'Currimao',
    'Dingras',
    'Dumalneg',
    'Banna (Espiritu)',
    'Laoag City',
    'Marcos',
    'Nueva Era',
    'Pagudpud',
    'Paoay',
    'Pasuquin',
    'Piddig',
    'Pinili',
    'San Nicolas',
    'Sarrat',
    'Solsona',
    'Vintar',
  ],
  'Ilocos Sur': [
    'Alilem',
    'Banayoyo',
    'Bantay',
    'Burgos',
    'Cabugao',
    'Candon',
    'Caoayan',
    'Cervantes',
    'Galimuyod',
    'Gregorio Del Pilar (Concepcion)',
    'Lidlidda',
    'Magsingal',
    'Nagbukel',
    'Narvacan',
    'Quirino (Angkaki)',
    'Salcedo (Baugen)',
    'San Emilio',
    'San Esteban',
    'San Ildefonso',
    'San Juan (Lapog)',
    'San Vicente',
    'Santa',
    'Santa Catalina',
    'Santa Cruz',
    'Santa Lucia',
    'Santa Maria',
    'Santiago',
    'Santo Domingo',
    'Sigay',
    'Sinait',
    'Sugpon',
    'Suyo',
    'Tagudin',
    'Vigan',
  ],
  'La Union': [
    'Agoo',
    'Aringay',
    'Bacnotan',
    'Bagulin',
    'Balaoan',
    'Bangar',
    'Bauang',
    'Burgos',
    'Caba',
    'Luna',
    'Naguilian',
    'Pugo',
    'Rosario',
    'San Fernando',
    'San Gabriel',
    'San Juan',
    'Santo Tomas',
    'Santol',
    'Sudipen',
    'Tubao',
  ],
  Pangasinan: [
    'Agno',
    'Aguilar',
    'Alaminos',
    'Alcala',
    'Anda',
    'Asingan',
    'Balungao',
    'Bani',
    'Basista',
    'Bautista',
    'Bayambang',
    'Binalonan',
    'Binmaley',
    'Bolinao',
    'Bugallon',
    'Burgos',
    'Calasiao',
    'Dagupan City',
    'Dasol',
    'Infanta',
    'Labrador',
    'Lingayen',
    'Mabini',
    'Malasiqui',
    'Manaoag',
    'Mangaldan',
    'Mangatarem',
    'Mapandan',
    'Natividad',
    'Pozorrubio',
    'Rosales',
    'San Carlos City',
    'San Fabian',
    'San Jacinto',
    'San Manuel',
    'San Nicolas',
    'San Quintin',
    'Santa Barbara',
    'Santa Maria',
    'Santo Tomas',
    'Sison',
    'Sual',
    'Tayug',
    'Umingan',
    'Urbiztondo',
    'Urdaneta',
    'Villasis',
    'Laoac',
  ],
  Batanes: ['Basco', 'Itbayat', 'Ivana', 'Mahatao', 'Sabtang', 'Uyugan'],
  Cagayan: [
    'Abulug',
    'Alcala',
    'Allacapan',
    'Amulung',
    'Aparri',
    'Baggao',
    'Ballesteros',
    'Buguey',
    'Calayan',
    'Camalaniugan',
    'Claveria',
    'Enrile',
    'Gattaran',
    'Gonzaga',
    'Iguig',
    'Lal-lo',
    'Lasam',
    'Pamplona',
    'Peñablanca',
    'Piat',
    'Rizal',
    'Sanchez-mira',
    'Santa Ana',
    'Santa Praxedes',
    'Santa Teresita',
    'Santo Niño (Faire)',
    'Solana',
    'Tuao',
    'Tuguegarao City',
  ],
  Isabela: [
    'Alicia',
    'Angadanan',
    'Aurora',
    'Benito Soliven',
    'Burgos',
    'Cabagan',
    'Cabatuan',
    'Cauayan',
    'Cordon',
    'Dinapigue',
    'Divilacan',
    'Echague',
    'Gamu',
    'Ilagan City',
    'Jones',
    'Luna',
    'Maconacon',
    'Delfin Albano (Magsaysay)',
    'Mallig',
    'Naguilian',
    'Palanan',
    'Quezon',
    'Quirino',
    'Ramon',
    'Reina Mercedes',
    'Roxas',
    'San Agustin',
    'San Guillermo',
    'San Isidro',
    'San Manuel',
    'San Mariano',
    'San Mateo',
    'San Pablo',
    'Santa Maria',
    'Santiago',
    'Santo Tomas',
    'Tumauini',
  ],
  'Nueva Vizcaya': [
    'Ambaguio',
    'Aritao',
    'Bagabag',
    'Bambang',
    'Bayombong',
    'Diadi',
    'Dupax Del Norte',
    'Dupax Del Sur',
    'Kasibu',
    'Kayapa',
    'Quezon',
    'Santa Fe',
    'Solano',
    'Villaverde',
    'Alfonso Castaneda',
  ],
  Quirino: [
    'Aglipay',
    'Cabarroguis',
    'Diffun',
    'Maddela',
    'Saguday',
    'Nagtipunan',
  ],
  Bataan: [
    'Abucay',
    'Bagac',
    'Balanga',
    'Dinalupihan',
    'Hermosa',
    'Limay',
    'Mariveles',
    'Morong',
    'Orani',
    'Orion',
    'Pilar',
    'Samal',
  ],
  Bulacan: [
    'Angat',
    'Balagtas (Bigaa)',
    'Baliuag',
    'Bocaue',
    'Bulacan',
    'Bustos',
    'Calumpit',
    'Guiguinto',
    'Hagonoy',
    'Malolos',
    'Marilao',
    'Meycauayan',
    'Norzagaray',
    'Obando',
    'Pandi',
    'Paombong',
    'Plaridel',
    'Pulilan',
    'San Ildefonso',
    'San Jose Del Monte',
    'San Miguel',
    'San Rafael',
    'Santa Maria',
    'Doña Remedios Trinidad',
  ],
  'Nueva Ecija': [
    'Aliaga',
    'Bongabon',
    'Cabanatuan City',
    'Cabiao',
    'Carranglan',
    'Cuyapo',
    'Gabaldon (Bitulok & Sabani)',
    'Gapan',
    'General Mamerto Natividad',
    'General Tinio (Papaya)',
    'Guimba',
    'Jaen',
    'Laur',
    'Licab',
    'Llanera',
    'Lupao',
    'Science City of Muñoz',
    'Nampicuan',
    'Palayan City',
    'Pantabangan',
    'Peñaranda',
    'Quezon',
    'Rizal',
    'San Antonio',
    'San Isidro',
    'San Jose City',
    'San Leonardo',
    'Santa Rosa',
    'Santo Domingo',
    'Talavera',
    'Talugtug',
    'Zaragoza',
  ],
  Pampanga: [
    'Angeles City',
    'Apalit',
    'Arayat',
    'Bacolor',
    'Candaba',
    'Floridablanca',
    'Guagua',
    'Lubao',
    'Mabalacat City',
    'Macabebe',
    'Magalang',
    'Masantol',
    'Mexico',
    'Minalin',
    'Porac',
    'San Fernando',
    'San Luis',
    'San Simon',
    'Santa Ana',
    'Santa Rita',
    'Santo Tomas',
    'Sasmuan (Sexmoan)',
  ],
  Tarlac: [
    'Anao',
    'Bamban',
    'Camiling',
    'Capas',
    'Concepcion',
    'Gerona',
    'La Paz',
    'Mayantoc',
    'Moncada',
    'Paniqui',
    'Pura',
    'Ramos',
    'San Clemente',
    'San Manuel',
    'Santa Ignacia',
    'Tarlac',
    'Victoria',
    'San Jose',
  ],
  Zambales: [
    'Botolan',
    'Cabangan',
    'Candelaria',
    'Castillejos',
    'Iba',
    'Masinloc',
    'Olongapo City',
    'Palauig',
    'San Antonio',
    'San Felipe',
    'San Marcelino',
    'San Narciso',
    'Santa Cruz',
    'Subic',
  ],
  Aurora: [
    'Baler',
    'Casiguran',
    'Dilasag',
    'Dinalungan',
    'Dingalan',
    'Dipaculao',
    'Maria Aurora',
    'San Luis',
  ],
  Batangas: [
    'Agoncillo',
    'Alitagtag',
    'Balayan',
    'Balete',
    'Batangas City',
    'Bauan',
    'Calaca',
    'Calatagan',
    'Cuenca',
    'Ibaan',
    'Laurel',
    'Lemery',
    'Lian',
    'Lipa City',
    'Lobo',
    'Mabini',
    'Malvar',
    'Mataasnakahoy',
    'Nasugbu',
    'Padre Garcia',
    'Rosario',
    'San Jose',
    'San Juan',
    'San Luis',
    'San Nicolas',
    'San Pascual',
    'Santa Teresita',
    'Santo Tomas',
    'Taal',
    'Talisay',
    'Tanauan',
    'Taysan',
    'Tingloy',
    'Tuy',
  ],
  Cavite: [
    'Alfonso',
    'Amadeo',
    'Bacoor City',
    'Carmona',
    'Cavite City',
    'Dasmariñas',
    'General Emilio Aguinaldo',
    'General Trias',
    'Imus City',
    'Indang',
    'Kawit',
    'Magallanes',
    'Maragondon',
    'Mendez (Mendez-nuñez)',
    'Naic',
    'Noveleta',
    'Rosario',
    'Silang',
    'Tagaytay City',
    'Tanza',
    'Ternate',
    'Trece Martires City',
    'Gen. Mariano Alvarez',
  ],
  Laguna: [
    'Alaminos',
    'Bay',
    'Biñan',
    'Cabuyao City',
    'Calamba',
    'Calauan',
    'Cavinti',
    'Famy',
    'Kalayaan',
    'Liliw',
    'Los Baños',
    'Luisiana',
    'Lumban',
    'Mabitac',
    'Magdalena',
    'Majayjay',
    'Nagcarlan',
    'Paete',
    'Pagsanjan',
    'Pakil',
    'Pangil',
    'Pila',
    'Rizal',
    'San Pablo City',
    'San Pedro',
    'Santa Cruz',
    'Santa Maria',
    'Santa Rosa',
    'Siniloan',
    'Victoria',
  ],
  Quezon: [
    'Agdangan',
    'Alabat',
    'Atimonan',
    'Buenavista',
    'Burdeos',
    'Calauag',
    'Candelaria',
    'Catanauan',
    'Dolores',
    'General Luna',
    'General Nakar',
    'Guinayangan',
    'Gumaca',
    'Infanta',
    'Jomalig',
    'Lopez',
    'Lucban',
    'Lucena City',
    'Macalelon',
    'Mauban',
    'Mulanay',
    'Manila City',
    'Padre Burgos',
    'Pagbilao',
    'Panukulan',
    'Patnanungan',
    'Perez',
    'Pitogo',
    'Plaridel',
    'Polillo',
    'Quezon',
    'Real',
    'Sampaloc',
    'San Andres',
    'San Antonio',
    'San Francisco (Aurora)',
    'San Narciso',
    'Sariaya',
    'Tagkawayan',
    'Tayabas',
    'Tiaong',
    'Unisan',
  ],
  Rizal: [
    'Angono',
    'Antipolo',
    'Baras',
    'Binangonan',
    'Cainta',
    'Cardona',
    'Jala-jala',
    'Rodriguez (Montalban)',
    'Morong',
    'Pililla',
    'San Mateo',
    'Tanay',
    'Taytay',
    'Teresa',
  ],
  Marinduque: [
    'Boac',
    'Buenavista',
    'Gasan',
    'Mogpog',
    'Santa Cruz',
    'Torrijos',
  ],
  'Occidental Mindoro': [
    'Abra De Ilog',
    'Calintaan',
    'Looc',
    'Lubang',
    'Magsaysay',
    'Mamburao',
    'Paluan',
    'Rizal',
    'Sablayan',
    'San Jose',
    'Santa Cruz',
  ],
  'Oriental Mindoro': [
    'Baco',
    'Bansud',
    'Bongabong',
    'Bulalacao (San Pedro)',
    'Calapan',
    'Gloria',
    'Mansalay',
    'Naujan',
    'Pinamalayan',
    'Pola',
    'Puerto Galera',
    'Roxas',
    'San Teodoro',
    'Socorro',
    'Victoria',
  ],
  Palawan: [
    'Aborlan',
    'Agutaya',
    'Araceli',
    'Balabac',
    'Bataraza',
    "Brooke's Point",
    'Busuanga',
    'Cagayancillo',
    'Coron',
    'Cuyo',
    'Dumaran',
    'El Nido (Bacuit)',
    'Linapacan',
    'Magsaysay',
    'Narra',
    'Puerto Princesa City',
    'Quezon',
    'Roxas',
    'San Vicente',
    'Taytay',
    'Kalayaan',
    'Culion',
    'Rizal (Marcos)',
    'Sofronio Española',
  ],
  Romblon: [
    'Alcantara',
    'Banton',
    'Cajidiocan',
    'Calatrava',
    'Concepcion',
    'Corcuera',
    'Looc',
    'Magdiwang',
    'Odiongan',
    'Romblon',
    'San Agustin',
    'San Andres',
    'San Fernando',
    'San Jose',
    'Santa Fe',
    'Ferrol',
    'Santa Maria (Imelda)',
  ],
  Albay: [
    'Bacacay',
    'Camalig',
    'Daraga (Locsin)',
    'Guinobatan',
    'Jovellar',
    'Legazpi City',
    'Libon',
    'Ligao',
    'Malilipot',
    'Malinao',
    'Manito',
    'Oas',
    'Pio Duran',
    'Polangui',
    'Rapu-rapu',
    'Santo Domingo (Libog)',
    'Tabaco',
    'Tiwi',
  ],
  'Camarines Norte': [
    'Basud',
    'Capalonga',
    'Daet',
    'San Lorenzo Ruiz (Imelda)',
    'Jose Panganiban',
    'Labo',
    'Mercedes',
    'Paracale',
    'San Vicente',
    'Santa Elena',
    'Talisay',
    'Vinzons',
  ],
  'Camarines Sur': [
    'Baao',
    'Balatan',
    'Bato',
    'Bombon',
    'Buhi',
    'Bula',
    'Cabusao',
    'Calabanga',
    'Camaligan',
    'Canaman',
    'Caramoan',
    'Del Gallego',
    'Gainza',
    'Garchitorena',
    'Goa',
    'Iriga City',
    'Lagonoy',
    'Libmanan',
    'Lupi',
    'Magarao',
    'Milaor',
    'Minalabac',
    'Nabua',
    'Naga City',
    'Ocampo',
    'Pamplona',
    'Pasacao',
    'Pili',
    'Presentacion (Parubcan)',
    'Ragay',
    'Sagñay',
    'San Fernando',
    'San Jose',
    'Sipocot',
    'Siruma',
    'Tigaon',
    'Tinambac',
  ],
  Catanduanes: [
    'Bagamanoc',
    'Baras',
    'Bato',
    'Caramoran',
    'Gigmoto',
    'Pandan',
    'Panganiban',
    'San Andres',
    'San Miguel',
    'Viga',
    'Virac',
  ],
  Masbate: [
    'Aroroy',
    'Baleno',
    'Balud',
    'Batuan',
    'Cataingan',
    'Cawayan',
    'Claveria',
    'Dimasalang',
    'Esperanza',
    'Mandaon',
    'Masbate',
    'Milagros',
    'Mobo',
    'Monreal',
    'Palanas',
    'Pio V. Corpuz (Limbuhan)',
    'Placer',
    'San Fernando',
    'San Jacinto',
    'San Pascual',
    'Uson',
  ],
  Sorsogon: [
    'Barcelona',
    'Bulan',
    'Bulusan',
    'Casiguran',
    'Castilla',
    'Donsol',
    'Gubat',
    'Irosin',
    'Juban',
    'Magallanes',
    'Matnog',
    'Pilar',
    'Prieto Diaz',
    'Santa Magdalena',
    'Sorsogon',
  ],
  Aklan: [
    'Altavas',
    'Balete',
    'Banga',
    'Batan',
    'Buruanga',
    'Ibajay',
    'Kalibo',
    'Lezo',
    'Libacao',
    'Madalag',
    'Makato',
    'Malay',
    'Malinao',
    'Nabas',
    'New Washington',
    'Numancia',
    'Tangalan',
  ],
  Antique: [
    'Anini-y',
    'Barbaza',
    'Belison',
    'Bugasong',
    'Caluya',
    'Culasi',
    'Tobias Fornier (Dao)',
    'Hamtic',
    'Laua-an',
    'Libertad',
    'Pandan',
    'Patnongon',
    'San Jose',
    'San Remigio',
    'Sebaste',
    'Sibalom',
    'Tibiao',
    'Valderrama',
  ],
  Capiz: [
    'Cuartero',
    'Dao',
    'Dumalag',
    'Dumarao',
    'Ivisan',
    'Jamindan',
    'Ma-ayon',
    'Mambusao',
    'Panay',
    'Panitan',
    'Pilar',
    'Pontevedra',
    'President Roxas',
    'Roxas City',
    'Sapi-an',
    'Sigma',
    'Tapaz',
  ],
  Iloilo: [
    'Ajuy',
    'Alimodian',
    'Anilao',
    'Badiangan',
    'Balasan',
    'Banate',
    'Barotac Nuevo',
    'Barotac Viejo',
    'Batad',
    'Bingawan',
    'Cabatuan',
    'Calinog',
    'Carles',
    'Concepcion',
    'Dingle',
    'Dueñas',
    'Dumangas',
    'Estancia',
    'Guimbal',
    'Igbaras',
    'Iloilo City',
    'Janiuay',
    'Lambunao',
    'Leganes',
    'Lemery',
    'Leon',
    'Maasin',
    'Miagao',
    'Mina',
    'New Lucena',
    'Oton',
    'Passi',
    'Pavia',
    'Pototan',
    'San Dionisio',
    'San Enrique',
    'San Joaquin',
    'San Miguel',
    'San Rafael',
    'Santa Barbara',
    'Sara',
    'Tigbauan',
    'Tubungan',
    'Zarraga',
  ],
  'Negros Occidental': [
    'Bacolod City',
    'Bago City',
    'Binalbagan',
    'Cadiz City',
    'Calatrava',
    'Candoni',
    'Cauayan',
    'Enrique B. Magalona (Saravia)',
    'Escalante',
    'Himamaylan',
    'Hinigaran',
    'Hinoba-an (Asia)',
    'Ilog',
    'Isabela',
    'Kabankalan',
    'La Carlota City',
    'La Castellana',
    'Manapla',
    'Moises Padilla (Magallon)',
    'Murcia',
    'Pontevedra',
    'Pulupandan',
    'Sagay City',
    'San Carlos City',
    'San Enrique',
    'Silay City',
    'Sipalay',
    'Talisay',
    'Toboso',
    'Valladolid',
    'Victorias',
    'Salvador Benedicto',
  ],
  Guimaras: [
    'Buenavista',
    'Jordan',
    'Nueva Valencia',
    'San Lorenzo',
    'Sibunag',
  ],
  Bohol: [
    'Alburquerque',
    'Alicia',
    'Anda',
    'Antequera',
    'Baclayon',
    'Balilihan',
    'Batuan',
    'Bilar',
    'Buenavista',
    'Calape',
    'Candijay',
    'Carmen',
    'Catigbian',
    'Clarin',
    'Corella',
    'Cortes',
    'Dagohoy',
    'Danao',
    'Dauis',
    'Dimiao',
    'Duero',
    'Garcia Hernandez',
    'Guindulman',
    'Inabanga',
    'Jagna',
    'Jetafe',
    'Lila',
    'Loay',
    'Loboc',
    'Loon',
    'Mabini',
    'Maribojoc',
    'Panglao',
    'Pilar',
    'Pres. Carlos P. Garcia (Pitogo)',
    'Sagbayan (Borja)',
    'San Isidro',
    'San Miguel',
    'Sevilla',
    'Sierra Bullones',
    'Sikatuna',
    'Tagbilaran City',
    'Talibon',
    'Trinidad',
    'Tubigon',
    'Ubay',
    'Valencia',
    'Bien Unido',
  ],
  Cebu: [
    'Alcantara',
    'Alcoy',
    'Alegria',
    'Aloguinsan',
    'Argao',
    'Asturias',
    'Badian',
    'Balamban',
    'Bantayan',
    'Barili',
    'Bogo',
    'Boljoon',
    'Borbon',
    'Carcar',
    'Carmen',
    'Catmon',
    'Cebu City',
    'Compostela',
    'Consolacion',
    'Cordova',
    'Daanbantayan',
    'Dalaguete',
    'Danao City',
    'Dumanjug',
    'Ginatilan',
    'Lapu-lapu City (Opon)',
    'Liloan',
    'Madridejos',
    'Malabuyoc',
    'Mandaue City',
    'Medellin',
    'Minglanilla',
    'Moalboal',
    'Naga',
    'Oslob',
    'Pilar',
    'Pinamungahan',
    'Poro',
    'Ronda',
    'Samboan',
    'San Fernando',
    'San Francisco',
    'San Remigio',
    'Santa Fe',
    'Santander',
    'Sibonga',
    'Sogod',
    'Tabogon',
    'Tabuelan',
    'Talisay',
    'Toledo City',
    'Tuburan',
    'Tudela',
  ],
  'Negros Oriental': [
    'Amlan (Ayuquitan)',
    'Ayungon',
    'Bacong',
    'Bais City',
    'Basay',
    'Bayawan (Tulong)',
    'Bindoy (Payabon)',
    'Canlaon City',
    'Dauin',
    'Dumaguete City',
    'Guihulngan',
    'Jimalalud',
    'La Libertad',
    'Mabinay',
    'Manjuyod',
    'Pamplona',
    'San Jose',
    'Santa Catalina',
    'Siaton',
    'Sibulan',
    'Tanjay',
    'Tayasan',
    'Valencia (Luzurriaga)',
    'Vallehermoso',
    'Zamboanguita',
  ],
  Siquijor: [
    'Enrique Villanueva',
    'Larena',
    'Lazi',
    'Maria',
    'San Juan',
    'Siquijor',
  ],
  'Eastern Samar': [
    'Arteche',
    'Balangiga',
    'Balangkayan',
    'Borongan',
    'Can-avid',
    'Dolores',
    'General Macarthur',
    'Giporlos',
    'Guiuan',
    'Hernani',
    'Jipapad',
    'Lawaan',
    'Llorente',
    'Maslog',
    'Maydolong',
    'Mercedes',
    'Oras',
    'Quinapondan',
    'Salcedo',
    'San Julian',
    'San Policarpo',
    'Sulat',
    'Taft',
  ],
  Leyte: [
    'Abuyog',
    'Alangalang',
    'Albuera',
    'Babatngon',
    'Barugo',
    'Bato',
    'Baybay',
    'Burauen',
    'Calubian',
    'Capoocan',
    'Carigara',
    'Dagami',
    'Dulag',
    'Hilongos',
    'Hindang',
    'Inopacan',
    'Isabel',
    'Jaro',
    'Javier (Bugho)',
    'Julita',
    'Kananga',
    'La Paz',
    'Leyte',
    'Macarthur',
    'Mahaplag',
    'Matag-ob',
    'Matalom',
    'Mayorga',
    'Merida',
    'Ormoc City',
    'Palo',
    'Palompon',
    'Pastrana',
    'San Isidro',
    'San Miguel',
    'Santa Fe',
    'Tabango',
    'Tabontabon',
    'Tacloban City',
    'Tanauan',
    'Tolosa',
    'Tunga',
    'Villaba',
  ],
  'Northern Samar': [
    'Allen',
    'Biri',
    'Bobon',
    'Capul',
    'Catarman',
    'Catubig',
    'Gamay',
    'Laoang',
    'Lapinig',
    'Las Navas',
    'Lavezares',
    'Mapanas',
    'Mondragon',
    'Palapag',
    'Pambujan',
    'Rosario',
    'San Antonio',
    'San Isidro',
    'San Jose',
    'San Roque',
    'San Vicente',
    'Silvino Lobos',
    'Victoria',
    'Lope De Vega',
  ],
  'Western Samar': [
    'Almagro',
    'Basey',
    'Calbayog City',
    'Calbiga',
    'Catbalogan',
    'Daram',
    'Gandara',
    'Hinabangan',
    'Jiabong',
    'Marabut',
    'Matuguinao',
    'Motiong',
    'Pinabacdao',
    'San Jose De Buan',
    'San Sebastian',
    'Santa Margarita',
    'Santa Rita',
    'Santo Niño',
    'Talalora',
    'Tarangnan',
    'Villareal',
    'Paranas (Wright)',
    'Zumarraga',
    'Tagapul-an',
    'San Jorge',
    'Pagsanghan',
  ],
  'Southern Leyte': [
    'Anahawan',
    'Bontoc',
    'Hinunangan',
    'Hinundayan',
    'Libagon',
    'Liloan',
    'Maasin',
    'Macrohon',
    'Malitbog',
    'Padre Burgos',
    'Pintuyan',
    'Saint Bernard',
    'San Francisco',
    'San Juan (Cabalian)',
    'San Ricardo',
    'Silago',
    'Sogod',
    'Tomas Oppus',
    'Limasawa',
  ],
  Biliran: [
    'Almeria',
    'Biliran',
    'Cabucgayan',
    'Caibiran',
    'Culaba',
    'Kawayan',
    'Maripipi',
    'Naval',
  ],
  'Zamboanga Del Norte': [
    'Dapitan City',
    'Dipolog City',
    'Katipunan',
    'La Libertad',
    'Labason',
    'Liloy',
    'Manukan',
    'Mutia',
    'Piñan (New Piñan)',
    'Polanco',
    'Pres. Manuel A. Roxas',
    'Rizal',
    'Salug',
    'Sergio Osmeña Sr.',
    'Siayan',
    'Sibuco',
    'Sibutad',
    'Sindangan',
    'Siocon',
    'Sirawai',
    'Tampilisan',
    'Jose Dalman (Ponot)',
    'Gutalac',
    'Baliguian',
    'Godod',
    'Bacungan (Leon T. Postigo)',
    'Kalawit',
  ],
  'Zamboanga Del Sur': [
    'Aurora',
    'Bayog',
    'Dimataling',
    'Dinas',
    'Dumalinao',
    'Dumingag',
    'Kumalarang',
    'Labangan',
    'Lapuyan',
    'Mahayag',
    'Margosatubig',
    'Midsalip',
    'Molave',
    'Pagadian City',
    'Ramon Magsaysay (Liargo)',
    'San Miguel',
    'San Pablo',
    'Tabina',
    'Tambulig',
    'Tukuran',
    'Zamboanga City',
    'Lakewood',
    'Josefina',
    'Pitogo',
    'Sominot (Don Mariano Marcos)',
    'Vincenzo A. Sagun',
    'Guipos',
    'Tigbao',
  ],
  'Zamboanga Sibugay': [
    'Alicia',
    'Buug',
    'Diplahan',
    'Imelda',
    'Ipil',
    'Kabasalan',
    'Mabuhay',
    'Malangas',
    'Naga',
    'Olutanga',
    'Payao',
    'Roseller Lim',
    'Siay',
    'Talusan',
    'Titay',
    'Tungawan',
  ],
  Bukidnon: [
    'Baungon',
    'Damulog',
    'Dangcagan',
    'Don Carlos',
    'Impasug-ong',
    'Kadingilan',
    'Kalilangan',
    'Kibawe',
    'Kitaotao',
    'Lantapan',
    'Libona',
    'Malaybalay',
    'Malitbog',
    'Manolo Fortich',
    'Maramag',
    'Pangantucan',
    'Quezon',
    'San Fernando',
    'Sumilao',
    'Talakag',
    'Valencia',
    'Cabanglasan',
  ],
  Camiguin: ['Catarman', 'Guinsiliban', 'Mahinog', 'Mambajao', 'Sagay'],
  'Lanao Del Norte': [
    'Bacolod',
    'Baloi',
    'Baroy',
    'Iligan City',
    'Kapatagan',
    'Sultan Naga Dimaporo (Karomatan)',
    'Kauswagan',
    'Kolambugan',
    'Lala',
    'Linamon',
    'Magsaysay',
    'Maigo',
    'Matungao',
    'Munai',
    'Nunungan',
    'Pantao Ragat',
    'Poona Piagapo',
    'Salvador',
    'Sapad',
    'Tagoloan',
    'Tangcal',
    'Tubod',
    'Pantar',
  ],
  'Misamis Occidental': [
    'Aloran',
    'Baliangao',
    'Bonifacio',
    'Calamba',
    'Clarin',
    'Concepcion',
    'Jimenez',
    'Lopez Jaena',
    'Oroquieta City',
    'Ozamis City',
    'Panaon',
    'Plaridel',
    'Sapang Dalaga',
    'Sinacaban',
    'Tangub City',
    'Tudela',
    'Don Victoriano Chiongbian  (Don Mariano Marcos)',
  ],
  'Misamis Oriental': [
    'Alubijid',
    'Balingasag',
    'Balingoan',
    'Binuangan',
    'Cagayan De Oro City',
    'Claveria',
    'El Salvador',
    'Gingoog City',
    'Gitagum',
    'Initao',
    'Jasaan',
    'Kinoguitan',
    'Lagonglong',
    'Laguindingan',
    'Libertad',
    'Lugait',
    'Magsaysay (Linugos)',
    'Manticao',
    'Medina',
    'Naawan',
    'Opol',
    'Salay',
    'Sugbongcogon',
    'Tagoloan',
    'Talisayan',
    'Villanueva',
  ],
  'Davao Del Norte': [
    'Asuncion (Saug)',
    'Carmen',
    'Kapalong',
    'New Corella',
    'Panabo',
    'Island Garden Samal',
    'Santo Tomas',
    'Tagum',
    'Talaingod',
    'Braulio E. Dujali',
    'San Isidro',
  ],
  'Davao Del Sur': [
    'Bansalan',
    'Davao City',
    'Digos',
    'Hagonoy',
    'Kiblawan',
    'Magsaysay',
    'Malalag',
    'Matanao',
    'Padada',
    'Santa Cruz',
    'Sulop',
  ],
  'Davao Oriental': [
    'Baganga',
    'Banaybanay',
    'Boston',
    'Caraga',
    'Cateel',
    'Governor Generoso',
    'Lupon',
    'Manay',
    'Mati',
    'San Isidro',
    'Tarragona',
  ],
  'Compostela Valley': [
    'Compostela',
    'Laak (San Vicente)',
    'Mabini (Doña Alicia)',
    'Maco',
    'Maragusan (San Mariano)',
    'Mawab',
    'Monkayo',
    'Montevista',
    'Nabunturan',
    'New Bataan',
    'Pantukan',
  ],
  'Davao Occidental': [
    'Don Marcelino',
    'Jose Abad Santos (Trinidad)',
    'Malita',
    'Santa Maria',
    'Sarangani',
  ],
  'North Cotabato': [
    'Alamada',
    'Carmen',
    'Kabacan',
    'Kidapawan',
    'Libungan',
    'Magpet',
    'Makilala',
    'Matalam',
    'Midsayap',
    "M'lang",
    'Pigkawayan',
    'Pikit',
    'President Roxas',
    'Tulunan',
    'Antipas',
    'Banisilan',
    'Aleosan',
    'Arakan',
  ],
  'South Cotabato': [
    'Banga',
    'General Santos City (Dadiangas)',
    'Koronadal',
    'Norala',
    'Polomolok',
    'Surallah',
    'Tampakan',
    'Tantangan',
    "T'boli",
    'Tupi',
    'Santo Niño',
    'Lake Sebu',
  ],
  'Sultan Kudarat': [
    'Bagumbayan',
    'Columbio',
    'Esperanza',
    'Isulan',
    'Kalamansig',
    'Lebak',
    'Lutayan',
    'Lambayong (Mariano Marcos)',
    'Palimbang',
    'President Quirino',
    'Tacurong',
    'Sen. Ninoy Aquino',
  ],
  Sarangani: [
    'Alabel',
    'Glan',
    'Kiamba',
    'Maasim',
    'Maitum',
    'Malapatan',
    'Malungon',
  ],
  'Cotabato City': ['Cotabato City'],
  'Metro Manila': [
    'Tondo I / Ii',
    'Binondo',
    'Quiapo',
    'San Nicolas',
    'Santa Cruz',
    'Sampaloc',
    'San Miguel',
    'Ermita',
    'Intramuros',
    'Malate',
    'Paco',
    'Pandacan',
    'Port Area',
    'Santa Ana',
    'Mandaluyong',
    'Marikina',
    'Pasig',
    'Quezon City',
    'San Juan',
    'Caloocan City',
    'Malabon',
    'Navotas',
    'Valenzuela',
    'Las Piñas',
    'Makati',
    'Muntinlupa',
    'Parañaque',
    'Pasay City',
    'Pateros',
    'Taguig City',
  ],
  Abra: [
    'Bangued',
    'Boliney',
    'Bucay',
    'Bucloc',
    'Daguioman',
    'Danglas',
    'Dolores',
    'La Paz',
    'Lacub',
    'Lagangilang',
    'Lagayan',
    'Langiden',
    'Licuan-baay (Licuan)',
    'Luba',
    'Malibcong',
    'Manabo',
    'Peñarrubia',
    'Pidigan',
    'Pilar',
    'Sallapadan',
    'San Isidro',
    'San Juan',
    'San Quintin',
    'Tayum',
    'Tineg',
    'Tubo',
    'Villaviciosa',
  ],
  Benguet: [
    'Atok',
    'Baguio City',
    'Bakun',
    'Bokod',
    'Buguias',
    'Itogon',
    'Kabayan',
    'Kapangan',
    'Kibungan',
    'La Trinidad',
    'Mankayan',
    'Sablan',
    'Tuba',
    'Tublay',
  ],
  Ifugao: [
    'Banaue',
    'Hungduan',
    'Kiangan',
    'Lagawe',
    'Lamut',
    'Mayoyao',
    'Alfonso Lista (Potia)',
    'Aguinaldo',
    'Hingyon',
    'Tinoc',
    'Asipulo',
  ],
  Kalinga: [
    'Balbalan',
    'Lubuagan',
    'Pasil',
    'Pinukpuk',
    'Rizal (Liwan)',
    'Tabuk',
    'Tanudan',
    'Tinglayan',
  ],
  'Mountain Province': [
    'Barlig',
    'Bauko',
    'Besao',
    'Bontoc',
    'Natonin',
    'Paracelis',
    'Sabangan',
    'Sadanga',
    'Sagada',
    'Tadian',
  ],
  Apayao: [
    'Calanasan (Bayag)',
    'Conner',
    'Flora',
    'Kabugao',
    'Luna',
    'Pudtol',
    'Santa Marcela',
  ],
  Basilan: [
    'Lamitan',
    'Lantawan',
    'Maluso',
    'Sumisip',
    'Tipo-tipo',
    'Tuburan',
    'Akbar',
    'Al-barka',
    'Hadji Mohammad Ajul',
    'Ungkaya Pukan',
    'Hadji Muhtamad',
    'Tabuan-lasa',
  ],
  'Lanao Del Sur': [
    'Bacolod-kalawi (Bacolod Grande)',
    'Balabagan',
    'Balindong (Watu)',
    'Bayang',
    'Binidayan',
    'Bubong',
    'Butig',
    'Ganassi',
    'Kapai',
    'Lumba-bayabao (Maguing)',
    'Lumbatan',
    'Madalum',
    'Madamba',
    'Malabang',
    'Marantao',
    'Marawi City',
    'Masiu',
    'Mulondo',
    'Pagayawan (Tatarikan)',
    'Piagapo',
    'Poona Bayabao (Gata)',
    'Pualas',
    'Ditsaan-ramain',
    'Saguiaran',
    'Tamparan',
    'Taraka',
    'Tubaran',
    'Tugaya',
    'Wao',
    'Marogong',
    'Calanogas',
    'Buadiposo-buntong',
    'Maguing',
    'Picong (Sultan Gumander)',
    'Lumbayanague',
    'Bumbaran',
    'Tagoloan Ii',
    'Kapatagan',
    'Sultan Dumalondong',
    'Lumbaca-unayan',
  ],
  Maguindanao: [
    'Ampatuan',
    'Buldon',
    'Buluan',
    'Datu Paglas',
    'Datu Piang',
    'Datu Odin Sinsuat (Dinaig)',
    'Shariff Aguak (Maganoy)',
    'Matanog',
    'Pagalungan',
    'Parang',
    'Sultan Kudarat (Nuling)',
    'Sultan Sa Barongis (Lambayong)',
    'Kabuntalan (Tumbao)',
    'Upi',
    'Talayan',
    'South Upi',
    'Barira',
    'Gen. S. K. Pendatun',
    'Mamasapano',
    'Talitay',
    'Pagagawan',
    'Paglat',
    'Sultan Mastura',
    'Guindulungan',
    'Datu Saudi-ampatuan',
    'Datu Unsay',
    'Datu Abdullah Sangki',
    'Rajah Buayan',
    'Datu Blah T. Sinsuat',
    'Datu Anggal Midtimbang',
    'Mangudadatu',
    'Pandag',
    'Northern Kabuntalan',
    'Datu Hoffer Ampatuan',
    'Datu Salibo',
    'Shariff Saydona Mustapha',
  ],
  Sulu: [
    'Indanan',
    'Jolo',
    'Kalingalan Caluang',
    'Luuk',
    'Maimbung',
    'Hadji Panglima Tahil (Marunggas)',
    'Old Panamao',
    'Pangutaran',
    'Parang',
    'Pata',
    'Patikul',
    'Siasi',
    'Talipao',
    'Tapul',
    'Tongkil',
    'Panglima Estino (New Panamao)',
    'Lugus',
    'Pandami',
    'Omar',
  ],
  'Tawi-tawi': [
    'Panglima Sugala (Balimbing)',
    'Bongao',
    'Mapun (Cagayan De Tawi-tawi)',
    'Simunul',
    'Sitangkai',
    'South Ubian',
    'Tandubas',
    'Turtle Islands',
    'Languyan',
    'Sapa-sapa',
    'Sibutu',
  ],
  'Agusan Del Norte': [
    'Buenavista',
    'Butuan City',
    'Cabadbaran',
    'Carmen',
    'Jabonga',
    'Kitcharao',
    'Las Nieves',
    'Magallanes',
    'Nasipit',
    'Santiago',
    'Tubay',
    'Remedios T. Romualdez',
  ],
  'Agusan Del Sur': [
    'Bayugan',
    'Bunawan',
    'Esperanza',
    'La Paz',
    'Loreto',
    'Prosperidad',
    'Rosario',
    'San Francisco',
    'San Luis',
    'Santa Josefa',
    'Talacogon',
    'Trento',
    'Veruela',
    'Sibagat',
  ],
  'Surigao Del Norte': [
    'Alegria',
    'Bacuag',
    'Burgos',
    'Claver',
    'Dapa',
    'Del Carmen',
    'General Luna',
    'Gigaquit',
    'Mainit',
    'Malimono',
    'Pilar',
    'Placer',
    'San Benito',
    'San Francisco (Anao-aon)',
    'San Isidro',
    'Santa Monica (Sapao)',
    'Sison',
    'Socorro',
    'Surigao City',
    'Tagana-an',
    'Tubod',
  ],
  'Surigao Del Sur': [
    'Barobo',
    'Bayabas',
    'Bislig',
    'Cagwait',
    'Cantilan',
    'Carmen',
    'Carrascal',
    'Cortes',
    'Hinatuan',
    'Lanuza',
    'Lianga',
    'Lingig',
    'Madrid',
    'Marihatag',
    'San Agustin',
    'San Miguel',
    'Tagbina',
    'Tago',
    'Tandag',
  ],
  'Dinagat Islands': [
    'Basilisa (Rizal)',
    'Cagdianao',
    'Dinagat',
    'Libjo (Albor)',
    'Loreto',
    'San Jose',
    'Tubajon',
  ],
};
