import { useState, useEffect } from 'react';

import { ModalContainer } from '../../../components/common/Containers';
import { Button } from '../../../components/common/Button';
import { Span } from '../../../components/common/Span';
import warningIcon from '../../../images/Warning_icon.png';
import { Input } from '../../../components/common/Input';
import { Dispatch, RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, TextField, Box } from '@mui/material';

import { DRIVER } from '../../../components/constants/status';
import { updateExpressStatus, updateDeliveryDriver, addDeliveryDriver } from '../../../common/api';

type Props = {
  setAssignedDriverModal: Function;
  assignedDriverModal: boolean;
  orderId: string;
  isAssignOrReAssign: string;
  vehicleType: string;
};

export default ({ setAssignedDriverModal, assignedDriverModal, orderId, isAssignOrReAssign, vehicleType, ...props }: Props) => {
  const dispatch = useDispatch<Dispatch>();

  const driver = useSelector((state: RootState) => state.Table.tables.drivers?.data)
    .filter(d => {
      const vehicle = d?.driverVehicle?.find(v => v?.status === 'active')?.vehicleType;
      if (vehicle === vehicleType) {
        return true;
      }
      return false;
    });

  const [driverId, setDriverId] = useState();
  const [reason, setReason] = useState('');

  const handleAssigned = async () => {
    setAssignedDriverModal(false);
    let assignResult;
    try {
      dispatch.UI.setIsLoading(true);
      if (isAssignOrReAssign === 'Assign Driver') {
        assignResult = await addDeliveryDriver({
          orderId,
          driver: { driverId },
        });
        if (assignResult?.isSuccess && isAssignOrReAssign === 'Assign Driver') {
          const res = await updateExpressStatus('assigned', orderId);
          dispatch.UI.setIsLoading(false);
          dispatch.UI.setAlert({
            message: `Order Number Assigned`,
            type: 'Success',
          });
        }
      } else {
        assignResult = await updateDeliveryDriver({
          orderId,
          driver: { driverId, reason },
        });
        dispatch.UI.setIsLoading(false);
        dispatch.UI.setAlert({ message: 'Driver Re-assign', type: 'Success' });
      }
    } catch (err: any) {
      let errMsg = err?.data?.error;
      if (errMsg === undefined) {
        errMsg =
          isAssignOrReAssign === 'Assign Driver'
            ? 'Unable to assign driver'
            : 'Unable to reassign driver';
      }
      dispatch.UI.setIsLoading(false);
      dispatch.UI.setAlert({ message: errMsg, type: 'Error' });
    }
  };

  useEffect(() => {
    const filter_1 = {
      field: 'driverApprovalStatus',
      operation: '==',
      value: 'approved'
    }

    const filter_2 = {
      field: 'driverStatus',
      operation: '==',
      value: 'active'
    }

    dispatch.Table.setFilterValue({ table: 'drivers', key: 'approvalStatus', filter: filter_1 });
    dispatch.Table.setFilterValue({ table: 'drivers', key: 'driverStatus', filter: filter_2 });

    dispatch.Table.getFirebaseData({ table: 'drivers', name: 'driver_profile' });

    return () => {
      dispatch.Table.resetTable('drivers');
    }
  }, [])

  return (
    <>
      {assignedDriverModal && (
        <>
          <ModalContainer>
            <form onSubmit={handleAssigned}>
              <div className="px-4 py-6 whitespace-normal">
                <div className="flex">
                  <img
                    src={warningIcon}
                    alt={warningIcon}
                    className="h-8 mr-2"
                  />
                  <div className="text-left">
                    <Span size="lg" weight="bold">
                      Assign Driver
                    </Span>
                  </div>
                </div>

                <div className="text-left">
                  <Autocomplete
                    className="my-4"
                    disablePortal
                    id="combo-box-demo"
                    options={driver}
                    onChange={(e, newValue) => {
                      const id =
                        newValue.id === undefined
                          ? newValue.driverId
                          : newValue.id;
                      setDriverId(id);
                    }}
                    getOptionLabel={(option: any) => {
                      const data =
                        option.firstName === undefined ? option : option;
                      return (
                        data.firstName +
                        ' ' +
                        data.middleName +
                        ' ' +
                        data.lastName
                      );
                    }}
                    renderOption={(props, option) => {
                      const data = option;
                      const dataStatus = option?.driverVehicle?.find(v => v?.status === 'active');
                      return (
                        <>
                          <li {...props}>
                            <Box>
                              <Span weight="semi" transform="capitalize">
                                {data.firstName} {data.middleName}{' '}
                                {data.lastName}
                              </Span>
                              <div className="flex">
                                <Span>{data.phoneNumber}</Span>
                                <Span
                                  padding="px-4"
                                  margin="mx-4"
                                  radius="xl"
                                  size="sm"
                                  color={
                                    dataStatus.status === 'active'
                                      ? 'white'
                                      : 'red'
                                  }
                                  bg={
                                    dataStatus.status === 'active'
                                      ? 'darkgreen'
                                      : 'red'
                                  }
                                  border={
                                    dataStatus.status === 'active'
                                      ? 'green'
                                      : 'red'
                                  }
                                >
                                  {DRIVER[dataStatus.status]}
                                </Span>
                              </div>
                            </Box>
                          </li>
                        </>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Driver" />
                    )}
                  />

                  {isAssignOrReAssign === 'Reassign Driver' && (
                    <>
                      <Span margin="mt-4">Re-assign Reason</Span>
                      <Input
                        required
                        placeholder="Reassign Reason"
                        type="text"
                        id="reassign"
                        onChange={(e: any) => setReason(e.target.value)}
                      />
                    </>
                  )}
                </div>

                <div className="flex justify-end my-4 mx-2">
                  <Button
                    width="none"
                    border="grey"
                    color="black"
                    padding="px-8"
                    bg="white"
                    margin="mx-2"
                    onClick={() => setAssignedDriverModal(false)}
                  >
                    Cancel
                  </Button>

                  <Button width="none" padding="px-8" type="submit">
                    Assign
                  </Button>
                </div>
              </div>
            </form>
          </ModalContainer>

          <div className="opacity-60 fixed inset-0 z-40 bg-black-1"></div>
        </>
      )}
    </>
  );
};
