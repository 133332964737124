import StatusRole from './StatusRole'

import { ColumnFilter } from "../../../components/Table/ColumnFilter";
import moment from 'moment';
import { Span } from '../../../components/common/Span';
import { ROLE_STATUS } from '../../../components/constants/status';

export const COLUMNS = [
    {
        accessor: 'id',
        Header: 'ID',
        Filter: ColumnFilter,
        isVisible: false,
    },
    {
        accessor: 'roleName',
        Header: 'Role Name',
        Filter: ColumnFilter,
        isVisible: true,
    },
    {
        accessor: 'roleDescription',
        Header: 'Role Description',
        Filter: ColumnFilter,
        isVisible: true,
    },
    {
        accessor: 'status',
        Header: 'Role Status',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
                <div className="flex justify-center whitespace-nowrap">
                    {d?.status === 'active' ?
                        <Span border='green' bg='green' padding='px-4' width='full' margin='mx-4' color='green' radius='xl'
                            size='sm'>
                            {ROLE_STATUS[d?.status]}
                        </Span>
                        :
                        <Span border='red' bg='red' padding='px-4' width='full' margin='mx-4' color='red' radius='xl'
                            size='sm'>
                            {ROLE_STATUS[d?.status]}
                        </Span>
                    }
                </div>
            )
        }
    },
    {
        accessor: data => data?.createBy?.email,
        Header: 'created By',
        isVisible: true,
    },
    {
        accessor: ' ',
        Header: ' ',
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
        isVisible: true,
        Cell: data => <StatusRole id={data.row.original.id} email={data.row.original.email} />
    }
]