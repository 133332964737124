import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { RootState } from '../../store';
import SubMenu from './SubMenu';

const Menu = ({ item }: { item: any }) => {
  const history = useHistory();
  const [subnav, setSubnav] = useState(false) as any;
  const [isNotHidden, setIsNotHidden] = useState<Boolean>(false);
  const userRole = useSelector((state: RootState) => state.User.userInfo);

  const [pathname, setPathname] = useState('/');
  const location = useLocation();
  const [open, setOpen] = useState(false);
  let subStatus = location?.pathname.search(item?.subName);

  const handleSubNav = () => {
    setSubnav(!subnav);
    if (subStatus > -1) {
      history.push('/parcels?tab=new');
    }
  };

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  useEffect(() => {
    const roleKeys = Object.keys(userRole?.permissions);
    roleKeys.map((roleKey) => {
      if (roleKey === item.roleType) {
        setIsNotHidden(userRole?.permissions[`${roleKey}`].canRead);
      }
    });
  }, [userRole]);

  return (
    <>
      {isNotHidden && (
        <>
          <li onClick={item.subNav && handleSubNav}>
            <Link
              to={item.path}
              className={
                item.subNav
                  ? null
                  : pathname === item.path?.split('?')[0]
                    ? 'link-active'
                    : null
              }
            >
              <div className="flex justify-between">
                <div className="side-menu flex">
                  {item.path?.includes(pathname) ? item.iconActive : item.icon}
                  <span>{item.title}</span>
                </div>

                <div className="my-auto">
                  {item.subNav && subStatus > -1
                    ? item.iconOpened
                    : item.subNav
                      ? item.iconClosed
                      : null}
                </div>
              </div>
            </Link>
          </li>
          <ul className="submenu">
            {subStatus > -1 &&
              item?.subNav?.map((item: any, index: any) => {
                return <SubMenu item={item} key={index} />;
              })}
          </ul>
        </>
      )}
    </>
  );
};

export default Menu;
