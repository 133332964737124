import PartialRatePage from '../containers/Rate/ParialLoad/PartialLoad';  
import Header from '../components/Header/Header' 
import SidebarPage from '../components/Sidebar/Sidebar'
import { MainContainer } from '../components/common/Containers'
 
const Express = () => {
    return ( 
        <>  
            <SidebarPage />
            <MainContainer>    
                <Header /> 
                <main> 
                    <PartialRatePage/>  
                </main> 
            </MainContainer>
        </>
    )
}

export default Express
