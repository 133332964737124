import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';

interface MyRouteProps extends RouteProps {
  component: any;
  path: any;
}

const AuthRoute: React.FC<MyRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { shouldRedirectToLogin, isUserLoggedIn } = useSelector(
    (state: RootState) => state.User
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        isUserLoggedIn ? (
          <Component {...props} />
        ) : (
          <>
            {shouldRedirectToLogin ? (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: props.location },
                }}
              />
            ) : null}
          </>
        )
      }
    />
  );
};

export default AuthRoute;
