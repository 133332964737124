import moment from "moment";
import React, { useState } from "react";
import { BsDot } from "react-icons/bs";
import { MdPayments } from "react-icons/md";
import { useDispatch } from "react-redux";
import { SpinnerCircularFixed } from "spinners-react";
import { uploadExpressPod } from "../../../../common/api";
import { GridContainer } from "../../../../components/common/Containers";
import { Span } from "../../../../components/common/Span";
import {
  EXPRESS,
  PACKAGE_STATUS,
} from "../../../../components/constants/status";
import { Dispatch } from "../../../../store";

function Stops({ expressId, dropOff, status }: any) {
  const dispatch = useDispatch<Dispatch>();
  const [isLoading, setIsLoading] = useState(false);

  const handlePOD = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const file = e.target.files[0];
    const fileType = file.type;
    if (
      ![
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/svg",
        "image/webp",
      ].includes(fileType)
    ) {
      dispatch.UI.setAlert({ message: "Invalid file type!", type: "Error" });
      setIsLoading(false);
      return;
    }
    try {
      const res = await uploadExpressPod(expressId, dropOff?.id, file);
      if (res.isSuccess) {
        setIsLoading(false);
        dispatch.UI.setAlert({
          message: res?.message ?? "Successfully uploaded",
          type: "Success",
        });
      }
    } catch (err: any) {
      setIsLoading(false);
      const errMsg = err?.data?.error ?? "Unable to upload file";
      dispatch.UI.setAlert({ message: errMsg, type: "Error" });
    }
  };

  const datedelivered = new Date(dropOff?.deliveredAt?.seconds * 1000);
  const deliveredAt = moment(datedelivered).format("MMMM Do YYYY h:mm:ss a");

  return (
    <GridContainer grid="four" padding="px-8" gap="two">
      <div>
        <Span weight="semi"> Address: </Span>
        <Span>{dropOff?.locationAddress}</Span>
        <p className="flex">
          <BsDot className="text-grey-11 my-auto" />
          <Span color="lightgrey" size="sm">
            {dropOff?.locationDetails}
          </Span>
        </p>
      </div>

      <div>
        <Span weight="semi"> Contact Person </Span>
        <Span>{dropOff?.contactName}</Span>
      </div>

      <div>
        <Span weight="semi"> Contact Number </Span>
        <Span>{dropOff?.contactPhone}</Span>
      </div>

      <div>
        <Span weight="semi"> Package Status </Span>
        {status === PACKAGE_STATUS.canceled ? (
          <Span weight="semi" color="red">
            CANCELED
          </Span>
        ) : status === PACKAGE_STATUS.failed_delivery ? (
          <Span weight="semi" color="red">
            FAILED DELIVERY
          </Span>
        ) : (dropOff?.action !== undefined &&
            dropOff?.action === "completed") ||
          status === PACKAGE_STATUS.delivered ? (
          <Span weight="semi" color="blue">
            DELIVERED
          </Span>
        ) : status === PACKAGE_STATUS.on_going &&
          dropOff?.action === undefined ? (
          <Span weight="semi" color="orange">
            OUT FOR DELIVERY
          </Span>
        ) : (
          <Span weight="semi" color="green">
            PENDING
          </Span>
        )}
      </div>

      {dropOff?.isReliableFP && (
        <div className="flex items-center gap-3 col-span-2">
          <Span color="red">
            <MdPayments />
          </Span>
          <Span color="red">Reliable For Payment</Span>
        </div>
      )}
      {/* <div>
        <Span weight="semi"> Item Category </Span>
        <Span>{dropOff?.itemCategory}</Span>

        <Span weight="semi" margin="mt-4">
          {" "}
          Driver Instruction{" "}
        </Span>
        <Span>{dropOff?.deliveryInstruction}</Span>
      </div> */}

      <div className="col-span-4">
        <Span weight="semi" margin="mt-4">
          {" "}
          Proof of Delivery:{" "}
        </Span>
        {isLoading ? (
          <SpinnerCircularFixed className="text-brown-1 h-6 w-6" />
        ) : (
          <>
            <a href={dropOff?.proofOfDelivery} target="_blank">
              <Span color="blue">{dropOff?.proofOfDelivery}</Span>
            </a>
            {dropOff?.proofOfDelivery == undefined && status === "delivered" ? (
              <>
                <input
                  type="file"
                  name="file"
                  id="file"
                  onChange={handlePOD}
                  accept="image/*"
                />
                <label htmlFor="file" className="cursor-pointer">
                  <Span color="brown" weight="semi">
                    Upload POD
                  </Span>
                </label>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </div>

      <div className="col-span-4">
        {dropOff?.deliveredAt != undefined ? (
          <>
            <Span align="right"> Completed as of {deliveredAt}</Span>
          </>
        ) : (
          <></>
        )}
      </div>

      {/* <div>
            <Span weight='semi'> Package Status </Span>
            <Span color='brown'>{PACKAGE[data?.status]}</Span>  
        </div>  */}
    </GridContainer>
  );
}

export default Stops;
