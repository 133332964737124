import { GridContainer, InputContainer } from '../../../../../components/common/Containers'
import { Select } from '../../../../../components/common/Select'
import { Span } from '../../../../../components/common/Span'

const Warehouse = ({ onChange, data, warehouse }) => {
    return (
        <div className="pt-4">
            {/* <Span margin="mt-4" size="lg" weight="medium">
                Warehouse
            </Span> */}
            <GridContainer grid="two" padding="none">
                <div>
                    <Span margin="mt-4">Warehouse</Span>
                    <InputContainer margin="none">
                        <Select
                            required
                            onChange={(e) => onChange(e, 'warehouse')}
                            value={data?.warehouse !== '' ? data?.warehouse : null}
                        >
                            <option disabled selected>Choose Warehouse</option>
                            {warehouse?.map(wh => (
                                <option key={wh?.id} value={wh?.id}>{wh?.name}</option>
                            ))}
                        </Select>
                    </InputContainer>
                </div>

                {/* <div>
                    <Span margin="mt-4">Last Mile</Span>
                    <InputContainer margin="none">
                        <Select
                            required
                            onChange={(e) => onChange(e, 'lastMile')}
                            value={data?.lastMile !== '' ? data?.lastMile : null}
                        >
                            <option disabled selected>Choose Warehouse</option>
                            {warehouse?.filter(wh => wh?.id !== data?.firstMile)?.map(wh => (
                                <option key={wh?.id} value={wh?.id}>{wh?.name}</option>
                            ))}
                        </Select>
                    </InputContainer>
                </div> */}
            </GridContainer>
        </div>
    )
}

export default Warehouse