import { post, put, remove } from '../core';

export const addBillingAccount = async(body: any) => {
    try{
        const res = await post('/billing', body);
        return res.data;
    }catch(err){
        throw err;
    }
}

export const updateBilling = async(billingId: any, body: any) => {
    try{
        const res = await put(`/billing/${billingId}`, body);
        return res.data;
    }catch(err){
        throw err;
    }
}

export const addBillingCustomer = async(body: any) => {
    try{
        const res = await post('/billing/create/customer', body)
        return res.data;
    }catch(err){
        throw err;
    }
}

export const deleteBillingCustomer = async(body: any) => {
    try{
        const { billingId, customerId } = body;
        const res = await remove(`/billing/delete/${billingId}/customer/${customerId}`)
        console.log(res);
        return res.data;
    }catch(err){
        throw err;
    }
}