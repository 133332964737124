import React from 'react'
import { MdDelete } from 'react-icons/md'
import { GridContainer, InputContainer } from '../../components/common/Containers'
import { Input } from '../../components/common/Input'
import { Select } from '../../components/common/Select'
import { Span } from '../../components/common/Span'
import { VEHICLETYPE } from '../../components/constants/status'

const Vehicle = ({ isShowDeleteVehicle, handleChange, removeVehicle, index, driverDetails }) => {

    const selectedVehicle = driverDetails?.driverVehicle?.map(v => v.vehicleType);

    return (
        <div className='relative'>
            {isShowDeleteVehicle && (
                <MdDelete className='text-red-2 text-2xl px-0.5 absolute right-1 top-1 cursor-pointer' onClick={() => removeVehicle(index)} />
            )}
            <GridContainer grid="two" padding='p-2' border='grey' margin='mt-4'>
                <div>
                    <Span margin="mt-4">Vehicle Type</Span>
                    <InputContainer border='grey'>
                        <Select
                            margin='mr-3'
                            border='none'
                            placeholder='Vehicle Type'
                            value={driverDetails.driverVehicle[index].vehicleType}
                            onChange={(e: any) => handleChange('vehicleType', e, 'driverVehicle', index)}
                            required
                        >
                            <option value="" selected disabled hidden>
                                Vehicle Type
                            </option>
                            {VEHICLETYPE?.map((item, index) => (
                                <option key={index} value={item} disabled={selectedVehicle.includes(item)}>
                                    {item?.replace('_', ' ')}
                                </option>
                            ))}
                        </Select>
                    </InputContainer>
                </div>

                <div>
                    <Span margin="mt-4">Status</Span>
                    <InputContainer border='grey'>
                        <Select
                            margin='mr-3'
                            border='none'
                            value={driverDetails.driverVehicle[index].status}
                            onChange={(e) => handleChange('status', e, 'driverVehicle', index)}
                            required
                        >
                            <option value="" selected disabled hidden>
                                Status
                            </option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                        </Select>
                    </InputContainer>
                </div>

                <div>
                    <Span>Vehicle Model</Span>
                    <Input
                        placeholder="Vehicle Model"
                        value={driverDetails.driverVehicle[index].vehicleModel}
                        onChange={(e) =>
                            handleChange('vehicleModel', e, 'driverVehicle', index)
                        }
                        required
                    />
                </div>

                <div>
                    <Span>Plate Number</Span>
                    <Input
                        placeholder="Registered Plate Number"
                        value={driverDetails.driverVehicle[index].plateNumber}
                        onChange={(e) =>
                            handleChange('plateNumber', e, 'driverVehicle', index)
                        }
                        required
                    />
                </div>
            </GridContainer>
        </div>
    )
}

export default Vehicle