import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
 
const SubMenu = ({item, setSubPath} : any) => { 

    const { pathname } = useLocation();
    // useEffect(() => {
    //     if(['/expressRate', '/parcelRate'].includes(item.path)){
    //         console.log('SUBMENU');
    //     }
    // },[item.path]);
    return ( <>
        <li>
            <Link to={item.path} className={pathname?.search(item?.path?.split('?')[0]) > -1 ? 'link-active' : null}>  
                <span>{item.title}</span> 
            </Link>
        </li> 
    </>);
};

export default SubMenu;