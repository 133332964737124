import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '../../store'

import { Span } from '../../components/common/Span'
import { Button } from '../../components/common/Button'
import { Container } from '../../components/common/Containers'
import EditRolePage from './Update/EditRolePage'

import Table from '../../components/Table/Table'
import { COLUMNS } from './Table/column'
import NoData from '../../components/common/NoData/NoData'

import { BiPlusCircle } from 'react-icons/bi'
import Loader from "../../components/Loader";
import Data from './Table/data.json'

import AddRole from './Add/AddRole'

export default () => {
    const dispatch = useDispatch<Dispatch>();
    const [isLoading, setIsLoading] = useState(true);

    const data: any[] = useSelector((state: RootState) => state.Table.tables.roles.data)
        ?.sort((a, b) => { return a?.createdAt?.seconds - b?.createdAt?.seconds });
    const filters: any = useSelector((state: RootState) => state.Table.tables.roles.filters);
    const editRoleId: any = useSelector((state: RootState) => state.UI.forms.editRole?.id);

    useEffect(() => {
        dispatch.Table.getFirebaseData({ name: 'role', table: 'roles' })
        setIsLoading(false);
    }, [filters])

    const columns = useMemo(() => COLUMNS, [])

    const [addRole, setAddRole] = useState(false);

    return (<>
        {isLoading ?
            <Loader />
            : <>
                <Container border='grey' padding='none'>
                    <div className='flex justify-between p-4'>
                        <Span margin='mt-4' weight='bold' size='2xl'>Role Management</Span>

                        <div className='flex justify-end p-4'>
                            <Button width='none'
                                onClick={() => { setAddRole(true); }}
                            >
                                <div className='flex pr-2'>
                                    <BiPlusCircle className='my-auto mx-2 text-2xl' />
                                    Add Role
                                </div>
                            </Button>
                        </div>
                    </div>

                    <div className='overflow-auto'>
                        {data?.length < 0
                            ? <NoData />
                            : <Table columns={columns} data={data} />
                        }
                    </div>
                </Container>

                {addRole && <>
                    <AddRole setAddRole={setAddRole} />
                </>}

                {editRoleId && <>
                    <EditRolePage setEditRole={editRoleId} />
                </>}
            </>}
    </>)
}
