import { useState } from "react";
import { Span } from "../../../../components/common/Span";
import {
  GridContainer,
  Container,
} from "../../../../components/common/Containers";

import { STATUS } from "../../../../components/constants/status";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Dispatch } from "../../../../store";
import { uploadParcelPod } from "../../../../common/api";
import { SpinnerCircularFixed } from "spinners-react";

const Package = ({ data }: any) => {
  const dispatch = useDispatch<Dispatch>();
  const [isLoading, setIsLoading] = useState(false);

  const handlePOD = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const file = e.target.files[0];
    const fileType = file.type;
    if (
      ![
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/svg",
        "image/webp",
      ].includes(fileType)
    ) {
      dispatch.UI.setAlert({ message: "Invalid file type!", type: "Error" });
      setIsLoading(false);
      return;
    }
    try {
      const res = await uploadParcelPod(data?.id, file);
      if (res.isSuccess) {
        setIsLoading(false);
        dispatch.UI.setAlert({
          message: res?.message ?? "Successfully uploaded",
          type: "Success",
        });
      }
    } catch (err: any) {
      setIsLoading(false);
      const errMsg = err?.data?.error ?? "Unable to upload file";
      dispatch.UI.setAlert({ message: errMsg, type: "Error" });
    }
  };

  return (
    <>
      <div>
        <Span weight="semi" size="lg">
          Package Information
        </Span>
        <GridContainer gap="none" padding="py-4">
          <Container
            border="right"
            bg="grey"
            margin="none"
            radius="none"
            padding="p-2"
          >
            <Span size="sm">Status</Span>
          </Container>
          <Container border="left" cols="span-2" radius="none" padding="p-2">
            {STATUS[data.status]}
          </Container>

          <Container
            border="right"
            bg="grey"
            margin="none"
            radius="none"
            padding="p-2"
          >
            <Span size="sm">Tracking Number</Span>
          </Container>
          <Container border="left" cols="span-2" radius="none" padding="p-2">
            {" "}
            {data.trackingNumber}{" "}
          </Container>

          <Container
            border="right"
            bg="grey"
            margin="none"
            radius="none"
            padding="p-2"
          >
            <Span size="sm">Created At</Span>
          </Container>
          <Container border="left" cols="span-2" radius="none" padding="p-2">
            {moment(data?.createdAt?.toDate()).format(
              "MMMM Do YYYY, h:mm:ss a"
            )}{" "}
          </Container>

          <Container
            border="right"
            bg="grey"
            margin="none"
            radius="none"
            padding="p-2"
          >
            <Span size="sm">Customer</Span>
          </Container>
          <Container border="left" cols="span-2" radius="none" padding="p-2">
            <Span transform="capitalize">
              {data?.customer?.firstName} {data?.customer?.lastName}{" "}
            </Span>
          </Container>

          <Container
            border="right"
            bg="grey"
            margin="none"
            radius="none"
            padding="p-2"
          >
            <Span size="sm">Pick up Date</Span>
          </Container>
          <Container border="left" cols="span-2" radius="none" padding="p-2">
            <Span transform="capitalize">
              {data?.pickUpDate &&
                moment(new Date(data?.pickUpDate))?.format("MMMM Do YYYY")}
            </Span>
          </Container>

          {/* <Container border='right' bg='grey' margin='none' radius='none' padding='p-2'>
                    <Span size='sm'>Branch</Span>
                </Container>  
                <Container border='left' cols='span-2' radius='none' padding='p-2'> </Container> */}

          <Container
            border="right"
            bg="grey"
            margin="none"
            radius="none"
            padding="p-2"
          >
            <Span size="sm">Reference 1</Span>
          </Container>
          <Container border="left" cols="span-2" radius="none" padding="p-2">
            {data.reference1}
          </Container>

          <Container
            border="right"
            bg="grey"
            margin="none"
            radius="none"
            padding="p-2"
          >
            <Span size="sm">Reference 2</Span>
          </Container>
          <Container border="left" cols="span-2" radius="none" padding="p-2">
            {data.reference2}
          </Container>

          <Container
            border="right"
            bg="grey"
            margin="none"
            radius="none"
            padding="p-2"
          >
            <Span size="sm">Reference 3</Span>
          </Container>
          <Container border="left" cols="span-2" radius="none" padding="p-2">
            {data.reference3}
          </Container>

          <Container
            border="right"
            bg="grey"
            margin="none"
            radius="none"
            padding="p-2"
          >
            <Span size="sm">Transport Mode</Span>
          </Container>
          <Container border="left" cols="span-2" radius="none" padding="p-2">
            {data?.packageDetails?.transportMode}
          </Container>

          {/* <Container border='right' bg='grey' margin='none' radius='none' padding='p-2'>
                    <Span size='sm'>Delivery Type</Span>
                </Container>  
                <Container border='left' cols='span-2' radius='none' padding='p-2'>{data?.packageDetails?.deliveryType} </Container> */}

          {/* <Container border='right' bg='grey' margin='none' radius='none' padding='p-2'>
                    <Span size='sm'>Package Size</Span>
                    </Container>  
                <Container border='left' cols='span-2' radius='none' padding='p-2'>{data?.packageDetails?.size}</Container> */}

          {/* <Container border='right' bg='grey' margin='none' radius='none' padding='p-2'>
                    <Span size='sm'>Amount to be collected</Span>
                
                    <Container border='left' cols='span-2' radius='none' padding='p-2'> 
                    </Container>
                </Container>   */}

          {/* <Container
            border="right"
            bg="grey"
            margin="none"
            radius="none"
            padding="p-2"
          >
            <Span size="sm">Length</Span>
          </Container>
          <Container border="left" cols="span-2" radius="none" padding="p-2">
            {" "}
            {data?.packageDetails?.length}
          </Container>

          <Container
            border="right"
            bg="grey"
            margin="none"
            radius="none"
            padding="p-2"
          >
            <Span size="sm">Height</Span>
          </Container>
          <Container border="left" cols="span-2" radius="none" padding="p-2">
            {data?.packageDetails?.height}
          </Container>

          <Container
            border="right"
            bg="grey"
            margin="none"
            radius="none"
            padding="p-2"
          >
            <Span size="sm">Width</Span>
          </Container>
          <Container border="left" cols="span-2" radius="none" padding="p-2">
            {data?.packageDetails?.width}
          </Container> */}

          <Container
            border="right"
            bg="grey"
            margin="none"
            radius="none"
            padding="p-2"
          >
            <Span size="sm">Estimated Weight</Span>
          </Container>
          <Container border="left" cols="span-2" radius="none" padding="p-2">
            {" "}
            {data?.packageDetails?.weight}
          </Container>

          {/* <Container border='right' bg='grey' margin='none' radius='none' padding='p-2'>
                    <Span size='sm'>Payment Type</Span>
                </Container>  
                <Container border='left' cols='span-2' radius='none' padding='p-2'> {data?.packageDetails?.paymentType}
                </Container> */}

          <Container
            border="right"
            bg="grey"
            margin="none"
            radius="none"
            padding="p-2"
          >
            <Span size="sm">Declared Value</Span>
          </Container>
          <Container border="left" cols="span-2" radius="none" padding="p-2">
            {" "}
            {data?.packageDetails?.value}
          </Container>

          <Container
            border="right"
            bg="grey"
            margin="none"
            radius="none"
            padding="p-2"
          >
            <Span size="sm">Delivery Fee</Span>
          </Container>
          <Container border="left" cols="span-2" radius="none" padding="p-2">
            {" "}
            {data?.deliveryFee}
          </Container>

          <Container
            border="right"
            bg="grey"
            margin="none"
            radius="none"
            padding="p-2"
          >
            <Span size="sm">Description</Span>
          </Container>
          <Container border="left" cols="span-2" radius="none" padding="p-2">
            {" "}
            {data?.packageDetails?.itemDescription}
          </Container>

          <Container
            border="darkgrey"
            cols="span-3"
            margin="none"
            radius="none"
            padding="p-2"
          >
            <Span size="sm">Proof of Delivery</Span>
            {isLoading ? (
              <SpinnerCircularFixed className="text-brown-1 h-6 w-6" />
            ) : (
              <>
                <a href={data?.proofOfDelivery} target="_blank">
                  <Span color="blue">{data?.proofOfDelivery}</Span>
                </a>
                {data?.status === "delivered" &&
                data?.proofOfDelivery === undefined ? (
                  <>
                    <input
                      type="file"
                      name="file"
                      id="file"
                      onChange={handlePOD}
                      accept="image/*"
                    />
                    <label htmlFor="file" className="cursor-pointer">
                      <Span color="brown" weight="semi">
                        Upload POD
                      </Span>
                    </label>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </Container>
        </GridContainer>
      </div>
    </>
  );
};

export default Package;
