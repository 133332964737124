import moment from 'moment';
import { Span } from '../../../components/common/Span';
import { ROLE_STATUS } from '../../../components/constants/status';

import { ColumnFilter } from "../../../components/Table/ColumnFilter";
import UserStatus from './UserStatus'

export const COLUMNS = [
    {
        accessor: 'id',
        Header: 'ID',
        Filter: ColumnFilter,
        isVisible: true,
    },
    {
        accessor: data => `${data.firstName} ${data.lastName}`,
        Header: 'Name',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
                <span className='capitalize'>
                    {`${d.firstName} ${d.lastName}`}
                </span>
            )
        }
    },
    {
        accessor: 'phoneNumber',
        Header: 'Contact No.',
        Filter: ColumnFilter,
        isVisible: true,
    },
    {
        accessor: 'email',
        Header: 'Email',
        Filter: ColumnFilter,
        isVisible: true,
    },
    {
        accessor: data => data?.role?.roleName,
        Header: 'Role',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
                <span className='capitalize'>
                    {d?.role?.roleName ?? ''}
                </span>
            )
        }
    },
    {
        accessor: 'status',
        Header: 'Status',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
                <div className="flex justify-center whitespace-nowrap">
                    {d.status === 'active' ?
                        <Span border='green' bg='green' padding='px-4' width='full' margin='mx-4' color='green' radius='xl'
                            size='sm'>
                            {ROLE_STATUS[d?.status]}
                        </Span>
                        :
                        <Span border='red' bg='red' padding='px-4' width='full' margin='mx-4' color='red' radius='xl'
                            size='sm'>
                            {ROLE_STATUS[d?.status]}
                        </Span>
                    }
                </div>
            )
        }
    },
    {
        accessor: data => {
            const date = new Date(data?.createdAt?.seconds * 1000);
            const formatedDate = moment(date).format('MMMM Do YYYY, h:mm:ss a')
            return formatedDate;
        },
        Header: 'Date Created',
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            let date = new Date(1000);

            if (d?.createdAt?.seconds) {
                date = new Date(d?.createdAt?.seconds * 1000);
            }

            return <>{moment(date).format('MMMM Do YYYY, h:mm:ss a')}</>
        }
    },
    {
        accessor: ' ',
        Header: ' ',
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
        isVisible: true,
        Cell: data => <UserStatus id={data.row.original.id} email={data.row.original.email} />
    }
]