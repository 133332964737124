import { SpinnerCircularFixed } from 'spinners-react';
import { RootState } from '../store';
import { useSelector } from 'react-redux';

const BackDrop = () => {
    
    const isLoading = useSelector((state: RootState) => state.UI.isLoading);

  return (
      <>
        {isLoading ? (
            <div className='fixed w-screen h-screen items-center justify-center bg-[rgba(0,0,0,0.2)] top-0 left-0 z-50'>
                <SpinnerCircularFixed 
                    className='min-h-screen m-auto'
                    style={{color: '#1c1c1c'}}
                />
            </div>
        ) : (
            <>
            </>
        )}
      </>
  );
};

export default BackDrop;
