import { onlyNumberKey, onlyNumberKeyAndDot } from '../../../../../common/utils'
import { GridContainer, InputContainer } from '../../../../../components/common/Containers'
import { Input } from '../../../../../components/common/Input'
import { Span, SpanContainer } from '../../../../../components/common/Span'

const BasicFare = ({ onChange, data }) => {
    return (
        <div className="pt-4">
            <Span margin="mt-4" size="lg" weight="medium">
                Basic Fare
            </Span>
            <GridContainer grid="two" padding="none">
                <div>
                    <Span>Base Fare</Span>
                    <InputContainer margin="none">
                        <SpanContainer
                            padding="normal"
                            position="absolute"
                            border="right"
                            weight="semi"
                        >
                            {' '}
                            ₱{' '}
                        </SpanContainer>
                        <Input
                            padding="pl-16"
                            type="text"
                            border="grey"
                            required
                            pattern="^[0-9\.]*$"
                            onKeyPress={onlyNumberKeyAndDot}
                            onChange={(e) => onChange(e, 'baseFare')}
                            value={data.baseFare}
                        />
                    </InputContainer>
                </div>

                <div>
                    <Span>Rate Per Kilo</Span>
                    <InputContainer margin="none">
                        <SpanContainer
                            padding="normal"
                            position="absolute"
                            border="right"
                            weight="semi"
                        >
                            {' '}
                            ₱{' '}
                        </SpanContainer>
                        <Input
                            padding="pl-16"
                            type="text"
                            border="grey"
                            required
                            pattern="^[0-9\.]*$"
                            onKeyPress={onlyNumberKeyAndDot}
                            onChange={(e) => onChange(e, 'ratePerKilo')}
                            value={data.ratePerKilo}
                        />
                    </InputContainer>
                </div>

                <div>
                    <Span>Min Weight</Span>
                    <InputContainer margin="none">
                        <SpanContainer
                            padding="normal"
                            position="absolute"
                            border="right"
                            weight="semi"
                        >
                            {' '}
                            Kg{' '}
                        </SpanContainer>
                        <Input
                            padding="pl-16"
                            type="text"
                            border="grey"
                            required
                            pattern="^[0-9\.]*$"
                            onKeyPress={onlyNumberKeyAndDot}
                            onChange={(e) => onChange(e, 'minWeight')}
                            value={data.minWeight}
                        />
                    </InputContainer>
                </div>

                <div>
                    <Span>Max Weight</Span>
                    <InputContainer margin="none">
                        <SpanContainer
                            padding="normal"
                            position="absolute"
                            border="right"
                            weight="semi"
                        >
                            {' '}
                            Kg{' '}
                        </SpanContainer>
                        <Input
                            padding="pl-16"
                            type="text"
                            border="grey"
                            required
                            pattern="^[0-9\.]*$"
                            onKeyPress={onlyNumberKeyAndDot}
                            onChange={(e) => onChange(e, 'maxWeight')}
                            value={data.maxWeight}
                        />
                    </InputContainer>
                </div>

                <div>
                    <Span>Driver Share</Span>
                    <InputContainer margin="none">
                        <SpanContainer
                            padding="normal"
                            position="absolute"
                            border="right"
                            weight="semi"
                        >%</SpanContainer>
                        <Input
                            padding="pl-14"
                            type="text"
                            border="grey"
                            pattern="^[0-9\.]*$"
                            onKeyPress={onlyNumberKey}
                            onChange={(e) => onChange(e, 'driverShare')}
                            value={data?.driverShare ?? 0}
                            required
                        />
                    </InputContainer>
                </div>

                <div>
                    <Span>Commission</Span>
                    <InputContainer margin="none">
                        <SpanContainer
                            padding="normal"
                            position="absolute"
                            border="right"
                            weight="semi"
                        >%</SpanContainer>
                        <Input
                            padding="pl-14"
                            type="text"
                            border="grey"
                            pattern="^[0-9\.]*$"
                            onKeyPress={onlyNumberKey}
                            onChange={(e) => onChange(e, 'commission')}
                            value={data?.commission ?? 0}
                            required
                        />
                    </InputContainer>
                </div>
            </GridContainer>
        </div>
    )
}

export default BasicFare