import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Login from './pages/index';
import Parcels from './pages/parcels';
import ParcelsView from './pages/parcelsView';
import Express from './pages/express';
import Customers from './pages/customers';
import Users from './pages/users';
import ExpressView from './pages/expressView';
import Logout from './pages/logout';
import Driver from './pages/driver';
import ExpressRate from './pages/expressRate';
import ExpressRateView from './pages/expressRateView';
import ParcelRate from './pages/parcelRate';
import ParcelRateView from './pages/parcelRateView';
import PartialRate from './pages/partialRate';
import PartialRateView from './pages/partialRateView';
import WalletListView from './pages/walletListView';
import TransactionLog from './pages/transaction';
import WithdrawalRequest from './pages/withdrawalRequest';
import Billing from './pages/billing';
import BillingView from './pages/billingView';
import RoleManagement from './pages/roleManagement';
import WarehouseManagement from './pages/whManagement';
import NotFound from './pages/notFound';
import InterislandRate from './pages/interislandRate';
import InterislandRateView from './pages/interislandRateView';

import AuthRoute from './components/AuthRoute/AuthRoute';
import Verification from './containers/Verification/Verification';
import ChangePassword from './containers/ChangePassword/ChangePassword';
import Upload from './containers/Upload/Upload';
import WalletList from './pages/walletList';

import { getAuth, onAuthStateChanged } from 'firebase/auth';

import './Firebase';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, Dispatch } from './store';
import Interisland from './pages/interisland';
import InterislandView from './pages/interislandView';

function App() {
  // Set an initializing state whilst Firebase connects
  const dispatch = useDispatch<Dispatch>();
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState();
  const userRole = useSelector((state: RootState) => state.User.userInfo!);

  function authStateChanged(user) {
    setUser(user);
    if (initializing) setInitializing(false);
  }

  useEffect(() => {
    const auth = getAuth();
    const subscriber = onAuthStateChanged(auth, authStateChanged);
    return subscriber;
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route
          path="/verifyemailandresetpassword"
          exact
          component={Verification}
        />
        <Route path="/changepassword" exact component={ChangePassword} />
        <Route path="/logout" exact component={Logout} />
        <Route path="/upload" exact component={Upload} />
        <AuthRoute
          path="/billing/view/:id"
          component={
            userRole?.permissions?.manageBilling ? BillingView : NotFound
          }
        />
        <AuthRoute
          path="/billing"
          component={userRole?.permissions?.manageBilling ? Billing : NotFound}
        />
        <AuthRoute
          path="/parcels"
          component={userRole?.permissions?.manageParcel ? Parcels : NotFound}
        />
        <AuthRoute
          path="/express"
          component={userRole?.permissions?.manageExpress ? Express : NotFound}
        />
        <AuthRoute
          path="/expres/view/:id/:tab"
          component={
            userRole?.permissions?.manageExpress ? ExpressView : NotFound
          }
        />
        <AuthRoute
          path="/interisland"
          component={userRole?.permissions?.manageInterisland ? Interisland : NotFound}
        />
        <AuthRoute
          path="/inter/view/:id/:tab"
          component={
            userRole?.permissions?.manageInterisland ? InterislandView : NotFound
          }
        />
        <AuthRoute
          path="/parcel/view/:parcelId/:tab"
          component={
            userRole?.permissions?.manageParcel ? ParcelsView : NotFound
          }
        />
        <AuthRoute
          path="/customers"
          component={
            userRole?.permissions?.manageCustomers ? Customers : NotFound
          }
        />
        <AuthRoute
          path="/users"
          component={userRole?.permissions?.manageUsers ? Users : NotFound}
        />
        <AuthRoute
          path="/driver"
          component={userRole?.permissions?.manageDrivers ? Driver : NotFound}
        />
        <AuthRoute
          path="/rate/expressRate"
          component={userRole?.permissions?.manageRate ? ExpressRate : NotFound}
        />
        <AuthRoute
          path="/rate/express/:rateExpressId/:id?"
          component={
            userRole?.permissions?.manageRate ? ExpressRateView : NotFound
          }
        />
         <AuthRoute
          path="/rate/interislandRate"
          component={userRole?.permissions?.manageRate ? InterislandRate : NotFound}
        />
        <AuthRoute
          path="/rate/interisland/:rateInterislandId/:id?"
          component={
            userRole?.permissions?.manageRate ? InterislandRateView : NotFound
          }
        />
        <AuthRoute
          path="/rate/parcelRate"
          component={userRole?.permissions?.manageRate ? ParcelRate : NotFound}
        />
        <AuthRoute
          path="/rate/parcel/:rateParcelId/:id?"
          component={
            userRole?.permissions?.manageRate ? ParcelRateView : NotFound
          }
        />
        <AuthRoute
          path="/rate/partialRate"
          component={userRole?.permissions?.manageRate ? PartialRate : NotFound}
        />
        <AuthRoute
          path="/rate/partial/:ratePartialId/:id?"
          component={
            userRole?.permissions?.manageRate ? PartialRateView : NotFound
          }
        />
        <AuthRoute
          path="/wallet/walletList"
          component={
            userRole?.permissions?.manageWallet ? WalletList : NotFound
          }
        />
        <AuthRoute
          path="/wallet/list/:id?/:type"
          component={
            userRole?.permissions?.manageWallet ? WalletListView : NotFound
          }
        />
        <AuthRoute
          path="/wallet/transactionLog"
          component={
            userRole?.permissions?.manageWallet ? TransactionLog : NotFound
          }
        />
        <AuthRoute
          path="/wallet/withdrawalRequest"
          component={
            userRole?.permissions?.manageWallet ? WithdrawalRequest : NotFound
          }
        />
        <AuthRoute
          path="/role"
          component={
            userRole?.permissions?.manageRole ? RoleManagement : NotFound
          }
        />
        <AuthRoute
          path="/warehouse"
          component={
            userRole?.permissions?.manageWarehouse ? WarehouseManagement : NotFound
          }
        />
        <AuthRoute path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
