import { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../../store";
import { Link } from 'react-router-dom';
import { Span } from '../../components/common/Span'
import { Button } from '../../components/common/Button'
import Loader from '../../components/Loader';
import { useLocation } from 'react-router-dom';

import Table from './Table/Table'
import { COLUMNS } from './Table/column'
import NoData from '../../components/common/NoData/NoData';

import { NEW, ONGOING, DELIVERED, CANCELED, TAB } from '../../components/constants/status';

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export default () => {
    const dispatch = useDispatch<Dispatch>();
    const query = useQuery();
    const tab: any = query.get('tab');
    const [status, setStatus] = useState(TAB[tab]) as any[];
    const [isLoading, setIsLoading] = useState(true);

    const unfilteredData: any[] = useSelector(
        (state: RootState) => state.Table.tables.express?.data
    );

    const getTime = (date) => {
        if(date.seconds){
            return date.seconds
        }else{
            return new Date(date).getTime() / 1000
        }
    }

    const data: any[] = unfilteredData.length > 0 ? unfilteredData?.filter(item => {
        if (status?.includes(item.status)) {
            return item
        }
        return null;
    }).sort((a, b) => { return getTime(a.deliverySchedule) - getTime(b.deliverySchedule) }) : [];

    const filters: any = useSelector((state: RootState) => state.Table.tables.express?.filters);

    const columns = useMemo(() => COLUMNS, []);

    useEffect(() => {
        dispatch.Table.subscribeToFirebase({ name: 'orders', table: 'express' })
        setIsLoading(false);

        return () => {
            dispatch.Table.UNSUBSCRIBE()
        }
    }, [filters])
    return (<>
        {isLoading ? (
            <Loader />
        ) : (
            <div className="border border-grey-1 rounded">
                <div className='p-4'>
                    <Span margin='mt-4' weight='bold' size='2xl'>Express List</Span>
                    <div className='flex mt-6'>
                        <Link to='/express?tab=new'>
                            <Button
                                onClick={() => setStatus(NEW)}
                                radius='none' width='none' border={status?.includes('new') ? 'bottom-grey' : 'bottom'} bg='none' color='black'
                                padding='px-4'>
                                New
                            </Button>
                        </Link>
                        <Link to='/express?tab=ongoing'>
                            <Button
                                onClick={() => setStatus(ONGOING)}
                                radius='none' width='none' border={status?.includes('ongoing') ? 'bottom-grey' : 'bottom'} bg='none' color='black'
                                padding='px-4'>
                                Ongoing
                            </Button>
                        </Link>
                        <Link to='/express?tab=delivered'>
                            <Button
                                onClick={() => setStatus(DELIVERED)}
                                radius='none' width='none' border={status?.includes('delivered') ? 'bottom-grey' : 'bottom'} bg='none' color='black'
                                padding='px-4'>
                                Completed
                            </Button>
                        </Link>
                        <Link to='/express?tab=canceled'>
                            <Button
                                onClick={() => setStatus(CANCELED)}
                                radius='none' width='none' border={status?.includes('canceled') ? 'bottom-grey' : 'bottom'} bg='none' color='black'
                                padding='px-4'>
                                Canceled
                            </Button>
                        </Link>
                    </div>
                </div>

                <div className='overflow-auto'>
                    {data?.length < 0
                        ? <NoData />
                        : <Table columns={columns} data={data} />
                    }
                </div>
            </div>
        )}
    </>
    )
}
