import { Span } from '../../../../components/common/Span'  
import { GridContainer, Container } from '../../../../components/common/Containers' 

const Customer = ({data}:any) => {
    return (
        <>
        <Span weight='semi' size='lg' padding='py-4'>Shipper Information</Span>
        <GridContainer gap='none' padding='py-4'>
            <Container border='right' bg='grey' margin='none' radius='none' padding='p-2'>
                <Span size='sm'>Shipper Name</Span>
            </Container>  
            <Container border='left' cols='span-2' radius='none' padding='p-2'> 
                <Span transform='capitalize'>{data?.shipper?.name}</Span> 
            </Container>

            <Container border='right' bg='grey' margin='none' radius='none' padding='p-2'>
                    <Span size='sm'>Pick Up Area</Span>
                </Container>  
                <Container border='left' cols='span-2' radius='none' padding='p-2'> {data?.shipper?.pickUpArea}</Container>

            <Container border='right' bg='grey' margin='none' radius='none' padding='p-2'>
                <Span size='sm'>Address</Span>
            </Container>  
            <Container border='left' cols='span-2' radius='none' padding='p-2'>{data?.shipper?.locationAddress} </Container>

            <Container border='right' bg='grey' margin='none' radius='none' padding='p-2'>
                <Span size='sm'>Email</Span>
            </Container>  
            <Container border='left' cols='span-2' radius='none' padding='p-2'> {data?.shipper?.email} </Container>

            <Container border='right' bg='grey' margin='none' radius='none' padding='p-2'>
                <Span size='sm'>Mobile</Span>
            </Container>  
            <Container border='left' cols='span-2' radius='none' padding='p-2'> {`${data?.shipper?.phoneNumber}`} </Container>
        </GridContainer>
        </>
  );
};

export default Customer;