import { useState } from 'react';
import { Span, SpanContainer } from '../../../../../components/common/Span';
import { Input } from '../../../../../components/common/Input';
import { Button } from '../../../../../components/common/Button';
import { IoCloseSharp } from 'react-icons/io5';
import PostErrorAlert from '../../../../../components/PostErrorAlert';
import BackDrop from '../../../../../components/BackDrop';
import { addPartialSettings } from '../../../../../common/api';
import { Dispatch, RootState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import '../../../CSS/Rate.css';
import { GridContainer, InputContainer } from '../../../../../components/common/Containers';
import { onlyNumberKey, onlyNumberKeyAndDot } from '../../../../../common/utils';
import { Select } from '../../../../../components/common/Select';

type Props = {
  setAddRateSetting: Function;
  rateSetting: boolean;
};

export default ({ setAddRateSetting, rateSetting, ...props }: Props) => {
  let { ratePartialId }: any = useParams();

  const warehouse = useSelector((state: RootState) => state.Table.tables.warehouse.data);

  const inititalState = {
    baseFare: '',
    commission: '',
    standardPricePerKM: '',
    driverShare: '',
    maxWeight: '',
    warehouse: '',
  }

  const [openAlert, setOpenAlert] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [data, setData] = useState(inititalState);

  const dispatch = useDispatch<Dispatch>();

  const onChange = (e: any, name: string) => {
    setData({
      ...data,
      [name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch.UI.setIsLoading(true);
      const payload = {
        ...data,
        baseFare: Number(data.baseFare),
        commission: Number(data.commission),
        standardPricePerKM: Number(data.standardPricePerKM),
        driverShare: Number(data.driverShare),
        maxWeight: Number(data.maxWeight),
      }
      const res = await addPartialSettings(ratePartialId, payload);
      if (res.isSuccess) {
        setAddRateSetting(false);
        dispatch.UI.setAlert({ message: 'Add rate Setting', type: 'Success' });
        dispatch.UI.setIsLoading(false);
        setData(inititalState);
      }
    } catch (err: any) {
      const errMsg = err?.data?.error === undefined ? 'Unable to add rate Setting' : err.data.error;
      setOpenAlert(true);
      setErrMessage(errMsg);
      dispatch.UI.setIsLoading(false);
    }
  };

  return (
    <>
      {rateSetting && (
        <form onSubmit={handleSubmit}>
          <div className="side-rate">
            <BackDrop />
            <div className="flex py-4 border-b border-grey-6">
              <IoCloseSharp
                onClick={() => {
                  setData(inititalState);
                  setAddRateSetting(false)
                }}
                className="my-auto mx-2 text-lg cursor-pointer"
              />
              <Span weight="semi" size="lg">
                Add Rate
              </Span>
            </div>

            <div className="pt-4">
              <Span margin="mt-4" size="lg" weight="medium">
                Basic Fare
              </Span>
              <GridContainer grid="two" padding="none">
                <div>
                  <Span margin="mt-4">Basic Rate</Span>
                  <InputContainer margin="none">
                    <SpanContainer
                      padding="normal"
                      position="absolute"
                      border="right"
                      weight="semi"
                    >₱</SpanContainer>
                    <Input
                      padding="pl-12"
                      type="text"
                      border="grey"
                      pattern="^[0-9\.]*$"
                      onKeyPress={onlyNumberKeyAndDot}
                      onChange={(e) => onChange(e, 'baseFare')}
                      value={data?.baseFare ?? 0}
                      required
                    />
                  </InputContainer>
                </div>

                <div>
                  <Span margin="mt-4">Price per Km</Span>
                  <InputContainer margin="none">
                    <SpanContainer
                      padding="normal"
                      position="absolute"
                      border="right"
                      weight="semi"
                    >₱</SpanContainer>
                    <Input
                      padding="pl-12"
                      type="text"
                      border="grey"
                      pattern="^[0-9\.]*$"
                      onKeyPress={onlyNumberKeyAndDot}
                      onChange={(e) => onChange(e, 'standardPricePerKM')}
                      value={data?.standardPricePerKM ?? 0}
                      required
                    />
                  </InputContainer>
                </div>

                <div>
                  <Span>Commission</Span>
                  <InputContainer margin="none">
                    <SpanContainer
                      padding="normal"
                      position="absolute"
                      border="right"
                      weight="semi"
                    >%</SpanContainer>
                    <Input
                      padding="pl-14"
                      type="text"
                      border="grey"
                      pattern="^[0-9\.]*$"
                      onKeyPress={onlyNumberKey}
                      onChange={(e) => onChange(e, 'commission')}
                      value={data?.commission ?? 0}
                      required
                    />
                  </InputContainer>
                </div>

                <div>
                  <Span>Driver Share</Span>
                  <InputContainer margin="none">
                    <SpanContainer
                      padding="normal"
                      position="absolute"
                      border="right"
                      weight="semi"
                    >%</SpanContainer>
                    <Input
                      padding="pl-14"
                      type="text"
                      border="grey"
                      pattern="^[0-9\.]*$"
                      onKeyPress={onlyNumberKey}
                      onChange={(e) => onChange(e, 'driverShare')}
                      value={data?.driverShare ?? 0}
                      required
                    />
                  </InputContainer>
                </div>

                <div>
                  <Span>Max Weight</Span>
                  <InputContainer margin="none">
                    <SpanContainer
                      padding="normal"
                      position="absolute"
                      border="right"
                      weight="semi"
                    >Kg</SpanContainer>
                    <Input
                      padding="pl-16"
                      type="text"
                      border="grey"
                      pattern="^[0-9\.]*$"
                      onKeyPress={onlyNumberKey}
                      onChange={(e) => onChange(e, 'maxWeight')}
                      value={data?.maxWeight ?? 0}
                      required
                    />
                  </InputContainer>
                </div>

              </GridContainer>

              <GridContainer grid='two' padding='none'>
                <div>
                  <Span margin='mt-4'>Warehouse</Span>
                  <InputContainer margin="none">
                    <Select
                      required
                      onChange={(e) => onChange(e, 'warehouse')}
                    >
                      <option disabled selected>Choose Warehouse</option>
                      {warehouse?.map(wh => (
                        <option key={wh?.id} value={wh?.id}>{wh?.name}</option>
                      ))}
                    </Select>
                  </InputContainer>
                </div>

              </GridContainer>
            </div>


            <PostErrorAlert
              openAlert={openAlert}
              setOpenAlert={setOpenAlert}
              message={errMessage}
            />

            <div className="flex absolute inset-x-0 bottom-0 mb-8 p-4 text-center">
              <Button
                margin="mx-2"
                bg="white"
                border="lightgrey"
                color="black"
                onClick={() => {
                  setData(inititalState);
                  setAddRateSetting(false)
                }}
              >
                Cancel
              </Button>
              <Button margin="mx-2" type="submit">
                Create
              </Button>
            </div>
          </div>
        </form>
      )
      }
    </>
  );
};

