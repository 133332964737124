import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';
import { debounce } from "lodash";
import { getAutoCompleteSuggestions, getPlaceDetails } from '../../common/api';
import { Autocomplete, TextField } from '@mui/material';
import { Span } from '../common/Span';
import location_icon from '../../images/Location.png'

const Location = ({ warehouse, setWarehouse }) => {
    const [locations, setLocations] = useState([]) as any[];
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState({
        address: warehouse?.location?.address ?? null,
        place_id: null
    })

    const getPlace = async () => {
        try {
            const res = await getPlaceDetails(data?.place_id);
            if (res.isSuccess) {
                const { location } = res?.result?.geometry;
                setWarehouse({
                    ...warehouse,
                    location: {
                        ...location,
                        address: data?.address,
                    }
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    const deb = useCallback(debounce(async (input) => {
        if (input.length > 3) {
            setIsLoading(true)
            try {
                const { predictions } = await getAutoCompleteSuggestions(input);

                setLocations(predictions);
                setIsLoading(false)
            } catch (err) {
                console.log(err);
                setIsLoading(false)
            }
        }
    }, 1000), []);

    const renderInput = (params) => {
        return <TextField
            placeholder='Select Address...'
            sx={{
                '& legend': {
                    display: 'none'
                },
                '& fieldset': {
                    top: 0
                },
            }}
            onChange={(e: any) => {
                setData({ ...data, address: e.target.value })
                deb(e.target.value)
            }}
            required
            {...params}
        />
    }

    useEffect(() => {
        deb('brgy ')
    }, [])

    useEffect(() => {
        if (data?.place_id) getPlace();
    }, [data?.place_id])

    return (
        <>
            <Span margin='mt-4'>Address</Span>
            <Autocomplete
                disablePortal
                disableClearable
                loading={isLoading}
                filterOptions={x => x}
                size="small"
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                onFocus={() => setOpen(true)}
                id="combo-box-demo"
                options={locations}
                inputValue={data?.address}
                onChange={(e: any, newValue: any) => {
                    setData({ ...data, address: newValue?.description, ...newValue })
                }}
                getOptionLabel={(option: any) => {
                    const data = option;
                    return data?.description ?? '';
                }}
                ListboxProps={{
                    style: {
                        maxHeight: '220px',
                    }
                }}
                renderOption={(props, option) => {
                    const data = option;
                    return (<>
                        <li {...props}>
                            <div className="flex flex-row items-center justify-center">
                                <img src={location_icon} alt="" className="w-6 h-6 object-contain" />
                                <Span>{data.description}</Span>
                            </div>
                        </li>
                    </>)
                }}
                renderInput={(params) => renderInput(params)}
            />
        </>
    )
}

export default Location