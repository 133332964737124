import { getUrl } from "../../utils";
import { post, put, remove, get } from "../core";

export const createCustomer = async (body: any) => {
  try {
    body = {
      ...body,
      phoneNumber: `+63${body.phoneNumber}`,
      url: `${getUrl()}verifyemailandresetpassword`
    }
    let res = await post(`/customer/create/user-account`, body);
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteCustomer = async (id: any) => {
  try {
    let res = await remove(`/customer/delete/user-account/${id}`);
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateCustomer = async ({ id, updatedCustomer }: any) => {
  try {
    const body = {
      phoneNumber: `+63${updatedCustomer.phoneNumber}`,
      firstName: updatedCustomer?.name?.split(' ')[0],
      lastName: updatedCustomer?.name?.split(' ')[1],
      email: updatedCustomer.email,
    }
    const res = await put(`/customer/update/user-account/${id}`, body);
    return res.data
  } catch (err) {
    return Promise.reject(err);
  }
}

export const setCustomerPassword = async (userId: any, password: any) => {
  try {
    const res = await put(`/admin/set-customer-password/${userId}`, password)
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
}

export const verifyCustomerSetPassword = async (userId: any) => {
  try {
    const res = await get(`/admin/check-customer-password/${userId}`)
    return res.data;
  } catch (err) {
    return Promise.reject(err)
  }
}

export const sendCustomerChangePasswordEmail = async (body: any) => {
  try {
    const res = await post('/admin/customer/reset-password', body)
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
}