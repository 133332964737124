import React, { useCallback, useMemo } from 'react'
import { Grid, Button, useTheme, Box, Stack, InputLabel, FormHelperText, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { UploadOutlined } from "@ant-design/icons";
import jwtDecode from 'jwt-decode';
import { object } from './object';

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

const Documents = ({ values, handleBlur, errors, handleChange, touched }) => {
    const theme = useTheme();
    const query = useQuery();
    const token: any = query.get('token');
    const decoded = jwtDecode(token);

    const buttonProps: any = (name) => ({
        fullWidth: true,
        variant: "outlined",
        component: "label",
        disableElevation: true,
        sx: {
            borderColor: `${theme.palette.divider}`,
            textTransform: 'capitalize',
            color: 'gray',
            height: '250px',
            background: `url(${values[name] ? URL?.createObjectURL(values[name]) : ''})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
        }
    })

    const boxProps: any = {
        sx: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }
    }

    const OBJECT = useMemo(() => object, [])
    
    return (
        <>
            <Grid container spacing={2}>
                {OBJECT?.map(({ name, label }) => (
                    <Grid key={name} item xs={12} md={4} sx={{ padding: '0 3rem', }}>
                        <Stack spacing={1}>
                            <InputLabel
                                htmlFor={name}
                                sx={{
                                    width: '100%',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    padding: '0 10px'
                                }}
                            >
                                {values[name]?.name ?? '...'}
                            </InputLabel>
                            <Button {...buttonProps(name)} onBlur={handleBlur(name)}>
                                <Box {...boxProps}>
                                    <UploadOutlined className=' text-[3rem]' />
                                    <Typography textAlign='center'>{label}</Typography>
                                </Box>
                                <input
                                    id={name}
                                    hidden
                                    accept="image/*"
                                    type="file"
                                    name={name}
                                    onChange={(e: any) => handleChange({ target: { name, value: e.target.files[0] } })}
                                />
                            </Button>
                            {touched[name] && errors[name] && (
                                <FormHelperText error id={name} sx={{ alignSelf: 'center' }}>
                                    {errors[name]}
                                </FormHelperText>
                            )}
                        </Stack>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default Documents