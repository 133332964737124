import { Span } from '../../../../components/common/Span'

import { ColumnFilter } from '../../../../components/Table/filter';   
import moment from 'moment'; 
import { TRANSACTION } from '../../../../components/constants/status' 

export const COLUMNS = [
    {
        id: 'id',
        field: 'id',
        accessor: 'id',
        Header: 'Reference No.',
        Filter: ColumnFilter, 
        isVisible: true,
    },
    {
        id: 'name',
        field: 'name',
        accessor: data => data?.wallet?.name,
        Header: 'Name',
        Filter: ColumnFilter, 
        isVisible: true,
        Cell: data => {
            const d = data.row.original?.wallet;
            return(
                <span className='capitalize'>{d?.name ?? ''}</span>
            )
        }        
    },
    {    
        accessor: data => `${data?.wallet?.type?.email} ${data?.wallet?.type?.phoneNumber}`,
        Header: 'Email/ Phone No.',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data.row.original?.wallet;
            return(
                <span>{`${d?.email ?? ''} ${d?.phoneNumber ?? ''}`}</span>
            )
        }    
    },      
    {
        id: 'userType',
        field: 'userType',
        accessor: data => data?.wallet?.type,
        Header: 'User Type',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data.row.original?.wallet;
            return(
                <span className='capitalize'>{d?.type ?? ''}</span>
            )
        }        
    },  
    {
        id: 'transactionType',
        field: 'transactionType',
        accessor: 'transactionType',
        Header: 'Transaction Type',
        Filter: ColumnFilter,
        isVisible: true,    
        Cell: data => {
            const d = data.row.original;
            return(
                <div className='justify-center whitespace-nowrap'>
                    <span className='capitalize'>
                        {TRANSACTION[d?.transactionType]}
                    </span>  
                </div>
            )
        }    
    }, 
    {   
        accessor: data => {
            const date = new Date(data?.createdAt?.seconds * 1000);
            const formatedDate = moment(date).format('MMMM Do YYYY, h:mm:ss a')
            return formatedDate;
        },
        id:'date',
        field: 'date',
        Header: 'Date Created',
        isVisible: true,
        Cell: data => {
        const d = data.row.original.createdAt;
        let date = new Date(1000);

        if(d?.seconds){
            date = new Date(d?.seconds * 1000);
        }
        return <>{moment(date).format('MMMM Do YYYY, h:mm:ss a')}</>   }
    },  
    {
        id: 'amount',
        field: 'amount',
        accessor: 'amount',
        Header: 'Amount',
        Filter: ColumnFilter, 
        isVisible: true,  
        Cell: data => {
            const d = data.row.original;
            const amount: any = d?.amount ?? 0;
            return (
            <span> 
                ₱ {parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </span>    
            ); 
        }        
    }
]