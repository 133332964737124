/* eslint-disable import/no-anonymous-default-export */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Span } from "../../../components/common/Span";
import { Button } from "../../../components/common/Button";
import { GridContainer } from "../../../components/common/Containers";

import { ImArrowLeft2 } from "react-icons/im";
import { BiPrinter } from "react-icons/bi";

import { useParams } from "react-router-dom";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";

import { generatePdf, syncParcel } from "../../../common/api";

import PackageInformation from "./Information/Package";
import ConsigneeInformation from "./Information/Consignee";
import CustomerInformation from "./Information/Customer";
import StatusButton from "./StatusButton";
import Loader from "../../../components/Loader";

let UNSUBSCRIBE: any;

export default () => {
  let { parcelId, tab } = useParams();
  const db = getFirestore();

  let [data, setData] = useState({} as any);
  const [isLoading, setIsLoading] = useState(true);

  let { status } = data;

  const getData = async () => {
    if (UNSUBSCRIBE) UNSUBSCRIBE();

    UNSUBSCRIBE = onSnapshot(doc(db, "parcel", parcelId), (doc) => {
      if (doc.exists()) {
        setData(doc.data());
        setIsLoading(false);
      } else {
        console.log("No such document!");
        setIsLoading(false);
      }
    });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="border border-grey-1 rounded p-4">
          <div className="flex justify-between my-4">
            <div className="flex py-4">
              <Link to={`/parcels?tab=${tab}`} className="my-auto text-grey-14">
                <ImArrowLeft2 />
              </Link>
              <Span weight="bold" size="lg" padding="px-4">
                View #{data.bookingNumber}
              </Span>
            </div>

            <Button
              width="none"
              bg="blue"
              onClick={() => {
                generatePdf(parcelId);
              }}
            >
              <div className="flex pr-2">
                <BiPrinter className="my-auto mx-2 text-2xl" />
                Print Waybill
              </div>
            </Button>
          </div>

          <div className="flex mt-8 mb-6">
            <Button
              width="none"
              bg="blue"
              onClick={() => {
                syncParcel(parcelId);
              }}
            >
              <div className="flex pr-2 pl-2">Sync parcel to Odoo</div>
            </Button>
          </div>

          <GridContainer grid="two" padding="py-4">
            <PackageInformation data={data} />
            <div>
              <ConsigneeInformation data={data} />
              <CustomerInformation data={data} />
            </div>
          </GridContainer>

          <div className="flex justify-between">
            <StatusButton parcelId={parcelId} status={status} />
          </div>
        </div>
      )}
    </>
  );
};
