import { Steps } from 'antd'
import { formatDateV2 } from '../../../../common/utils/helpers';
import './HistoryStatus.scss'

const { Step } = Steps;

export default ({ express }: any) => {

    const pendingSchedule = express?.createdAt ? formatDateV2(express?.createdAt) : '';
    const assignedSchedule = express?.pickup?.assignedAt || express?.pickup?.reassignedAt ? formatDateV2(express?.pickup?.assignedAt ?? express?.pickup?.reassignedAt) : '';
    const forpickupSchedule = express?.forpickupAt ? formatDateV2(express?.forpickupAt) : formatDateV2(express?.pickup?.acceptedAt);
    const pickupSchedule = express?.pickedupAt ? formatDateV2(express?.pickedupAt) : formatDateV2(express?.pickup?.pickUpAt);
    const fortransferSchedule = express?.fortransferAt ? formatDateV2(express?.fortransferAt) :  formatDateV2(express?.pickup?.forTransferAt);
    const ongoingTransferSchedule = express?.ongoingtransferAt ? formatDateV2(express?.ongoingtransferAt) : '';
    const transferredSchedule = express?.transferredAt ? formatDateV2(express?.transferredAt) : '';
    const assignedDeliverySchedule = express?.dropOff?.assignedAt || express?.dropOff?.reassignedAt ? formatDateV2(express?.dropOff?.assignedAt ?? express?.dropOff?.reassignedAt) : '';
    const forDeliverySchedule = express?.fordeliveryAt ? formatDateV2(express?.fordeliveryAt) : formatDateV2(express?.dropOff?.acceptedAt);
    const ongoingDeliverySchedule = express?.ongoingdeliveryAt ? formatDateV2(express?.ongoingdeliveryAt) : formatDateV2(express?.dropOff?.pickUpAt);
    const deliveredSchedule = express?.deliveredAt ? formatDateV2(express?.deliveredAt) : formatDateV2(express?.arrivedAtDropOffPoint);
    const canceledSchedule = express?.canceledAt ? formatDateV2(express?.canceledAt) : '';
    const failedSchedule = express?.faileddeliveryAt ? formatDateV2(express?.faileddeliveryAt) : '';

    let StatusExpress: any = [
        {
            label: 'Pending',
            value: 'pending',
            timestamp: pendingSchedule,
        },
        {
            label: 'Assigned',
            value: 'assigned',
            timestamp: assignedSchedule,
        },
        {
            label: 'For PickUp',
            value: 'for_pickup',
            timestamp: forpickupSchedule,
        },
        {
            label: 'Picked Up',
            value: 'picked_up',
            timestamp: pickupSchedule,
        },
        {
            label: 'For Transfer',
            value: 'for_transfer',
            timestamp: fortransferSchedule,
        },
        {
            label: 'Ongoing Transfer',
            value: 'ongoing_transfer',
            timestamp: ongoingTransferSchedule,
        },
        {
            label: 'Transferred',
            value: 'transferred',
            timestamp: transferredSchedule,
        },
        {
            label: 'Assigned Delivery',
            value: 'assigned_delivery',
            timestamp: assignedDeliverySchedule,
        },
        {
            label: 'For Delivery',
            value: 'for_delivery',
            timestamp: forDeliverySchedule,
        },
        {
            label: 'Ongoing Delivery',
            value: 'ongoing_delivery',
            timestamp: ongoingDeliverySchedule,
        },
        {
            label: 'Delivered',
            value: 'delivered',
            timestamp: deliveredSchedule,
        },
        {
            label: 'Canceled',
            value: 'canceled',
            timestamp: canceledSchedule,
        },
        {
            label: 'Failed Delivery',
            value: 'failed_delivery',
            timestamp: failedSchedule,
        },
    ]

    const FILTER = (str) => {
        const res = StatusExpress?.filter(s => s.value !== str)
        return res;
    }

    StatusExpress = express?.pickup?.assignedAt || express?.pickup?.reassignedAt ? FILTER('none') : FILTER('assigned');
    StatusExpress = express?.dropOff?.assignedAt || express?.dropOff?.reassignedAt ? FILTER('none') : FILTER('assigned_delivery');
    StatusExpress = express?.status !== 'canceled' ? FILTER('canceled') : FILTER('none');
    StatusExpress = express?.status !== 'failed_delivery' ? FILTER('failed_delivery') : FILTER('none');
    StatusExpress = ['failed_delivery', 'canceled'].includes(express?.status)  ? FILTER('delivered') : FILTER('none');
    const currentIndex = StatusExpress?.findIndex(item => item.value === express?.status);

    return (
        <>
            <Steps direction='vertical' size="small" current={currentIndex} style={{height: '80vh'}}>

                {StatusExpress?.map((item, index) => (
                    <Step
                        title={item.label}
                        key={index}
                        description={currentIndex >= index ? item.timestamp.includes('Invalid date') ? '' : item.timestamp : ''}
                    />
                ))}
            </Steps>
        </>
    );
}; 
