import { SpinnerCircularFixed } from 'spinners-react';     

export default () => { 
    return <>
        <div className='my-auto items-center overscroll-none'> 
            <SpinnerCircularFixed 
                className='min-h-screen m-auto'
                style={{color: '#1c1c1c'}}  
            />
        </div> 
    </>
} 