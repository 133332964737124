import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { Span } from '../common/Span';
import location_icon from '../../images/Location.png'

type Props = {
    onChange: Function,
    value: any,
    options: Array<any>,
    placeholder: string,
    disabled?: boolean
}

const SingleInput = ({ onChange, value, options, placeholder, disabled }: Props) => {

    const renderInput = (params) => {
        return <TextField
            required
            placeholder={placeholder}
            variant='outlined'
            sx={{
                "& .MuiOutlinedInput-root": {
                    '& legend': {
                        display: 'none'
                    },
                    '& fieldset': {
                        top: 0,
                    },
                },
                "& .MuiOutlinedInput-root.Mui-focused": {
                    "& fieldset": {
                        borderWidth: '1px'
                    }
                },
                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#e5e7eb !important'
                }
            }}
            {...params}
        />
    }

    return (
        <>
            <Autocomplete
                disabled={disabled}
                aria-required
                disableClearable
                size="small"
                id="combo-box-demo"
                options={options}
                value={value}
                onChange={(e, value) => onChange({ target: { value } })}
                getOptionLabel={(option: any) => option}
                ListboxProps={{
                    style: {
                        maxHeight: '220px',
                    }
                }}
                renderOption={(props, option) => {
                    const data = option;
                    return (<>
                        <li {...props}>
                            <div className="flex flex-row items-center justify-center">
                                <img src={location_icon} alt="" className="w-6 h-6 object-contain" />
                                <Span>{data}</Span>
                            </div>
                        </li>
                    </>)
                }}
                renderInput={(params) => renderInput(params)}
            />
        </>
    )
}

export default SingleInput