import ExpressViewPage from '../containers/Express/View/ExpressView'  
import Header from '../components/Header/Header' 
import SidebarPage from '../components/Sidebar/Sidebar'
import { MainContainer } from '../components/common/Containers'  
import { useParams } from 'react-router-dom'
 
const ExpressView = () => {

    let params:any = useParams();

    console.log("params", params) 

    return ( 
        <>  
            <SidebarPage />
            <MainContainer>    
                <Header /> 
                <main> 
                    <ExpressViewPage id={params.id}/>  
                </main> 
            </MainContainer>
        </>
    )
}

export default ExpressView
