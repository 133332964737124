import {useState, useEffect} from 'react'
import { ModalContainer } from '../../../../components/common/Containers'
import { Button } from '../../../../components/common/Button' 
import { Span } from '../../../../components/common/Span' 
import questionIcon from '../../../../images/Qmark_icon.png'
import { deleteBillingCustomer } from '../../../../common/api';
import { Dispatch } from '../../../../store';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

type Props = {
    setDeleteModal: Function,
    id: string,
    deleteModal: boolean,
    user: string
} 
  
export default ({setDeleteModal, id, deleteModal, user, ...props}: Props) => { 
    
    const dispatch = useDispatch<Dispatch>();
    const billingId = useParams()?.id;

    const handleDelete = async () => {
        try{
            dispatch.UI.setIsLoading(true);
            setDeleteModal(false)
            const res = await deleteBillingCustomer({customerId: id, billingId})
            dispatch.UI.setAlert({message:'Customer deleted', type:'Success'})
            dispatch.UI.setIsLoading(false);
        }catch(err: any){
            console.log(err)
            const errMsg = err?.data?.error ?? 'Unable to delete customer';
            dispatch.UI.setAlert({message:errMsg, type:'Error'})
            dispatch.UI.setIsLoading(false);
        }
    };

    return (<>    
    {deleteModal && <>
        <ModalContainer>  
            <div className="px-4 py-6 whitespace-normal"> 
                    <div className='flex'>
                        
                <img src={questionIcon} alt={questionIcon} className='h-8 mr-2' />
                    <div className='text-left'>  
                    <Span size='lg' weight='bold'>Are you sure you want to delete this customer?</Span>
                
                    <Span color='lightgrey'>
                        Note: You cannot undo this action
                    </Span> 
                    </div>
                </div>
                
                
                <div className='flex justify-end my-4 mx-2'> 
                    <Button width='none' border='grey' color='black' padding='px-8' bg='white' margin='mx-2'
                        onClick={() => setDeleteModal(false)}
                    >
                        No
                    </Button>  
                    
                    <Button width='none' padding='px-8' onClick={handleDelete}>
                        Yes
                    </Button>
                </div> 
            </div> 
        </ModalContainer> 

        <div className="opacity-60 fixed inset-0 z-40 bg-black-1"></div>
    </>}
  </>);
}   