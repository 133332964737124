import { ModalContainer } from '../../../components/common/Containers'
import { Button } from '../../../components/common/Button' 
import { Span } from '../../../components/common/Span'  
import warningIcon from '../../../images/Warning_icon.png'
    
type Props = {
    setChangePassword: Function, 
    changePassword: boolean
} 
  
export default ({setChangePassword, changePassword, ...props}: Props) => { 
      
    return (<>   
    {changePassword && <> 
        <ModalContainer>  0
            <div className="p-6 whitespace-normal"> 
                <div className='flex'> 
                    <img src={warningIcon} alt={warningIcon} className='h-8 mr-2' />
                    <div className='text-left'>  
                    <Span size='lg' weight='bold'>Check your email</Span>
                
                    <Span color='lightgrey' size='sm'>
                        Note: We have sent a password instruction to your email
                    </Span> 
                    </div>
                </div>
                
                
                <div className='flex justify-end my-4 mx-2'>   
                    <Button width='none' padding='px-8' onClick={() => setChangePassword(false)}>
                        Ok
                    </Button>
                </div> 
            </div> 
        </ModalContainer> 

        <div className="opacity-60 fixed inset-0 z-40 bg-black-1"></div>
    </>}
  </>);
}   