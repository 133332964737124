import { useState } from 'react';
import { createDriver } from '../../../common/api';
import PostErrorAlert from '../../../components/PostErrorAlert';
import BackDrop from '../../../components/BackDrop';
import { onlyNumberKey } from '../../../common/utils';
import { Span, SpanContainer } from '../../../components/common/Span';
import { Input } from '../../../components/common/Input';
import { Select } from '../../../components/common/Select';
import { Button } from '../../../components/common/Button';
import { InputContainer, Container } from '../../../components/common/Containers';
import '../CSS/Driver.css';
import { IoCloseSharp } from 'react-icons/io5';
import { BsPlusLg } from 'react-icons/bs';
import { RiErrorWarningFill } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../../store';
import Switch from '../../../components/Switch/Switch';
import { DRIVERGROUP, VEHICLETYPE } from '../../../components/constants/status';
import SingleInput from '../../../components/AutoComplete/SingleInput';
import { provinceData } from '../../../components/Location/location_json/province';
import { cityData } from '../../../components/Location/location_json/city';
import { area, clustercities, clusterData } from '../../../components/Location/location_json/cluster';
import Vehicle from '../Vehicle';

type Props = {
  setAddDriver: Function;
};

export default ({ setAddDriver, ...props }: Props) => {

  const dispatch = useDispatch<Dispatch>();

  const [openAlert, setOpenAlert] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [cancel, setCancel] = useState(false);
  const [active, setActive] = useState(false);
  const [driverDetails, setDriverDetails] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    province: '',
    city: '',
    address: '',
    driverVehicle: [
      {
        vehicleType: '',
        plateNumber: '',
        vehicleModel: '',
        status: '',
      },
    ],
    deliveryArea: {
      driverGroup: '',
      cluster: '',
      clusterCity: '',
      clusterArea: '',
      status: '',
    },
    driverLicenseRestriction: '',
  });

  const isShowAddVehicle = driverDetails.driverVehicle?.length !== VEHICLETYPE?.length;
  const isShowDeleteVehicle = driverDetails.driverVehicle?.length > 1;

  const handleAddDriver = async (e) => {
    e.preventDefault();
    const newDriver = {
      ...driverDetails,
      systemStatus: active ? 'active' : 'inactive',
    };
    try {
      dispatch.UI.setIsLoading(true);
      const res = await createDriver(newDriver);
      if (res?.isSuccess) {
        setAddDriver(false);
        dispatch.UI.setAlert({ message: 'Driver use added!', type: 'Success' });
        dispatch.UI.setIsLoading(false);
        dispatch.Table.getFirebaseData({ name: 'driver_profile', table: 'drivers' })
      }
    } catch (err: any) {
      const errMsg = err?.data?.error ?? 'Unable to add driver';
      dispatch.UI.setIsLoading(false);
      setErrMessage(errMsg);
      setOpenAlert(true);
    }
  };

  const handleChange = (name: string, event: any, dest: string, index?: any) => {
    const value = event.target.value;

    switch (dest) {
      case 'none':
        setDriverDetails({
          ...driverDetails,
          [name]: value,
        });
        break;
      case 'driverVehicle':
        const payload = {
          ...driverDetails,
          [dest]: [...driverDetails[dest]?.map((v, i) => i === index ? { ...v, [name]: value } : (name === 'status' && value === 'active') ? { ...v, status: 'inactive' } : v)]
        }
        setDriverDetails(payload);
        break;
      default:
        setDriverDetails({
          ...driverDetails,
          [dest]: {
            ...driverDetails[dest],
            [name]: value,
          },
        });
        return;
    }
  };

  const addVehicle = (e) => {
    e.preventDefault();
    const newVehicle = {
      vehicleType: '',
      plateNumber: '',
      vehicleModel: '',
      status: '',
    }
    setDriverDetails({
      ...driverDetails,
      driverVehicle: [...driverDetails?.driverVehicle, newVehicle]
    })
  }

  const removeVehicle = (index) => {
    setDriverDetails({
      ...driverDetails,
      driverVehicle: [...driverDetails?.driverVehicle.filter((v, i) => i !== index)]
    })
  }

  return (
    <>
      {cancel && (
        <>
          <div className="popup_edit">
            <div className="flex">
              <RiErrorWarningFill className="text-xl my-auto text-orange-1" />
              <Span margin="mx-2">
                Discard changes made in the user information?
              </Span>
            </div>

            <div className="flex justify-end py-2">
              <Button
                margin="mx-2"
                bg="white"
                border="lightgrey"
                color="black"
                width="none"
                padding="px-2"
                radius="none"
                onClick={() => setCancel(false)}
              >
                Cancel
              </Button>
              <Button
                margin="mx-2"
                width="none"
                padding="px-2"
                radius="none"
                onClick={() => window.location.reload()}
              >
                Discard
              </Button>
            </div>
          </div>
        </>
      )}
      <div className="side-parcel absolute inset-y-0 right-0 px-0">
        <BackDrop />
        <div className="flex py-2">
          <IoCloseSharp
            onClick={() => setAddDriver(false)}
            className="my-auto mx-2 text-lg cursor-pointer"
          />
          <Span weight="semi" size="lg">
            Add Driver
          </Span>
        </div>

        <Container border="grey">
          <div className="flex justify-between">
            <Span weight="semi" color="brown" margin="my-auto">
              System Status
            </Span>

            <Switch refSwitch={null} active={active} setActive={setActive} />
          </div>
        </Container>

        <form onSubmit={handleAddDriver}>
          <div className="text-left py-4">
            <Span weight="semi" size="lg" transform="uppercase">
              Profile
            </Span>

            <Span margin="mt-4">First Name</Span>
            <Input
              placeholder="First Name"
              onChange={(e) => handleChange('firstName', e, 'none')}
              required
            />

            <Span margin="mt-4">Middle Name (Optional)</Span>
            <Input
              placeholder="Middle Name"
              onChange={(e) => handleChange('middleName', e, 'none')}
            />

            <Span margin="mt-4">Last Name</Span>
            <Input
              placeholder="Last Name"
              onChange={(e: any) => handleChange('lastName', e, 'none')}
              required
            />
          </div>

          <div className="text-left py-4">
            <Span weight="semi" size="lg" transform="uppercase">
              Contact Details
            </Span>

            <Span margin="mt-4">Phone Number</Span>
            <InputContainer margin="none">
              <SpanContainer padding="p-2" position="absolute">
                +63
              </SpanContainer>
              <Input
                padding="pl-10"
                type="text"
                maxLength={10}
                placeholder="Phone Number"
                onChange={(e) => handleChange('phoneNumber', e, 'none')}
                required
                minLength={10}
                onKeyPress={onlyNumberKey}
              />
            </InputContainer>

            <Span margin="mt-4">Email</Span>
            <Input
              placeholder="Email"
              onChange={(e) => handleChange('email', e, 'none')}
              required
              type="email"
            />
          </div>

          <div className="text-left py-4">
            <Span weight="semi" size="lg" transform="uppercase">
              Address
            </Span>

            <Span margin="mt-4">Province</Span>
            <SingleInput
              value={driverDetails?.province}
              onChange={(e) => handleChange('province', e, 'none')}
              options={provinceData}
              placeholder='Select Province...'
            />

            <Span margin="mt-4">City</Span>
            <SingleInput
              value={driverDetails?.city}
              onChange={(e) => handleChange('city', e, 'none')}
              options={cityData[driverDetails?.province] ?? []}
              placeholder='Select City...'
              disabled={driverDetails?.province === ''}
            />

            <Span margin="mt-4">Street, Brgy</Span>
            <Input
              placeholder="Street, Brgy"
              onChange={(e) => handleChange('address', e, 'none')}
              required
            />
          </div>

          <div className="text-left py-4">
            <Span weight="semi" size="lg" transform="uppercase">
              Delivery Details
            </Span>

            <Span margin="mt-4">Driver Group</Span>
            <InputContainer border='grey'>
              <Select
                margin='mr-3'
                border='none'
                onChange={(e) => handleChange('driverGroup', e, 'deliveryArea')}
                required
              >
                <option value="" selected disabled hidden>
                  {' '}
                  Driver Group{' '}
                </option>
                {DRIVERGROUP?.map((type, index) => (
                  <option key={index} value={type.value}>
                    {type.name}
                  </option>
                ))}
              </Select>
            </InputContainer>

            <Span margin="mt-4">Cluster</Span>
            <SingleInput
              value={driverDetails?.deliveryArea?.cluster}
              onChange={(e) => handleChange('cluster', e, 'deliveryArea')}
              options={clusterData}
              placeholder='Select Cluster...'
            />

            <Span margin="mt-4">Area</Span>
            <SingleInput
              value={driverDetails?.deliveryArea?.clusterArea}
              onChange={(e) => handleChange('clusterArea', e, 'deliveryArea')}
              options={area[driverDetails?.deliveryArea?.cluster] ?? []}
              placeholder='Select Area...'
              disabled={driverDetails?.deliveryArea?.cluster == ''}
            />

            <Span margin="mt-4">City</Span>
            <SingleInput
              value={driverDetails?.deliveryArea?.clusterCity}
              onChange={(e) => handleChange('clusterCity', e, 'deliveryArea')}
              options={clustercities[driverDetails?.deliveryArea?.clusterArea] ?? []}
              placeholder='Select City...'
              disabled={driverDetails?.deliveryArea?.clusterArea === ''}
            />

          </div>

          <div className="text-left py-4">
            <Span weight="semi" size="lg" transform="uppercase">
              Registered Vehicle
            </Span>

            <Span margin="mt-4">Driver's License Restriction</Span>
            <Input
              onChange={(e) =>
                handleChange('driverLicenseRestriction', e, 'none')
              }
              required
              maxLength={1}
              onKeyPress={onlyNumberKey}
            />
          </div>

          <Container border="grey">
            <div className="flex justify-between">
              <Span weight="semi" color="brown">
                Vehicle
              </Span>
            </div>

            {driverDetails?.driverVehicle?.map((vehicle, index) => (
              <Vehicle isShowDeleteVehicle={isShowDeleteVehicle} removeVehicle={removeVehicle} handleChange={handleChange} driverDetails={driverDetails} index={index} key={index} />
            ))}

            <div className='mt-4 border-t border-grey-1'>
              {isShowAddVehicle && (
                <Button width='none' bg='white' color='grey' border='lightgrey' margin='mt-4'
                  onClick={addVehicle}
                >
                  <div className='flex pr-2'>
                    <BsPlusLg className='my-auto mx-2' />
                    Add Vehicle
                  </div>
                </Button>
              )}
            </div>
          </Container>

          <PostErrorAlert
            openAlert={openAlert}
            setOpenAlert={setOpenAlert}
            message={errMessage}
          />

          <div className="flex inset-x-0 bottom-0 py-4 text-center">
            <Button
              margin="mx-2"
              bg="white"
              border="lightgrey"
              color="black"
              onClick={() => setAddDriver(false)}
            >
              Cancel
            </Button>
            <Button margin="mx-2" type="submit">
              Add Driver
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};
