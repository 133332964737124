import React from 'react'
import ParcelsRatePage from '../containers/Rate/Parcel/ParcelRate'  
import Header from '../components/Header/Header' 
import SidebarPage from '../components/Sidebar/Sidebar'
import { MainContainer } from '../components/common/Containers'  
 
const ParcelsRate = () => { 

    return ( 
        <>  
            <SidebarPage />
            <MainContainer>    
                <Header /> 
                <main> 
                    <ParcelsRatePage/>   
                </main> 
            </MainContainer>
        </>
    )
}

export default ParcelsRate
