import { useEffect, useMemo, useState } from 'react'   
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../store';

import { Span } from '../../../components/common/Span'  
import { Container } from '../../../components/common/Containers' 
import NoData from '../../../components/common/NoData/NoData'
import Loader from "../../../components/Loader";
import Table from '../../../components/Table/Table' 

import { COLUMNS } from './Table/column'  

export default () => {  
    
    const dispatch = useDispatch<Dispatch>();

    const data: any[] = useSelector((state: RootState) => state.Table.tables.transaction_log.data)
        ?.sort((a, b) => {return a?.createdAt?.seconds - b?.createdAt?.seconds});
        
    const filters: any = useSelector((state: RootState) => state.Table.tables.transaction_log.filters);

    const columns = useMemo(() => COLUMNS, [])
    
    const [isLoading, setIsLoading] = useState(true);  
    
    useEffect(() => {
        dispatch.Table.subscribeToFirebase({name: 'wallet_transaction', table: 'transaction_log'})
        setIsLoading(false);
        const TIMEOUT = setTimeout(() => {
            dispatch.Table.subscribeToFirebase({name: 'wallet', table: 'wallet'})
        },1000);

        return () => {
            dispatch.Table.UNSUBSCRIBE();
            clearTimeout(TIMEOUT);
        }
    },[filters]);
    return (<>
        {isLoading ? 
            <Loader /> 
            : <>
            <Container border='grey' padding='none'>
                <div className='flex justify-between p-4'>
                    <Span margin='mt-4' weight='bold' size='2xl'>Transaction Log</Span>  
                </div>   

                <div className='overflow-auto'>
                    {data?.length < 0 
                        ? <NoData />
                        : <Table columns={columns} data={data}/> 
                    }
                </div>
                
            </Container>    
        </>}     
    </>)
}