import React from 'react'
import LoginPage from '../containers/Login/Login'   
 
const Login = () => {
    return ( 
        <> 
            <LoginPage/>    
        </>
    )
}

export default Login
