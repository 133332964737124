import { useState } from "react";   

import { Button } from '../../../../components/common/Button'     

import CancelModal from '../../Modal/Cancel' 
import ForPickupModal from '../../Modal/ForPickup' 
import PickedUpModal from '../../Modal/PickedUp' 
import OngoingModal from '../../Modal/Ongoing'  
import DeliveredModal from '../../Modal/Delivered'
import FailDelivery from '../../Modal/FailDelivery';

import { EXPRESS } from '../../../../components/constants/status'   

const StatusExpress = ({orderId, status}:any) => {   

    const [failModal, setFailModal] = useState(false)
    const handleFail = (e) => {
        e.preventDefault(); 
        setFailModal(true);
    } 

    const [cancelModal, setCancelModal] = useState(false)
    const handleCancel = (e) => {
        e.preventDefault(); 
        setCancelModal(true);
    } 

    const [forPickupModal, setForPickupModal] = useState(false)
    const handleForPickup = (e) => {
        e.preventDefault(); 
        setForPickupModal(true);
    }

    const [pickedUpModal, setPickedUpModal] = useState(false)
    const handlePickedUp = (e) => {
        e.preventDefault(); 
        setPickedUpModal(true);
    }

    const [ongoingModal, setOngoingModal] = useState(false)
    const handleOngoing = (e) => {
        e.preventDefault(); 
        setOngoingModal(true);
    }

    const [deliveredModal, setDeliveredModal] = useState(false)
    const handleDelivered = (e) => {
        e.preventDefault(); 
        setDeliveredModal(true);
    }

    return (<>    
    <div className='flex justify-between'>  

        {/* New */}
        {EXPRESS[status] === 'New' && <>  
            <Button bg='red' width='none' padding='px-4' onClick={handleCancel}>
                Cancel Booking
            </Button>  
        </>} 


        {/* Assigned */}
        {EXPRESS[status] === 'Assigned' && <>   
            <Button bg='red' width='none' padding='px-4' onClick={handleFail}>
                Failed Delivery
            </Button>

            <Button 
                bg='white-brown' border='brown' color='brown' width='none' padding='px-4'  
                onClick={handleForPickup}
            >
                For Pickup
            </Button>
        </>} 

        {/* For Pickup */}
        {EXPRESS[status] === 'For Pickup' && <>   
            <Button bg='red' width='none' padding='px-4' onClick={handleFail}>
                Failed Delivery
            </Button>

            <Button 
                bg='white-brown' border='brown' color='brown' width='none' padding='px-4'  
                onClick={handlePickedUp}
            >
                Picked Up
            </Button>
        </>} 

        {/* Picked up */}
        {EXPRESS[status] === 'Picked up' && <>   
            <Button bg='red' width='none' padding='px-4' onClick={handleFail}>
                Failed Delivery
            </Button>

            <Button 
                bg='white-brown' border='brown' color='brown' width='none' padding='px-4'  
                onClick={handleOngoing}
            >
                Ongoing
            </Button>
        </>} 

        {/* Ongoing */}
        {EXPRESS[status] === 'Ongoing' && <>   
            <Button bg='red' width='none' padding='px-4' onClick={handleFail}>
                Failed Delivery
            </Button>

            <Button 
                bg='white-brown' border='brown' color='brown' width='none' padding='px-4'  
                onClick={handleDelivered}
            >
                Delivered
            </Button>
        </>} 
 
    </div> 


    <CancelModal setCancelModal={setCancelModal} orderId={orderId} cancelModal={cancelModal}/>   
    <ForPickupModal setForPickupModal={setForPickupModal} orderId={orderId} forPickupModal={forPickupModal}/> 
    <PickedUpModal setPickedUpModal={setPickedUpModal} orderId={orderId} pickedUpModal={pickedUpModal}/> 
    <OngoingModal setOngoingModal={setOngoingModal} orderId={orderId} ongoingModal={ongoingModal}/> 
    <DeliveredModal setDeliveredModal={setDeliveredModal} orderId={orderId} deliveredModal={deliveredModal}/> 
    <FailDelivery failModal={failModal} setFailModal={setFailModal} orderId={orderId} />

    </>)
}

export default StatusExpress

            