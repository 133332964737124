import { Span } from '../../../../components/common/Span'  
import { ColumnFilter } from "../../../../components/Table/ColumnFilter";      
import moment from 'moment';     

import StatusRate from './StatusRate'

export const COLUMNS = [  
    {  
        accessor: data => `${data.name} ${data.isDefault}`,
        Header: 'Name',
        Filter: ColumnFilter, 
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return (
            <div className='flex justify-center'>
                <span className='capitalize px-2'>{d.name}</span> 
                {d.isDefault
                    ? <Span color='brown' bg='brown' border='brown' radius='xl' padding='px-4'>Default</Span> 
                    : null
                }
            </div>
            ); }
    }, 
    {   
        Header: 'Created by',
        Filter: ColumnFilter, 
        accessor: data => `${data?.createdBy?.email}`, 
        isVisible: true,
    }, 
      
    {   
        accessor: data => {
            const date = new Date(data?.createdAt?.seconds * 1000);
            const formatedDate = moment(date).format('MMMM Do YYYY, h:mm:ss a')
            return formatedDate;
        },
        id:'createdAt',
        field: 'createdAt',
        Header: 'Date Created',
        isVisible: true,
        Cell: data => {
        const d = data.row.original;
        let date = new Date(1000);

        if(d?.createdAt?.seconds){
            date = new Date(d?.createdAt?.seconds * 1000);
        }
        return <>{moment(date).format('MMMM Do YYYY, h:mm:ss a')}</>   }
    },  
    {   
        accessor: data => {
            const date = new Date(data?.updatedAt?.seconds * 1000);
            const formatedDate = moment(date).format('MMMM Do YYYY, h:mm:ss a')
            return formatedDate;
        },
        id:'updatedAt',
        field: 'updatedAt',
        Header: 'Date Updated',
        Filter: ColumnFilter,  
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            let date = new Date(1000);
    
            if(d?.updatedAt?.seconds){
                date = new Date(d?.updatedAt?.seconds * 1000);
            }
            return <>{moment(date).format('MMMM Do YYYY, h:mm:ss a')}</>
        }
    }, 
    {
        accessor: ' ',
        Header: ' ', 
        Filter: ColumnFilter,
        disableFilters: true, 
        disableSortBy: true,
        isVisible: true, 
        Cell: data => <StatusRate id={data.row.original.ratePartialLoadId} onClick={() => alert('click')}/> 
    }
]