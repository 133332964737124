import { ModalContainer } from '../common/Containers'
import { Button } from '../common/Button'
import { Span } from '../common/Span'
import questionIcon from '../../images/Qmark_icon.png'
import { deleteExpressRateCard, deleteInterislandRateCard, deleteParcelRateCard, deletePartialRateCard } from "../../common/api";
import { Dispatch } from '../../store';
import { useDispatch } from 'react-redux';

type Props = {
    setDeleteModal: Function,
    dataToDelete: string,
    id: any
}

export default ({ setDeleteModal, id, dataToDelete }: Props) => {

    const dispatch = useDispatch<Dispatch>();

    const handleDelete = async (e) => {
        e.preventDefault();
        try {
            dispatch.UI.setIsLoading(true);
            setDeleteModal(false);
            let res;

            switch (dataToDelete) {
                case 'express_rate_card':
                    res = await deleteExpressRateCard(id);
                    break;
                case 'interisland_rate_card':
                    res = await deleteInterislandRateCard(id);
                    break;
                case 'parcel_rate_card':
                    res = await deleteParcelRateCard(id);
                    break;
                case 'partial_rate_card':
                    res = await deletePartialRateCard(id);
                    break;
                default:
                    res = null;
                    break;
            }

            if (res.isSuccess) {
                dispatch.UI.setIsLoading(false);
                dispatch.UI.setAlert({ message: 'Data Deleted', type: 'Success' });
            }
        } catch (err: any) {
            const errMsg = err?.data?.error === undefined ? 'Unable to delete Data' : err.data.error;
            dispatch.UI.setAlert({ message: errMsg, type: 'Error' });
            dispatch.UI.setIsLoading(false);
        }
    }

    return (
        <>
            <ModalContainer>
                <div className="p-6 whitespace-normal">
                    <div className='flex'>

                        <img src={questionIcon} alt={questionIcon} className='h-8 mr-2' />
                        <div className='text-left'>
                            <Span size='lg' weight='bold'>Are you sure you want to delete this Data?</Span>

                            <Span color='lightgrey'>
                                Note: You cannot undo this action
                            </Span>
                        </div>
                    </div>


                    <div className='flex justify-end my-4 mx-2'>
                        <Button width='none' border='grey' color='black' padding='px-8' bg='white' margin='mx-2'
                            onClick={() => setDeleteModal(false)}
                        >
                            No
                        </Button>

                        <Button width='none' padding='px-8' onClick={handleDelete}>
                            Yes
                        </Button>
                    </div>
                </div>
            </ModalContainer>

            <div className="opacity-60 fixed inset-0 z-40 bg-black-1"></div>
        </>
    );
}   