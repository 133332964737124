import { ModalContainer } from '../../../components/common/Containers'
import { Button } from '../../../components/common/Button' 
import { Span } from '../../../components/common/Span' 
import { Dispatch } from '../../../store'
import { useDispatch } from 'react-redux'
import { updateExpressStatus } from '../../../common/api'

import questionIcon from '../../../images/Qmark_icon.png'

type Props = {
    setFailModal: Function,
    failModal: boolean,
    orderId: string
} 
  

export default ({failModal, setFailModal, orderId, ...props}: Props) => { 

    const dispatch = useDispatch<Dispatch>();
 
    const handleFail = async() => {
        setFailModal(false);
        try{
            dispatch.UI.setIsLoading(true);
            const res = await updateExpressStatus("failed_delivery", orderId);
            dispatch.UI.setIsLoading(false);
            if(res.isSuccess){
                dispatch.UI.setAlert({message: `Order Number Fail Delivery`, type: 'Success'})
            }
        }catch(err){
            dispatch.UI.setIsLoading(false);
            dispatch.UI.setAlert({message: 'Unable to update booking!', type: 'Error'})
        }
    }
      
    return (<>
    {failModal && (
        <>
            <ModalContainer>  
            <div className="px-4 py-6 whitespace-normal"> 
                <div className='flex'> 
                    <img src={questionIcon} alt={questionIcon} className='h-8 mr-2' />
                    <div className='text-left'>  
                    <Span size='lg' weight='bold'>Are you sure you want to Fail Delivery this booking?</Span>
                
                    <Span color='lightgrey'>
                        Note: You cannot undo this action
                    </Span> 
                    </div>
                </div>
                
                
                <div className='flex justify-end my-4 mx-2'> 
                    <Button width='none' border='lightgrey' color='darkgrey' padding='px-8' bg='white' margin='mx-2'
                        onClick={() => setFailModal(false)}
                    >
                        No
                    </Button>  
                    
                    <Button width='none' padding='px-8' onClick={handleFail}>
                        Yes
                    </Button>
                </div> 
            </div> 
        </ModalContainer> 

        <div className="opacity-60 fixed inset-0 z-40 bg-black-1"></div>
        </>
    )}
  </>);
} 