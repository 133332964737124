import { post, put } from "../core";

export const updateWalletAmount = async(body: any) => {
    try{
        const res = await post('/admin/create/wallet-transaction', body)
        return res.data;
    }catch(err){
        return Promise.reject(err)
    }
}

export const updateRequestWalletStatus = async(status: string,body: any) => {
    try{
        const res = await put(`/wallet/withdrawal-request/${status}`, body)
        return res.data;
    }catch(err){
        return Promise.reject(err)
    }
}