import { Link } from 'react-router-dom' 
import { BsThreeDotsVertical } from 'react-icons/bs'
import { Span } from '../../../../components/common/Span'  
import { ColumnFilter } from "../../../../components/Table/ColumnFilter";   
import moment from 'moment';
import StatusBilling from './StatusBilling'
import { DRIVER, APPROVAL, DRIVERGROUPSHOW } from '../../../../components/constants/status';    

export const COLUMNS = [
    { 
        accessor: 'name',
        Header: 'Name',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return( 
                <span className='capitalize'>
                    {d?.firstName + ' ' + d?.lastName}
                </span>   
            )
        }
    }, 
    {
        accessor: data => data?.email ?? '',
        Header: 'Email',
        Filter: ColumnFilter,
        isVisible: true,        
    },
    {
        accessor: ' ',
        Header: ' ', 
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
        isVisible: true, 
        Cell: data => <StatusBilling id={data?.row?.original?.id ?? ''}/> 
 
    }
]