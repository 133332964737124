import { BiError } from 'react-icons/bi';
import { RiCloseFill } from 'react-icons/ri';
import classnames from 'classnames';
import { useEffect } from 'react';

type Props = {
    openAlert: boolean,
    setOpenAlert: Function,
    message: string
}

const PostErrorAlert = ({openAlert, setOpenAlert, message}: Props) => {

const handleClose = () => {
    setOpenAlert(false);
}

useEffect(() => {
  let TIMEOUT;
    if(openAlert){
        TIMEOUT = setTimeout(() => {
            handleClose();
        }, 5000);
    }else{
      clearTimeout(TIMEOUT);
    }
},[openAlert]);

  return (
    <>
    {openAlert ? (
      <div className={classnames(`flex w-full mt-4 p-2 bg-red-100 dark:bg-red-200`)} role='alert'>
          <BiError className={classnames('w-5 h-5 flex-shrink-0 text-red-700 dark:text-red-800')} fill='currentColor' />
          <div className={classnames('ml-3 text-sm font-semi-bold text-red-700 dark:text-red-800')}>
            {message}
          </div>
          <button type="button" className={classnames("ml-auto -mx-1.5 -my-1.5  rounded-lg focus:ring-2  p-1.5  inline-flex h-8 w-8 bg-red-100 text-red-500 focus:ring-red-400 hover:bg-red-200 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300")} data-collapse-toggle="alert-2" aria-label="Close">
            <span className="sr-only">Close</span>
            <RiCloseFill className='w-5 h-5' onClick={handleClose}/>
            </button>
      </div>
    ):(
      <>
      </>
    )}
  </>
  )
};

export default PostErrorAlert;
