import { useEffect, useMemo, useState} from 'react' 
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../../store";
import { useLocation, Link } from 'react-router-dom' 

import { Span } from '../../components/common/Span' 
import { Button } from '../../components/common/Button' 
import { Container } from '../../components/common/Containers';

import { BiPlusCircle } from 'react-icons/bi'

import Table from './Table/Table' 
import { COLUMNS } from './Table/column'  
import { IFilterValue } from '../../models/TableModel';
 
import Loader from "../../components/Loader"; 
import AddParcel from './AddParcel/AddParcel'  

import NoData from '../../components/common/NoData/NoData';

function useQuery(){
    return new URLSearchParams(useLocation().search)
}

export default () => {  
    const query = useQuery();
    const [status, setStatus] = useState(query.get('tab'))
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch<Dispatch>();

    const data: any[] = useSelector((state: RootState) => state.Table.tables.parcels.data)
        .filter(parcel => parcel.status !== 'pending')
        .sort((a, b) => {return a.createdAt.seconds - b.createdAt.seconds});
    
    const filters: any = useSelector(
        (state: RootState) => state.Table.tables.parcels.filters
    );
    
    useEffect(() => {

        let filter : IFilterValue =  {
            field: "status",
            operation: "==",
            value: status
        }

        if(status === 'ongoing'){
            filter = {
                field: "status",
                operation: "not-in",
                value: ['new', 'delivered', 'canceled']
            }
        }

        dispatch.Table.setFilterValue({
            table: 'parcels',
            key: 'status',
            filter
        }) 
    }, [status])
    

    useEffect(() => { 
        dispatch.Table.subscribeToFirebase({ name: 'parcel', table: 'parcels' })
        setIsLoading(false);

        return () => {
            dispatch.Table.UNSUBSCRIBE()
        }
    }, [filters]) 
 
    const columns = useMemo(() => COLUMNS, []); 
    
    const [parcel, setAddParcel] = useState(false); 

    return (<>  
    {isLoading ? 
        <Loader /> 
    : <>  
        <Container border='grey' padding='none'> 
            <div className='p-4'>
                <div className='flex justify-between'>
                    <Span margin='mt-4' weight='bold' size='2xl'>Order List</Span>  
                    <div className='flex justify-end px-4 my-4'>
                        <Button width='none' onClick={() => {setAddParcel(true);}}>
                            <div className='flex pr-2'>
                                <BiPlusCircle className='my-auto mx-2 text-2xl'/> 
                                Add Parcel
                            </div>
                        </Button>
                    </div>  
                </div> 

                <div className='flex'>
                <Link to='/parcels?tab=new'>
                    <Button 
                        onClick={() => setStatus('new')}
                        radius='none' width='none' border={status === 'new' ? 'bottom-grey' : 'bottom'} bg='none' color='black'
                        padding='px-4'>
                        New
                    </Button>
                </Link>
                <Link to='/parcels?tab=ongoing'>
                    <Button
                        onClick={() => setStatus('ongoing')}
                        radius='none' width='none' border={status === 'ongoing' ? 'bottom-grey' : 'bottom'} bg='none' color='black' 
                        padding='px-4'>
                        Ongoing
                    </Button>
                </Link>
                <Link to='/parcels?tab=delivered'>
                    <Button 
                        onClick={() => setStatus('delivered')}
                        radius='none' width='none' border={status === 'delivered' ? 'bottom-grey' : 'bottom'} bg='none' color='black'
                        padding='px-4'>
                        Completed
                    </Button>
                </Link>
                <Link to='/parcels?tab=canceled'>
                    <Button 
                        onClick={() => setStatus('canceled')}
                        radius='none' width='none' border={status === 'canceled' ? 'bottom-grey' : 'bottom'} bg='none' color='black'
                        padding='px-4'>
                        Canceled
                    </Button>
                </Link>
                </div>
            </div>   
            <div className='overflow-auto'>
                {data?.length < 0 
                    ? <NoData />
                    : <Table columns={columns} data={data}/>
                } 
            </div>
        </Container> 
           
        {parcel && <>
            <AddParcel setAddParcel={setAddParcel}/>
        </>}  
    </>}
    </>
    )
}