import { useState } from "react";   
import '../CSS/Driver.css'
import { useDispatch } from 'react-redux';
import { Dispatch } from "../../../store"; 
import edit_icon from '../../../images/edit_icon.png'; 
import ReactTooltip from 'react-tooltip';

const StatusDriver = ({id}:any) => {  
    const dispatch = useDispatch<Dispatch>(); 

    const handleEdit = () => {
        dispatch.UI.resetForm('editDriver')
        dispatch.UI.updateFormId({
            target: "editBilling",
            value: id
        })
    }

    return (<>   
        <div className='flex my-auto'>     
             
            <img src={edit_icon} placeholder={edit_icon} 
                className="status_icon" data-tip data-for='edit'
                onClick={handleEdit}
            /> 
            <ReactTooltip id='edit' backgroundColor="#8d8d8d">
                <span>Edit</span>
            </ReactTooltip>  
        </div>    
    </>)
}

export default StatusDriver

            