import { Steps } from 'antd'
import { formatDateV2 } from '../../../../common/utils/helpers';
import './HistoryStatus.scss'

const { Step } = Steps;

export default ({ express }: any) => {

    const pendingSchedule = express?.createdAt ? formatDateV2(express?.createdAt) : '';
    const assignedSchedule = express?.assignedAt ? formatDateV2(express?.assignedAt) : '';
    const forpickupSchedule = express?.forpickupAt ? formatDateV2(express?.forpickupAt) : formatDateV2(express?.acceptedAt);
    const pickupSchedule = express?.pickedupAt ? formatDateV2(express?.pickedupAt) : formatDateV2(express?.arrivedAtPickUpPoint);
    const ongoingSchedule = express?.ongoingAt ? formatDateV2(express?.ongoingAt) : formatDateV2(express?.pickup?.pickUpAt);
    const deliveredSchedule = express?.deliveredAt ? formatDateV2(express?.deliveredAt) : formatDateV2(express?.arrivedAtDropOffPoint);
    const canceledSchedule = express?.canceledAt ? formatDateV2(express?.canceledAt) : '';
    const failedSchedule = express?.faileddeliveryAt ? formatDateV2(express?.faileddeliveryAt) : '';

    let StatusExpress: any = [
        {
            label: 'Pending',
            value: 'pending',
            timestamp: pendingSchedule,
        },
        {
            label: 'Assigned',
            value: 'assigned',
            timestamp: assignedSchedule,
        },
        {
            label: 'For PickUp',
            value: 'for_pickup',
            timestamp: forpickupSchedule,
        },
        {
            label: 'Picked Up',
            value: 'picked_up',
            timestamp: pickupSchedule,
        },
        {
            label: 'On Going',
            value: 'on_going',
            timestamp: ongoingSchedule,
        },
        {
            label: 'Delivered',
            value: 'delivered',
            timestamp: deliveredSchedule,
        },
        {
            label: 'Canceled',
            value: 'canceled',
            timestamp: canceledSchedule,
        },
        {
            label: 'Failed Delivery',
            value: 'failed_delivery',
            timestamp: failedSchedule,
        },
    ]

    const FILTER = (str) => {
        const res = StatusExpress?.filter(s => s.value !== str)
        return res;
    }

    StatusExpress = express?.assignedAt ? FILTER('none') : FILTER('assigned');
    StatusExpress = express?.status !== 'canceled' ? FILTER('canceled') : FILTER('none');
    StatusExpress = express?.status !== 'failed_delivery' ? FILTER('failed_delivery') : FILTER('none');
    StatusExpress = ['failed_delivery', 'canceled'].includes(express?.status)  ? FILTER('delivered') : FILTER('none');
    const currentIndex = StatusExpress?.findIndex(item => item.value === express?.status);

    return (
        <>
            <Steps direction='vertical' size="small" current={currentIndex} style={{height: '80vh'}}>

                {StatusExpress?.map((item, index) => (
                    <Step
                        title={item.label}
                        key={index}
                        description={currentIndex >= index ? item.timestamp.includes('Invalid date') ? '' : item.timestamp : ''}
                    />
                ))}
            </Steps>
        </>
    );
}; 
