import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Span, SpanContainer } from '../../../../../components/common/Span';
import { Input } from '../../../../../components/common/Input';
import { Button } from '../../../../../components/common/Button';
import {
  GridContainer,
  InputContainer,
} from '../../../../../components/common/Containers';
import { Select } from '../../../../../components/common/Select';
import { IoCloseSharp } from 'react-icons/io5';
import PostErrorAlert from '../../../../../components/PostErrorAlert';
import BackDrop from '../../../../../components/BackDrop';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../../../store';
import { updateParcelRateSettings } from '../../../../../common/api';
import { useParams } from 'react-router-dom';
import {
  onlyNumberKeyAndDot,
  onlyNumberKey,
} from '../../../../../common/utils';

import '../../../CSS/Rate.css';

import { getFirestore, doc, getDoc } from 'firebase/firestore';

export default ({ rateParcelId }: { rateParcelId: any }) => {
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();
  const { id } = useParams();

  const [openAlert, setOpenAlert] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const data: any | [] = useSelector((state: RootState) => state.Table.tables.parcel_rate.data)
    .filter((d) => d.rateParcelId === rateParcelId)[0].settings
    .filter((i) => i.id === id)[0];

  const initialState = {
    pickUpArea: data?.pickUpArea,
    minWeight: data?.minWeight,
    baseFare: data?.baseFare,
    driverShare: data?.driverShare,
    maxWeight: data?.maxWeight,
    dropOffArea: data?.dropOffArea,
    commission: data?.commission,
    ratePerKilo: data?.ratePerKilo,
  }

  const [updatedDetails, setUpdatedDetails] = useState() as any;

  const isDataChange = () => {
    const originalData = Object.entries(data).map((entry) => `${entry[0]} ${entry[1]}`);
    const updatedData = Object.entries(updatedDetails).map((entry) => `${entry[0]} ${entry[1]}`);
    const result = updatedData.map((data) => originalData.includes(data)).some((res) => res === false);
    return result;
  };

  const onChange = (e: any, name: any) => {
    const value = e.target.value;
    setUpdatedDetails({
      ...updatedDetails,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isDataChange()) {
      setOpenAlert(true);
      setErrMessage('No changes made!');
      return;
    }
    try {
      const payload = {
        ...updatedDetails,
        minWeight: parseFloat(updatedDetails?.minWeight),
        baseFare: parseFloat(updatedDetails?.baseFare),
        driverShare: parseFloat(updatedDetails?.driverShare),
        maxWeight: parseFloat(updatedDetails?.maxWeight),
        commission: parseFloat(updatedDetails?.commission),
        ratePerKilo: parseFloat(updatedDetails?.ratePerKilo),
      };
      dispatch.UI.setIsLoading(true);
      const res = await updateParcelRateSettings(rateParcelId, id, payload);
      if (res.isSuccess) {
        history.push(`/rate/parcel/${rateParcelId}`);
        dispatch.UI.setAlert({ message: 'Rate card updated', type: 'Success' });
        dispatch.UI.setIsLoading(false);
        //window.location.reload();
      }
    } catch (err: any) {
      const errMsg =
        err?.data?.error === undefined
          ? 'Unable to update rate card'
          : err.data.error;
      setOpenAlert(true);
      setErrMessage(errMsg);
      dispatch.UI.setIsLoading(false);
    }
  };

  let [location, setLocation] = useState([] as any);
  const db = getFirestore();
  const getData = async () => {
    const docRef = doc(db, 'config', 'parcel_delivery');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setLocation(docSnap.data().areas);
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }

  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setUpdatedDetails(initialState);
  }, [data]);

  return (
    <div className="side-rate">
      <BackDrop />
      <form onSubmit={handleSubmit}>
        <div className="flex py-4 border-b border-grey-6 mb-4">
          <IoCloseSharp
            onClick={() => history.push(`/rate/parcel/${rateParcelId}`)}
            className="my-auto text-lg cursor-pointer"
          />
          <Span weight="semi" size="xl" margin="mx-2">
            Update Rate
          </Span>
        </div>

        <GridContainer grid="two" padding="none">
          <div className="col-span-2">
            <Span>Pickup Area</Span>
            <Select
              value={updatedDetails?.pickUpArea}
              onChange={(e) => onChange(e, 'pickUpArea')}
              required
            >
              {location?.map((loc: any) => {
                return <option className="capitalize">{loc}</option>;
              })}
            </Select>
          </div>

          <div className="col-span-2">
            <Span>Drop Area</Span>
            <Select
              value={updatedDetails?.dropOffArea}
              onChange={(e) => onChange(e, 'dropOffArea')}
              required
            >
              {location?.map((loc: any) => {
                return <option className="capitalize">{loc}</option>;
              })}
            </Select>
          </div>

          <div>
            <Span>Base Fare</Span>
            <InputContainer margin="none">
              <SpanContainer
                padding="normal"
                position="absolute"
                border="right"
                weight="semi"
              >
                {' '}
                ₱{' '}
              </SpanContainer>
              <Input
                padding="pl-16"
                type="text"
                border="grey"
                required
                pattern="^[0-9\.]*$"
                onKeyPress={onlyNumberKeyAndDot}
                onChange={(e) => onChange(e, 'baseFare')}
                value={updatedDetails?.baseFare}
              />
            </InputContainer>
          </div>

          <div>
            <Span>Min Weight</Span>
            <InputContainer margin="none">
              <SpanContainer
                padding="normal"
                position="absolute"
                border="right"
                weight="semi"
              >
                {' '}
                Kg{' '}
              </SpanContainer>
              <Input
                padding="pl-16"
                type="text"
                border="grey"
                required
                pattern="^[0-9\.]*$"
                onKeyPress={onlyNumberKeyAndDot}
                onChange={(e) => onChange(e, 'minWeight')}
                value={updatedDetails?.minWeight}
              />
            </InputContainer>
          </div>

          <div>
            <Span>Max Weight</Span>
            <InputContainer margin="none">
              <SpanContainer
                padding="normal"
                position="absolute"
                border="right"
                weight="semi"
              >
                {' '}
                Kg{' '}
              </SpanContainer>
              <Input
                padding="pl-16"
                type="text"
                border="grey"
                required
                pattern="^[0-9\.]*$"
                onKeyPress={onlyNumberKeyAndDot}
                onChange={(e) => onChange(e, 'maxWeight')}
                value={updatedDetails?.maxWeight}
              />
            </InputContainer>
          </div>

          <div>
            <Span>Driver Share</Span>
            <InputContainer margin="none">
              <SpanContainer
                padding="normal"
                position="absolute"
                border="right"
                weight="semi"
              >
                {' '}
                %{' '}
              </SpanContainer>
              <Input
                padding="pl-16"
                type="text"
                border="grey"
                required
                pattern="^[0-9\.]*$"
                onKeyPress={onlyNumberKey}
                onChange={(e) => onChange(e, 'driverShare')}
                value={updatedDetails?.driverShare}
              />
            </InputContainer>
          </div>

          <div>
            <Span>Commission</Span>
            <InputContainer margin="none">
              <SpanContainer
                padding="normal"
                position="absolute"
                border="right"
                weight="semi"
              >
                {' '}
                %{' '}
              </SpanContainer>
              <Input
                padding="pl-16"
                type="text"
                border="grey"
                required
                pattern="^[0-9\.]*$"
                onKeyPress={onlyNumberKey}
                onChange={(e) => onChange(e, 'commission')}
                value={updatedDetails?.commission}
              />
            </InputContainer>
          </div>

          <div>
            <Span>Rate Per Kilo</Span>
            <InputContainer margin="none">
              <SpanContainer
                padding="normal"
                position="absolute"
                border="right"
                weight="semi"
              >
                {' '}
                ₱{' '}
              </SpanContainer>
              <Input
                padding="pl-16"
                type="text"
                border="grey"
                required
                pattern="^[0-9\.]*$"
                onKeyPress={onlyNumberKeyAndDot}
                onChange={(e) => onChange(e, 'ratePerKilo')}
                value={updatedDetails?.ratePerKilo}
              />
            </InputContainer>
          </div>
        </GridContainer>

        <PostErrorAlert
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          message={errMessage}
        />

        <div className="flex absolute inset-x-0 bottom-0 mb-8 py-4 text-center">
          <Button
            margin="mx-2"
            bg="white"
            border="lightgrey"
            color="black"
            onClick={() => history.push(`/rate/parcel/${rateParcelId}`)}
          >
            Cancel
          </Button>
          <Button margin="mx-2" type="submit">
            Update
          </Button>
        </div>
      </form>
    </div>
  );
};
