import { Link } from 'react-router-dom' 
import { BsThreeDotsVertical } from 'react-icons/bs'
import { Span } from '../../../components/common/Span'  
import { ColumnFilter } from "../../../components/Table/ColumnFilter";   
import moment from 'moment';
import StatusDriver from './StatusBilling'
import { DRIVER, APPROVAL, DRIVERGROUPSHOW } from '../../../components/constants/status';    

export const COLUMNS = [
    { 
        accessor: 'name',
        Header: 'Name',
        Filter: ColumnFilter,
        isVisible: true,
        Cell: data => {
            const d = data.row.original;
            return( 
                <span className='capitalize'>
                    {d.name}
                </span>   
            )
        }
    }, 
    {
        accessor: data => data?.email ?? '',
        Header: 'Email',
        Filter: ColumnFilter,
        isVisible: true,        
    },
    {   
        accessor: data => {
            const date = new Date(data?.createdAt?.seconds * 1000);
            const formatedDate = moment(date).format('MMMM Do YYYY, h:mm:ss a')
            return formatedDate;
        },
        Header: 'Date Created',
        isVisible: true,
        Cell: data => {
        const d = data.row.original;
        let date = new Date(1000);

        if(d?.createdAt?.seconds){
            date = new Date(d?.createdAt?.seconds * 1000);
        }

        return <>{moment(date).format('MMMM Do YYYY, h:mm:ss a')}</>   }
    },
    {
        accessor: ' ',
        Header: ' ', 
        Filter: ColumnFilter,
        disableFilters: true,
        disableSortBy: true,
        isVisible: true, 
        Cell: data => <StatusDriver id={data?.row?.original?.id ?? ''}/> 
 
    }
]