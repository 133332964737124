import { GridContainer } from "../../../../components/common/Containers";
import { Span } from "../../../../components/common/Span";
import { BsDot } from "react-icons/bs";
import {
  CANCELED,
  DELIVERED,
  EXPRESS,
  NEW,
  ONGOING,
  PACKAGE_STATUS,
  STATUS,
} from "../../../../components/constants/status";
import { MdPayments } from "react-icons/md";
import AssignedDriver from "../../Modal/AssignedDriver";
import { useState } from "react";
import { Button } from "../../../../components/common/Button";

export default ({ pickUp, data, id }: any) => {

  const [assignedDriverModal, setAssignedDriverModal] = useState(false);
  const handleAssignedDriver = (e) => {
    e.preventDefault();
    setAssignedDriverModal(true);
  };

  const isAssignOrReAssign =
    pickUp?.driver?.firstName != null && pickUp?.driver?.lastName != null
      ? "Reassign Driver"
      : "Assign Driver";

  return (
    <>
      <GridContainer grid="four" padding="px-8">
        <div>
          <Span weight="semi"> Address: </Span>
          <Span>{pickUp?.location?.address}</Span>
          <p className="flex">
            <BsDot className="text-grey-11 my-auto" />
            <Span color="lightgrey">{pickUp?.locationDetails}</Span>
          </p>
        </div>

        <div>
          <Span weight="semi"> Contact Person </Span>
          <Span>{pickUp?.contactName}</Span>
        </div>

        <div>
          <Span weight="semi"> Contact Number </Span>
          <Span>{pickUp?.contactPhone}</Span>
        </div>

        <div>
          {EXPRESS[data?.status] !== "New" && (
            <>
              <Span
                weight="semi"
                color="brown"
                transform="uppercase"
              >
                Driver Details
              </Span>
              
              <Span weight="semi" margin="mt-4" size="xl">
                {pickUp?.driver?.firstName}&nbsp;
                {pickUp?.driver?.lastName}
              </Span>

              <Span margin="mt-4" color="grey">
                Contact No:
              </Span>
              <Span>{pickUp?.driver?.phoneNumber}</Span>
            </>
          )}

          {["pending", "assigned", "for_pickup"].includes(data?.status) && (
            <Button bg="green" margin="mt-4" onClick={handleAssignedDriver}>
              {isAssignOrReAssign}
            </Button>
          )}
        </div>

        <AssignedDriver
          setAssignedDriverModal={setAssignedDriverModal}
          assignedDriverModal={assignedDriverModal}
          orderId={id}
          isAssignOrReAssign={isAssignOrReAssign}
          vehicleType={data?.vehicleType}
        />

        {pickUp?.isReliableFP && (
          <div className="flex items-center gap-3 col-span-2">
            <Span color="red">
              <MdPayments />
            </Span>
            <Span color="red">Reliable For Payment</Span>
          </div>
        )}
      </GridContainer>
    </>
  );
};
