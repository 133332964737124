import { useMemo, useState, useEffect } from 'react'   
import { Dispatch, RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux'; 

import { Span } from '../../../components/common/Span'  
import { Container } from '../../../components/common/Containers' 
import NoData from '../../../components/common/NoData/NoData'
import Loader from "../../../components/Loader"; 

import Table from './Table/Table' 
import { COLUMNS } from './Table/column'  

export default () => {  
     
    const dispatch = useDispatch<Dispatch>();
    const data: any[] = useSelector((state: RootState) => state.Table.tables.wallet?.data)
    const columns = useMemo(() => COLUMNS, [])
    
    const [isLoading, setIsLoading] = useState(true); 
    useEffect(() => {
        dispatch.Table.subscribeToFirebase({
            name: 'wallet', 
            table: 'wallet'
        })
        setIsLoading(false);

        return () => {
            dispatch.Table.UNSUBSCRIBE()
        }
    },[]);

    return (<>
        {isLoading ? 
            <Loader /> 
            : <>
            <Container border='grey' padding='none'>
                <div className='flex justify-between p-4'> 
                        <Span margin='mt-4' weight='bold' size='2xl'>Wallet List</Span>   
                </div> 

                <div className='overflow-auto'>
                    {data?.length < 0 
                        ? <NoData />
                        : <Table columns={columns} data={data}/> 
                    }
                </div> 
            </Container>  
        </>}   
    </>)
}